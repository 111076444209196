import axios from 'axios';
import { Callbacks, data } from 'jquery';
import { config } from './config'
import { browserHistory } from 'react-router';
let axioInstance = axios.create();
axioInstance.defaults.timeout = 50000;
let axioInstanceWithHeader = axios.create();
axioInstanceWithHeader.defaults.timeout = 50000;
axioInstanceWithHeader.defaults.headers['authorization'] = "Bearer " + localStorage.getItem('accessToken')
console.log(axioInstanceWithHeader.defaults.headers['authorization'], "dejjeow")
axioInstanceWithHeader.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    console.log('interceptor',response)
    return response;
  }, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // return Promise.reject(error);
    console.log('interceptor',error)
    if (error?.response?.data?.Error === 'invalid or expired jwt') {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('user')
        window.location.href = "/#/login";
    }
    return Promise.reject(error);
  });
axioInstance.interceptors.response.use(function (response) {
    console.log('interceptor',response)
    return response;
  }, function (error) {
    console.log('interceptor',error)
    if (error?.response?.data?.Error === 'invalid or expired jwt') {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('user')
        window.location.href = "/#/login";
    }
    return Promise.reject(error);
  });
export function registerFresherUser({ userName, emailId, password, cntLocation, phoneNumber, highestQualification,
    courses, specialization, passingYear, skills, collegeUniversity, additionalDetails, preferredWorkArea,
    professionalBackground, gender }) {
    const request = axios.post(config.BASE_URL + '/registration', {
        "userName": userName,
        "emailId": emailId,
        "password": password,
        "cntLocation": cntLocation,
        "phoneNumber": phoneNumber,
        "activationStatus": 0,
        "highestQualification": highestQualification,
        "courses": courses,
        "specialization": specialization,
        "collegeUniversity": collegeUniversity,
        "passingYear": passingYear,
        "skills": skills,
        "professionalBackground": professionalBackground,
        "preferredWorkArea": preferredWorkArea,
        "gender": gender,
        "additionalDetails": additionalDetails

    }).then(response => response.data)

    return {
        type: 'USER_REGISTER_FRESHER',
        payload: request
    }
}


// user API


export const getCurrentUser = (callBack = "") => axioInstanceWithHeader.get(config.BASE_URL + '/get_current_user')
    .then(res => {
        localStorage.setItem('user', JSON.stringify(res.data.data));
        callBack && callBack(res.data.data);
    })

export const userSignUp = (data) => axios.post(config.BASE_URL + '/registration', { ...data });

export const updateProfile = (data) => axioInstanceWithHeader.post(config.BASE_URL + '/update_profile', { userData: data });
export const updateUserReview = (data) => axioInstanceWithHeader.post(config.BASE_URL + '/update_userReview', { userData: data });
export const inviteForJob = (data,userId) => axioInstanceWithHeader.post(config.BASE_URL + '/hire_talent/sent_invitation', { receiver: data,sender:userId });
export const undoJobinvite = (data,userId) => axioInstanceWithHeader.post(config.BASE_URL + '/hire_talent/undo_invitation', { receiver: data,sender:userId });


export const userSignUpOtp = data => axios.post(config.BASE_URL + '/registration_otp', { ...data });
export const forgetPasswordOtp = data => axios.post(config.BASE_URL + '/forgetPassword_otp', { ...data });


export const forgotPasswordWithOtp = data => axios.post(config.BASE_URL + '/forgot_password_setup', { ...data });

export const verifyEmailUniqueness = emailId => axioInstanceWithHeader.get(config.BASE_URL + '/verify_email_uniqueness', { params: { emailId } });

export function searchUser(search) {
    axioInstance.defaults.headers['authorization'] = "Bearer " + localStorage.getItem('accessToken')
    return axioInstance.get(config.BASE_URL + '/user/search_user', { params: { search } });
}

export const updateNotificationRead = () => axioInstanceWithHeader.post(config.BASE_URL + '/update_notification', {});


////POST JOB FEATURE/////////////////////////////////////////////////////////////////////////////////////////

export function postJob(data) {
    axioInstance.defaults.headers['authorization'] = "Bearer " + localStorage.getItem('accessToken')
    return axioInstance.post(config.BASE_URL + '/jobpost', { ...data })
}

export const getJobList = data => {
    axioInstance.defaults.headers['authorization'] = "Bearer " + localStorage.getItem('accessToken')
    return axioInstance.get(config.BASE_URL + '/jobpost', { params: data });
}

export const getJobListUserSide = data => {
    axioInstance.defaults.headers['authorization'] = "Bearer " + localStorage.getItem('accessToken')
    return axioInstance.get(config.BASE_URL + '/jobpost/findjobs', { params: data });
}

export const getJobPostDetail = id => {
    axioInstance.defaults.headers['authorization'] = "Bearer " + localStorage.getItem('accessToken')
    return axioInstance.get(config.BASE_URL + '/jobpost/show', { params: { id } })
};

export const deleteJobPost = id => {
    axioInstance.defaults.headers['authorization'] = "Bearer " + localStorage.getItem('accessToken')
    return axioInstance.delete(config.BASE_URL + '/jobpost', { params: { id } })
}

export function publishJobPost(data) {
    axioInstance.defaults.headers['authorization'] = "Bearer " + localStorage.getItem('accessToken')
    return axioInstance.post(config.BASE_URL + '/publish_post', { ...data });
}


////////////////////////////////////////////////////////////////////////////////////////////////////////////////


export const userJobDetail = id => axioInstanceWithHeader.get(config.BASE_URL + '/jobpost/user_side_show', { params: { id } });

export const loginUser = ({ emailId, password }) => axioInstance.post(config.BASE_URL + '/login', { emailId: emailId, password: password })
export const auth2Login = ( data ) => axioInstance.post(config.BASE_URL + '/login/outh2', data)

export const saveJobs = id => axioInstanceWithHeader.post(config.BASE_URL + '/savejobs', { id });

export const getSavedJobs = () => axioInstanceWithHeader.get(config.BASE_URL + '/savejobs');

export const getAppliedobs = () => axioInstanceWithHeader.get(config.BASE_URL + '/appliedjobs');

export const addUserToFilm = data => axioInstanceWithHeader.post(config.BASE_URL + '/create_film', { ...data });

export const sendInvitation = data => axioInstanceWithHeader.post(config.BASE_URL + '/jobpost/sent_invitation', { ...data });

export const saveUser = data => axioInstanceWithHeader.post(config.BASE_URL + '/jobpost/save_user', { ...data });

export const shortlistUser = data => axioInstanceWithHeader.post(config.BASE_URL + '/jobpost/shortlist_user', { ...data });

export const hireUser = data => axioInstanceWithHeader.post(config.BASE_URL + '/jobpost/hire_user', { ...data });

export const getUsersForFilm = data => axioInstanceWithHeader.get(config.BASE_URL + '/get_users_for_film', { params: { ...data } })

export const sendInvitationLink = data => axioInstanceWithHeader.post(config.BASE_URL + '/send_invitation', { ...data });

export const getAllFilms = () => axioInstanceWithHeader.get(config.BASE_URL + '/get_all_films');

export const getFilm = id => axioInstanceWithHeader.get(config.BASE_URL + '/makefilm', { params: { id } });

export const getUserInformation = id => axioInstanceWithHeader.get(config.BASE_URL + '/get_userInformation', { params: { id } });

export const getTalentUserSide = data => axioInstanceWithHeader.get(config.BASE_URL + '/get_talent', { params: { ...data } });

////JOBAPPLICATION

export const getJobApplicationByJob = id => axioInstanceWithHeader.get(config.BASE_URL + '/jobapplication', { params: { id } });

export const getUserApplication = id => axioInstanceWithHeader.get(config.BASE_URL + '/get_job_application', { params: { id } });

export const withDrawApplication = id => axioInstanceWithHeader.patch(config.BASE_URL + '/jobapplication', { id });
export const editJobApplication = data => axioInstanceWithHeader.post(config.BASE_URL + '/editjobapplication',  data );

export const submitApplication = data => axioInstanceWithHeader.post(config.BASE_URL + '/jobapplication', { ...data });


///////////////////////////////Auditions////////////////////////////////////////////////////////////////////////////////////////////////

export const createAudition = data => axioInstanceWithHeader.post(config.BASE_URL + '/audition', { ...data });

export const deleteAudition = id => axioInstanceWithHeader.delete(config.BASE_URL + '/audition', { params: { id } })

export const getAuditionList = data => axioInstanceWithHeader.get(config.BASE_URL + '/audition', { params: data });

export const getAuditionDetails = id => axioInstanceWithHeader.get(config.BASE_URL + '/audition_details', { params: { id } });

export const sendInvitationAudition = data => axioInstanceWithHeader.post(config.BASE_URL + '/audition/sent_invitation', { ...data });

export const saveUserAudition = data => axioInstanceWithHeader.post(config.BASE_URL + '/audition/save_user', { ...data });

export const shortlistUserAudition = data => axioInstanceWithHeader.post(config.BASE_URL + '/audition/shortlist_user', { ...data });

export const searchAuditions = data => axioInstanceWithHeader.get(config.BASE_URL + '/audition/search_auditions', { params: data });

export const createAuditionApplication = data => axioInstanceWithHeader.post(config.BASE_URL + '/audition_application', { ...data });
export const hireTalent = (data) => axioInstanceWithHeader.post(`${config.BASE_URL}/hire_talent`, { ...data });
export const updateOrder = (data) => {
  return  axioInstanceWithHeader.post(`${config.BASE_URL}/update_hired_talent`, { ...data })
};
export const updateMilestoneCompleted = (data) => {
    return  axioInstanceWithHeader.post(`${config.BASE_URL}/updateMilestoneCompleteStatus`, { ...data })
  };

export const getHiredContracts = data => axioInstanceWithHeader.post(config.BASE_URL + '/get_hired_Contracts', { ...data });
export const updateMilestone = data => axioInstanceWithHeader.post(config.BASE_URL + '/update_milestone', { ...data });
export const getGstDetails = data => axioInstanceWithHeader.get(config.BASE_URL + '/getGstDetails');
export const getCommissionRate = data => axioInstanceWithHeader.get(config.BASE_URL + '/getCommissionRate');
export const getAllContracts = (pageIndex) => axioInstanceWithHeader.get(config.BASE_URL + '/getMyAllContracts?pageIndex='+pageIndex);

export const getUsersRole = userId => axioInstanceWithHeader.post(config.BASE_URL + '/getUserRole', { userId });
export const getUserList = userId => axioInstanceWithHeader.get(config.BASE_URL + `/getUserList`);
export const getUserTransactions = userId => axioInstanceWithHeader.get(config.BASE_URL + `/payment/getTransaction?userId=${userId}`);
export const addUserFunds = data => axioInstanceWithHeader.post(config.BASE_URL + `/fund/add`,data);
export const addUserFundsUsingUpi = data => axioInstanceWithHeader.post(config.BASE_URL + `/fund/add/UPI`,data);

export const getUserFunds = userId => axioInstanceWithHeader.get(config.BASE_URL + `/fund/getUserFund?userId=${userId}`);


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export function changePwdUser({ oldPassword, newPassword, id }) {
    const request = axios.post(config.BASE_URL + '/changePassword', {
        "oldPassword": oldPassword,
        "newPassword": newPassword,
        "id": id,
    }).then(response => response.data)

    return {
        type: 'USER_CHANGE_PASSWORD',
        payload: request
    }
}

//get forgot password key
export function forgotPwdUser({ emailId }) {
    const request = axios.post(config.BASE_URL + '/forgotPassword', {
        "emailId": emailId,
    }).then(response => response.data)

    return {
        type: 'USER_FORGOT_KEY',
        payload: request
    }
}


//post auditions
export function postAuditions({ userID, auditionTitle, auditionDate, timingFrom, timingTo,
    auditionsFor, isPaid, paymentDescription, descriptions, location, city, specialInstruct, verificationStatus,
    roleName, characterType, roleDescription, gender, playingAgeMin, playingAgeMax, performanceSkill,
    athleticSkills, accent, spokenLanguage, musicalInstruments, dance, physique, ethnicApperance,
    eyeColor, hairLength, hairColor, voiceType, uniqueTraits, heightFtFrom, heightInFrom, heightFtTo,
    heightInTo, weightMax, weightMin }) {
    const request = axios.post(config.BASE_URL + '/add/auditions', {
        "userID": userID,
        "auditionTitle": auditionTitle,
        "auditionDate": auditionDate,
        "timingFrom": timingFrom,
        "timingTo": timingTo,
        "auditionsFor": auditionsFor,
        "isPaid": isPaid,
        "paymentDescription": paymentDescription,
        "descriptions": descriptions,
        "location": location,
        "city": city,
        "specialInstruct": specialInstruct,
        "verificationStatus": verificationStatus,
        "roleName": roleName,
        "characterType": characterType,
        "roleDescription": roleDescription,
        "gender": gender,
        "playingAgeMin": playingAgeMin,
        "playingAgeMax": playingAgeMax,
        "performanceSkill": performanceSkill,
        "athleticSkills": athleticSkills,
        "accent": accent,
        "spokenLanguage": spokenLanguage,
        "musicalInstruments": musicalInstruments,
        "dance": dance,
        "physique": physique,
        "ethnicApperance": ethnicApperance,
        "eyeColor": eyeColor,
        "hairLength": hairLength,
        "hairColor": hairColor,
        "voiceType": voiceType,
        "uniqueTraits": uniqueTraits,
        "heightFtFrom": heightFtFrom,
        "heightInFrom": heightInFrom,
        "heightFtTo": heightFtTo,
        "heightInTo": heightInTo,
        "weightMax": weightMax,
        "weightMin": weightMin

    }).then(response => response.data)

    return {
        type: 'POST_AUDITIONS',
        payload: request
    }
}


//apply for an auditions
export function applyAuditions({ userID, auditionId, auditionTitle, applicantName, phoneNumber, email,
    city, state, pinCode, shortDescription, gender, performanceSkill,
    athleticSkills, accent, spokenLanguage, musicalInstruments, dance, physique, ethnicApperance,
    eyeColor, hairLength, hairColor, voiceType, uniqueTraits, heightFtFrom, heightInFrom, heightFtTo,
    heightInTo, weightMax, weightMin }) {
    const request = axios.post(config.BASE_URL + '/apply/auditions', {
        "userID": userID,
        "auditionId": auditionId,
        "auditionTitle": auditionTitle,
        "applicantName": applicantName,
        "phoneNumber": phoneNumber,
        "email": email,
        "city": city,
        "state": state,
        "pinCode": pinCode,
        "shortDescription": shortDescription,
        "gender": gender,
        "performanceSkill": performanceSkill,
        "athleticSkills": athleticSkills,
        "accent": accent,
        "spokenLanguage": spokenLanguage,
        "musicalInstruments": musicalInstruments,
        "dance": dance,
        "physique": physique,
        "ethnicApperance": ethnicApperance,
        "eyeColor": eyeColor,
        "hairLength": hairLength,
        "hairColor": hairColor,
        "voiceType": voiceType,
        "uniqueTraits": uniqueTraits,
        "heightFtFrom": heightFtFrom,
        "heightInFrom": heightInFrom,
        "heightFtTo": heightFtTo,
        "heightInTo": heightInTo,
        "weightMax": weightMax,
        "weightMin": weightMin

    }).then(response => response.data)

    return {
        type: 'APPLY_AUDITIONS',
        payload: request
    }
}
export const getAppliedAuditions= () => axioInstanceWithHeader.get(config.BASE_URL + '/get/appliedAuditions');


export const uploadFiles = (data,configs) => axioInstanceWithHeader.post(config.FILE_UPLOAD_URL + '/fileUpload', data,configs);

export const getInvoiceData = (transactionId) => axioInstanceWithHeader.get(config.BASE_URL + '/hire/getInvoiceData?transactionId='+transactionId);
export const generateInvoice = (body) => axioInstanceWithHeader.post(config.BASE_URL + '/hire/generateInvoice',body);
export const requestHiringAccess = body => axioInstanceWithHeader.post(config.BASE_URL + '/requestHiringAceess', body);
export const getHiringAccessById = body => axioInstanceWithHeader.post(config.BASE_URL + '/hiringAceessbyId', body);
export const getJobs = (data) => {
    return axioInstance.get(config.BASE_URL + '/jobpost/getAlljobs',{ params: data });
}
export const getLatestAuditions = data => axioInstanceWithHeader.get(config.BASE_URL + '/audition/getLatest', { params: data });
