import React from "react";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import ApplyAuditionForm from "../../components/ApplyAudition/ApplyAuditionForm";
import { Link } from "@material-ui/core";

const ApplyAuditionPage = ({ prevStep, nextStep, handleSubmit, ...props }) => {

  return (

    <>

      <Header {...props} />
      <SubHeader />
      
      <section id="postjob_detail" className="submit_application default_bg">
        <img src="images/make_film_bg.png" alt="img" className="bg_img" />
        <div className="container">
          <h3 className="main_head">Submit Application</h3>

          <ApplyAuditionForm {...props} />
          <div className="application_btn info_bx">

            <button className="stroke_btn" style={{ marginRight: "20px" }} onClick={() => props.history.push(`/UserSideAudition/${props.auditionDetails._id}`)}>Back</button>

            <button type="submit" className="blue_btn pull-right" onClick={handleSubmit}>Submit Application</button>
            {/* <button type="submit" className="default_btn pull-right" style={{ marginRight: "20px" }} onClick={(e) => handleSubmit(e, false)}>Save as Draft</button> */}
          </div>
        </div>
      </section>

      <Footer />

    </>
  );
}
export default ApplyAuditionPage;
