import React from "react";
import { Link } from 'react-router-dom';
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import AuditionListHeader from "../../components/AuditionDirectorSide/AuditionListHeader";
import AuditionListSidebar from "../../components/AuditionDirectorSide/AuditionListSidebar";
import AuditionList from "../../components/AuditionDirectorSide/AuditionList";

const AuditionListPage = props => (
  <div>
    <Header {...props} />
    <SubHeader />
    {/* CTA section start*/}
    <section id="cta" className="mid_info dark_clr inner_banner">
          <div className="container side_space">
          <div class="home_grey_bx clearfix"><img src="images/post_audition.png" alt="img" class="right_pattern" />
            <div className="row">   
            <div className="col-md-9 cta_info">
            <h2>BiG Casting Calls: Your Bollywood Break Awaits!</h2>
            <p>Find and apply casting calls, auditions and roles on Bollywood is Good (BiG), the most trusted platform for every artist and creator. Get started today!!</p>
            </div>
            </div>
            </div>
          </div>
      </section>
      {/* CTA section ends */}
    <section id="breakdown_list" className="dark_clr audition_list">
      <div className="container">
        <AuditionListHeader />
        <div className="row">
          <AuditionListSidebar {...props} />
          <AuditionList {...props} />
        </div>
      </div>
    </section>
    <Footer />
  </div>
);
export default AuditionListPage;
