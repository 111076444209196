/* eslint-disable default-case */
import React, { Component } from "react";
import { Link } from 'react-router-dom';
import PersonalInfo from "../../components/CreateUserProfile/PersonalInfo";
import Experience from "../../components/CreateUserProfile/Experience";
import AdditionalInfo from "../../components/CreateUserProfile/AdditionalInfo";
import RegisterHeader from "../../components/CreateUserProfile/RegisterHeader";
import PhysicalInfo from "../../components/CreateUserProfile/PhysicalInfo";
import EducationInfo from "../../components/CreateUserProfile/EducationInfo";
import TermsPopup from "../../components/CreateUserProfile/TermsPopup";
import { useTranslation } from "react-i18next";
import Award from "../../components/CreateUserProfile/Award";
import InvoiceInfo from "../../components/CreateUserProfile/InvoiceInfo";

function CreateUserProfilePage({ handleChange, nextStep, prevStep, handleChangeDropDown, submitForm, ...props }) {
  setTimeout(() => {
    localStorage.removeItem('profileStep');
  }, 2000);
  const { t } = useTranslation();
  switch (props.step) {
    case 1:
      return (
        // <>

        <section id="login" className="register_page create_profile_page">
          <div className="container-fluid side_space">

            <div className="stepwizard col-lg-8 col-md-12 col-sm-12 col-xs-12 pull-right">
              <div className="bg_box register_type clearfix diwdius">
                <RegisterHeader
                  changeRegisterStep={props.changeRegisterStep}
                  step={props.step}
                  {...props}
                />
                <PersonalInfo
                  nextStep={nextStep}
                  handleChange={handleChange}
                  handleChangeDropDown={handleChangeDropDown}
                  submitForm={submitForm}
                  {...props}
                />

              </div>
            </div>
          </div>
        </section>
      );
    case 2:
      return (
        <section id="login" className="register_page create_profile_page">
          <div className="container-fluid side_space">
            <div className="stepwizard col-lg-8 col-md-12 col-sm-12 col-xs-12 pull-right">
              <div className="bg_box register_type clearfix">
                <RegisterHeader
                  changeRegisterStep={props.changeRegisterStep}
                  step={props.step}
                />
                <EducationInfo
                  nextStep={nextStep}
                  prevStep={prevStep}
                  handleChange={handleChange}
                  handleChangeDropDown={handleChangeDropDown}
                  submitForm={submitForm}
                  {...props}
                />

              </div>
            </div>
          </div>
        </section>
      );
    case 3:
      return (
        <section id="login" className="register_page create_profile_page">
          <div className="container-fluid side_space">
            <div className="stepwizard col-lg-8 col-md-12 col-sm-12 col-xs-12 pull-right">
              <div className="bg_box register_type clearfix">
                <RegisterHeader
                  changeRegisterStep={props.changeRegisterStep}
                  step={props.step}
                />
                <Experience
                  {...props}
                  nextStep={nextStep}
                  prevStep={prevStep}
                  handleChange={handleChange}
                  handleChangeDropDown={handleChangeDropDown}
                  submitForm={submitForm}
                />

              </div>
            </div>
          </div>
        </section>
      );
    case 4:
      return (
        <section id="login" className="register_page create_profile_page">
          <div className="container-fluid side_space">
            <div className="stepwizard col-lg-8 col-md-12 col-sm-12 col-xs-12 pull-right">
              <div className="bg_box register_type clearfix">
                <RegisterHeader
                  changeRegisterStep={props.changeRegisterStep}
                  step={props.step}
                />
                <PhysicalInfo
                  nextStep={nextStep}
                  prevStep={prevStep}
                  handleChange={handleChange}
                  handleChangeDropDown={handleChangeDropDown}
                  submitForm={submitForm}
                  {...props}
                />

              </div>
            </div>
          </div>
        </section>
      );

    case 5:
      return (
        <section id="login" className="register_page create_profile_page">
          <div className="container-fluid side_space">
            <div className="stepwizard col-lg-8 col-md-12 col-sm-12 col-xs-12 pull-right">
              <div className="bg_box register_type clearfix">
                <RegisterHeader
                  changeRegisterStep={props.changeRegisterStep}
                  step={props.step}
                />
                <Award
                  nextStep={nextStep}
                  prevStep={prevStep}
                  handleChange={handleChange}
                  handleChangeDropDown={handleChangeDropDown}
                  submitForm={submitForm}
                  {...props}
                />

              </div>
            </div>
          </div>
        </section>
      );
      case 6:
        return (
          <>
            <section id="login" className="register_page create_profile_page">
              <div className="container-fluid side_space">
                <div className="stepwizard col-lg-8 col-md-12 col-sm-12 col-xs-12 pull-right">
                  <div className="bg_box register_type clearfix">
                    <RegisterHeader
                      changeRegisterStep={props.changeRegisterStep}
                      step={props.step}
                    />
                    <InvoiceInfo
                      nextStep={nextStep}
                      prevStep={prevStep}
                      handleChange={handleChange}
                      handleChangeDropDown={handleChangeDropDown}
                      submitForm={submitForm}
                      {...props}
                    />
  
                  </div>
                </div>
              </div>
            </section>
  
            <TermsPopup
              handleChange={handleChange}
              {...props}
            />
          </>
          // </>
        );
    case 7:
      return (
        <>
          <section id="login" className="register_page create_profile_page">
            <div className="container-fluid side_space">
              <div className="stepwizard col-lg-8 col-md-12 col-sm-12 col-xs-12 pull-right">
                <div className="bg_box register_type clearfix">
                  <RegisterHeader
                    changeRegisterStep={props.changeRegisterStep}
                    step={props.step}
                  />
                  <AdditionalInfo
                    nextStep={nextStep}
                    prevStep={prevStep}
                    handleChange={handleChange}
                    handleChangeDropDown={handleChangeDropDown}
                    submitForm={submitForm}
                    {...props}
                  />

                </div>
              </div>
            </div>
          </section>

          <TermsPopup
            handleChange={handleChange}
            {...props}
          />
        </>
        // </>
      );

  }

  return (
    <div className="container">

      <CreateUserProfilePage {...props} />
    </div>
  );
}

export default CreateUserProfilePage;
