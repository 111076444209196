import React from "react";
import LoginForm from "./LoginForm";
import FormFooter from "../../components/register/FormFooter";
 
const LoginPage = ({stateObj , LoginSubmit , ...props }) =>  <section id="login">
        <img className="login_bg" alt="img" src="images/cinema.jpg" />
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-7 login_form pull-right">
              <div className="bg_box clearfix">
                <LoginForm  LoginSubmit = {LoginSubmit} {...props}/>  
                {/* <FormFooter /> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    

export default LoginPage;
