import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight, FaTimes } from "react-icons/fa";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import '../../scss/celebrityManagement.scss'
import _ from "lodash";

class CampaignManagementPage extends Component {

  render() {
    return (
      <>
        <Header />
        <SubHeader /> 
          <section id="inner_celeb_banner">
            <div class="container">
              <div class="row">
                <div class="col-sm-6 col-xs-8 inner_celebrity_info">
                  <h1>CAMPAIGN MANAGEMENT</h1>
                  <p align="justify"> The next to do is create a complete campaign in place to reach your target audience, once you have a clear idea regarding your targeted market and consumer. To carried out efficiently the market campaign it’s important to plan down the minute information related to it whether it s big or small. A thorough budget and plan customized entirely to your business and market is what your business needs.

CONTACT US</p>
                
              <Link to="/ReachUs" class="stroke_btn">Contact US</Link>
                </div>
                <div class="col-sm-6 inner_celebrity_img">
                <img src="images/campaign-management-company.webp" alt="BollywoodisGood is best Celebrity Management Company in India" width="699" height="450" class="wow fadeInDown" data-wow-delay="0.3s" />	 
                </div>
              </div>
            </div>
          </section>

          <section id="inner_about">
          <div class="container">
          <div class="row">
          <div class="col-sm-5">
          <img src="images/digital-campaign-management.webp"  width="458" height="458" alt="Celebrity Management Services in India" />
          </div>
          <div class="col-sm-7 inner_about_info">
          <div class="inner_head">
          <h2>Campaign Management Services</h2>
          </div>
          <p align="justify">To willingly purchase your products and services this is the perfect plan you will guide your target customers. You are going to need wide knowledge, cautious planning, and a skillful team to built plans, and numerous businesses don’t have resources that are capable of doing these things. Therefore, BollywoodisGood has abundance of knowledge and experience in effectual <strong>campaign management</strong>, since our team does it every single day for the clients who are connected with us.</p>
          <br />              
          <p align="justify">BollywoodisGood team of experts indulge their time in conducting research, listening to clients and surveys earlier to the start of all marketing campaigns targeting the customers.</p>
          <br />
          <p align="justify">
    <b>Social Media Campaigns:</b> It is implemented in the order of social media strategy. Mostly the social media campaign is done on Instagram, Twitter, Weibo, Facebook, Renren etc. and these are for leads generations or brand engagement.
</p>
          </div>
          </div>
          </div>
          </section>

          <section id="our_role">
          <div class="container">
          <div class="row">
          <div class="col-sm-7 role_info">          
          <div class="inner_head">
          <h2>Campaign Management Company</h2>
          </div>
         <ul>
          <li><p align="justify"><strong> Inbound marketing Campaigns:</strong> Inbound marketing is all about promoting your company or brands via podcasts, eBooks, blogs, video, e-newsletters, SEO, social media marketing, , whitepapers and other forms of content marketing which provide to helps in bringing customers more close to the brand or company, where they want to be.</p></li>
          <br />
          <li><p align="justify"><strong>Online Display Ads:</strong> Joint with superior targeting options is Display banners like Interest targeting and device targeting that delivers not only direct response from target customers but also lifts the brand.</p></li>
         
          <br />
          <li><p align="justify"><strong>Search Campaigns:</strong> Mainly SEO campaigns are for enhancing organic search traffic rankings whilst PPC campaigns are implemented to make straight reply, either making online sales, downloads or developing leads. So if you looking for the <strong>Campaign Managemment company</strong>, BollywoodisGood is the best option for you.</p></li>
         </ul>
          </div>
          <div class="col-sm-5">
          <img src="images/campaign-managment-services.webp"  loading="lazy" width="458" height="459" alt="Artist Management Services" />
          </div>
          </div>
          </div>
          </section>  

          <section id="inner_services">
          <div class="container">
          <div class="text-center inner_head">
        <h2>BollywoodisGood - Digital Campaign Management</h2>
        </div>
          <p align="justify">Our <strong>campaign management services</strong> lean to pursue our marketing examination, and these service work completely together. BollywoodisGood utilizes the wide data we collect with the assistance of your team to build a perfectly fit strategy for your business. We will be able to analyze with the data that what your company have to do to attract the clients you want, by what means to connect them and convert them into faithful customers, and how to enhance your sales. The campaign that is developed by us has clear objectives and markers of success to assist you calculate your outcomes and efficiency. We will develop a campaign that will spell out the plan that you have made for your brand or company and exactly communicate what you want. We are here to put all your plans into actions.  </p>
        </div>
        
          </section> 

          <section id="inner_about">
          <div class="container">
          <div class="row">
          <div class="col-sm-5">
          <img src="images/email-campaign.webp"  loading="lazy" width="458" height="459" alt="Artist Management Services" />
          </div>
          <div class="col-sm-7 role_info">         
          <div class="inner_head">
          <h2>Influencer Marketing </h2>
          </div>
          <p align="justify">BollywoodisGood is skillful at analyzing exactly what kind of Return on Investment you can imagine, and what will make you achieve it as fast as possible. And via the process of your campaign implementation we will be there for adjustments, ongoing planning, and support. Your <strong>campaign management</strong> will be done by our team in order to make it more effective. Here are some strategies that we will follow:</p>
          <br />
          <ul>
          <li>Content Strategy</li>
          <li>eMail Strategy</li>
          <li>Marketing Strategy</li>
          <li>Paid Media Management</li>
          <li>Website Creation</li>
          <li>Nurturing Strategy</li>
          <li>Product and service offers</li>
          <li>SEO Tactics</li>
          <li>Social Media Strategy</li>
          <li>Re-Targeting Campaign Management</li>
          </ul>
              
          </div>
          </div>
          </div>
          </section>  

          

          <section id="last_info">
          <div class="container">
          <div class="row">
          <div class="col-xs-12">
          <blockquote> 
          <p align="justify">We will even let you know the values like cost per impression, cost per click, and cost per touch. This knowledge has been gained by us with years of experience and skilled team that we have. We will create and manage your campaign and will be there with you from beginning to end. 

So Get in touch with BollywoodisGood to experience the best <strong>Digital Campaign services</strong> in this field.
</p>
          </blockquote>
          </div>
          </div>
          </div>
          </section>  

          

        
         
        <Footer />
      </>
    );
  }
}

export default CampaignManagementPage;
