import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight, FaTimes } from "react-icons/fa";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import '../../scss/celebrityManagement.scss'
import _ from "lodash";

class CelebrityBrandEndorsement extends Component {

  render() {
    return (
      <>
        <Header />
        <SubHeader /> 
          <section id="inner_celeb_banner" className="dark_clr">
            <div class="container">
              <div class="row">
                <div class="col-sm-6 col-xs-8 inner_celebrity_info">
                  <h1>Celebrity Brand Endrosement</h1>
                  <p align="justify">It’s a fact that celebrity endorsement benefits both the parties’ i.e. the brand and the celebrities. But celebrities knows this truth that when they endorse any brand their fans use it because of the trust they have on that particular celebrity, hence with income the endorsement brings responsibility and can affect the stardom.</p>
                
              <Link to="/ReachUs" class="stroke_btn">Contact US</Link>
                </div>
                <div class="col-sm-6 inner_celebrity_img">
                <img src="images/celebrity-brand-endrosement.webp" alt="BollywoodisGood is best Celebrity Management Company in India" width="699" height="450" class="wow fadeInDown" data-wow-delay="0.3s" />	 
                </div>
              </div>
            </div>
          </section>

          <section id="inner_about">
          <div class="container">
          <div class="row">
          <div class="col-sm-5">
          <img src="images/celebrity-branding.webp"  width="458" height="458" alt="Celebrity Management Services in India" />
          </div>
          <div class="col-sm-7 inner_about_info">
          <div class="inner_head">
          <h2>Celebrity Brand Marketing Services</h2>
          </div>
          <p align="justify">No doubt that celebrities use endorsements to boost their star value. There are various examples of brands those have achieved astronomical increase in sales after celebrity endorsements and similarly there are many examples of endorsements that have helped and build a strong careers of celebrities. There are various companies that offer celebrity brand endorsement services but there are only few that offer great service and have a huge reputation in the market like BollywoodisGood.</p>
          <br/>
          <p align="justify">To BollywoodisGood, celebrity endorsement services come naturally. They are considered as one of the top notch celebrity brand endorsement companies and are vying for best online company in India tag. The celebrity brand endorsement services of BollywoodisGood are incomparable and trusted by many clients. Similarly our services are also in good demand and now we are dealing with some leading brands as our clients, therefore our clientele is ever expanding. Many top celebrities have hired us for endorsement of brands and are now our esteemed clients.</p>
          </div>
          </div>
          </div>
          </section>

          <section id="our_role">
          <div class="container">
          <div class="row">
          <div class="col-sm-7 role_info">
          <div class="inner_head">
          <h2>Celebrity Branding Services</h2>
          </div>
          <p align="justify">We feel pride that some big brands that have availed our celebrity brand endorsement services and have provided us with positive word of mouth publicity. The celebrities who believe that their stardom has been improved by endorsing brands of our clients have also shared positive feedback with others which have helped us in increasing our clientele. These feedbacks boost our confidence and make us believe in what we are doing. Getting satisfied customers is the aim of our company and when we receive that appreciation from our clients that increases the enthusiasm to work more.</p>
          <br />
          <p align="justify"><strong>Why choose BollywoodisGood for Celebrity Brand Endorsement services?</strong></p>
          <br />
          <ul>
            <li>We have one of the most popular and best celebrities for endorsements.</li>
            <li>BollywoodisGood has been very victorious Satellite media tours (SMTs) and Video News releases (VNRs) that helps in brand endorsement a lot.</li>
            <li>Our company has expertise in Print, Movie, TV, Sports and Internet endorsements.</li>           
          </ul>
          </div>
          <div class="col-sm-5">
          <img src="images/our_role_img.webp"  loading="lazy" width="458" height="459" alt="Artist Management Services" />
          </div>
          </div>
          </div>
          </section>  

          <section id="inner_services">
          <div class="container">
            <h3>WHY CELEBRITY BRAND ENDORSEMENT IS A MUST?</h3>
            <br />
          <p align="justify">For brand equity building celebrity brand endorsement is a best way and gives 100% results.

When it comes to ads, celebrity endorsements have great recall value and people remember the ads because if the celebrities and the story.

When a celerity endorse any brand, they make people trust it and as we all are very well aware with the fact that celebrities enjoy trust among large sections of society and its best for the brands to share this trust through celebrity endorsement.

Featuring a celebrity in a brand ad can make the ad stand out in the crowd and the brand gets the fans of that celebrity as their customers.</p>
          </div>
          </section>          

          <section id="inner_about">
            <div class="container">
            <div class="row">
            <div class="col-sm-5">
            <img src="images/bollywood_stars.webp" alt="img" />
            </div>
            <div class="col-sm-7 inner_about_info">
            <div class="inner_head">
            <h2>Here are the ways of Celebrity Endorsement</h2>
            </div>
            <p>The media route is the most common one celebrity brand endorsement. It can be done via Print, TV and internet and this gives best results. This way the brand enjoys great visibility and reach. The best part is that this method is easily captures the attention of common people.</p>
           <br />
            <p>Endorsement through T-shirt ads comes in the movie and sports event ads. The cars of various companies are being used by actors in the movies that gets attention of the people who watching it. This is considered as movie ad.</p>
            <br />
            <p>You must have noticed that these days the popular brands or the new one showcase its products by featuring it in the real life of celebrities. Though this method has least visibility but it is very effective. When fans notice that a particular brand is used by a celebrity in his real life, even they get the temptation to buy it.</p>
           
            </div>
            </div>
            </div>
          </section>
                      

        <section id="last_info">
        <div class="container">
        <div class="row">
        <div class="col-xs-12">
        <blockquote>
        <p align="justify">Hence, celebrity brand endorsement is a great method to enhance any brands and celebrities value. Get in touch with BollywoodisGood to experience the best services in this field.</p>
        </blockquote>
        </div>
        </div>
        </div>
        </section>
        <Footer />
      </>
    );
  }
}

export default CelebrityBrandEndorsement;
