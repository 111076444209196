import NotFound from "../pages/NotFound";
import React, { Component } from 'react';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        //Update state so the next render will show thw fallback UI
        return { hasError: true };
    }
    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error repoarting service
    }
    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <NotFound />;
        }
        return this.props.children;
    }
}
export default ErrorBoundary;