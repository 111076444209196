import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import NewsGallery from './NewsGallery';

class newsPart extends Component{
    constructor(props){
    super(props);
    this.state = {}
    }
    render(){
        return(
          <div>              
              {/* news section start */}
        <section id="news_gallery">
          <div className="container-fluid side_space">
            <div className="row">
              <div className="col-md-12 main_head">
                <h2>News Gallery</h2>
                <ul id="nav-tabs-wrapper" className="nav nav-tabs nav-pills">
                  <li className="active"><a href="#tab1" data-toggle="tab">Today</a></li>
                  <li><a href="#tab2" data-toggle="tab">This Week</a></li>
                  <li><a href="#tab3" data-toggle="tab">This Month</a></li>
                  <li><a href="#tab4" data-toggle="tab">Last 3 Month</a></li>
                </ul>
              </div>
              <div className="tab-content col-md-12">
                <div role="tabpanel" className="tab-pane fade in active" id="tab1">
                  <div className="row">
                    <div className="col-md-4 col-sm-12 big_img">
                      <a href="#">
                        <div className="hover_img">
                          <img src="images/box_office_banner.jpg" alt="img" />
                        </div>
                      </a>
                      <a href="#"><h5>Avengers: Endgame</h5></a>
                      <small>52 views &nbsp;   |   &nbsp; 10 months ago</small>
                    </div>
                    <div className="col-md-8 col-sm-12">
                      <div className="row">
                        <NewsGallery />    
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 text-center bottom_btn">
                <a href="#" className="blue_btn">View More</a>
              </div>
            </div>
          </div>
        </section>
        {/* news section ends */}
</div>
        );
    }
}

export default newsPart;