import React from 'react';
import { Link } from 'react-router-dom';
import { FaTimes, FaSuitcase, FaHeart, FaSearch, FaAngleLeft, FaAngleRight, FaLocationArrow, FaMoneyBill, FaClock,} from 'react-icons/fa';
import _ from 'lodash'

const AllApplicationTab = ({ currentUser,jobPostDetail,saveUser,shortlistUser,...props}) => { 
    console.log(jobPostDetail , "Dediwehiw")
        return(             
        <div className="tab-pane active" id="review_tab1">   
            <div className="listing_filter">
                <div class="form-group search_field">
                    <input type="search" placeholder="Search" class="form-control" />
                    <button type="button" class="default_btn"><FaSearch /></button></div>
                </div>                    
                <ul>
                {
                _.map(jobPostDetail?.appliedUsers, val => (
                    <li>
                        <div className="row">
                            <div className="people_img col-md-2">
                                <img src="images/akshay.jpg" alt="img" />
                                <span className="online"><div className="circle_icon"></div> </span>
                            </div>
                            <div className="people_list col-md-10">
                                <div className="people_list_info clearfix">
                                    <span className="name_title">{val.userName}</span>
                                    <h6>{val.workAndAwards?.roleDetails}</h6>
                                    <div className="people_short_info">
                                        <span><FaLocationArrow /><strong>{val.workAndAwards?.countryName}</strong></span>
                                        <span><FaClock /><strong>Rs 2500</strong> / hr</span>
                                        <span><FaMoneyBill /><strong>Rs 80k+</strong> earned</span>
                                    </div>
                                    <small> Earned Rs 0 on abc projects </small>
                                    <div className="skills">
                                        <p>9 relevant skills</p>
                                        
                                       {
                                           _.map(val.eduction?.skills , val1 => (
                                            <span> {val1}</span>
                                           ) )
                                       }
                                       {
                                           _.map(val.eduction?.specialization , val1 => (
                                            <span> {val1}</span>
                                           ) )
                                       }
                                        <Link to= {`/ClientSideApplication/${_.find(jobPostDetail.applications , {userId : val._id})?._id}`}><li>View More</li></Link>

                                    </div>
                                </div>
                                <div className="side_action_btn pull-right">
                                { _.find(jobPostDetail?.savedUsers , {_id : val._id}) && <span>Saved</span> || <div onClick = {() => saveUser(val._id)} className="icon"><FaHeart /></div>}
                                    { _.find(jobPostDetail?.shortListedUsers , {_id : val._id}) && <span>Shortlisted</span> || <a onClick = {() => shortlistUser(val._id)} style={{cursor:"pointer"}} className="stroke_btn">Shortlist</a>}
                                    
                                </div>
                            </div>
                        </div>
                    </li>
                ))
            }
            </ul>
                <div class="pagination_part clearfix text-right">
                    <br />
                    <ul class="pagination">
                    <li><Link to="#"><FaAngleLeft /> Prev</Link></li>
                    <li><Link to="#">1</Link></li>
                    <li><Link to="#">2</Link></li>
                    <li><Link to="#">3</Link></li>
                    <li><Link to="#">4</Link></li>
                    <li><Link to="#">5</Link></li>
                    <li><Link to="#"> Next <FaAngleRight /></Link></li>
                    </ul>
                </div>
        </div>  
        ); 
}
export default AllApplicationTab;