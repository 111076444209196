import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight, FaTimes } from "react-icons/fa";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import '../../scss/celebrityManagement.scss'
import _ from "lodash";

class CelebrityEvent extends Component {

  render() {
    return (
      <>
        <Header />
        <SubHeader /> 
          <section id="inner_celeb_banner" className="dark_clr">
            <div class="container">
              <div class="row">
                <div class="col-sm-6 col-xs-8 inner_celebrity_info">
                  <h1>Celebrity Event Management Company</h1>
                  <p align="justify">In the world of entertainment celebrities in are the faces of our nation. The renowned artists and sports heroes require assistance regarding their business negotiations as brand ambassadors.</p>
                
              <Link to="/ReachUs" class="stroke_btn">Contact US</Link>
                </div>
                <div class="col-sm-6 inner_celebrity_img">
                <img src="images/celebrity-event-management-company.webp" alt="BollywoodisGood is best Celebrity Management Company in India" width="699" height="450" class="wow fadeInDown" data-wow-delay="0.3s" />	 
                </div>
              </div>
            </div>
          </section>
          <section id="inner_about">
          <div class="container">
          <div class="row">
          <div class="col-sm-5">
          <img src="images/Celebrity-management-Services.webp"  width="458" height="458" alt="Celebrity Management Services in India" />
          </div>
          <div class="col-sm-7 inner_about_info">
          <div class="inner_head">
          <h2>Celebrity Event Management Services</h2>
          </div>
          <p align="justify">BiG's- Celebrity Event Organisers recognizes the talents in promising artists and the existing rocking and popular celebrities to endorse products and do public awareness via Corporate Shows & Concerts, guest appearance for the targeted consumer market. With celebrity managers who have excellent liaison with the rich and the popular in the world of fashion, movies and sports. Our clients are happy and satisfied with the services. Our managers are dedicated and happy to take new challenges to make the customers satisfied.</p>
          <br/>
          <p align="justify">Celebrity management is not only bringing together the Artists but also includes travel, security, planning logistics, taking permissions and technical stage knowledge and we as the complete celebrity event management company, is one stop solutions for Guest Appearance, Celebrity Management and Coordination from Television and Films.</p>
          </div>
          </div>
          </div>
          </section>
          <section id="our_role">
          <div class="container">
          <div class="row">
          <div class="col-sm-7 role_info">
          <div class="inner_head">
          <h2>Artist Event Management Services</h2>
          </div>
          <p align="justify">We offer Artist for: Marriage, Corporate Event, Ticketed Concerts, Sangeet, Live Concerts, Award Functions, Dealers Meet, Inaugurations, and International Shows.</p>
          <br />
          <p align="justify">We as the celebrity management company also manage a famous list of clientele including Movie Stars, TV stars, Sportsman and other popular personalities. Celebrity event management is one of the most esteemed services provided by the portal. In India the Bollywood celebrities are very important part of the society and we arrange events for these celebrities to make the event a magnificent one.</p>
          <br />
          <p align="justify">Celebrity event management services is one of the most required services by the clients. Some of the most famous celebrity management services offers management and coordination of actors, management and coordination of co-stars, management of artists, services offered to hosts as well as guests, comedians, management of singers, models, sports person, anchors, as well as TV star artists.</p>
          </div>
          <div class="col-sm-5">
          <img src="images/Bollywood-Celebrity-Event-Organisers.webp"  loading="lazy" width="458" height="459" alt="Artist Management Services" />
          </div>
          </div>
          </div>
          </section>

          <section id="inner_services">
          <div class="container">
          <div class="text-center inner_head">
          <h2>Bollywood Celebrity Event Organisers</h2>
          </div>
          <p align="justify">We have elite network of connection and this assists them in arranging and managing the famous level celebrities for any events. For any events celebrities are the center of attraction and our experienced staff members are competent of organizing such large events in the well-organized manner. The main motto is to do righteousness with the investment of the client, hence the best quality service is provided to all the clientele.Celebrity Event Organisers</p>
          <br />
          <p align="justify">Celebrities find the nitty-gritty of public life very demanding. Therefore, we make sure that they feel comfortable, whilst our devoted celebrity managers make arrangement for them to meet business connections, scheduling their appointments and re-scheduling if essential. We make sure the security and safety of the client. Our team of young Celebrity managers dedicatedly assists sports icons and divas with projects in a positive way as they cannot afford to make errors. It is being managed by us that the celebrities is giving a pleasing appearance that their fans are liking and appreciating, we even make sure that they give the right speech, at the right time, right place and right duration. BiG's celebrity manager will plan out all these activities to make the star appearance the highlight of the event</p>
          </div>
          </section>              

        <section id="last_info">
        <div class="container">
        <div class="row">
        <div class="col-xs-12">
        <blockquote>
        <p align="justify">We make sure that our clients are given proper care, molded and showcased in front of the camera effortlessness; it should be a cake walk for them. The managers look into the collaboration with brands, whether the payment is substantial and the brand they are endorsing will do well for their career. Our highly motivated team takes the client throughout a journey that is satisfying and even briefs them about a variety of activities for the day. We have a remarkable clientele and can assure you a star studded night for your occasion with a prearranged sparkle that will last throughout the evening.
<br/><br/>
The team is very professional and make sure that the client is very much at ease with us and do not have to face any sort of problems. The reputation of the client is our main priority and we assure that it enhances with every event, occasion and collaboration they make through us. Hence, if you are looking for a professional celebrity event management company then does consider BiG. Get in touch with us and we will take care of everything else. Our team will assist you and will guide you through the process and will make sure that the journey is hassle-free.</p>
        </blockquote>
        </div>
        </div>
        </div>
        </section>
        <Footer />
      </>
    );
  }
}

export default CelebrityEvent;
