import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PeopleSidebarList from '../people/PeopleSidebarList';

class peopleSidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <div>
<div id="filter-sidebar" className="col-xs-6 col-sm-3 visible-sm visible-md visible-lg collapse sliding-sidebar">
                                <h3>Refine Search</h3>
                                <div className="filter_points">
                                    <h6 data-toggle="collapse" data-target="#group-1" aria-expanded="true">
                                        <i className="fa fa-fw  fa-angle-right parent-collapsed" />
                                        Job category
                      </h6>
                                    <div id="group-1" className="list-group collapse in">
                                       <PeopleSidebarList />
                                    </div>
                                </div>
                                <div className="filter_points">
                                    <h6 data-toggle="collapse" data-target="#group-2" aria-expanded="true">
                                        <i className="fa fa-fw  fa-angle-right parent-collapsed" />
                                        Age Range
                      </h6>
                                    <div id="group-2" className="list-group collapse in">
                                    <PeopleSidebarList />
                                        <p className="list-group-item half_fields"><input type="text" placeholder="Min" /> to <input type="text" placeholder="Max" /> <input type="button" className="stroke_btn" defaultValue="Go" /></p>
                                    </div>
                                </div>
                                <div className="filter_points">
                                    <h6 data-toggle="collapse" data-target="#group-3">
                                        <i className="fa fa-fw  fa-angle-right parent-collapsed" />
                                        STARmeter
                      </h6>
                                    <div id="group-3" className="list-group collapse">
                                    <PeopleSidebarList />
                                        <p className="list-group-item half_fields"><input type="text" placeholder="Min" /> to <input type="text" placeholder="Max" /> <input type="button" className="stroke_btn" defaultValue="Go" /></p>
                                    </div>
                                </div>
                                <div className="filter_points">
                                    <h6 data-toggle="collapse" data-target="#group-4">
                                        <i className="fa fa-fw  fa-angle-right parent-collapsed" />
                                        Birthplace
                      </h6>
                                    <div id="group-4" className="list-group collapse">
                                        <p className="list-group-item full_field"><input type="text" placeholder="Enter City" /> <input type="button" className="stroke_btn" defaultValue="Go" /></p>
                                    </div>
                                </div>
                                <div className="filter_points">
                                    <h6 data-toggle="collapse" data-target="#group-3">
                                        <i className="fa fa-fw  fa-angle-right parent-collapsed" />
                                        Media
                      </h6>
                                    <div id="group-3" className="list-group collapse">
                                    <PeopleSidebarList />
                                    </div>
                                </div>
                                <div className="filter_points">
                                    <h6 data-toggle="collapse" data-target="#group-6">
                                        <i className="fa fa-fw  fa-angle-right parent-collapsed" />
                                        Physical Attributes
                      </h6>
                                    <div id="group-6" className="list-group collapse">
                                    <PeopleSidebarList />
                                    </div>
                                </div>
                                <div className="filter_points">
                                    <h6 data-toggle="collapse" data-target="#group-7">
                                        <i className="fa fa-fw  fa-angle-right parent-collapsed" />
                                        Skills
                      </h6>
                                    <div id="group-7" className="list-group collapse in">
                                    <PeopleSidebarList />
                                    </div>
                                </div>
                                <div className="filter_points">
                                    <h6 data-toggle="collapse" data-target="#group-8">
                                        <i className="fa fa-fw  fa-angle-right parent-collapsed" />
                                        Employment Details
                      </h6>
                                    <div id="group-8" className="list-group collapse">
                                    <PeopleSidebarList />
                                    </div>
                                </div>
                            </div>
            </div>
        );
    }
}

export default peopleSidebar;