import React from "react";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import UserWalletFilter from '../../components/UserWallet/UserWalletFilter'
import UserWalletList from '../../components/UserWallet/UserWalletList'
const UserWalletPage = ({...props }) => {
  return (
    <>
      <Header {...props} />
      <SubHeader />
      <section id="people_page" className="default_bg wallet_page">
        <img src="images/make_film_bg.png" alt="img" className="bg_img" />
        <div className="container side_space">
        <div className="row">
        <UserWalletFilter {...props} />

        <UserWalletList {...props} />
        </div>

        </div>
      </section>
      <Footer />
    </>
  );
};

export default UserWalletPage;
