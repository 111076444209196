import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import 'jquery/src/jquery';
import { FaCaretDown, FaFilm, FaList, FaLocationArrow, FaPencilAlt, FaSearch, FaSuitcase, FaUsers, FaRegUser, 
  FaRegEdit, FaRegEnvelopeOpen, FaPowerOff, FaRegCreditCard, FaThermometer, FaBars } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import styles from '../../../css/style.css';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import NotificationDropdown from '../../../components/Notification/NotificationDropdown';
import { connect } from 'react-redux';
import { compose } from "redux";
import { firestoreConnect } from 'react-redux-firebase';
import _ from 'lodash';
import { updateNotificationRead } from '../../../actions/index';


class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }


  handleUpdateNotifications = () => {
    updateNotificationRead();
  }

  //   case 'jobInvitation' : this.props.history.push(`./UserSideJob/${val.elementId}`); 
  //   break;
  // case 'jobShortlisted' : this.props.history.push(`./UserSideJob/${val.elementId}`); 
  //   break;
  // case 'jobHired' : this.props.history.push(`./UserSideJob/${val.elementId}`); 
  //   break;
  //   this.props.history.push(`./PostJobDetail/${val.elementId}`) ; 

  redirectPage = val => {
    console.log(val, 'gdfstd')

    if (this.props.history) {
      if (val.mainCategory === 'jobPost') {
        switch (val.subCategory) {
          case 'jobInvitation': this.props.history.push(`./UserSideJob/${val.elementId}`); break;
          case 'shortlisted': this.props.history.push(`./UserSideJob/${val.elementId}`); break;
          case 'hired': this.props.history.push(`./UserSideJob/${val.elementId}`); break;
          case 'jobApplication': this.props.history.push(`./PostJobDetail/${val.elementId}`); break;
        }
      }
      if (val.mainCategory === 'makeMyFilm') {
        switch (val.subCategory) {
          //case 'filmInvitation': this.props.history.push(`./UserSideJob/${val.elementId}`); break;
        }
      }

      if (val.mainCategory === 'Audition') {
        switch (val.subCategory) {
          case 'AuditionApplication': this.props.history.push(`/AuditionDetail/${val.elementId}`); break;
          case 'shortlisted': this.props.history.push(`/UserSideJob/${val.elementId}`); break;
          case 'hired': this.props.history.push(`/UserSideJob/${val.elementId}`); break;
          case 'jobApplication': this.props.history.push(`/PostJobDetail/${val.elementId}`); break;
          case 'NA': this.props.history.push(`/AuditionDetail/${val.elementId}`); break;
        }
      }
    }
  }

  render() {
    return (
      <div>
        <header>
          {/* upper header start */}
          <div className="top_head">
            <div className="container">
              <div className="row">
                <div className="top_nav">
                  <nav className="navbar">
                   <div>
                   <button className="navbar-toggle" type="button" data-toggle="collapse" data-target=".js-navbar-collapse"> <span className="sr-only">Toggle navigation</span> <span className="icon-bar" /> <span className="icon-bar" /> <span className="icon-bar" /> </button>
                      <Link to="/home" className="logo navbar-brand" ><img src="images/logo_1.png" alt="img" /></Link>
                   </div>
                   <div className='flex_bx'>
                   <div className='menu_items menu_drop nav navbar-nav'>
                     <div className="search_filed" style={{minWidth: 300}}>
                            <fieldset style={{position:'relative'}}>
                              <input type="text" placeholder="Search for a person, title, or company" />
                              <button type="button" className="btn search_button"><FaSearch  color='#ff3838'/></button>
                            </fieldset>
                          </div>
                          <li className="dropdown" style={{ position: 'relative', cursor: 'pointer' }} >
                          <a onClick={() => this.handleUpdateNotifications()} className="dropdown-toggle" data-toggle="dropdown"><img src="images/inbox.png" alt="img" />
                          </a>
                          <span onClick={() => this.handleUpdateNotifications()} className="dropdown-toggle noti-count" data-toggle="dropdown" >{_.filter(this.props.notifications, val => !val.read).length}</span>
                          <NotificationDropdown
                            notifications={this.props.notifications}
                            redirectPage={this.redirectPage} /> 
                        </li>
                        </div>
                    <div className="navbar-header collapse navbar-collapse js-navbar-collapse" style={{float:'right'}}>
                      {/* <div className="search mobile_view">
                        <fieldset>
                          <input type="text" placeholder="Search for a person, title, or company" />
                          <button type="button" className="btn"><i className="fa fa-search" /></button>
                        </fieldset>
                      </div> */}
                    
                     
                    {/* </div>

                    <div className="collapse navbar-collapse js-navbar-collapse text-right"> */}
                    
                      <ul className="nav navbar-nav menu_drop">                       
                        {/* <li><Link to="/yourPage">Your Page</Link></li>
                        <li><Link to="/news">News</Link></li>
                        <li><Link to="/help">Help</Link></li> 
                        <li>
                          <Link to="/auditions" data-toggle="tooltip" title="Auditions" data-placement="bottom">
                          <img src="images/casting.png" alt="img" /></Link>
                          </li> */}
                        
                        
                        <li className="dropdown single_drop">
                          <a href="#" className="dropdown-toggle" data-toggle="dropdown"><img src="images/user.png" alt="img" /> <i className="fa fa-angle-down" /></a>
                          <ul className="dropdown-menu">
                            {localStorage.getItem('user') && <li><Link to={`/userProfile/${JSON.parse(localStorage.getItem('user'))._id}`}> <span><FaRegUser /></span> User Profile</Link></li>}
                            {localStorage.getItem('user') && <li><Link to={`/userProfile/${JSON.parse(localStorage.getItem('user'))._id}?tab=5`}><span><FaThermometer /></span> Merit Score</Link></li>}
                            {localStorage.getItem('user') &&
                            <li><Link to="/CreateUserProfile"> <span><FaRegEdit /></span> Update Profile</Link></li>}
                            {/* <li><Link to="/chat">Chat</Link></li> */}
                            {localStorage.getItem('user') &&  <li><Link to="/wallet"> <span><FaRegCreditCard /></span> My Wallet</Link></li>}<br/>
                            <li><Link to="/ReachUs"> <span><FaRegEnvelopeOpen /></span> Contact Us</Link></li>
                            {localStorage.getItem('user') && <li><Link to="/login" onClick={() => { localStorage.removeItem('accessToken');localStorage.removeItem('user') }}> <span><FaPowerOff /></span> Logout</Link></li>}
                          </ul>
                        </li>
                        <li className="dropdown mega-dropdown">
                          <Link to="#" className="dropdown-toggle toggle-icon" data-toggle="dropdown"><FaBars /></Link>
                          <ul className="dropdown-menu mega-dropdown-menu">
                            
                            <li className="mega_links">
                            <h5>Auditions</h5>
                              <ul>
                              <li><Link to="/SearchAudition">Audition Near Me</Link></li>
                              <li><Link to="/CreateAudition">Post Audition</Link></li>
                              <li><Link to="/SearchAudition">Post Audition List</Link></li>
                              </ul>
                              
                              <h5>Hire</h5>
                              <ul>
                                <li><Link to="/FindTalent">Hire Talent</Link></li>
                                <li><Link to="/hiredContracts">Hired Talent List</Link></li>
                              </ul>
                              <h5>User Profile</h5>
                              <ul>
                                <li><Link to={`${localStorage.getItem('user') ? `/userProfile/${JSON.parse(localStorage.getItem('user'))._id}`: '/login'}`}>  Edit Profile</Link></li>
                                <li><Link to={`${localStorage.getItem('user') ? `/userProfile/${JSON.parse(localStorage.getItem('user'))._id}?tab=5`: '/login'}`}> Merit Score</Link></li>
                                <li><Link to="/wallet"> My Wallet</Link></li>
                              </ul>
                                                       
                             
                            </li>
                            <li className="mega_links">
                            <h5>Crew Member Job</h5>
                              <ul>
                                <li><Link to="/PostJob">Post Job</Link></li>
                                <li><Link to="/SearchJob">Search Job</Link></li>
                                <li><Link to="/JobList">Posted Job List</Link></li>
                              </ul>
                            {/* <h5>User</h5>
                              <ul>
                                <li><Link to="/CreateUserProfile">Create Profile</Link></li>
                              </ul> */}
                              {/* <h5>Other</h5>
                              <ul>
                                <li><Link to="/MakeFilm">Make Team/Film</Link></li>
                              </ul> */}
                              <h5>Celebrity Services</h5>
                              <ul>
                                <li><Link to="/CelebrityManagement">Celebrity Management</Link></li>
                                <li><Link to="/CelebrityEventManagement">Celebrity Event Management</Link></li>
                                <li><Link to="/CelebritySocialMediaManagement">Celebrity Social Media Management </Link></li>
                                {/* <li><Link to="/BollywoodCelebrityManagement">Bollywood Celebrity Management</Link></li> */}
                                <li><Link to="/CelebrityBrand">Celebrity Brand Endrosement</Link></li>
                              </ul>

                            </li>
                            <li className="mega_links">
                              <h5>Production Services</h5>
                              <ul>
                              <li><Link to="/AdFilmMaking">Ad Film Making</Link></li>
                              <li><Link to="/CreativeAdAgency">Creative Ad</Link></li>
                              <li><Link to="/PortfolioPhotoshootServices">Portfolio Shoot</Link></li>
                              <li><Link to="/VideoProductionCompany">Video Production</Link></li>
                              <li><Link to="/AnimatedVideoProductionCompany">Animated Video</Link></li>
                              <li><Link to="/MusicProductionCompany">Music Production</Link></li> 
                             
                                {/* <li><Link to="/SportsCelebrityManagement">Sports Celebrity Management</Link></li>
                                <li><Link to="/ModelingAgency">Modeling Agency</Link></li>
                                <li><Link to="/FilmTalentManagement">Film Talent Management</Link></li>
                                <li><Link to="/AdvertisingAgency">Ad Company</Link></li> */}
                                  
                              </ul>
                              <h5>Marketing Promotional Strategy</h5>
                              <ul>
                                {/* <li><Link to="/PromotionalStrategyBuilding">Promotional Strategy</Link></li> */}
                                <li><Link to="/CampaignManagement">Campaign Management</Link></li>
                                <li><Link to="/VideoMarketingCompany">Video Marketing</Link></li>
                                
                              </ul>
                            </li>
                            <li className="mega_links">   
                               {/* part of Marketing Promotional Strategy*/}
                                <ul>
                                  <li><Link to="/PaidAdvertisingAgency">Google Ads</Link></li>
                                  <li><Link to="/MobileMarketingCompany">Mobile Marketing</Link></li>
                                  <li><Link to="/YoutubeMarketingAgency">Youtube Marketing</Link></li>
                                  <li><Link to="/OutdoorAdvertisingCompany">Outdoor Ads</Link></li>
                                </ul>

                              <h5>Influencer Services  </h5>
                              <ul>
                                <li><Link to="/InfluencerMarketingCompany">Influencer Marketing</Link></li>
                                <li><Link to="/InfluencerAdNetwork">Influencer Ad Network</Link></li>
                                {/* <li><Link to="/DigitalInfluencerAgency">Digital Influencers</Link></li>                                 */}
                                </ul>

                                <h5>Influencer Categories</h5>
                              <ul>
                              <li><Link to="/TechInfluencerMarketing">Technology </Link></li>
                                <li><Link to="/SportsFitnessInfluencerMarketing">Sports & Fitness</Link></li>
                            
                                
                              </ul>
                            </li>
                            <li className="mega_links">
                            {/* part of  Influencer Categories*/}
                              <ul>
                                <li><Link to="/FashionInfluencerMarketing">Fashion</Link></li>
                                <li><Link to="/AutomotiveInfluencerCompany">Automotive</Link></li>
                                <li><Link to="/EntertainmenInfluencerMarketing">Movies, Movies & Games</Link></li>
                                <li><Link to="/HomeApplianceInfluencerMarketing">Home Appliances</Link></li>
                                <li><Link to="/AudioBooksInfluencerMarketing">Books & AudioBooks</Link></li>
                                <li><Link to="/BeautyHealthInfluencerMarketing">Beauty & Health</Link></li>
                                <li><Link to="/KitchenAppliancesInfluencerMarketing">Kitchen Appliances</Link></li>
                                <li><Link to="/BabyProductsInfluencerMarketing">Toys, Baby Products & Kids Fashion</Link></li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                        {
                          !localStorage.accessToken && <li><Link to="/login" className="stroke_btn">Login</Link></li>
                        }

                      </ul>
                      <ul className="nav navbar-nav ">
                       
                      </ul>
                    </div>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
            {/* upper header end */}

          </div></header>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  // if(!this.props.notifications || this.props.notifications.length === 0) return;
  let loggedInUserStr = localStorage.getItem('user');
  const loggedInUser = loggedInUserStr ? JSON.parse(loggedInUserStr): {}
  const allowNotifications = loggedInUser?.additionalInfo?.allowPushNotification;
  let filters = [];
  filters = loggedInUser?.additionalInfo?.pushNotificationFilter || [];
  let notificationList =[]
  const noti_list = []
  if(!allowNotifications){
    notificationList =[]
  }else{
    let initialList = state.firestore.ordered.notifications || []
    initialList = initialList.filter(e=> e.userId === JSON.parse(localStorage.getItem("user"))?._id || e.userId === '')
    initialList.forEach((d)=>{
      if(d.mainCategory === "Audition" && d.subCategory === 'AuditionApplication' && d.filter){
        let allow = false
        d.filter.forEach((fltr)=>{
          if(filters.includes(fltr)){
            allow = true;    
          }
        })
        if(allow)noti_list.push(d)
      }else{
        noti_list.push(d)
      }
    })
    notificationList = noti_list
  }
  console.log(noti_list,notificationList,'Notifications')
  return {
    notifications: notificationList
  }
};

export default compose(withRouter, connect(mapStateToProps),
  firestoreConnect([{
    collection: 'notifications',
    limit: 100,
    // where: [['userId', '==', `${JSON.parse(localStorage.getItem('user'))?._id}`],['userId', '==', '']], /// [[] , [] ,[]] // can give multiple conditions
    orderBy: ['createdAt', 'desc'] // order by read we will do //
  }])
)(Header);

