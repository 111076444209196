import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight, FaTimes } from "react-icons/fa";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import '../../scss/celebrityManagement.scss'
import _ from "lodash";

class VideoMarketing extends Component {

  render() {
    return (
      <>
        <Header />
        <SubHeader /> 
          <section id="inner_celeb_banner">
            <div class="container">
              <div class="row">
                <div class="col-sm-6 col-xs-8 inner_celebrity_info">
                  <h1>VIDEO MARKETING COMPANY</h1>
                  <p align="justify"> The 2nd most famous search engines is YouTube after Google, and in organic traffic, web pages with videos in them generally experience a 157% raise. In 2019, in the US videos has account for approximately 85% of online traffic. So <strong>hire video marketing company</strong> to enhance your business. 
        </p>
                
              <Link to="/ReachUs" class="stroke_btn">Contact US</Link>
                </div>
                <div class="col-sm-6 inner_celebrity_img">
                <img src="images/video-marketing-company.webp" alt="BollywoodisGood is best Celebrity Management Company in India" width="699" height="450" class="wow fadeInDown" data-wow-delay="0.3s" />	 
                </div>
              </div>
            </div>
          </section>

          <section id="inner_about">
          <div class="container">
          <div class="row">
          <div class="col-sm-5">
          <img src="images/video-marketing-services.webp"  width="458" height="458" alt="Celebrity Management Services in India" />
          </div>
          <div class="col-sm-7 inner_about_info">
          <div class="inner_head">
          <h2>VIDEO MARKETING SERVICES PORTFOLIO</h2>
          </div>
          <p align="justify">In case you are thinking to integrate video into your marketing plan, then you are making a right move, and your customers will surely going to thank you. Of making engaging videos for every sort of <strong>video marketing campaigns</strong> BollywoodisGood has a proven track record, consisting of website videos, social media videos, and video advertising. If you wish that your videos to fascinate or be capable of convincing on your audience, we are the perfect <strong>video marketing company</strong> for you. </p>
          <br />              
          <p align="justify"><strong>Video Marketing:</strong> The pass to improved Leads, increased Sales, and permanent Customers and it’s a fact that online <strong>video marketing services</strong> offer higher CTR on web pages if you go for it with help of a <strong>video marketing company</strong> that one’s without videos. <br />
You must be aware of the fact that numerous consumers who watch online product videos says that it is easier to understand the product or services and give them the confidence to make the purchasing decision.  
</p>
          </div>
          </div>
          </div>
          </section>

          <section id="our_role">
          <div class="container">
          <div class="row">
          <div class="col-sm-7 role_info">          
          <div class="inner_head">
          <h2>VIDEO MARKETING Agency</h2>
          </div>
          <p align="justify"> As per the study that users who watched an explanatory video surely brought the product or avail the services and you won’t believe the percentage its 75%. Now that’s a huge number.</p>
          <br /> 
          <p align="justify">Who watch videos they tend to stay on a website Audience on average 2 minutes longer and are 64% more expected to make a purchase.</p>
          <br /> 
          <p align="justify">Than traditional website a video by itself is 50 times more to be expected to emerge on the first page of Google. </p>
          <br /> 
          <p align="justify">“How-to” videos, reviews of products, or Company profile videos are verified methods to expand your real estate search engine and enhance the functionality of your business’ SEO campaign. </p>
          </div>
          <div class="col-sm-5">
          <img src="images/video-marketing-agency.webp"  loading="lazy" width="458" height="459" alt="Artist Management Services" />
          </div>
          </div>
          </div>
          </section>  

          <section id="inner_about">
          <div class="container">
          <div class="row">
          <div class="col-sm-5">
          <img src="images/viral-vieo-marketing.webp"  loading="lazy" width="458" height="459" alt="Artist Management Services" />
          </div>
          <div class="col-sm-7 role_info">
          <p align="justify"> <strong>BollywoodisGood</strong> is your companion if you are looking to enhance your brand publicity, create from your website more leads and calls, or merely promote your videos on your Facebook advertising campaign. The BiG’s expert professional team of video marketing has experience and expertise in:</p>
          <br />
          <ul>
          <li>Editing and post-production</li>
          <li>Video SEO</li>
          <li>Pre-production</li>
          <li>On-site shooting</li>
          <li>Video graphic creation</li>
          <li>Scriptwriting</li>
          <li>YouTube marketing</li>
          </ul>
          <br />
          <p align="justify"> Online get in touch with us for further details regarding our <strong>video marketing services for businesses.</strong></p>  
          </div>
          </div>
          </div>
          </section>

            <section id="our_role">
          <div class="container">
          <div class="row">
          <div class="col-sm-7 role_info">          
          <div class="inner_head">
          <h2>Our Video Marketing Services</h2>
          </div>
          <p align="justify">From our online <strong>video marketing company </strong>when you select <b>video marketing services</b>, we’ll assist you with every characteristic of your company’s video formation from beginning to end, including:</p>
          <br /> 
          <ul>
            <li>Professional video script copywriting</li>
            <li>On-site shot design, consultation, and filming</li>
            <li>Premium quality video publication and production</li>
            <li>Making of Video graphic</li>
          </ul>
          <br />
          <p align="justify">BollywoodisGood’s internal production team offers every video equipment that includes lighting and camera equipment.</p>
          <br />
          <p align="justify">Under 3 minutes for profile videos and in 60 seconds for ads the ideal marketing videos should be. Since short videos are better liked by the customers, they are further expected to watch your videos if they obtain a thought that you offer fast, concise details.</p>
          </div>
          <div class="col-sm-5">
          <img src="images/videoplay.webp"  loading="lazy" width="458" height="410" alt="Artist Management Services" />
          </div>
          </div>
          </div>
          </section>   

          <section id="inner_services">
          <div class="container">         
          <blockquote>
<p align="justify">
   <strong> In your marketing plan we suggest containing the following videos:</strong><br />
<strong>Competitor differentiators</strong> – Add the details that depicts what makes your products or service different from your competitors?<br />
<strong>Guarantees</strong> – A viewer can turn into a customer if he/she gets strong guarantees.<br />
<strong>Call to action</strong> – Along with the URL to the website where you wish them to redirect and you can even include a clear call to action.<br />
Hence, video marketing is one of the best medium to promote your services, brand or products in the market. 

</p>
</blockquote>
</div>
        
          </section> 

           
        <Footer />
      </>
    );
  }
}

export default VideoMarketing;
