import React, { Component, useState } from "react";
import { Link } from 'react-router-dom';
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import SearchAuditionFilter from "../../components/SearchAudition/SearchAuditionFilter";
import SearchAuditionList from "../../components/SearchAudition/SearchAuditionList";
import { FaSlidersH } from "react-icons/fa";

const SearchAuditionPage = (props) => {
  const [showFilter, setToggleFilter] = useState(true);

  return(  <div>
    <Header />
    <SubHeader />
     {/* CTA section start*/}
     <section id="cta" className="mid_info dark_clr inner_banner">
          <div className="container side_space">
          <div class="home_grey_bx clearfix"><img src="images/post_audition.png" alt="img" class="right_pattern" />
            <div className="row">   
            <div className="col-md-9 cta_info">
            <h2>BiG Casting Calls: Your Bollywood Break Awaits!</h2>
            <p>Find and apply casting calls, auditions and roles on Bollywood is Good (BiG), the most trusted platform for every artist and creator. Get started today!!</p>
            </div>
            </div>
            </div>
          </div>
      </section>
      {/* CTA section ends */}

    <section id="breakdown_list" className="default_bg audition_list">
      <div className="container">      
        <div className="row">
        <div className="col-sm-12 heading header_btn">
        <h2>Showcase Your Talent Opportunities!!</h2>    
       <div> 
       <button className="blue_btn" onClick={()=> window.history.back()}>Back</button>
        <Link to="/CreateAudition" className="blue_btn">Post Audition</Link>
        </div>  
        </div>
        <div className="filter-toggler" onClick={()=>{setToggleFilter(!showFilter)}}><button className="blue_btn"><FaSlidersH /> Filters </button></div>
          <SearchAuditionFilter {...props} {...{showFilter: showFilter, setToggleFilter: setToggleFilter}}/>
          <SearchAuditionList {...props} {...{showFilter: showFilter, setToggleFilter: setToggleFilter}}/>
        </div>
      </div>
    </section>
    <Footer />
  </div>
)};
export default SearchAuditionPage;
