import React from 'react';
import {View, StyleSheet } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceTableRow from './InvoiceTableRow'
import InvoiceTableBlankSpace from './InvoiceTableBlankSpace'
import InvoiceTableFooter from './InvoiceTableFooter'

const tableRowsCount = 11;

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderTopWidth: 1,
        borderColor: '#555',
    },
});

  const InvoiceItemsTable = ({invoice}) => {
    invoice.items.forEach(item => {
      if (invoice?.payer?.state !== invoice?.payee?.state) {
          item['gstPercent'] = item.taxRate.cgst + item.taxRate.sgst
      }else{
          item['gstPercent'] = item.taxRate.igst
      }
  });
    return(
    <View style={styles.tableContainer}>
        <InvoiceTableHeader />
        <InvoiceTableRow items={invoice.items} payee={invoice.payee} payer={invoice.payer}/>
        {/* <InvoiceTableBlankSpace rowsCount={ tableRowsCount - invoice.items.length} /> */}
        <InvoiceTableFooter items={invoice.items} payee={invoice.payee} payer={invoice.payer} />
    </View>
  )};
  
  export default InvoiceItemsTable