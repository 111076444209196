import React from "react";
import { Link } from 'react-router-dom';
import { FaRupeeSign, FaTimes, FaRegEnvelope, FaMapMarkerAlt, FaEye, } from 'react-icons/fa';
import _ from 'lodash';

const TeamList = props => (
    <div className="row">
        <div className="col-md-12 table_info_bx">
            <div className="shadow_bg clearfix">
                <div className="table_head">
                    <h5>Selected Team</h5>
                </div>
                <div className="table-responsive">
                    <table className="table people_table">
                        <tbody>

                            {
                                _.map(_.groupBy(props.filmData.categoryWiseUsers, 'category'), (val, key) => (
                                    <>
                                        <tr className="table_label">
                                            <td colSpan="5">{key}</td>
                                            <td colSpan="2" align="right"></td>
                                        </tr>
                                        {
                                            _.map(val, val1 => {
                                                const user = _.find(props.filmData.team, { _id: val1.userId })
                                                return (
                                                    <tr>
                                                        <td className="user_cat_info">
                                                            <img src="images/akshay.jpg" alt="img" />
                                                            <Link to="people_detail.php"><h5>{user.userName}</h5></Link>
                                                            <small>4.5 rating</small>
                                                        </td>
                                                        <td><p><small><FaMapMarkerAlt /> {user.city}</small></p></td>
                                                        <td><p><small>{user.phoneNumber ? String(user ?.phoneNumber) ?.slice(0, 4) + "*******" : ""}</small></p></td>
                                                        <td><p><small><FaRupeeSign /> 00000.00</small></p></td>
                                                        {
                                                            val1.invited ? <span>Invited</span> : <td onClick={() => props.onsendSingleInvitation(val1.userId)} ><FaRegEnvelope /> Invite</td>
                                                        }

                                                        <td><Link to={`UserProfile/${user._id}`} className="invite_icon"><FaEye /> Contact</Link></td>
                                                        <td onClick={() => props.onAddRemoveUser(user._id)}><FaTimes /></td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </>
                                ))
                            }


                        </tbody>
                    </table>

                </div>
            </div>

            <div className="all_contact_link btn_wraper">
                <Link to="/MakeFilmContact" className="blue_btn">View All Contact Details</Link>
            </div>
        </div>
    </div>
);

export default TeamList;