import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight, FaTimes } from "react-icons/fa";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import '../../scss/celebrityManagement.scss'
import _ from "lodash";

class OutdoorAdvertising extends Component {

  render() {
    return (
      <>
        <Header />
        <SubHeader /> 
          <section id="inner_celeb_banner">
            <div class="container">
              <div class="row">
                <div class="col-sm-6 col-xs-8 inner_celebrity_info">
                  <h1>OUTDOOR ADVERTISING</h1>
                  <p align="justify"> To grab attention of the audience, <strong>outdoor advertising</strong> is consisting of any sort of marketing that is done to do that. The most popular outdoor advertising is Billboard however there are a variety of other options, a lot of whirch nowadays possess digital components.</p>
              <Link to="/ReachUs" class="stroke_btn">Contact US</Link>
                </div>
                <div class="col-sm-6 inner_celebrity_img">
                <img src="images/outdoor-advertising-company.webp" alt="BollywoodisGood is best Celebrity Management Company in India" width="699" height="450" class="wow fadeInDown" data-wow-delay="0.3s" />	 
                </div>
              </div>
            </div>
          </section>

          <section id="inner_about">
          <div class="container">
          <div class="row">
          <div class="col-sm-5">
          <img src="images/outdoor-marketing-agency.webp"  width="458" height="458" alt="Celebrity Management Services in India" />
          </div>
          <div class="col-sm-7 inner_about_info">
          <div class="inner_head">
          <h2>Outdoor Advertising Services</h2>
          </div>
          <p align="justify">Certainly, the shades of <strong>outdoor advertising</strong> increase the meaning of this format to contain a complete bunch of stuffs you might never have even measured:</p>
          <br />
          <ul>
            <li>Experiential advertising, like the popular coffee shop prank</li>
            <li>Cars branded through advertiser imagery and logos</li>
            <li>Advertisements on park benches or in bus shelters</li>
            <li> Before you watch your film promotions in movie theaters</li>
          </ul>
          <br />
          <p align="justify">The list will never end. Now a day, you can discover a comprehensive number of instances of outdoor advertisements.</p>
          <br />
          <p align="justify">Just about everything is covered by the umbrella of outdoor advertising you search outside the home.</p>
          </div>
          </div>
          </div>
          </section>

          <section id="our_role">
          <div class="container">
          <div class="row">
          <div class="col-sm-7 role_info">          
          <div class="inner_head">
          <h2>Types of Outdoor Advertising</h2>
          </div>
         <p align="justify"> Since it reaches millions of people by mass transit, foot, or by car, <strong>outdoor media</strong> plays a vital in advertising world. It's generally very rapid and effective. Below are the universal type of <strong>outdoor media advertising</strong>:</p>
          <br /> 
          <ul>
            <li>Billboard advertising</li>   
            <li>Point of sale displays</li>   
            <li>As ambient media also referred as Guerrilla advertising</li>   
            <li> Hoardings.</li>   
            <li>Pole Kiosks</li>   
            <li> Bus Shelters.</li>   
            <li>Bus Panels (Inside & Outside the bus).</li>   
            <li>Train Panels (Inside & Outside).</li>   
            <li>Sole Railway Station Branding.</li>   
            <li>Complete Bus Exterior Branding.</li>   
            <li>Complete Exterior Local Train Branding in Mumbai.</li>          
          </ul>
          </div>
          <div class="col-sm-5">
          <img src="images/outdoor-branding-company.webp"  loading="lazy" width="458" height="459" alt="Artist Management Services" />
          </div>
          </div>
          </div>
          </section>  

          <section id="inner_services">
          <div class="container">     
          <div class="col-sm-12">
<div class="text-center inner_head">
<h2>Types of Outdoor Advertising</h2>

<div class="col-xs-6 full text-right left_points">
<p>
	 • Boards &amp; Glow Signs on Railway Stations<br />
	 • 3-D Displays (Balloons, Replicas)<br />
	 • Branding inside Express Trains<br />
	 • Metro Train Advertising <br />
     • Tram Outside branding <br />
	
	Airport Advertising •<br />
</p></div>
<div class="col-xs-6 full">
<p align="left">
•	Cinema Theatre Displays/ Mall Branding<br />
•	Dealer Boards, Exhibition Displays<br />
•	Auto Rickshaw – Panels &amp; Hood<br />
•   Branding on Postal Vans<br />
•	Mobile Hoardings<br />
•	Wall Paintings<br />

</p>
</div>
</div>
</div>
          </div>
        
          </section> 

           
          <section id="inner_about">
          <div class="container">
          <div class="row">
          <div class="col-sm-5">
          <img src="images/outdoor-marketing-services.webp"  width="458" height="458" alt="Celebrity Management Services in India" />
          </div>
          <div class="col-sm-7 inner_about_info">        
            <ul>
              <li>
                <h6>Outdoor Media</h6>
                <p align="justify">BollywoodisGood as a outdoor advertising services provides a wide variety of traditional as well as innovative multi-format media.
Stylishly designed billboards and panels located purposefully on foot-over-bridges, ducts, bridges and escalators for utmost visibility.</p></li>
          <br />
          <li>
                <h6>Retail Space & Activation</h6>
                <p align="justify">BollywoodisGood is recognized to generate modified outdoor solutions that can efficiently attain our clients' advertising aim. To assist our customers develop their business, we even offer retail spaces at the metro station. These spaces built opportunity in the new world and it’s the ideal method to make powerful small business endeavor. In addition, we are equipped with expert activation team that works constantly to boost the brands by generating out-of-the-box promotion stages.</p></li>
     
          </ul>
       
          </div>
          </div>
          </div>
          </section>

          

          <section id="last_info">
<div class="container">
<div class="row">
<div class="col-xs-12">
<blockquote>
<p align="justify"> <b> Inside Station Branding</b><br />
In the metro stations millions of Commuters features Innovative advertising that is places by BollywoodisGood such as wall wrap, train wraps, and running video ad films on LED screens. Further these successful advertising options, BollywoodisGood  also has traditional OOH media like panels placed purposefully on the bridges or smartly planned billboards, security posts, foot-over-bridges, escalators, mechanical fare collection gates, ticket selling counters and every other civil structures in the metro stations. <br /><br />
 <b>Airport Advertising</b><br />
Brands who are trying to reach international audience for them we BollywoodisGood prefer to do Airport advertising. Common airport advertising platforms:<br />
 <b>Wraps and Clings: </b>To approximately any surface containing walls, floors, ceilings, columns and more wraps and clings can be applied.<br />
 <b>Backlit displays:</b> In this type of advertisement large posters are placed that consequently grab the attention of those walking by.<br />
 <b>Banners: </b>In order to demand attention banners such as roadside billboards usually grab large amount of space. <br />
 <b>Digital (TVs/Tablet/etc.): </b>With moving graphics or interactive experiences Digital advertisements have the ability to catch people’s eyes.<br />
 <b>WiFi: </b>Numerous airports nowadays provide free WiFi, now Businesses can take this advantage and run their ads on WiFi landing pages.<br />
 <b>Benches/Shelters:</b> On either with wraps or posters a few airports also let benches and bus shelters to have advertising.

</p>
</blockquote>
</div>
</div>
</div>
</section>
           
        
           
        <Footer />
      </>
    );
  }
}

export default OutdoorAdvertising;
