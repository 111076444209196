import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  FaStarHalf,
  FaStar,
  FaAngleRight,
  FaAngleLeft,
  FaFilter,
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaLinkedin,
  FaYoutube,
} from "react-icons/fa";
import _, { isArray } from "lodash";
import UserProfileSidebar from "../../components/UserProfile/UserProfileSidebar";
import { config } from "../../actions/config";
import moment from "moment";
import { MeritFactor } from "../../Constants.js/meritFactor";
import { getMeritScore } from "../../functions/meritCalculation";
import MyContracts from "./MyContracts";
import Spinner from 'react-bootstrap/Spinner';
import { Modal } from "react-bootstrap";


const containerStyle = {
  backgroundImage: 'url("images/tag.png")',
  backgroundSize: 'contain', // Adjust as needed
  backgroundRepeat: 'no-repeat', // Adjust as needed
  width: '175px',
  height: '45px', // Set a desired height
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

const UserProfileDetail = ({ user,loginUser,inviteForJob,undoInviteHandler,usersRoleDetails,eligibleForHiring, ...rest }) => {
  // console.log(rest)
  const activeTab = rest.activeTab;
  const [meritFactor, setMeritFactor] = useState([])
  const [loader, setLoader] = useState(true)

  const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

  useEffect(() => {
    if(user && user._id){
     const MF =  getMeritScore(user)
      setMeritFactor(MF)
      setLoader(false)
    }
  }, [user]);
  // useEffect(() => {
  //   setMeritFactor(MeritFactor)
  // }, [MeritFactor])
  
  return (
    <section id="people_other_info" style={{position: 'relative'}}>
     {loader && <div style={{position:'absolute', left: '50%', top: '50%', zIndex:99}}>Loading...</div>}
        <div className="row">
          <div className="col-md-12">
            <div className="postjob_tab">
              <ul className="nav nav-tabs">
                <li className={activeTab === 1 ? 'active':''}>
                  <Link to="#tab_default_2" data-toggle="tab">
                    About
                  </Link>
                </li>
                <li className={activeTab === 2 ? 'active':''}>
                  <Link to="#tab_default_3" data-toggle="tab">
                    Work History{" "}
                  </Link>{" "}
                </li>
                <li className={activeTab === 3 ? 'active':''}>
                  <Link to="#tab_default_4" data-toggle="tab">
                    Skills & Expertise
                  </Link>
                </li>

                <li className={activeTab === 4 ? 'active':''}>
                  <Link to="#tab_default_5" data-toggle="tab">
                    Invoice Info
                  </Link>
                </li>
                <li className={activeTab === 5 ? 'active':''}>
                  <Link to="#tab_default_6" data-toggle="tab">
                    Merit score
                  </Link>
                </li>
                <li className={activeTab === 6 ? 'active':''}>
                  <Link to="#tab_default_7" data-toggle="tab">
                    My Contracts{" "}
                  </Link>
                </li>
                {/* <li className={activeTab === 6 ? 'active':''}>
                  <Link to="#tab_default_7" data-toggle="tab">
                    Certifications
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
          <div className="profile_area col-md-12 no_padding">
            <div className="col-md-12">
              <div className="shadow_bg clearfix" style={{position:'relative'}}>
                { loginUser?.emailId !== user?.emailId ?
                 <div className='text-right pt-3' >
                  {eligibleForHiring ? <Link to={`/HireTalent/${user?._id}`}>
                    <button className='secondary-button ml-auto' > Hire</button>
                  </Link>
                  : <button className='secondary-button ml-auto' onClick={()=>{setShow(true)}}> Hire</button>}
                  <a href="javascript:void">
                   {user?.jobInvites?.includes(loginUser._id)? <button className='blue_btn ml-auto' style={{borderRadius:20}} onClick={undoInviteHandler}> Invited</button>
                    :<button className='blue_btn ml-auto' style={{borderRadius:20}} onClick={inviteForJob}> Invite For job</button>}
                  </a>

                </div>  : null}
                <hr />
                <div className="tab-content">
                  <div className={`tab-pane ${activeTab === 1 ? ' active':''}`} id="tab_default_2">
                    <div className="tab_head">
                      <div className="tab_title portfolio-sec">
                        <div className="tag_text" style={{marginRight:10, paddingRight:10, borderRight:'1px solid gray'}}>
                        <h5>About</h5>
                        <p>{user?.bio}</p>
                        </div>
                        <div style={containerStyle} className="tag_part">
                        <h5 className="portfolio-id">{user?.portfolioId || 'portfolioId'}</h5>
                        </div>
                      </div>
                    </div>
                    <div className="tab_info">
                      {/* <h5>{user ?.physicalTraits ?.jobType}</h5>
                      <p>{user ?.additionalInfo ?.desiredRole}</p>
                      <br /> */}

                      <div className="tab_info">
                        <h5>Total Experience</h5>
                        <p>{user ?.physicalTraits ?.JobDescription}</p>
                                            </div>

                      <div className="job_list clearfix">
                        {
                          user ?.additionalInfo ?.specificIndividual &&

                            <ul>
                              <li>
                                <span>Desired Role You Are Looking For : </span>{" "}
                                {user ?.additionalInfo ?.desiredRole}
                              </li>
                            </ul>
                        }

                      </div>

                      <div className="job_list clearfix">
                        {
                          user ?.additionalInfo ?.specificIndividual &&

                            <ul>
                              {/* <li>
                                <span>Looking for to work with any specific individuals :</span>{" "}
                                {user ?.additionalInfo ?.specificIndividual}
                              </li> */}
                            </ul>
                        }

                      </div>

                      <div className="educational_info job_list clearfix">
                        <br />
                      <h5>Education</h5>
                      <hr />
                      <div className="row">
          {_.map(user ?.education, (val) => (
            <>
           
            <div className="col-md-6">
              <p>
                <strong>{val.collegeUniversity}  </strong>
                {val.location}
              </p>
              <p>{val.course}</p>
              {val.courseDuration &&
                < p > {val.courseDuration} Months</p>
              }
              <p>{val.passingYear}</p>
              <br />
              
              </div>
            </>
            
          ))}
          </div>
                      </div>
                     
                      {user?.education?.length && user?.education?.[0]?.skills ? (
                        <ul className="detail_list clearfix">
                          <p>
                            <strong>My skills includes</strong>
                          </p>
                          <li>{user ?.education ?.[0] ?.skills}</li>
                          {/* <li>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque.</li>
           <li>Dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam elore magnam .</li>
           <li>Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adore magnam aliquam quaerat voluptatem.</li>
           <li>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque.</li> */}
                        </ul>
                      ):''}

                      <br />

                     {user.profession === 'Artist or Actor or Talent' && <div className="job_list job_list_info clearfix">
                        <h5>Physical Information</h5>
                        <hr />
                        <ul>
                          {user ?.physicalTraits ?.height ?.from ?.feet && (
                            <li>
                              <span>Height:</span>
                              {user ?.physicalTraits ?.height ?.from ?.feet} ft{" "}
                              {user ?.physicalTraits ?.height ?.from ?.inch} in to{" "}
                              {user ?.physicalTraits ?.height ?.to ?.feet} ft{" "}
                              {user ?.physicalTraits ?.height ?.to ?.inch} in
                            </li>
                          )}

                          {user ?.physicalTraits ?.weight ?.min && (
                            <li>
                              <span>Weight:</span>{" "}
                              {user ?.physicalTraits ?.weight ?.min} kg to{" "}
                              {user ?.physicalTraits ?.weight ?.max} kg
                            </li>
                          )}

                          {user ?.physicalTraits ?.physique && (
                            <li>
                              <span>Physique:</span>
                              {user ?.physicalTraits ?.physique}
                            </li>
                          )}

                          {user ?.physicalTraits ?.eyeColor && (
                            <li>
                              <span>Eye Color:</span>{" "}
                              {user ?.physicalTraits ?.eyeColor}
                            </li>
                          )}

                          {user ?.physicalTraits ?.hairLength && (
                            <li>
                              <span>Hair color:</span>{" "}
                              {user ?.physicalTraits ?.hairColor}
                            </li>
                          )}

                          {user ?.physicalTraits ?.hairLength && (
                            <li>
                              <span>Hair Length:</span>{" "}
                              {user ?.physicalTraits ?.hairLength}
                            </li>
                          )}

                          {user ?.physicalTraits ?.voiceType && (
                            <li>
                              <span>Voice Type:</span>{" "}
                              {user ?.physicalTraits ?.voiceType}
                            </li>
                          )}

                          {user ?.physicalTraits ?.uniqueTraits && (
                            <li>
                              <span>Unique Traits:</span>
                              {user ?.physicalTraits ?.uniqueTraits}
                            </li>
                          )}
                          {user ?.physicalTraits ?.appearance && (
                            <li>
                              <span>Appearance or D/C:</span>
                              <small>{user ?.physicalTraits ?.appearance}</small>
                            </li>
                          )}
                        </ul>
                      </div>}
                      { user?._id === loginUser?._id  || user?.awards && user?.awards.length >0 ? <div className="job_list clearfix">
                        <br />
                        <h5>Awards</h5>
                        <hr />
                        <div className="row">
                          { user?.awards && user?.awards.length >0 ? _.map(user ?.awards, (val) => (
                            <>
                              <div className="col-md-6">
                                <div className="award_bx">
                                  <div className="award_pic">
                                    <a href={config.FILE_URL+val.awardImage} target="_blank" rel="noopener noreferrer">
                                    <img src={config.FILE_URL+val.awardImage} alt="img" />
                                    </a>
                                  </div>
                                  <div className="award_detail">
                                    <span>{val.awardYear}</span>
                                    <p>
                                      <strong>{val.awardName}</strong>
                                    </p>
                                    <small>{val.awardFor}</small>
                                    <small>
                                      Nominated for {val.nominatedFor ? val.nominatedFor :':  Yet to Submit'}
                                    </small>
                                    <small> Appearance Timing : {val.appearanceTime}</small>
                                    <small><a href={val.awardVedioLink} target="_blank">Award Url</a></small>

                                  </div>
                                </div>
                              </div>
                            </>
                          )) :  user?._id === loginUser?._id ?
                            <div className="col-md-6">
                              <div className="award_bx">
                                <div className="award_detail"><p>
                                 {"No Awards Added, Needs to update by user!"}
                                </p>
                                </div>
                              </div>
                            </div>:null
                        }
                        </div>
                      </div> :null}
                    </div>
                    <br />
                    <div className="job_list clearfix">
                      <hr />
                    <div className="col-sm-6">
                    <h5>Additional Info</h5>
                      <ul>
                        {user.dateOfBirth && (
                          <li>
                            <span>D.O.B:</span> {moment(user.dateOfBirth).format('DD MMM yyyy')}
                          </li>
                        )}

                        {user ?.additionalInfo ?.location && (
                          <li>
                            <span>Preferred Location:</span>{" "}
                            {user ?.additionalInfo ?.location}
                          </li>
                        )}

                        {user ?.additionalInfo ?.currentCompensationAmount && (
                          <li>
                            <span>Current Payout:</span>{" "}
                            {user?.country == "India"? 'INR': 'USD'} {user ?.additionalInfo ?.currentCompensationAmount}
                          </li>
                        )}

                        {
                          user ?.additionalInfo ?.expectedCompensationAmount &&
                            <li>
                              <span>Expected Payout:</span>
                              {user?.country == "India"? 'INR': 'USD'} {user ?.additionalInfo ?.expectedCompensationAmount}
                            </li>
}

                        {
                          user ?.additionalInfo ?.specificIndividual &&
                            <li>
                              <span>Looking for to work with :</span>{" "}
                              {user ?.additionalInfo ?.specificIndividual}
                            </li>
                        }

                        <li>
                          <span>Language :</span>
                          <br />
                          {user ?.languagesSpeak && <><span>Speak: </span> {user ?.languagesSpeak?.length ? user ?.languagesSpeak ?.join(', '): ' Yet to Submit'} <br /></>}
                          {user ?.languagesRead && <><span>Read: </span> {user ?.languagesRead.length ? user ?.languagesRead ?.join(', '): ' Yet to Submit'} <br /></>}
                          {user ?.languagesWrite && <><span>Write: </span> {user ?.languagesWrite.length ? user ?.languagesWrite ?.join(', '): ' Yet to Submit'} <br /></>}
                        </li>
                      </ul>
                    </div>
                    <div className="col-sm-6">
                    <h5>Social Media Profiles</h5>
                    <div className="flex just">
                   {user?.additionalInfo?.fbProfile && <FaFacebook size={'2em'} title="Facebook" color="#3b5998" style={{marginRight:10, cursor:'pointer'}} onClick={()=> window.open(user?.additionalInfo?.fbProfile)}/>}
                   {user?.additionalInfo?.instagramProfile && <FaInstagram size={'2em'} color="#8a3ab9" style={{marginRight:10, cursor:'pointer'}} onClick={()=> window.open(user?.additionalInfo?.instagramProfile)}/>}
                   {user?.additionalInfo?.twitterProfile && <FaTwitter size={'2em'} color=" #00acee" style={{marginRight:10, cursor:'pointer'}} onClick={()=> window.open(user?.additionalInfo?.twitterProfile)}/>}
                   {user?.additionalInfo?.linkedinProfile && <FaLinkedin size={'2em'} color="#0A66C2" style={{marginRight:10, cursor:'pointer'}} onClick={()=> window.open(user?.additionalInfo?.linkedinProfile)}/>}
                    {user?.additionalInfo?.youtubeProfile && <FaYoutube size={'2em'} color="#c4302b" style={{marginRight:10, cursor:'pointer'}} onClick={()=> window.open(user?.additionalInfo?.youtubeProfile)}/>}
                    </div>
                    </div>
                    <div className="col-sm-12">
                    <hr />
                    <h5>Profile Reviews</h5>
                    {user?.userReview && isArray(user?.userReview) && user?.userReview?.map((review)=>(
                    <div style={{marginBottom: 20}}>
                      <p><small>"{review.reviewMsg}"</small></p>
                      <div style={{display:'flex', justifyContent:'end'}}> <cite><i> {review.reviewerName}</i></cite></div>
                    </div>))}
                    </div>
                    </div>
                  </div>
                  <div className={`tab-pane ${activeTab === 2 ? ' active':''}`} id="tab_default_3">
                    <div className="tab_head">
                      <div className="tab_title">
                        <h5>Work History</h5>
                      </div>
                      {/* <div className="tab_links">
                        <li className="dropdown">
                          <a
                            href="#lala"
                            className="dropdown-toggle circle_btn"
                            data-toggle="dropdown"
                          >
                            {" "}
                            <FaFilter />{" "}
                          </a>
                          <ul className="dropdown-menu" role="listbox">
                            <li>
                              <Link to="/" role="option">
                                Newest first
                              </Link>
                            </li>
                            <li>
                              <Link to="/" role="option">
                                Highest rated
                              </Link>
                            </li>
                            <li>
                              <Link to="/" role="option">
                                Lowest rated
                              </Link>
                            </li>
                            <li>
                              <Link to="/" role="option">
                                Largest projects
                              </Link>
                            </li>
                          </ul>
                        </li>
                      </div> */}
                    </div>
                    <div className="tab_info client_his">
                      <ul>
                        {_.map(user.workAndAwards, (val) => (
                          <>
                            <li>
                              <div className="work_head">
                                <h5>{val.profession}</h5>
                                <h5>{val.projectCompanyName}</h5>
                                <p>
                                  <small>
                                    <strong>Role</strong> {val.roleType}
                                  </small>
                                  <small>
                                    <strong>Film Type</strong> {val.filmType}
                                  </small>
                                  <small>
                                    <strong>Industry</strong> {val.filmIndustry}
                                  </small>
                                  <small>
                                    <strong>Genre</strong> {val.genre}
                                  </small>
                                </p>
                              </div>
                              <p>{val.roleDetail}</p>
                              <p>
                                <strong>Work Video: </strong>{" "}
                                <Link to="">{val.videoLink}</Link>
                              </p>
                              <p>
                                <small>
                                  <strong>Loc.</strong> {val.location}
                                </small>
                                <small>
                                  <strong>Exp.</strong>
                                   {val.workExperience == "NaN Year" ? "": val.workExperience }
                                </small>
                                <small>
                                  <strong>Duration</strong> {val.duration}
                                </small>
                              </p>
                              <div className="project_shotinfo">
                                <div className="applicant_rating">
                                  <p>
                                    <div className="rating">
                                      <div className="star">
                                        <FaStar />
                                        <FaStar />
                                        <FaStar />
                                        <FaStar />
                                        <FaStarHalf />
                                        <strong>4.5</strong>
                                      </div>
                                    </div>
                                  </p>
                                </div>
                                <p>{val.fromDate} to {val.toDate}</p>
                              </div>
                            </li>

                            {/* <hr /> */}
                          </>
                        ))}
                      </ul>
                      {/* <hr /> */}
                      {/* <div className="pagination_part clearfix text-right">
                        <ul className="pagination">
                          <li>
                            <Link to="#">
                              <FaAngleLeft />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">1</Link>
                          </li>
                          <li>
                            <Link to="#">2</Link>
                          </li>
                          <li>
                            <Link to="#">3</Link>
                          </li>
                          <li>
                            <Link to="#">
                              <FaAngleRight />
                            </Link>
                          </li>
                        </ul>
                      </div> */}
                    </div>
                  </div>
                  <div className={`tab-pane ${activeTab === 3 ? ' active':''}`} id="tab_default_4">
                    <div className="tab_head">
                      <div className="tab_title">
                        <h5>Skills & Expertise</h5>
                      </div>
                    </div>
                    <div className="tab_info skills_part">
                      {/* <h5>Age</h5>
                          <span>Young Adult</span><span>Senior</span><span>Child</span> <span>Teenage</span> */}
                      {/* <h5>Voice Talent Deliverables</h5>
                          <span>Characters/Video Games</span><span>eLearning</span><span>Advertisement</span> <span>Trailer</span>
                          <span>Phone System</span><span>Audiobook</span>
                          <h5>Audio Editing Software</h5>
                          <span>Audacity</span><span>Ableton Live</span><span>Adobe Audition</span> */}
                      <h5>Video & Photo</h5>
                     { user ?.workAndAwards?.map(d =>{
                        return(
                          <a href={d.videoLink} target="_blank" rel="noopner noreferrer">{d.videoLink}</a>
                        )
                      })}
                      {/* <h5>Accents</h5>
                          <span>English - American</span><span>English (British)</span><span>English - Australian</span> */}
                    </div>
                  </div>
                  <div className={`tab-pane ${activeTab === 4 ? ' active':''}`} id="tab_default_5">
                    <div className="tab_head">
                      <div className="tab_title">
                        <h5>Invoice Related Informations</h5>
                      </div>
                    </div>
                    <div className="tab_info">
                      {/* <div className="tab_info">
                      </div> */}
                      <div className="job_list clearfix">
                        <h5>Address Details</h5>
                        <hr />
                            <ul>
                              <li>
                                <span>Address : </span>{" "}
                                {user?.invoiceInfo?.address || ' Yet to Submit'}
                              </li>
                              <li>
                                <span>Country :</span>{" "}
                                {user?.invoiceInfo?.country || ' Yet to Submit'}
                              </li>
                              <li>
                                <span>State :</span>
                                {user?.invoiceInfo?.state || ' Yet to Submit'}
                              </li>
                              <li>
                                <span>City :</span>
                                {user?.invoiceInfo?.city || ' Yet to Submit'}
                              </li>
                            </ul>
                      </div>
                      <br />
                      <div className="job_list clearfix">
                        <h5>GST Details</h5>
                        <hr />
                        <ul>
                            <li>
                              <span>GST:</span>
                              {user?.GST || ' Yet to Submit'}
                            </li>
                            <li>
                              <span>PAN:</span>{" "}
                              {user?.PAN || ' Yet to Submit'} 
                            </li>
                            <li>
                              <span>Name on PAN:</span>{" "}
                              {user?.nameOnPan || ' Yet to Submit'} 
                            </li>
                        </ul>
                      </div>
                      <div className="job_list clearfix">
                        <br />
                        <h5>BANK Details</h5>
                        <hr />
                        <ul>
                        <li>
                              <span>Beneficiary Name in Bank:</span>
                              {user?.invoiceInfo?.accountHolderName || ' Yet to Submit'}
                            </li>
                            <li>
                              <span>Account Number:</span>
                              {user?.invoiceInfo?.accountNo || ' Yet to Submit'}
                            </li>
                            <li>
                              <span>IFSC:</span>{" "}
                              {user?.invoiceInfo?.ifsc || ' Yet to Submit'} 
                            </li>
                            <li>
                              <span>Branch Name:</span>{" "}
                              {user?.invoiceInfo?.branchName || ' Yet to Submit'} 
                            </li>
                        </ul>
                      </div>
                    </div>
                    <br />
   
                  </div>
                  <div className={`tab-pane ${activeTab === 5 ? ' active':''}`} id="tab_default_6">
                    <div className="tab_head">
                      <div className="tab_title">
                        <h5>Profile Status </h5>
                      </div>
                    </div>
                    <div className="tab_info">
                     
                        {meritFactor.map(merit =>{
                          return(
                            <div className="score-container" >
                              <div>{merit.title}</div>
                              <div className="progress merit-score" style={{width: '90%'}}>
                                <div className="merit-score-bar" role="progressbar" aria-valuenow="70"
                                  aria-valuemin="0" aria-valuemax="100" style={{ width: merit.progress+"%" }}>
                                  {merit.progress >= 50 &&<span>{merit.progress}% Completed</span>}
                                  
                                </div>
                                {merit.progress < 50 && <span >{merit.progress}% Completed</span>}
                              </div>
                              {loginUser?.emailId === user?.emailId ? <span className="edit-merit" onClick={()=>localStorage.setItem('profileStep',merit.profileStep)}>
                                <Link to="/CreateUserProfile" >edit</Link> 
                              </span>:null}
                            </div>
                          )
                        })}
                     

                    </div>
                  </div>
                  <div className={`tab-pane ${activeTab === 6 ? ' active':''}`} id="tab_default_7">
                    <div className="tab_head">
                      <div className="tab_title">
                        <h5>My Contracts</h5>
                      </div>
                    </div>
                   {loginUser && <MyContracts currentUser={loginUser} />}
                  </div>
                  <div className={`tab-pane ${activeTab === 7 ? ' active':''}`} id="tab_default_7">
                    <div className="tab_head">
                      <div className="tab_title">
                        <h5>Certifications</h5>
                      </div>
                    </div>
                    <div className="tab_info certificate_info">
                      <ul>
                        <li>
                          <img src="images/title_detail_icon.png" alt="icon" />
                          <div className="course_info">
                            <h5>Diploms in Voice Acting</h5>
                            <p>Lorem ipsum institude, Mumbai</p>
                            <span>Issued December 2019</span>
                          </div>
                        </li>
                        <li>
                          <img src="images/title_detail_icon.png" alt="icon" />
                          <div className="course_info">
                            <h5>Diploms in Voice Acting</h5>
                            <p>Lorem ipsum institude, Mumbai</p>
                            <span>Issued December 2019</span>
                          </div>
                        </li>
                        <li>
                          <img src="images/title_detail_icon.png" alt="icon" />
                          <div className="course_info">
                            <h5>Diploms in Voice Acting</h5>
                            <p>Lorem ipsum institude, Mumbai</p>
                            <span>Issued December 2019</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <UserProfileSidebar user={user} /> */}
          </div>
        </div>
      { loginUser?.emailId === user?.emailId ? <div class="all_contact_link btn_wraper">
          <Link class="blue_btn" to="/CreateUserProfile">
            Edit Profile
          </Link>
        </div>: null}

        <Modal show={show} onHide={handleClose} animation={false} backdrop={false}>
        <div style={{backgroundColor: '#ffff' }}>
        <Modal.Header closeButton style={{backgroundColor: "#99ecf2"}}>
          <Modal.Title>Alert</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="skills_part">
            
            <h4>You are not eligible to hire right now ! </h4>
            <br />
            <p>Please contact to Admin for granting acsess</p>
            <br />
  
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="submit"
            className="blue_btn"
            data-dismiss="modal"
            onClick={() => {
              // if(isCompletedSelected){

              // }else{
                rest.requestHiringAccess();
              // }
              handleClose();
            }}>
            Request Permission
          </button>
        </Modal.Footer>
        </div>
      </Modal>
    </section>
  );
};

export default UserProfileDetail;
