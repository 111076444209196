import React from 'react';
import { Link } from 'react-router-dom';
import AuditionDetailData from './AuditionDetailData';
import AuditionDetailSidebar from './AuditionDetailSidebar'
import InviteforAudition from './InviteforAudition'
import AuditionApplication from './AuditionApplication'

const PostAuditionInfo = props => (
    <section id="postjob_detail" className="default_bg audition_people_list">
        <img src="images/post_job_bg.png" alt="img" className="bg_img" />
        <div className="container">
            <div className="row">
                <div className="col-xs-12 jobdetail_tab">
                    <div className='justify-between'>
                    <h3 className="main_head">Audition Details</h3>
                    <Link to="/SearchAudition" className="blue_btn">Back</Link>
                    </div>
                    <div className="postjob_tab">
                        <ul className="nav nav-tabs">
                            <li className="active"><Link to="#tab_default_1" data-toggle="tab">View Audition</Link></li>
                            <li><Link to="#tab_default_2" data-toggle="tab">Invite Peoples</Link></li>
                            {props?.usersRoleDetails?.roleName !== 'User' && <li><Link to="#tab_default_3" data-toggle="tab">Review Applications </Link></li>}
                        </ul>
                    </div>

                    <div className="tab-content">
                        <div className="tab-pane active" id="tab_default_1">
                            <div className="shadow_bg clearfix">
                                <div className="row">
                                    <AuditionDetailData {...props} />
                                    <AuditionDetailSidebar {...props} />
                                </div>
                            </div>
                        </div>
                        <InviteforAudition {...props} />
                        <AuditionApplication {...props} />

                    </div>
                </div>
            </div>
        </div>
    </section>
)
export default PostAuditionInfo;