import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PeopleTableData from '../people/PeopleTableData';

class peopleRightArea extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <div>
               <div className="col-sm-9 left_space">
                                <div className="shadow_bg clearfix">
                                    <div className="fiter_option">
                                        <h6>1,407,645 Results</h6>
                                        <div className="filter_fields">
                                            <div className="form-group">
                                                <label>Sort By :</label>
                                                <select className="form-control">
                                                    <option>STARmeter</option>
                                                    <option>Change this week</option>
                                                    <option>Age</option>
                                                    <option>Height</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <table className="table people_table">
                                        <thead>
                                            <tr>
                                                <th />
                                                <th>STARMETER</th>
                                                <th align="center">THIS WEEK</th>
                                                <th align="center">AGE</th>
                                                <th align="center">HEIGHT</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                          <PeopleTableData />
                                         
                                        </tbody>
                                    </table>
                                </div>
                            </div> 
            </div>
        );
    }
}

export default peopleRightArea;