import React, { Component } from "react";
import UserSideJobDetail from '../../components/UserSideJobDetail/Index';
import { userJobDetail } from '../../actions/index';
import _ from 'lodash';
class UserSideJobPage extends Component {

  state = { jobPostDetail: {} }

  componentDidMount() {
    if (this.props.match ?.params ?.id) {
      userJobDetail(this.props.match.params.id)
        .then(res => {
          const user = JSON.parse(localStorage.getItem('user'));
          const jobPostDetail = res.data ?.data;
          const jobApplication = _.find(jobPostDetail.job ?.applications , { userId: user._id })
          this.setState({
            jobPostDetail,
            currentUser: user,
            applied: _.find(user.appliedJobs, { _id: jobPostDetail.job._id }) ?._id,
            saved: _.find(user.savedJobs, { _id: jobPostDetail.job._id }) ?._id,
            jobApplication
          })
        });
    }
  }

  render() {
    console.log(this.state, JSON.parse(localStorage.getItem('user')), "dedheiwhui");
    return <UserSideJobDetail {...this.state} {...this.props} user={JSON.parse(localStorage.getItem('user'))} />
  }
}

export default UserSideJobPage

