import React, { Component } from 'react';
import { getInvoiceData } from '../../actions';
import AggregatorInvoiceToMMPage from '../../pages/AggregatorInvoiceToMM/AggregatorInvoiceToMM';
import {AggregatorInvoice} from '../../core/services/invoiceData'
class AggregatorInvoiceToMMContainer extends Component {
    state={invoiceData:null}
    componentDidMount (){
        console.log(this.props.transactionId)
        getInvoiceData(this.props.transactionId).then( res =>{
            if (res && res.data && res.data.data) {
                console.log(res.data)
                this.setState({invoiceData :res.data.data.aggMMInvoice})
            }
        })
    
    }
    render() {
        
        return (
            <div>
                {this.state.invoiceData &&<AggregatorInvoiceToMMPage invoice={this.state.invoiceData} transactionId={this.props?.transactionId || ''} />}
            </div>
        );
    }
}

export default AggregatorInvoiceToMMContainer;
