import React, { useEffect, useState } from 'react'
import PaymentButton from '../../components/Razorpay/PaymentButton/PaymentButton'
import { Modal } from 'react-bootstrap';
import { FormControl, InputLabel, TextField } from '@material-ui/core';

import './index.css'
import { addUserFunds, addUserFundsUsingUpi, getUserFunds } from '../../actions';
import axios from 'axios';
import { config } from '../../actions/config';
import swal from 'sweetalert';


// add fund using razorpay
// const AddFundModal = ({onPayment}) =>{
//     const [show, setShow] = useState(false);
//     const [amount, setAmount] = useState('');
//     const handleClose = () => setShow(false);
//     const handleShow = () => setShow(true);

//     const onPaymentDone =(data)=>{
//         console.log(data)
//         addUserFunds(data).then(res =>{
//             onPayment()
//         })
//         handleClose()
//     }
//     return (
//       <>
//         <button className='blue_btn' onClick={()=>handleShow()}>Add Funds </button>
//         <Modal show={show} onHide={handleClose} animation={false}>
//           <Modal.Header closeButton>
//             <Modal.Title>Add More Funds</Modal.Title>
//           </Modal.Header>
//           <Modal.Body>
//             <div className="skills_part">
            
//               <p>Enter Amount and proceed. </p>
//               <br />
//               <FormControl fullWidth variant={"outlined"} size="medium">
//                 {/* <InputLabel id="amount">Amount </InputLabel> */}
//                 <TextField id="outlined-basic" label="Amount" variant="outlined" color="primary" focused type={'number'} value={amount} onChange={(e)=>setAmount(e.target.value)}/>
//               </FormControl>
//             </div>
//           </Modal.Body>
//           <Modal.Footer>
//           <PaymentButton onPaymentDone={onPaymentDone} paybleAmount={amount} buttonText="Add Funds" buttonClass='blue_btn btn-sm' />
//           </Modal.Footer>
//         </Modal>
       
//       </>
//     );
//   };


// add fund using UPI
const AddFundModal = ({onPayment}) =>{
  const [show, setShow] = useState(false);
  const [amount, setAmount] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [disableButton, setDisableButton] = useState(false);
  const [txId, setTxId] = useState('');
  const [upiInfo, setUpiInfo] = useState()
  useEffect(() => {
    try {
        axios.get(`${config.BASE_URL}/admin/get/upi`).then((res) => {
            if (res && res.data && res.data && res.data.data) {
                setUpiInfo(res.data.data)
            }
        })
      } catch (ex) {
        console.log('upload error', ex);
      }
}, [])
  const onPaymentDone =()=>{
    const userString = localStorage.getItem('user')
    const user = userString? JSON.parse(userString):null
       const data = {
        orderCreationId: 'order_'+Date.now(),
        "userId": user._id,
        "amount": amount,
        "transactionId": txId,
        emailId: user.emailId,
        userName: user.userName
    };
    addUserFundsUsingUpi(data).then(res =>{
          onPayment()
          handleClose()
          swal("Successful", { buttons: false, timer: 2000, icon: "success" });
      }).catch((e)=>{
        handleClose()
      })
  }
  return (
    <>
      <button className='blue_btn' onClick={()=>handleShow()}>Add Funds </button>
      <Modal show={show} onHide={handleClose} animation={false} className="upi_pay">
        <Modal.Header closeButton>
          <Modal.Title>Add More Funds</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="skills_part" >
            <p><strong>Note :</strong>  <small>Scan below QR or use UPI ID to add the fund and submit transaction id. </small> </p> <br />
            <label className="control-label"> BIG UPI ID : <small>{upiInfo?.upiId}</small> </label>
            <br />
            <img src={`${config.FILE_URL+upiInfo?.qrUrl}`} alt="" />
            <br />
          </div>
          <div className="skills_part">
            <br />
            {/* <FormControl fullWidth variant={"outlined"} size="medium" >
            <TextField
              focused
              type="text"
              id="outlined-basic"
              label="Transaction Id" 
              variant="outlined" 
              color="primary" 
              placeholder="Enter Transaction id here"
              value={txId}
              onChange={(e)=> setTxId(e.target.value)}
              
            />
           </FormControl> */}
           <FormControl fullWidth variant={"outlined"} size="medium">
              {/* <InputLabel id="amount">Amount </InputLabel> */}
              <TextField id="outlined-basic" label="Transaction Id" focused variant="outlined" color="primary" type={'text'} value={txId} onChange={(e)=> setTxId(e.target.value)}/>
            </FormControl>
           <br />
            <br />
            <FormControl fullWidth variant={"outlined"} size="medium">
              {/* <InputLabel id="amount">Amount </InputLabel> */}
              <TextField id="outlined-basic" label="Amount" variant="outlined" color="primary" type={'number'} value={amount} onChange={(e)=>setAmount(e.target.value)}/>
            </FormControl>
           
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* Pay using upi */}
        <button className={'blue_btn btn-sm'} disabled={ disableButton || !txId || !amount} src={process.env.PUBLIC_URL+'icon/razorpay.png'} height={50} alt="logo" style={{cursor:'pointer'}} onClick={()=> onPaymentDone()} >
                      {'Add Funds'}
                </button>


          {/* Razorpay Button */}
        {/* <PaymentButton onPaymentDone={onPaymentDone} paybleAmount={amount} buttonText="Add Funds" buttonClass='blue_btn btn-sm' /> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

const MyFunds = () => {
const [userFunds, setUserFunds] = useState()
    useEffect(() => {
        getMyFunds()
    }, [])
    
    const getMyFunds =()=>{
        const userString = localStorage.getItem('user')
        const user = userString? JSON.parse(userString):null
        getUserFunds(user?._id).then(res =>{
            setUserFunds(res?.data?.data)
        }).catch(e=>{
            console.log(e)
        })
    }
    const onPayment=()=>{
        getMyFunds()
    }
    
    return (
        <div className='card-wrapper'>
            <div className='fund_info'>
                <div className='fund-text'>
                Available Fund : <span>&#8377;{Number(userFunds?.amount || 0).toFixed(2)}</span>
                </div>
                <div className='text-right'>
                    {/* <PaymentButton onPaymentDone={onPaymentDone}  className='blue_btn btn-sm' /> */}
                    <AddFundModal onPayment={onPayment}/>
                </div>
            </div>
        </div>
    )
}

export default MyFunds