import React from 'react';
import { Link } from 'react-router-dom';
import { FaTimes, FaSuitcase, FaHeart, FaSearch, FaAngleLeft, FaAngleRight, FaLocationArrow, FaMoneyBill, FaClock, } from 'react-icons/fa';
import _ from 'lodash';
import { config } from '../../actions/config';

const ShortlistAudition = ({AuditionDetail , ...props}) => {
    console.log(props, "Ddedhe");
    return (
        <div className="tab-pane" id="review_tab2">
            <div className="row">

                {
                    _.map(AuditionDetail.shortListedUsers, val => (
                        <div className="col-md-3 col-sm-4 col-xs-6 full">
                            <div className="celebrity_detail text-center clearfix">
                                <div className="people_img">
                                <img src={val.profilePic ? config.FILE_URL+val.profilePic : 'images/fill_user.png'} alt="img" />
                                    {/* <span className="online"><div className="circle_icon"></div> </span> */}
                                </div>
                                <div className="people_list">
                                    <div className="people_list_info clearfix">

                                        <h6>{val.userName}</h6>
                                        <div className="people_short_info">
                                            <span>{val.additionalInfo?.desiredRole}</span>
                                            <span>{val.additionalInfo?.location}</span>
                                        </div>
                                        <span className="cele_price">Rs {val.hourlyRate} / {val.additionalInfo?.expectedCompensationType}</span>
                                    </div>
                                    <div className="end_info double_action">
                                        <div className="icon"><FaHeart /></div>
                                        {/* <a style={{ cursor: "pointer" }} className="stroke_btn">Chat</a>
                                        <a style={{ cursor: "pointer" }} className="stroke_btn">Video Call</a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }


            </div>
            {/* <div class="pagination_part clearfix text-right">
                <br />
                <ul class="pagination dark_pagi">
                    <li><Link to="#"><FaAngleLeft /> Prev</Link></li>
                    <li><Link to="#">1</Link></li>
                    <li><Link to="#">2</Link></li>
                    <li><Link to="#">3</Link></li>
                    <li><Link to="#">4</Link></li>
                    <li><Link to="#">5</Link></li>
                    <li><Link to="#"> Next <FaAngleRight /></Link></li>
                </ul>
            </div> */}
        </div>
    );
}
export default ShortlistAudition;