import React, { Component } from "react";
import SignUpPage from "../../pages/SignUp/SignUpPage";
import { userSignUp } from '../../actions/index';
import swal from 'sweetalert';
import registrationConst from './registrationConst';
import _ from 'lodash';
export class SignUpContainer extends Component {

  state = { ...registrationConst, errors: {} };

  nextStep = () => this.setState(state => ({ step: state.step + 1 })); // Proceed to next step

  prevStep = () => this.setState(state => ({ step: state.step - 1 })); //Prev step

  changeRegisterStep = event => this.setState(state => ({ step: event }));

  handleChangeSingle = e => this.setState({ [e.target.name]: e.target.value }); // Handle fields change

  handleChangeDropDown = e => this.setState({ [e.name]: e.value }); // Handle fields change

  submitForm = e => {
    e.preventDefault();
    userSignUp(_.pickBy(_.omit(this.state, ['errors']), _.identity))
      .then(res => this.props.history.push('/'))
      .catch(e => {
        if (e ?.response ?.data ?.Error ?.errors) {
          let errors = {}
          _.map(e.response.data.Error.errors, (val, key) => {
            errors[key] = val.message;
          })
          this.setState({ errors });

        }
        else {
          swal(e.response.data.Error, { buttons: false, timer: 2000, icon: 'error' });
        }
      })
  };


  render() {
    return <SignUpPage
      {...this.state}
      changeRegisterStep={this.changeRegisterStep}
      handleChange={this.handleChangeSingle}
      handleChangeDropDown={this.handleChangeDropDown}
      prevStep={this.prevStep}
      nextStep={this.nextStep}
      submitForm={this.submitForm}
    />
  }
}

//const mapStateToProps = (state, props) => ({signupDetails: state.signup.signupData});
//const mapDispatchToProps = (dispatch , props ) => ({userSignUp: (signupData) => dispatch(Actions.registerProfessionalUser(signupData))});

//export default connect(mapStateToProps, mapDispatchToProps)(SignUpContainer);
export default SignUpContainer;

