import { MeritFactor } from "../Constants.js/meritFactor";

export const getMeritScore = (user) => {
  MeritFactor.forEach((e) => {
    switch (e.key) {
      case "awards":
        if (user.awards && user.awards.length) {
            e.progress =0;
            const weight = 100/user.awards.length
          user.awards.forEach((m) => {
            if (m.awardVedioLink) {
              e.progress += weight/2;
            }
            if (m.awardImage) {
              e.progress += weight/2;
            }
          });
        }
        break;
      case "physicalTraits":
        if (user.physicalTraits) {
          e.progress = 0;
          if (user.physicalTraits.height) {
            e.progress += 20;
          }
          if (user.physicalTraits.physique) {
            e.progress += 20;
          }
          if (user.physicalTraits.voiceType) {
            e.progress += 20;
          }
          if (user.physicalTraits.hairLength) {
            e.progress += 20;
          }
          if (user.physicalTraits.eyeColor) {
            e.progress += 20;
          }
        }
        break;
      case "profilePic":
        if (user.profilePic) {
          e.progress = 100;
        }
        break;
      case "education":
        if (user.education.length) {
          e.progress = 100;
        }
        break;
        case "phoneNumber":
            if (user.phoneNumber) {
              e.progress = 100;
            }
            break;
            case "videoLink":
                if (user.workAndAwards && user.workAndAwards.length) {
                    e.progress =0;
                    const weight = 100/user.workAndAwards.length
                    user.workAndAwards.forEach((wa) =>{
                        if (wa.videoLink) {
                            e.progress += weight;
                        }
                    })
                }
                break;
                case "taxInfo":
                  e.progress =0;
                  if(user.PAN){
                    e.progress  += 40;
                  }
                  if(user.GST){
                    e.progress  += 40;
                  }
                  if(user.nameOnPan){
                    e.progress  += 20;
                  }
                  break;
      default:
        break;
    }
  });
  return MeritFactor;
};
