import React, { Component } from "react";
import _ from "lodash";
import { useEffect } from "react";
import { getAllContracts } from "../../actions";
import { useState } from "react";

const UserProfileSidebar = ({ user }) => {
  const [totalPendingAmount, setTotalPendingAmount] = useState(0);
  const [totalEarnedAmount, setTotalEarnedAmount] = useState(0);
  const [totalContracts, setTotalContracts] = useState(0)

  useEffect(() => {
    getAllContracts()
    .then(res => {
      if(res && res.data && res.data?.data && res.data?.data.length){
        const contracts = res.data?.data;
        const myContrats = (contracts || []).filter(e =>e.isActive && (user._id === e.employerId ||  user._id === e.employeeId))
        setTotalContracts(myContrats.length)
        let pendingAmount = 0;
        let earnedAmount = 0;
        myContrats.forEach(con =>{
          con.milestone.forEach((milestone) =>{
            if (milestone.status === 'In Progress') {
              pendingAmount+= isNaN(milestone.milestoneAmount)?0: milestone.milestoneAmount 
            }
            if (milestone.status === 'Completed') {
              earnedAmount+= isNaN(milestone.milestoneAmount)? 0 : milestone.milestoneAmount
  
            }
          })
        })
        console.log(earnedAmount,'earnedAmount')
        setTotalEarnedAmount(earnedAmount)
        setTotalPendingAmount(pendingAmount);
      }
  
    })
  }, [user._id])
  return (
    <div className="col-md-12 side_details">
      <div className="shadow_bg clearfix">
        <div className="about_profile">
          <ul>
            <li>
              <span>Rs {+totalEarnedAmount.toFixed(2)} </span> <p>Earnings</p>
            </li>
            <li>
              <span>{totalContracts}</span> <p>Jobs</p>
            </li>
            <li>
              <span>Rs {+totalPendingAmount.toFixed(2)}</span> <p>Pending</p>
            </li>
          </ul>
        </div>

        <div className="people_contact profile_info_bx">
          <h6>Rate</h6>
          <h5>Rs {user ?.additionalInfo ?.currentCompensationAmount}/{user ?.additionalInfo ?.currentCompensationType}</h5>
        </div>

        <div className="people_contact profile_info_bx">
          <h6>Availability</h6>
          {user ?.additionalInfo ?.currentWorkStatus}
          <p>
            {user ?.additionalInfo ?.tillDate
              ? "Till Date : " + user ?.additionalInfo ?.tillDate
                : user ?.additionalInfo ?.fromAvailableHours
                  ? user ?.additionalInfo ?.fromAvailableHours + "to" + user ?.additionalInfo ?.toAvailableHours
                    : ""}
          </p> <br />
          {user ?.additionalInfo ?.currentWorkStatus == "Partially Available " ?
            <p>
              <p> <strong>Hours: </strong> {user ?.additionalInfo ?.hoursPerDay}</p>
              <p> <strong>Days:</strong> {_.map(user ?.additionalInfo ?.days, e=>(
                (<>
                  <span>{e +', '}</span>
                  </>)
              ))}</p>
            </p> :null
          }
 
         
          {/* <p>3+ days response time </p>                   */}
        </div>
     
      </div>
    </div >
  );
};
export default UserProfileSidebar;
