import React, { Component } from 'react';
import Header from '../../../components/shared/header/Header';
import Footer from '../../../components/shared/footer/Footer';
import PeopleSidebar from '../../../components/people/PeopleSidebar';
import PeopleRightArea from '../../../components/people/PeopleRightArea';
import PeopleBanner from '../../../components/people/PeopleBanner';

class TopStarMeter extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (

            <div>
                <Header {...this.props} />
                <PeopleBanner />
                <section id="all_list">
                    <div className="container">
                        <div className="row">
                            <PeopleSidebar />
                            <PeopleRightArea />
                        </div>
                    </div>
                </section>
                <Footer />
            </div>

        );
    }
}

export default TopStarMeter;