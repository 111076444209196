import React, { Component } from "react";
import { Link } from 'react-router-dom';
import PersonalInfo from "../../components/register/PersonalInfo";
import Experience from "../../components/register/Experience";
import AdditionalInfo from "../../components/register/AdditionalInfo";
import RegisterHeader from "../../components/register/RegisterHeader";
import { useTranslation } from "react-i18next";
import Award from "../../components/register/Award";
import FormFooter from "../../components/register/FormFooter";

function SignUpPage({ handleChange, nextStep, prevStep, handleChangeDropDown, submitForm, ...props }) {
  const { t } = useTranslation();
  switch (props.step) {
    case 1:
      return (
        <section id="login" className="register_page">
          <img className="login_bg" alt="img" src="images/cinema.jpg" />
          <div className="container">
            <div className="stepwizard col-md-6 pull-right">
              <div className="bg_box register_type clearfix">
                <RegisterHeader
                  changeRegisterStep={props.changeRegisterStep}
                  step={props.step}
                  {...props}
                />
                <PersonalInfo
                  nextStep={nextStep}
                  handleChange={handleChange}
                  handleChangeDropDown={handleChangeDropDown}
                  {...props}
                />
                <p className="text-center"
                  style={{ background: "#b7d2ef", margin: "20px 0 0", padding: "10px" }}>
                  {t("SinupAlreadyAc")}
                  <Link to="/Login"> <strong>{t("SignupFooterLoginLink")}</strong></Link>
                </p>
                <FormFooter />
              </div>
            </div>
          </div>
        </section>
      );
    case 2:
      return (
        <section id="login" className="register_page">
          <img className="login_bg" alt="img" src="images/cinema.jpg" />
          <div className="container">
            <div className="stepwizard col-md-6 pull-right">
              <div className="bg_box register_type clearfix">
                <RegisterHeader
                  changeRegisterStep={props.changeRegisterStep}
                  step={props.step}
                />
                <Experience
                  {...props}
                  nextStep={nextStep}
                  prevStep={prevStep}
                  handleChange={handleChange}
                  handleChangeDropDown={handleChangeDropDown}
                />
                <p className="text-center"
                  style={{ background: "#b7d2ef", margin: "20px 0 0", padding: "10px" }}>
                  {t("SinupAlreadyAc")}
                  <Link to="/Login"> <strong>{t("SignupFooterLoginLink")}</strong></Link>
                </p>
                <FormFooter />
              </div>
            </div>
          </div>
        </section>
      );

    case 3:
      return (
        <section id="login" className="register_page">
          <img className="login_bg" alt="img" src="images/cinema.jpg" />
          <div className="container">
            <div className="stepwizard col-md-6 pull-right">
              <div className="bg_box register_type clearfix">
                <RegisterHeader
                  changeRegisterStep={props.changeRegisterStep}
                  step={props.step}
                />
                <Award
                  nextStep={nextStep}
                  prevStep={prevStep}
                  handleChange={handleChange}
                  handleChangeDropDown={handleChangeDropDown}
                  {...props}
                />
                <p className="text-center"
                  style={{ background: "#b7d2ef", margin: "20px 0 0", padding: "10px" }}>
                  {t("SinupAlreadyAc")}
                  <Link to="/Login"> <strong>{t("SignupFooterLoginLink")}</strong></Link>
                </p>
                <FormFooter />
              </div>
            </div>
          </div>
        </section>
      );
    case 4:
      return (
        <section id="login" className="register_page">
          <img className="login_bg" alt="img" src="images/cinema.jpg" />
          <div className="container">
            <div className="stepwizard col-md-6 pull-right">
              <div className="bg_box register_type clearfix">
                <RegisterHeader
                  changeRegisterStep={props.changeRegisterStep}
                  step={props.step}
                />
                <AdditionalInfo
                  nextStep={nextStep}
                  prevStep={prevStep}
                  handleChange={handleChange}
                  handleChangeDropDown={handleChangeDropDown}
                  submitForm={submitForm}
                  {...props}
                />
                <p className="text-center"
                  style={{ background: "#b7d2ef", margin: "20px 0 0", padding: "10px" }}>
                  {t("SinupAlreadyAc")}
                  <Link to="/login"> <strong>{t("SignupFooterLoginLink")}</strong></Link>
                </p>
                <FormFooter />
              </div>
            </div>
          </div>
        </section>
      );
  }

  return (
    <div className="container">
      <SignUpPage {...props} />
    </div>
  );
}

export default SignUpPage;
