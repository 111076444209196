import React from "react";
import { Link, useHistory } from 'react-router-dom';
import { Grid, Typography } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { globalStyles } from "../../core/theme/Styles";
import Header from "../../components/shared/header/Header";
import Footer from "../../components/shared/footer/Footer";
import HomeSlider from "../../components/HomeSection/HomeSlider";
// import FlashNews from "../../components/HomeSection/FlashNews";
import MakeFilm from "../../components/HomeSection/MakeFilm";
import NewsPart from "../../components/HomeSection/newsPart/NewsPart";
import MovieStatus from "../../components/HomeSection/movieStatus/MovieStatus";
import BoxOffice from "../../components/HomeSection/boxOffice/BoxOffice";
import TopCards from "../../components/HomeSection/TopCards";
import AuditionPart from '../../components/HomeSection/auditionPart/AuditionPart';
import MidSection from '../../components/HomeSection/MidSection';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: theme.palette.page.background.color,
  },
}));

const popularTags=[
  {
  title: 'Popular Jobs',
  link:'/SearchJob'
},
{
  title: 'Movie Auditions',
  link:'/SearchAudition?filmType=Feature Film'
},
{
  title: 'Mumbai Auditions',
  link:'/SearchAudition?location=Mumbai'
},
{
  title: 'Talent Management Services',
  link:'/CelebrityManagement'
},
{
  title: 'Auditions Near Me',
  link:'/SearchAudition'
},
{
  title: 'Update profile',
  link:'/CreateUserProfile'
},
{
  title: 'Hire Talent',
  link:'/FindTalent'
},

{
  title: 'Post Jobs',
  link:'/PostJob'
},

]
function HomePage(props) {
  const history = useHistory()
  const classes = useStyles();
  const { t } = useTranslation();
  const globalStyle = globalStyles();

  const onTagClick =(tag)=>{
    if(localStorage.getItem('user')){
      history.push(tag.link)
    }else{
      history.push('/login')
    }
  }
  return (
    <div>
      <Header />
      <TopCards />
      <HomeSlider />
      <MovieStatus {...props}/>
      <AuditionPart {...props} />
      

      {/*<MidSection />
        <FlashNews />   
       <BoxOffice />
      <MakeFilm />  */}

       {/* CTA section start*/}
       <section id="cta" className="mid_info dark_clr">
          <div className="container side_space">
          <div class="home_grey_bx clearfix"><img src="images/cinema.jpg" alt="img" class="right_pattern" />
            <div className="row">   
            <div className="col-md-7 col-sm-8 pull-right cta_info">
            <h2>Say goodbye to finance headaches in Bollywood! BiG takes care of every invoice, GST, TDs, and more.</h2>
            <p>With BiG, producers, actors, and filmmakers can now focus solely on their creative endeavours while leaving all the financial complexities to us.</p>
            {!(localStorage.getItem('accessToken')) && <Link to="/login" className="blue_btn">Get Started Today</Link>}
            </div>
            </div>
            </div>
          </div>
        </section>
        {/* CTA section ends */}
        {/* popular tags section start*/}
      <section id="popular_tag" className="mid_info dark_clr">
          <div className="container side_space">         
            <div className="row">      
            <div class="head_part col-sm-12">
            <h2>Popular Tags</h2>           
            </div>      
            <div className="col-sm-6">          
            <img src="images/acting.jpg" alt="img" />
            </div>
            <div class="col-sm-6">               
                  <ul class="pill_result">
                   {popularTags.map((tag)=>
                     <li><span class="pill-text cursor-pointer" onClick={()=>onTagClick(tag)} >{tag.title}</span></li>
                   )}
                    {/* <li><span class="pill-text">Paid Jobs</span></li>
                    <li><span class="pill-text">Remote Acting Jobs</span></li>
                    <li><span class="pill-text">Kids Auditions</span></li>
                    <li><span class="pill-text">Extra Jobs</span></li>
                    <li><span class="pill-text">Netflix Auditions</span></li>
                    <li><span class="pill-text">Disney Auditions</span></li>
                    <li><span class="pill-text">Commercial Castings</span></li>
                    <li><span class="pill-text">Movie Auditions</span></li>
                    <li><span class="pill-text">UGC Jobs</span></li>
                    <li><span class="pill-text">New York City</span></li>
                    <li><span class="pill-text">Los Angeles</span></li>
                    <li><span class="pill-text">TV / Series</span></li>
                    <li><span class="pill-text">Singing Jobs</span></li>
                    <li><span class="pill-text">Dance Jobs</span></li>
                    <li><span class="pill-text">Reality</span></li> */}
                    </ul>
           </div>
           
            </div>
            </div>
        </section>
        {/* popular tags section ends */}

      {/* <NewsPart />*/}
     
           
      <Footer />
    </div>
  );
}
export default HomePage;
