import { createStore, applyMiddleware , compose } from "redux";
import rootReducers from "./rootReducers";
import apiMiddleware from './middleware/apiMiddleware';
import { reduxFirestore, getFirestore} from 'redux-firestore';
import {    reactReduxFirebase , getFirebase} from 'react-redux-firebase';
import thunk from 'redux-thunk';
import fbConfig from './fbConfig';
const store = createStore(rootReducers, 
    compose(
    applyMiddleware(thunk.withExtraArgument(getFirebase , getFirestore)),
    reduxFirestore(fbConfig),
    reactReduxFirebase(fbConfig)
    )
    );


export default store;