import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import FooterScript from '../footerScript/FooterScript';
import { FaFacebook, FaTwitter, FaLinkedin, FaPinterest, FaInstagram } from 'react-icons/fa';

class footer extends Component {
  constructor(props) {
    super(props);
    this.state = {}

  }

  render() {

    const token = localStorage.getItem("accessToken");

    return (
      <div>
        <footer>
          <div className="container side_space">
            <div className="row">
              {/* <div className="top_links text-center col-sm-12 col-xs-12">
                                <ul>
                                    <li><Link to="/yourPage">My Page</Link></li>
                                    <li><Link to="/castingNotice">Casting Notices</Link></li>
                                    <li><Link to="/people">People</Link></li>
                                    <li><Link to="/title">Title</Link></li>
                                    <li><Link to="/companies">Companies</Link></li>
                                    <li><a href="membership_ac.php">Account Settings</a></li>
                                    <li><Link to="/news">News</Link></li>
                                </ul>
                            </div> */}
              <div className="col-sm-12 col-xs-12">
                <div className="row pt-4">
                  <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12 foot_logo">
                    <Link to="/home">
                      <img src="images/logo_1.png" width={120} alt="img" />
                    </Link>
                    <div className='social_icons pt-4'>
                      <ul>
                        <li><a target='_blank' href="https://www.facebook.com/people/Bollywood-is-Good-BiG/100075786801309/"><FaFacebook /></a></li>
                        <li><a target='_blank' href="https://twitter.com/is_bollywood"><FaTwitter /></a></li>
                        <li><a target='_blank' href="https://www.linkedin.com/in/bollywood-is-good-big/"><FaLinkedin /></a></li>
                        <li><a target='_blank' href="https://www.instagram.com/bollywood_is_good_big/"><FaInstagram /></a></li>
                      </ul>
                    </div>
                  </div>
                  {/* <div className="col-lg-10 col-md-9 col-sm-8 col-xs-7">
                                       
                                    </div> */}
                  <div className="col-lg-10 col-md-9 col-sm-12 col-xs-12">
                    <ul className="footer-menu">

                      <li className="mega_links">
                        <h5 className='m_title'>Job</h5>
                        <ul>
                          <li><Link to={token ? "/PostJob" : '/login'}>Post Job</Link></li>
                          <li><Link to="/SearchJob">Search Job</Link></li>
                          <li><Link to="/JobList">Post Job List</Link></li>
                        </ul>

                      </li>
                      <li className="mega_links">
                        <h5 className='m_title'>Auditions</h5>
                        <ul>
                          <li><Link to={token ? "/CreateAudition" : '/login'}>Post Audition</Link></li>
                          <li><Link to="/SearchAudition">Post Audition List</Link></li>
                          <li><Link to="/SearchAudition">Audition Near Me</Link></li>
                        </ul>
                      </li>
                      <li className="mega_links">
                        <h5 className='m_title'>Hire Talent</h5>
                        <ul>
                          <li><Link to={token ? "/FindTalent" : '/login'}>Hire Talent</Link></li>
                          <li><Link to={token ? "/hiredContracts" : '/login'}>Hired List</Link></li>
                        </ul>
                      </li>
                      <li className="mega_links">
                        <h5 className='m_title'>User</h5>
                        <ul>
                          <li>
                            {token ?
                              <Link to="/CreateUserProfile">Update Profile</Link> :
                              <Link to='/NewSignup'>Create Profile</Link>
                            }</li>

                        </ul>
                      </li>
                      <li className="mega_links">
                        <h5 className='m_title'>Other</h5>
                        <ul>
                          <li><Link to={token ? "/MakeFilm" : '/login'}>Make Team/Film</Link></li>
                          <Link to={token ? "/wallet" : '/login'}>Invoices </Link>

                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-12 col-sm-12 col-xs-12 copyright text-center"> <small>©2023 BollywoodisGood(BiG) | All Rights Reserved</small>
                    {/* <ul>
                                            <li><Link to="/privacy">Privacy Policy</Link></li>
                                            <li><Link to="/help">Help</Link></li>
                                        </ul> */}
                  </div>
                </div>
              </div>
              {/* <div className="col-sm-4 footer_info_part col-xs-12">
                                <h3>Lorem ipsum dolor sit</h3>
                                <p>Subscribe to our newsletter and get unique alerts.</p>
                                <a href="#" className="stroke_btn">Sign Up</a>
                            </div> */}
            </div>
          </div>
        </footer>

        <FooterScript />
      </div>
    );
  }
}

export default footer;