import React, { Component } from "react";
import ApplyAuditionPage from "../../pages/ApplyAudition/ApplyAuditionPage";
import { getAuditionDetails, createAuditionApplication, uploadFiles } from '../../actions/index';
import _ from 'lodash';

class ApplyAuditionContainer extends Component {

  state = { step: 1, auditionDetails: {}, introductionVideoUrl: "", scriptVideoUrl: "", askQueries: "", auditionId: this.props.match ?.params ?.id };


  handleChangeValues = e => {
    this.setState({ [e.target.name]: e.target.value })
  };


  componentDidMount() {
    if (this.props.match ?.params ?.id) {
      getAuditionDetails(this.props.match.params.id)
        .then(res => this.setState({ auditionDetails: res.data ?.data }));
    }

  }

  handleSubmit = (e, published) => {
    console.log(e, "sdwwd")
    e.preventDefault()
    createAuditionApplication({ ...this.state, published })
      .then(res => this.props.history.push('/SearchAudition'));

  };
  fileSelectedHandler = async(e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    const configs = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    const targetName = e.target.name;
    try {
      const res = await uploadFiles(formData,configs)
      if (res && res.data && res.data.data) {
        this.setState({ [targetName]: res.data.data.filename });
      }
    } catch (ex) {
      console.log('upload error', ex);
    }
  }

  
  render() {

    return <ApplyAuditionPage
      {...this.state}
      {...this.props}
      nextStep={this.nextStep}
      prevStep={this.prevStep}
      handleChangeValues={this.handleChangeValues}
      handleSubmit={this.handleSubmit}
      fileSelectedHandler={this.fileSelectedHandler}
    />
  }
}

export default ApplyAuditionContainer;
