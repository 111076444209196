import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import store from './core/store';
import {rrfProps} from './core/store';
import { Provider } from 'react-redux';
import i18n from './core/i18n/i18n';
import { I18nextProvider } from "react-i18next";
import theme from './core/theme/defaultTheme';
import { ThemeProvider } from '@material-ui/core/styles';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';

import TimeAgo from 'javascript-time-ago'

import en from 'javascript-time-ago/locale/en.json'
import ru from 'javascript-time-ago/locale/ru.json'

TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(ru)
const render = App => {
return ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
    {/* <ReactReduxFirebaseProvider {...rrfProps}> */}
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={theme}>
               <App/>
        </ThemeProvider>
      </I18nextProvider>
      {/* </ReactReduxFirebaseProvider> */}
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
}

render(App);

if (module.hot) {
  module.hot.accept('./app/App', () => {
    const NextApp = require('./app/App').default;
    render(NextApp);
  });
}

serviceWorker.unregister();
