import React from "react";
import { Link } from "react-router-dom"
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import UserProfileHeader from "../../components/UserProfile/UserProfileHeader";
import UserProfileDetail from "../../components/UserProfile/UserProfileDetail";

const UserProfilePage = ({ step, ...props }) => {
  return (
    <>
      <Header {...props} />
      <SubHeader />
      
      <section id="people_page" className="default_bg">
        <img src="images/make_film_bg.png" alt="img" className="bg_img" />
        <div className="container side_space">
        <div className="row">
        <div className="col-md-3">
        <UserProfileHeader {...props} />
        </div>
        <div className="col-md-9">
        <UserProfileDetail {...props} />
        </div>
        </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default UserProfilePage;
