/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import {
//   FaStarHalf,
//   FaStar,
//   FaAngleRight,
//   FaAngleLeft,
//   FaFilter,
// } from "react-icons/fa";
import _ from "lodash";
// import UserProfileSidebar from "../UserProfile/UserProfileSidebar";
import { config } from "../../actions/config";
import moment from "moment";
import Select from "react-select";
import { profession } from "../CreateUserProfile/registerConst";
import HireTalentSidebar from "./HireTalentSidebar";
import { AmountBreackdown } from "./AmountBreakdown";
import axios from "axios";

const HireTalentDetail = ({ step, user, loginUser, handleChangeSingle, milestone, handleChangeNestedValue, handleChangeObjectValue, addMoreFields, removeFields, fileSelectedHandler, updateCurrentOrder, nextStep, prevStep, onPaymentDone, ...props }) => {

  const [countries, setCountries] = useState([])
  const [StateOptions, setStates] = useState([]);
  const [districtOptions, setCity] = useState([]);

  useEffect(() => {
    const headers = {
      "Accept": "application/json",
      "api-token": "pnQxi9SC9cQHgO4vfLHdvfU6SvZNRHTe-0VyoOn9CbIXPtQQ-mvXd9P7rFIea4K3DQU",
      "user-email": "rupeshv@tokyotechie.com"
    };
    axios.get('https://www.universal-tutorial.com/api/getaccesstoken', { headers }).then(res => {
      axios.get('https://www.universal-tutorial.com/api/countries', { headers: { Authorization: "Bearer " + res.data.auth_token, Accept: 'application/json' } }).then(contriesRes => {
        let countries = _.map(contriesRes.data, (val) => ({
          label: val.country_name,
          value: val.country_name,
        }))
        setCountries(countries);
      })
    })
  }, [])

  useEffect(() => {
    if (props.companyAddress?.country) {
      const headers = {
        "Accept": "application/json",
        "api-token": "pnQxi9SC9cQHgO4vfLHdvfU6SvZNRHTe-0VyoOn9CbIXPtQQ-mvXd9P7rFIea4K3DQU",
        "user-email": "rupeshv@tokyotechie.com"
      };
      axios.get('https://www.universal-tutorial.com/api/getaccesstoken', { headers }).then(res => {
        axios.get('https://www.universal-tutorial.com/api/states/' + props.companyAddress?.country, { headers: { Authorization: "Bearer " + res.data.auth_token, Accept: 'application/json' } }).then(stateRes => {
          let stateOptions = _.map(stateRes.data, (val) => ({
            label: val.state_name,
            value: val.state_name,
          }))
          setStates(stateOptions);
        })
      })
    }
  }, [props.companyAddress?.country])
  useEffect(() => {
    if (props.companyAddress?.state) {
      const headers = {
        "Accept": "application/json",
        "api-token": "pnQxi9SC9cQHgO4vfLHdvfU6SvZNRHTe-0VyoOn9CbIXPtQQ-mvXd9P7rFIea4K3DQU",
        "user-email": "rupeshv@tokyotechie.com"
      };
      axios.get('https://www.universal-tutorial.com/api/getaccesstoken', { headers }).then(res => {
        axios.get('https://www.universal-tutorial.com/api/cities/' + props.companyAddress?.state, { headers: { Authorization: "Bearer " + res.data.auth_token, Accept: 'application/json' } }).then(cityRes => {
          let cityOptions = _.map(cityRes.data, (val) => ({
            label: val.city_name,
            value: val.city_name,
          }))
          setCity(cityOptions);
        })
      })
    }
  }, [props.companyAddress?.state])



  const [paymentDescription, setPaymentDescription] = useState('');
  //  const [step, setStep] = useState(1);
  // const tabChangeHandler = e => {
  //   if (e > 0 && e < 5) {
  //     setStep(e)
  //   }
  // }
  return (
    <section id="people_other_info" className="small_para">
      <div className="container">
        <div className="row">
          {/* <div className="col-md-12">
            <div className="postjob_tab">
              <ul className="nav nav-tabs">
                <li className={step === 1 && "active"}>
                  <Link to="#tab_default_2" data-toggle="tab" onClick={()=>tabChangeHandler(1)}>
                    Job Details
                  </Link>
                </li>
                <li className={step === 2 && "active"}>
                  <Link to="#tab_default_3" data-toggle="tab" onClick={()=>tabChangeHandler(2)}>
                    Contract term{" "}
                  </Link>{" "}
                </li>
                <li className={step === 3 && "active"}>
                  <Link to="#tab_default_4" data-toggle="tab" onClick={()=>tabChangeHandler(3)}>
                    Work Decription
                  </Link>
                </li>
                <li className={step === 4 && "active"}>
                  <Link to="#tab_default_5" data-toggle="tab"onClick={()=>tabChangeHandler()}>
                    Payment
                  </Link>
                </li>
              </ul>
            </div>
          </div> */}
          <div className="profile_area col-md-12 no_padding">
            <div className="col-md-8 col-sm-7">
              <div className="shadow_bg clearfix">
                <div className="tab-content">
                  <div className={step === 1 ? "tab-pane active" : "tab-pane"} id="tab_default_2">
                    <div className="tab_head">
                      <div className="tab_title">
                        <h6>Job Details</h6>
                      </div>
                    </div>
                    <div className="tab_info">
                      <div className="tab_info">
                        <label>Hiring Team* </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Hiring Team"
                          name="hiringTeam"
                          value={props?.hiringTeam}
                          onChange={handleChangeSingle}
                        />
                        {props?.errors?.hiringTeam === '' ? <span className="text-danger" style={{ fontSize: 12 }}>Hiring team is required</span> : null}
                        <br />
                      </div>



                      <div className="job_list clearfix">
                        <label>Contract Title*</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="This will use as invoice description"
                          name="contractTitle"
                          value={props.contractTitle}
                          onChange={handleChangeSingle}
                        />
                        {props?.errors?.contractTitle === '' ? <span className="text-danger" style={{ fontSize: 12 }}>Contract title is required</span> : null}
                        <br />

                      </div>
                      <div className="job_list clearfix">
                        <label>Job Category*</label>
                        <Select
                          options={profession}
                          name='jobCategory'
                          onChange={(e) => handleChangeSingle({ target: { name: "jobCategory", value: e.label } })}
                        />
                        {props?.errors?.jobCategory === '' ? <span className="text-danger" style={{ fontSize: 12 }}>Job category is required</span> : null}
                      </div>
                    </div>
                    {/* <br />
                    <div className="job_list clearfix">
                    <button className="blue-btn" on>Next</button>
                    </div> */}
                  </div>
                  <div className={step === 2 ? "tab-pane active" : "tab-pane"} id="tab_default_3">
                    <div className="tab_head">
                      <div className="tab_title">
                        <h6>Milestone</h6>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="paymentOption">Payment option</label>
                      <div className="inline_feild">
                        <label className="check_bx">
                          <input
                            type="radio"
                            checked={props.paymentOption === 'fixed'}
                            name="paymentOption"
                            onClick={() =>
                              handleChangeSingle({
                                target: {
                                  value: 'fixed',
                                  name: "paymentOption",
                                },
                              })
                            }
                          />
                          Fixed
                          <span className="checkmark"></span>
                        </label>

                        <label className="check_bx">
                          <input
                            type="radio"
                            checked={props.paymentOption === 'perHour'}
                            name="paymentOption"
                            onClick={() =>
                              handleChangeSingle({
                                target: {
                                  value: 'perHour',
                                  name: "paymentOption",
                                },
                              })
                            }
                          />
                          Per Hour
                          <span className="checkmark"></span>
                        </label>

                        <label className="check_bx">
                          <input
                            type="radio"
                            checked={props.paymentOption === 'perDay'}
                            name="paymentOption"
                            onClick={() =>
                              handleChangeSingle({
                                target: {
                                  value: 'perDay',
                                  name: "paymentOption",
                                },
                              })
                            }
                          />
                          Per Day
                          <span className="checkmark"></span>
                        </label>

                        <label className="check_bx">
                          <input
                            type="radio"
                            checked={props.paymentOption === 'weekly'}
                            name="paymentOption"
                            onClick={() =>
                              handleChangeSingle({
                                target: {
                                  value: 'weekly',
                                  name: "paymentOption",
                                },
                              })
                            }
                          />
                          Weekly
                          <span className="checkmark"></span>
                        </label>
                        <label className="check_bx">
                          <input
                            type="radio"
                            checked={props.paymentOption === 'monthly'}
                            name="paymentOption"
                            onClick={() =>
                              handleChangeSingle({
                                target: {
                                  value: 'monthly',
                                  name: "paymentOption",
                                },
                              })
                            }
                          />
                          Monthly
                          <span className="checkmark"></span>
                        </label>
                      </div>

                    </div>
                    {/* <div className="form-group" style={{paddingTop:10}}>                    
                      <label className="control-label">Max fixed amount to pay</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Pay for Project"
                              name="maxFixedPay"
                              value={props?.maxFixedPay}
                              onChange={ handleChangeSingle }
                            />
                      </div> */}
                    {/* <div className="form-group">
                          <label className="check_bx">
                            <input
                              type="radio"
                              checked={props.depositeType === 'whole'}
                              name="depositeType"
                              onClick={() =>
                                handleChangeSingle({
                                  target: {
                                    value: 'whole',
                                    name: "depositeType",
                                  },
                                })
                              }
                            />
                            Deposit for the whole project
                            <span className="checkmark"></span>
                          </label>
                          </div> */}
                    {/* <div className="form-group">
                          <label className="check_bx">
                            <input
                              type="radio"
                              checked={props.depositeType === 'milestoneWise'}
                              name="depositeType"
                              onClick={() =>
                                handleChangeSingle({
                                  target: {
                                    value: 'milestoneWise',
                                    name: "depositeType",
                                  },
                                })
                              }
                            />
                            Deposit a lesser amount to cover first milestone (part-payments)
                            <span className="checkmark"></span>
                          </label>
                    </div> */}

                    <div className="form-group" style={{ marginTop: 32 }}>
                      {props.depositeType === 'milestoneWise' ? _.map(milestone, (val, index) => (
                        <div className="row" style={{ display: 'flex', alignItems: 'center' }}>
                          <div className="col-md-1">
                            <label> {index + 1}.</label>
                          </div>
                          <div className="form-group col-sm-5" style={{ paddingLeft: 0 }}>
                            <label className="control-label">Milestone Description</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="What is the Task?"
                              name="milestoneDescription"
                              value={val?.milestoneDescription}
                              onChange={(e) =>
                                handleChangeNestedValue({
                                  name: "milestoneDescription",
                                  value: e.target.value,
                                  key: "milestone",
                                  index: index,
                                })
                              }
                            />
                            {/* {!val?.milestoneDescription ? <span className="text-danger" style={{fontSize:12}}>Enter Description</span>:null} */}
                          </div>

                          <div className="row">
                            <div className="col-md-12 form-group">
                              <label className="control-label">
                                Due Date
                              </label>
                              <input
                                type="date"
                                className={`form-control ${val?.dueDate ? "" : "date-time-picker"}`}
                                name="dueDate"
                                value={moment(val?.dueDate).format("YYYY-MM-DD") || " "}
                                onChange={(e) =>
                                  handleChangeNestedValue({
                                    name: "dueDate",
                                    value: e.target.value,
                                    key: "milestone",
                                    index: index,
                                  })
                                }
                              />
                              {/* {!val?.dueDate ? <span className="text-danger" style={{fontSize:12}}>Select due date</span>:null} */}
                            </div>
                          </div>
                          <div className="form-group col-sm-3">
                            <label className="control-label">Deposit Amount</label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Amount for milestone"
                              value={val?.milestoneAmount}
                              onChange={(e) =>
                                handleChangeNestedValue({
                                  name: "milestoneAmount",
                                  value: e.target.value,
                                  key: "milestone",
                                  index: index,
                                })
                              }
                            />
                            {/* {!val?.milestoneAmount ? <span className="text-danger" style={{fontSize:12}}>Enter Amount</span>:null} */}
                          </div>
                          <div className="col-sm-1">
                            <span style={{ cursor: 'pointer' }} onClick={() => removeFields('milestone', index)}><img src={process.env.PUBLIC_URL + 'icon/minus.svg'} height={20} alt="" /></span>
                          </div>

                          {/* <div className="col form-group text-right">
                        <a style={{ cursor: 'pointer' }} onClick={() => removeFields('milestone',index)}>
                        <span><img src={process.env.PUBLIC_URL+'icon/minus.svg'} height={20} alt="" /></span>
                            </a>
                        </div> */}
                        </div>
                      ))
                        :
                        <div className="form-group col-md-6" style={{ paddingLeft: 0 }}>
                          <label className="control-label">
                            Due Date
                          </label>
                          <input
                            type="date"
                            className={`form-control ${props.dueDate ? "" : "date-time-picker"}`}
                            name="dueDate"
                            value={moment(props?.dueDate).format("YYYY-MM-DD") || " "}
                            onChange={handleChangeSingle}
                          />
                        </div>
                      }
                      {props.depositeType === 'milestoneWise' && <div className="row">
                        <div className="col-md-12 text-right">
                          <a style={{ cursor: 'pointer' }} onClick={() => addMoreFields('milestone')}>
                            <span><img src={process.env.PUBLIC_URL + 'icon/plus.svg'} height={20} alt="" /></span> Add Milestone
                          </a>
                        </div>
                      </div>}
                      {/* <hr /> */}
                      {/* <div className="pagination_part clearfix text-right">
                        <ul className="pagination">
                          <li>
                            <Link to="#">
                              <FaAngleLeft />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">1</Link>
                          </li>
                          <li>
                            <Link to="#">2</Link>
                          </li>
                          <li>
                            <Link to="#">3</Link>
                          </li>
                          <li>
                            <Link to="#">
                              <FaAngleRight />
                            </Link>
                          </li>
                        </ul>
                      </div> */}
                    </div>
                    {props.paymentOption === 'fixed' && <>
                      <label htmlFor="contract-desccription" style={{ borderTop: '1px solid #ddd', width: '100%', paddingTop: 10 }}>How do fixed-price contract works?</label>
                      <p id="contract-desccription">
                        Fixed contract payments on Bollywood is Good (BiG) work by allowing users to set a
                        predetermined amount for a specific task or service. Once both parties agree to the terms
                        of the contract, the payment is made upfront or according to the agreed payment schedule.
                        Upon completion of the task or service as per the contract, the funds are released to the
                        service provider. This system ensures transparency and security for both parties involved
                        in the transaction.</p>
                    </>}

                    {props.paymentOption == 'perHour' && <>
                      <label htmlFor="contract-desccription" style={{ borderTop: '1px solid #ddd', width: '100%', paddingTop: 10 }}>How do hourly contracts work?</label>
                      <p id="contract-desccription">
                        Per hour contract payments on Bollywood is Good (BiG) operate by allowing users to agree
                        on an hourly rate for a particular service or task. Once the contract is established, the
                        service provider logs their working hours on the platform. At the end of each hour, the
                        client is billed according to the agreed-upon rate. This payment model offers flexibility
                        and transparency, as clients only pay for the actual hours worked, and service providers are
                        compensated for their time accordingly.
                      </p>
                    </>}

                    {props.paymentOption == 'perDay' && <>
                      <label htmlFor="contract-desccription" style={{ borderTop: '1px solid #ddd', width: '100%', paddingTop: 10 }}>How do perDay contracts work?</label>
                      <p id="contract-desccription">
                        Per day contract payments on Bollywood is Good (BiG) function by enabling users to agree on a
                        fixed daily rate for a specific service or task. Once the contract terms are established, the
                        service provider begins working on the task or service. At the end of each day, the client is
                        invoiced for the agreed-upon daily rate. This payment model provides flexibility and
                        transparency, as clients only pay for the actual days worked, and service providers are
                        compensated for their time accordingly.
                      </p>
                    </>}

                    {props.paymentOption == 'weekly' && <>
                      <label htmlFor="contract-desccription" style={{ borderTop: '1px solid #ddd', width: '100%', paddingTop: 10 }}>How do weekly contracts work?</label>
                      <p id="contract-desccription">
                        Per week contract payments on Bollywood is Good (BiG) function by enabling users to agree on a
                        fixed weekly rate for a specific service or task. Once the contract terms are established, the
                        service provider begins working on the task or service. At the end of each week, the client is
                        invoiced for the agreed-upon weekly rate. This payment model provides predictability and
                        consistency for both parties involved, as the payment amount remains constant regardless
                        of the number of hours worked within the week.
                      </p>
                    </>}

                    {props.paymentOption == 'monthly' && <>
                      <label htmlFor="contract-desccription" style={{ borderTop: '1px solid #ddd', width: '100%', paddingTop: 10 }}>How do monthly contracts work?</label>
                      <p id="contract-desccription">
                        Per month contract payments on Bollywood is Good (BiG) operate by allowing users to agree on a
                        fixed monthly rate for a particular service or task. Once the contract terms are agreed upon,
                        the service provider begins working on the task or service. At the end of each month, the
                        client is invoiced for the predetermined monthly rate. This payment model offers stability
                        and consistency for both parties involved, as the payment amount remains constant
                        regardless of the number of hours worked within the month.
                      </p>
                    </>}

                  </div>
                  <div className={step === 3 ? "tab-pane active" : "tab-pane"} id="tab_default_4">
                    <div className="tab_head">
                      <div className="tab_title">
                        <h6> Work Description</h6>
                      </div>
                    </div>
                    <div className="tab_info skills_part">
                      <div className="form-group">
                        <label htmlFor="desccription"></label>
                        <textarea

                          rows={10}
                          id="desccription"
                          type="text"
                          className="form-control"
                          placeholder="Somthing about the work"
                          name="workDescription"
                          value={props.workDescription}
                          onChange={handleChangeSingle}
                        />
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className="form-group upload_resume_field">
                          <br />
                          <label htmlFor="attachement">
                            <span style={{ padding: '8px 15px' }}><img src={process.env.PUBLIC_URL + 'icon/attachment.svg'} height={20} alt="" /> Attach File</span>
                          </label>
                          <input
                            id="attachement"
                            type="file"
                            name="attachement"
                            className="form-control"
                            accept="image/*"
                            onChange={(e) => { fileSelectedHandler(e) }}
                          />

                        </div>
                        {props?.attachement &&
                          <div className="form-group upload_resume_field">
                            <a href={config.FILE_URL + props?.attachement || ''} target="_blank" rel="noopener noreferrer">
                              <img src={process.env.PUBLIC_URL + 'images/green-check.jpg'} alt='' style={{ height: 40, borderRadius: 10 }} /> View
                            </a>
                          </div>
                        }
                      </div>
                      {/* <h6>Accents</h6>
                          <span>English - American</span><span>English (British)</span><span>English - Australian</span> */}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'end', }}>
                      <div className="formGroup" style={{ width: '75%' }}>
                        <label htmlFor="address">Payment Description</label>
                        <input
                          id="paymentDesc"
                          type="text"
                          className="form-control"
                          placeholder="Short description about payment"
                          name="paymentDescription"
                          value={props.paymentDescription}
                          onChange={handleChangeSingle}
                        />
                      </div>
                      <div style={{ display: 'flex', width: '22%', marginLeft: '3%', height: 45 }}>
                        {/* <div>
                          <input type="radio" name="razorpay" id="razorpay-radio" checked />
                        </div> */}
                        {/* <RazorpayButton paybleAmount={props?.depositeType ==='whole' ? props.maxFixedPay :  props.firstMilestoneAmount || 0} contractId={'x'} description={paymentDescription} payeeId={user._id} onPaymentDone={(res) => { onPaymentDone(res)}} /> */}
                        <AmountBreackdown {...props} firstMilestoneAmount={milestone.length > 0 ? milestone[0].milestoneAmount : 0} paymentDescription={props.paymentDescription || 'BIG'} onPaymentDone={onPaymentDone} user={user} contractId={props._id} milestoneIndex={0} />
                      </div>
                    </div>
                  </div>
                  {/* <div className={step === 4 ? "tab-pane active" : "tab-pane"} id="tab_default_5">
                    <div className="tab_head">
                      <div className="tab_title">
                        <h6> Payment</h6>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="address">Company Address</label>
                      <input
                        id="address"
                        type="text"
                        className="form-control"
                        placeholder="Line 1"
                        name="addressLine1"
                        value={props.addressLine1}
                        onChange={(e) => {
                          handleChangeObjectValue({
                            name: "address",
                            value: e?.target.value,
                            key: "companyAddress",
                          });
                        }}
                      />
                      
                    </div>
                    <div className="row">
                      <div className="form-group col-md-4">
                        <label className="control-label">{"Country*"}</label>

                        <Select
                          options={countries}
                          isClearable
                          value={{ label: props.companyAddress?.country, value: props.companyAddress?.country }}
                
                          onChange={(e) => {
                            handleChangeObjectValue({
                              name: "country",
                              value: e?.value,
                              key: "companyAddress",
                            });
                          }}
                        />

                        <small>{props.countryError}</small>

                      </div>


                      <div className="form-group col-md-4">
                        <label className="control-label">{"State*"}</label>
                        <Select
                          isClearable
                          options={StateOptions}
                          value={{ label: props.companyAddress?.state, value: props.companyAddress?.state }}
                          onChange={(e) => {
                            handleChangeObjectValue({
                              name: "state",
                              value: e?.value,
                              key: "companyAddress",
                            });
                          }}
                        />
                      </div>
                      <div className="form-group col-md-4">

                        <label className="control-label">{"City"}</label>
                        <Select
                          isClearable
                          options={districtOptions}
                          value={{ label: props.companyAddress?.city, value: props.companyAddress?.city }}
                          onChange={(e) => {
                            handleChangeObjectValue({
                              name: "city",
                              value: e?.value,
                              key: "companyAddress",
                            });
                          }}
                        />
                      </div>
                      <div className="form-group col-md-4">
                      <label htmlFor="address">PIN Code</label>
                      <input
                        id="pinCode"
                        type="text"
                        className="form-control"
                        placeholder="Line 1"
                        name="Enter PIN Code"
                        value={props.pinCode}
                        onChange={(e) => {
                          handleChangeObjectValue({
                            name: "pinCode",
                            value: e?.target.value,
                            key: "companyAddress",
                          });
                        }}
                      />
                      </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'end', }}>
                      <div className="formGroup" style={{ width: '75%' }}>
                        <label htmlFor="address">Payment Description</label>
                        <input
                          id="paymentDesc"
                          type="text"
                          className="form-control"
                          placeholder="Short description about payment"
                          name="paymentDescription"
                          value={props.paymentDescription}
                          onChange={handleChangeSingle}
                        />
                      </div>
                      <div style={{ display: 'flex', width: '22%', marginLeft: '3%', height: 45 }}>
                      
                        <AmountBreackdown {...props} firstMilestoneAmount={milestone.length > 0 ? milestone[0].milestoneAmount : 0} paymentDescription={props.paymentDescription || 'BIG'} onPaymentDone={onPaymentDone} user={user} contractId={props._id} />
                      </div>
                    </div>

                  </div> */}
                  <div className="tab-pane" id="tab_default_6">
                    <div className="tab_head">
                      <div className="tab_title">
                        <h6>Employment History</h6>
                      </div>
                    </div>
                    <div className="tab_info emp_his">
                      <ul>
                        <li>
                          <h6>Voice Actor | Various</h6>
                          <span>June 2020 - Present</span>
                        </li>
                        <li>
                          <h6>Screen Actor | Various Television Roles</h6>
                          <span>August 2011 - Present</span>
                        </li>
                        <li>
                          <h6>Senior copywriter | Stencil Brand Solutions</h6>
                          <span>February 2018 - April 2018</span>
                        </li>
                        <li>
                          <h6>Screen Actor | Various Television Roles</h6>
                          <p>Microphones</p>
                          <p>Sed ut perspiciatis unde omnis iste natus </p>
                          <span>August 2011 - Present</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="tab-pane" id="tab_default_7">
                    <div className="tab_head">
                      <div className="tab_title">
                        <h6>Certifications</h6>
                      </div>
                    </div>
                    <div className="tab_info certificate_info">
                      <ul>
                        <li>
                          <img src="images/title_detail_icon.png" alt="icon" />
                          <div className="course_info">
                            <h6>Diploms in Voice Acting</h6>
                            <p>Lorem ipsum institude, Mumbai</p>
                            <span>Issued December 2019</span>
                          </div>
                        </li>
                        <li>
                          <img src="images/title_detail_icon.png" alt="icon" />
                          <div className="course_info">
                            <h6>Diploms in Voice Acting</h6>
                            <p>Lorem ipsum institude, Mumbai</p>
                            <span>Issued December 2019</span>
                          </div>
                        </li>
                        <li>
                          <img src="images/title_detail_icon.png" alt="icon" />
                          <div className="course_info">
                            <h6>Diploms in Voice Acting</h6>
                            <p>Lorem ipsum institude, Mumbai</p>
                            <span>Issued December 2019</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>

                <button class="blue_btn" onClick={() => prevStep(step)}>Previous</button>
                {step < 3 && <button className="blue_btn" onClick={() => nextStep(step)}>Next</button>}
                {step === 3 && <button className="blue_btn" ><Link style={{ color: '#fff' }} to={'/FindTalent'}>Finish without payment</Link></button>}
                {/* {step === 4 && <button disabled={!props.enableSubmit} className="blue_btn" onClick={() => {updateCurrentOrder('final') }}>Submit</button>} */}
              </div>
            </div>
            <HireTalentSidebar user={user} />
          </div>
        </div>
      </div>
    </section>
  );
};



export default HireTalentDetail;
