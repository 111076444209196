import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight, FaTimes } from "react-icons/fa";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import '../../scss/celebrityManagement.scss'
import _ from "lodash";

class AdvertisingAgencyCompany extends Component {

  render() {
    return (
      <>
        <Header />
        <SubHeader /> 
          <section id="inner_celeb_banner" className="dark_clr">
            <div class="container">
              <div class="row">
                <div class="col-sm-6 col-xs-8 inner_celebrity_info">
                  <h1>AD AGENCY</h1>
                  <p align="justify"> Rightly said that to sell any product or service it is mandatory to showcase it appropriately and advertisement helps to get them displayed fascinatingly. These days it’s important to make your audience see what benefits and luxury your product or service is giving to them, hence advertising it in an appealing manner will attract many customers <strong>Ad Agency</strong> helps you in that. </p>
              <Link to="/ReachUs" class="stroke_btn">Contact US</Link>
                </div>
                <div class="col-sm-6 inner_celebrity_img">
                <img src="images/film-making-services-company.webp" alt="BollywoodisGood is best Celebrity Management Company in India" width="699" height="450" class="wow fadeInDown" data-wow-delay="0.3s" />	 
                </div>
              </div>
            </div>
          </section>

          <section id="inner_about">
          <div class="container">
          <div class="row">
          <div class="col-sm-5">
          <img src="images/commercial-film-makers.webp"  width="458" height="458" alt="Celebrity Management Services in India" />
          </div>
          <div class="col-sm-7 inner_about_info">
          <div class="inner_head">
          <h2>Ad Company</h2>
          </div>
          <p align="justify">Without advertisement no company can enhance its sales, product improvements and brand’s market value. In the growth of a brand or company the advertisement stands as a guidance and help in the market scenario.</p>
          <br/>
          <p align="justify">Now, here we come into existence BollywoodisGood one of the top <strong>Ad agency in India</strong>. We believe in beating our own set target and that helped us to become the ideal <strong>Ad Making services company</strong> not only in Mumbai but all over India. At BollywoodisGood, we also believe in strategic thinking and developing the art of perfect timing. In an advertisement the message that we show must bond with the target audience and creative strategy is the alignment that helps in signifying the objectives and consumer research. This is the foundation of any powerful campaign that makes a unique mark with a high recall value and generates business.
    <br /><br />
    However wait we are not completed yet. There are benefits of advertising for a brand, and now we are going to share some secrets.  
    </p>
          </div>
          </div>
          </div>
          </section>

          <section id="our_role">
          <div class="container">
          <div class="row">
          <div class="col-sm-7 role_info">
          <div class="inner_head">
          <h2>Ad Making Service</h2>
          </div>
          <p align="justify"> <strong>Introduce new products into the market</strong> – This step is going to build the brand or will say goodbye to the brand from the market. Launching a product in a proper manner is very crucial. Your target audience must know about the products and services you are offering
<br /><br />
<strong>Market Expansion –</strong> It is the main strategy that needs to be taken care of when any brand wants to popularize their products. BollywoodisGood <strong>Advertising companies</strong> link the gap among the company and the target audience thereby in potential pockets producing new markets.
<br /><br />
<strong>Enhancing Sales –</strong><strong> Ad company/Ad agency</strong> play a significant role in enhancing the sales of a product. On the other hand good advertisement also built a strong customer base amongst the consumers and that make sure prospective sales for un-launched products too.
<br /><br />
<strong>Fights Competition –</strong> It is a fact that once a new and creative product is launched in the market, competitors will follow the suit. Hence to make a unique presence for brands and their products the advertisement is a must.
</p>
          </div>
          <div class="col-sm-5">
          <img src="images/commercial-film-makers.webp"  loading="lazy" width="458" height="459" alt="Artist Management Services" />
          </div>
          </div>
          </div>
          </section>  

          <section id="inner_services">
          <div class="container">
          <div class="text-center inner_head">
        <h2>BollywoodisGood : Advertisement Company</h2>
        </div>
        <p align="justify">BollywoodisGood is coming with new techniques and tools to make the advertisement for any brand or company more appealing. To experience the best services and enhance the product value in the market through advertisement, get connected with us now. We make sure that the requirement of models, photographer, video maker and other related resources is arranged on time and utilized appropriately to make the advertisement valuable. We have a links with every resource that is needed and will arrange for the brand advertisement in cost-efficient manner. With us the customers are not only going to get the quality advertisement but will going to capture the interest of the audience and market.</p>
          </div>
          </section>     

          <section id="last_info">
<div class="container">
<div class="row">
<div class="col-xs-12">
<blockquote>
<p align="justify">Get in touch with BollywoodisGood to experience the best Advertisement services in Mumbai to enhance your brand.</p>
</blockquote>
</div>
</div>
</div>
</section>    
        <Footer />
      </>
    );
  }
}

export default AdvertisingAgencyCompany;
