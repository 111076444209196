import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight, FaTimes } from "react-icons/fa";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import '../../scss/celebrityManagement.scss'
import _ from "lodash";

class MusicProduction extends Component {

  render() {
    return (
      <>
        <Header />
        <SubHeader /> 
          <section id="inner_celeb_banner">
            <div class="container">
              <div class="row">
                <div class="col-sm-6 col-xs-8 inner_celebrity_info">
                  <h1>MUSIC PRODUCTION COMPANY</h1>
                  <p align="justify">In terms of music, musicians imagine their lives and today, a music album is the fastest and better way where musicians can develop themselves and make music as the main part of their existence. BollywoodisGood is a music production company and here our goal is to build music to fascinate and amaze.</p>
                
              <Link to="/ReachUs" class="stroke_btn">Contact US</Link>
                </div>
                <div class="col-sm-6 inner_celebrity_img">
                <img src="images/music-production-company.webp" alt="BollywoodisGood is best Celebrity Management Company in India" width="699" height="450" class="wow fadeInDown" data-wow-delay="0.3s" />	 
                </div>
              </div>
            </div>
          </section>

          <section id="inner_about">
          <div class="container">
          <div class="row">
          <div class="col-sm-5">
          <img src="images/music-production-services.webp"  width="458" height="458" alt="Celebrity Management Services in India" />
          </div>
          <div class="col-sm-7 inner_about_info">
          <div class="inner_head">
          <h2>Music Production Services</h2>
          </div>
          <p align="justify">Our team of experts designs vivacious sounds to entertain, motivate and calm the senses. We consider that convincing albums can create an artist securely strengthen their existence in the music world.</p>
         <br />
          <p align="justify">The interest of your audience elevated remarkably by having a music album and BollywoodisGood can assist you with the artistic elements infused with the utilization of technology. With our unparallel services we can provide the wanted turning point to your music album.</p>
          <br/>
          <p align="justify">At BollywoodisGood the sound is our playground where the team of experts and professionals will help you in shaping your career so that you enjoy the making and further process. Our first priority is client satisfaction we increase at each step start from pre to post. So looking for the Music Production Services, then Contact us.</p>
          </div>
          </div>
          </div>
          </section>

          <section id="our_role">
          <div class="container">
          <div class="row">
          <div class="col-sm-7 role_info">          
          <div class="inner_head">
          <h2>For effective animation our time-tested process</h2>
          </div>
          <p align="justify">BollywoodisGood is experts in 3D / 2D motion graphics, live action, character, animated interaction, hand-drawn animation and typographic.BollywoodisGood is experts in 3D / 2D motion graphics, live action, character, animated interaction, hand-drawn animation and typographic. BollywoodisGood is beyond an animation video production agency, our objective is to build mutual partnerships that permit us to send the company’s messages in the most appealing way.</p>
          <br />
          <p align="justify">We don’t rush to create a video for you but we conduct our research and understand the goal of the company and the team. If you are looking for animated or motion graphics content we are here to assist you make video that tells the tale to your audience & share experiences that reverberate with your audiences.</p>
          <br />
          <p align="justify">BiG’s animations shares effectual messages that assist you to attain more with the videos.</p>
          
          </div>
          <div class="col-sm-5">
          <img src="images/explainer-video-production.webp"  loading="lazy" width="458" height="459" alt="Artist Management Services" />
          </div>
          </div>
          </div>
          </section>  

          <section id="inner_about">
          <div class="container">
          <div class="row">
          <div class="col-sm-5">
          <img src="images/music-production-agency.webp"  loading="lazy" width="458" height="459" alt="Artist Management Services" />
          </div>
          <div class="col-sm-7 role_info">         
          <p align="justify">In case you are looking for a music production company, feel free to get in touch with us. We will make sure that your music album is exciting for your audience and is an unforgettable experience for you.</p>
          <br />
          <ul>
            <li>
            <h6><strong>ADVERTISING JINGLES</strong></h6>
              <p align="justify">For TV, Radio and Internet these sorts of musical advertisements are produced. In making brand recognition Commercial Jingles are extremely effectual. At BollywoodisGood the premium team of writers, musicians and vocalists has an excessive experience of years in the sector of Jingles production and related kind of Audio Branding like Audio Logo, Ad Music Mp3 tag, Music Logo, and Mnemonics.</p>
            </li>
            <br />
            <li>
            <h6><strong>RADIO PRODUCTION</strong></h6>
              <p align="justify">In Audio-Music Production category, BollywoodisGood has astral status as creative radio productions producer. The team here is extremely resourceful and competent in creating a variety of Radio Program ID, Radio commercials, Top of the Hour segments, Station ID, Music Beds, Intro Music and packaging for Radio Sweepers, Radio Stinger Bumper, Radio Shows and Retail Radio production for national, international and local Radio stations.</p>
            </li>
          </ul>
           </div>         
        
          </div>
          </div>
          </section>  

          <section id="our_role">
          <div class="container">
          <div class="row">
          <div class="col-sm-7 role_info">          
          <div class="inner_head">
          <h2>CORPORATE ANTHEMS</h2>
          </div>
          <p align="justify">Of our Audio-Music production team another strong point is Corporate Anthems. Corporate Anthems are ever more utilized by organizations all across the globe to communicate their brand’s values, missions and story. These are known as the most effectual music branding technique. Carefully our Production House designs and creates Corporate Jingles and Corporate Songs incorporating the most inspirational corporate music for the final reason of audio branding.</p>
          <br />
          <ul>
            <li>
              <h6><strong>MUSIC PRODUCTION</strong></h6>
              <p align="justify">BollywoodisGood as a music production company exercises specially made music pieces and exceptional compositions for Music Albums, Film Songs, Background Events and scores. Every note is cautiously combined to offer cinematic, groovy and melodious pieces of music planned for Background Music, Songs Production for Videos and Background theme music for Shows and events. We are a Music production company and we make sure that we produce and help the musician to create a magic for their audience.</p>
            </li>
           
          </ul>
          </div>
          <div class="col-sm-5">
          <img src="images/voice-recording-services.webp"  loading="lazy" width="458" height="459" alt="Artist Management Services" />
          </div>
          </div>
          </div>
          </section>  

          <section id="inner_services">
          <div class="container">
<h6>PROFESSIONAL VOICE RECORDING</h6>
<p align="justify">Professional voice recordings for internet, TV and Radio we have a complete Voice Actors library. After experiencing the vital procedure of voice casting these Voice Over artists, use their voice talent to perform premium quality Voice Overs for narrations, commercial Spots and much more.</p>
<br />
<h6>ON-HOLD MARKETING</h6>
<p align="justify">For attaining business professionalism on-hold marketing is the vital tool as it assists in increasing the rate of satisfied customers as well as product sales. Auto Attendants, On Hold Music, IVR Recordings and Company Voicemail greetings via competitive productions , we are enthused to give you with the perfect key as per your needs.</p>
<br />
<p align="justify">Hence, Get in touch with BollywoodisGood to experience the best services in this field.</p>
          </div>
          </section>     

          

        
         
        <Footer />
      </>
    );
  }
}

export default MusicProduction;
