import React from 'react';
import { useTranslation } from "react-i18next";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import _ from "lodash";
import { vlidateNumberField } from "../../Utilities/validateInputField";
import { loadOptionsResolveLanguage, defaultOption } from "../../Constants.js/languageConst";
// import filmCourseOptions from '../CreateUserProfile/filmCoursesConst';
import AsyncSelect from "react-select/async";
import { ActorType, Genre } from "../JobPost/jobPostConst";
import { roleOption, filmIndustry, filmType, uniqueTraits, physique, postGraduation, jobNature, workingStyle, schedule, abilityToCommut, supplementalPayTypes } from "../CreateUserProfile/registerConst";
import { FaPlus, FaMinus, } from "react-icons/fa";
import { Placeholder } from '../../commons/ReactSelectNoOptionsMessage';
import { config } from '../../actions/config';

const InfliuencerBrandCollab = ({
    prevStep,
    nextStep,
    ...props
}) => {
    const { t } = useTranslation();
    return (
        <>
          
          <div className="tab_head">
                <h4>Brand Collaboration</h4>
                <hr />
               </div>

               <div className='row'>     
                <div className="form-group col-md-6 col-sm-12">
                    <label>Previous Brand Collaborations</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        name="Collaborations"
                    />
                </div>
                <div className="form-group col-md-6 col-sm-12">
                    <label>Type of Collaborations</label>
                    <CreatableSelect
                        components={{ Placeholder }}
                        placeholder={''}
                        options={''}
                        />
                </div>
                <div className="form-group col-md-6 col-sm-12">
                    <label>Success Metrics from Previous Campaigns</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        name="Engagement"
                    />
                </div>
                <div className="form-group col-md-6 col-sm-12">
                    <label>Fee Structure</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        name="fee"
                    />
                </div>
                <div className="form-group col-md-6 col-sm-12">
                    <label>Preferred Brands</label>
                    <CreatableSelect
                        components={{ Placeholder }}
                        placeholder={''}
                        options={''}
                        />
                </div>
                <div className="form-group col-md-6 col-sm-12">
                    <label>Availability for Campaigns</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        name="Engagement"
                    />
                </div>

                <div className="form-group col-md-12 col-sm-12">
                    <label>Contractual Requirements</label>
                    <textarea
                            className="form-control"
                            placeholder="Enter here"
                            value={''}
                            name="description"
                            onChange={''}
                    />
                </div>
                <div className="col-md-6 col-sm-12 form-group radio_btn inline_feild">
                                        <p>
                                            {" "}
                                            <strong>Compliance with FTC Guidelines</strong>
                                        </p>
                                        <label className="check_bx">
                                            <input
                                                type="radio"
                                                value="checkbx1"
                                                name="checkField"
                                            />
                                            Yes
                                            <span className="checkmark"></span>
                                        </label>
                                        <label className="check_bx">
                                            <input
                                                type="radio"
                                                value="checkbx"
                                                name="checkField"                                               
                                            />
                                            No
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>

            <div className="col-sm-12">                     
                     <hr />
                     <button
                         type="submit"
                         className="blue_btn pull-right"
                         onClick={''}
                     >
                         {" "}
                         Post a Job{" "}
                     </button>
                     
                     <a href="/" className="default_btn">
                         Cancel
                    </a>
                    &nbsp; &nbsp;
                    <button className="stroke_btn" onClick={prevStep}>Back</button>  
                 </div>
                </div>

        </>
    )
}

export default InfliuencerBrandCollab