import { profession } from "../CreateUserProfile/registerConst";

export const JobCategory = profession;

export const FilmType = [
  { label: "Movie", value: "Movie" },
  { label: "Short Film", value: "Short Film" },
  { label: "Web Series OTT", value: "Web Series OTT" },
  { label: "Other", value: "Other" },
];


export const Genre = [
  { label: "Action", value: "Action" },
  { label: "Adventure", value: "Adventure" },
  { label: "Animated", value: "Animated" },
  { label: "Comedy", value: "Comedy" },
  { label: "Drama", value: "Drama" },
  { label: "Fantasy", value: "Fantasy" },
  { label: "Historical or Biopic", value: "Historical or Biopic" },
  { label: "Horror", value: "Horror" },
  { label: "Science Fiction", value: "Science Fictionl" },
  { label: "Suspense", value: "Suspense" },
  { label: "Thriller", value: "Thriller" },
  { label: "Western", value: "Western" },
  { label: "Other", value: "Other" }
];

export const JobExp = [
  { value: "Entry Level", label: "Entry Level" },
  { value: "Intermediate", label: "Intermediate" },
  { value: "Expert", label: "Expert" },
]


export const SeeJob = [
  { label: "Public", value: "Public" },
  { label: "Only BiG Talent", value: "Only BiG Talent" },
  { label: "Invite Only", value: "Invite Only" },
];

export const TalentType = [
  { label: "Independent", value: "Independent" },
  { label: "Agency", value: "Agency" },
];

export const JobHistory = [
  { label: "Total Job Allotted", value: "Total Job Allotted" },
  { label: "Job Inprogress", value: "Job Inprogress" },
  { label: "Job Completed", value: "Job Completed " },

  { label: "Job Cancelled", value: "Job Cancelled" },
  { label: "Job Incomplete", value: "Job Incomplete" },
  { label: "Client Remark out of 10", value: "Client Remark out of 10" }
];

export const ActorType = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Child Artist", value: "Child Artist" },
  { label: "Pet", value: "Pet" },
  // { label: "Other", value: "Other" },
];

export const auditionType = [
  { label: "Online Audition", value: "Online Audition" },
  { label: "Physical Visit Audition", value: "Physical Visit Audition" },
  { label: "Both", value: "Both" },
];
export const Compensations = [
  { label: "Pay", value: "Pay" },
  { label: "Non-Pay", value: "Non-Pay" },
  { label: "Others", value: "Others" },
]
export const Ethnicity =[
  { label: "Indian", value: "Indian" },
  { label: "Asian", value: "Asian" },
  { label: "African American", value: "African American" },
  { label: "Latino/Hispanic", value: "Latino/Hispanic" },
  { label: "Middle Eastern", value: "Middle Eastern" },
  { label: "European Descent", value: "European Descent" },
  { label: "Ethnic Ambiguity", value: "Ethnic Ambiguity" },
]