import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight, FaTimes } from "react-icons/fa";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import '../../scss/celebrityManagement.scss'
import _ from "lodash";

class YoutubeMarketing extends Component {

  render() {
    return (
      <>
        <Header />
        <SubHeader /> 
          <section id="inner_celeb_banner">
            <div class="container">
              <div class="row">
                <div class="col-sm-6 col-xs-8 inner_celebrity_info">
                  <h1>YOUTUBE MARKETING AGENCY</h1>
                  <p align="justify"> The foremost thing that <strong>YouTube marketing</strong> permits you is to convey yourself and display your personality in a mode that other platforms merely don’t. As per the study over 75% of communication is non-verbal, and that instantly making a video is a far more effectual way of communication. </p>
        
              <Link to="/ReachUs" class="stroke_btn">Contact US</Link>
                </div>
                <div class="col-sm-6 inner_celebrity_img">
                <img src="images/youtube-marketing-company.webp" alt="BollywoodisGood is best Celebrity Management Company in India" width="699" height="450" class="wow fadeInDown" data-wow-delay="0.3s" />	 
                </div>
              </div>
            </div>
          </section>

          <section id="inner_about">
          <div class="container">
          <div class="row">
          <div class="col-sm-5">
          <img src="images/youtube-ad-agency.webp"  width="458" height="458" alt="Celebrity Management Services in India" />
          </div>
          <div class="col-sm-7 inner_about_info">
          <div class="inner_head">
          <h2>Youtube Marketing Services</h2>
          </div>
          <p align="justify"> Remember the time in classes as a kid when nobody was paying attention, hence the teacher uses to put on a documentary? The topic was similar but all of a sudden everyone started paying attention to the screen. That’s the effect of the video. The same thing goes with <strong>Youtube marketing.</strong></p>
          <br />
          <p align="justify">YouTube marketing also has an enormous impact due to the demonstration of your capability and professionalism. It’s a fact that making a premium quality video with outstanding production values is hard and for that your need a <strong>Youtube Marketing Agency</strong> that’s will significantly provide the more professional and perfect way to market your Youtube regarding the services and products you offer. Finally, YouTube marketing is successful and not everyone can make it a success but BollywoodisGood can make it effectual for you as we have an expert team that handles you’re the marketing of your Youtube.</p>
          </div>
          </div>
          </div>
          </section>

          <section id="our_role">
          <div class="container">
          <div class="row">
          <div class="col-sm-7 role_info">          
          <div class="inner_head">
          <h2>YouTube Marketing Agency: We follow a process</h2>
          </div>
         <p align="justify">Doing YouTube marketing is very tough as in today’s era everybody is into it and the market has become intense. Therefore BollywoodisGood follows a proper process to make the marketing worthy and give the perfect results to you. Here is the process:</p>
          <br /> 
          <ul>
            <li>
            <p align="justify"><strong>Core Analysis :</strong> Performing deep analysis of channels, target groups and competitors with successive discussion and goal setting.</p></li>
           <br />
           <li><p align="justify"><strong>Roadmap :</strong>
            Personal and on-site strategy development of Youtube and Instagram goals.</p></li>
           <br />
           <li><p align="justify"><strong>Strategy :</strong>
           From one source Instagram concept, production, and editorial plan.</p></li>
           <br />
           <li><p align="justify"><strong>Community :</strong>
           Community management creates brand fans and makes certain organic reach.</p></li>
           <br />
           <li><p align="justify"><strong>Instagram Ads :</strong>
            Creating campaign assignment, paid coverage for content, evaluation and dark posts.</p></li>
          
          </ul>
          </div>
          <div class="col-sm-5">
          <img src="images/youtube-marketing-agency.webp"  loading="lazy" width="458" height="459" alt="Artist Management Services" />
          </div>
          </div>
          </div>
          </section>  

           
          <section id="inner_about">
          <div class="container">
          <div class="row">
          <div class="col-sm-5">
          <img src="images/youtube-marketing-services.webp"  width="458" height="458" alt="Celebrity Management Services in India" />
          </div>
          <div class="col-sm-7 inner_about_info">        
            <ul>
              <li><p align="justify"><strong>Reporting :</strong>
         With subsequent monitoring and reporting analyzes of content, community, and campaign</p></li>
          <br />
          <li><p align="justify"><strong>Meetings :</strong>
          For strategy optimization, frequent meetings are done with our social media experts.</p></li>
          <br />
          <li><p align="justify"><strong>Influencer :</strong>
          A central tool in social marketing is Brand advocates and recommendation marketing.</p></li>
          <br />
          <li><p align="justify"><strong>Events :</strong>
          With top media output, we organize influencer events.</p></li>
          </ul>
        <br />
        <p align="justify">These are some of the steps that the process has and else you will be able to know once you sign up with us for YouTube marketing with BollywoodisGood. Our years of experience in the field and expert professionals who are working with us make it possible to deliver satisfaction and 100% positive results. Hire our <strong>youtube marketing experts </strong>to enhace your business and increase the brand value. </p>
          </div>
          </div>
          </div>
          </section>

          <section id="inner_services">
          <div class="container">     
          <div class="text-center inner_head">
          <center><h2>How Does Our YouTube Marketing Company Work?</h2></center>
          </div>
          <p align="justify">There is no single strategy for YouTube marketing that can be used for every brand of the company. And this fact is taken very seriously by BiG’s experts. Every company has its own individuality and unique services and products; hence the planning and process for every company are done separately as per the requirement and the company or band’s details. <br /><br />
As a <strong>YouTube marketing agency</strong>, we usually estimate your website traffic to decide the best online platforms to spend, and frequently maintain the steadiness between your video marketing actions and their outcomes.<br /><br />
BolywoodisGood’s YouTube marketing team formulates and executes a multi-faceted plan together to attract more leads to your business. Not only this but we even make sure that the outcome of the marketing strategy is positive.
</p>
          </div>
        
          </section> 
           
        
           
        <Footer />
      </>
    );
  }
}

export default YoutubeMarketing;
