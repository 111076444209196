import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MainSlider from '../../components/HomeSection/MainSlider';

class slider extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    return (
      <div>
        {/* slider section start */}
        <section id="slider_part">
          <div className="container side_space">
            <div className="row">
             <div className='col-xs-12 no_space'>
              <MainSlider />
              </div>
            </div>
          </div>
        </section>
        {/* slider section ends */}
      </div>
    );
  }
}

export default slider;