export const jobData = {
    title: "",
    category: "",
    filmType: "",
    genre: "",
    description: "",
    coverLetter: "",
    skills: [],
    experienceLevel: "",
    currentCountry: "",
    currentCity: "",
    currentState: "",
    currentZipCode: "",
    visibility: "",
    talentCount: "",
    talentType: "",
    jobHistory: "",
    amountEarned: "",
    payType: "",
    hourlyRate: "",
    totalBudget: "",
    totalHours: "",
    startDate: "",
    endDate: "",
    screeningQuestions: [],
    otherQuestions: [],
}