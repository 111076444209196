import React from "react";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import NewsFeed from "../../components/NewsFeed/NewsFeed";

const NewsFeedPage = (props) => (
  <>
    <Header />
    <SubHeader />
    <NewsFeed {...props} />
    <Footer />
  </>
);

export default NewsFeedPage;
