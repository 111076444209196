import React from 'react';
import { useTranslation } from "react-i18next";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import _ from "lodash";
import { vlidateNumberField } from "../../Utilities/validateInputField";
import { loadOptionsResolveLanguage, defaultOption } from "../../Constants.js/languageConst";
// import filmCourseOptions from '../CreateUserProfile/filmCoursesConst';
import AsyncSelect from "react-select/async";
import { ActorType, Genre } from "./jobPostConst";
import { roleOption, filmIndustry, filmType, uniqueTraits, physique, postGraduation, jobNature, workingStyle, schedule, abilityToCommut, supplementalPayTypes } from "../../components/CreateUserProfile/registerConst";
import { FaPlus, FaMinus, } from "react-icons/fa";
import { Placeholder } from '../../commons/ReactSelectNoOptionsMessage';
import { config } from '../../actions/config';

const DetailsAndDescription = ({
    handleChangeDropDown,
    handleChangeSingle,
    screeningQuestions,
    addNewQuestionRow,
    onChangeQuestions,
    onAddingSuggestedRow,
    otherQuestions,
    removeQuestionRow,
    prevStep,
    nextStep,
    PostJobSubmit,
    defaultProps,
    ...props
}) => {
    const { t } = useTranslation();
    return (
        <>
            <div >
                <div className="tab_head">
                    <h4>Details and Description</h4>
                    <hr />
                </div>
                <div className="row">
                   {props.category === 'Artist or Actor or Talent' &&  <div className="form-group col-md-8 col-sm-12 clearfix">
                        <label>
                            Character type
                            <small
                                style={{ fontSize: 10, color: "green", marginLeft: 12 }}
                            >
                                (You can add if not found)
                            </small>
                        </label>
                        <CreatableSelect
                            options={ActorType}
                            components={{ Placeholder }}
                            placeholder={'Please choose actor type'}
                            isMulti
                            value={_.map(props.talentType, (val) => {
                                if (val) {
                                    return ({
                                    label: val,
                                    value: val,
                                })
                                }
                        })}
                            onChange={(e) =>
                                handleChangeDropDown({
                                    name: "talentType",
                                    value: _.map(e, (val) => val.label),
                                })
                            }
                        />
                    </div>}
                    {props.category === 'Artist or Actor or Talent' && (
                        <div className="form-group col-md-6 col-sm-12">
                            <label>Age</label>
                            <div className="row">
                                <div className="col-sm-6">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Min"
                                        name="minAge"
                                        value={props.minAge || ""}
                                        onChange={(e) => {
                                            vlidateNumberField(e) && handleChangeSingle(e);
                                        }}
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Max"
                                        name="maxAge"
                                        value={props.maxAge || ""}
                                        onChange={(e) => {
                                            vlidateNumberField(e) && handleChangeSingle(e);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    {props.category === 'Artist or Actor or Talent' && (
                        <div className="form-group col-md-6 col-sm-12">
                            <label>Role Type
                                <small style={{ fontSize: 10, color: "green", marginLeft: 12 }}>
                                    (You can add if not found)
                                </small>

                            </label>
                            <Select
                                options={roleOption}
                                isMulti
                                value={_.map(props.roleType, (val) => ({
                                    label: val,
                                    value: val,
                                }))}
                                onChange={(e) =>
                                    handleChangeDropDown({
                                        name: "roleType",
                                        value: _.map(e, (val) => val.label),
                                    })
                                }
                            />
                        </div>
                    )}

                  {props.category === 'Artist or Actor or Talent' &&  <div className="form-group col-md-12 col-sm-12">
                        <label>Charecter Breif / Sketch </label>
                        <textarea
                            className="form-control"
                            placeholder="Enter here"
                            value={props.description}
                            name="description"
                            onChange={handleChangeSingle}
                        />
                        <small>Minimum 100 Character</small>
                    </div>}
                    {props.category !== 'Artist or Actor or Talent' &&
                    <div style={{padding: 15}}>
                    <div className='row'>
                    <div className="form-group col-md-12 col-sm-12">
                        <label className="control-label">Job Description</label>
                    <textarea
                    rows={2}
                        className="form-control"
                        placeholder=""
                        name="jobDescription"
                        value={props.jobDescription || ""}
                        onChange={(e) => {
                            handleChangeSingle(e);
                        }}
                    />
                    </div>
                    <div className="form-group col-md-6 col-sm-12">
                        <label className="control-label">Working Style</label>
                                    <Select
                                        options={workingStyle}
                                        value={{
                                            value: props.workStyle,
                                            label: props.workStyle,
                                        }}
                                        onChange={(e) =>
                                            handleChangeDropDown({
                                                name: "workStyle",
                                                value: e.label,
                                            })
                                        }
                                    />
                    </div>
                    <div className="form-group col-md-6 col-sm-12">
                    <label className="control-label">Benefits
                    <small style={{ fontSize: 10, color: "green", marginLeft: 12 }}> (You can add if not found) </small>
                        </label>
                    <CreatableSelect
                            options={[{label:'', value:''}]}
                            isMulti
                            value={_.map(props.benefits, (val) => {
                                if (val) {
                                    return ({
                                        label: val,
                                        value: val,
                                    })
                                }
                            })}
                            onChange={(e) =>
                                handleChangeDropDown({
                                    name: "benefits",
                                    value: _.map(e, (val) => val.label),
                                })
                            }
                        />
           
                    </div>
                    </div>
                    <div className='row'>
                   
                    <div className="form-group col-md-6 col-sm-12">
                        <label className="control-label">Schedule</label>
                                    <Select
                                        options={schedule}
                                        value={{
                                            value: props.schedule,
                                            label: props.schedule,
                                        }}
                                        onChange={(e) =>
                                            handleChangeDropDown({
                                                name: "schedule",
                                                value: e.label,
                                            })
                                        }
                                    />
                    </div>
                    <div className="form-group col-md-6 col-sm-12">
                        <label className="control-label">Ability to commute/relocate</label>
                                    <Select
                                        options={abilityToCommut}
                                        value={{
                                            value: props.abilityToCommut,
                                            label: props.abilityToCommut,
                                        }}
                                        onChange={(e) =>
                                            handleChangeDropDown({
                                                name: "abilityToCommut",
                                                value: e.label,
                                            })
                                        }
                                    />
                    </div>
                    </div>
                    {/* <div className='row'>
                   
                    </div> */}
                    </div>
                    }
                    <div className="form-group col-md-6 col-sm-12">
                        <label>Genre</label>
                        <small style={{ fontSize: 10, color: "green", marginLeft: 12, fontWeight: "bold" }}>
                            (You can add if not found)
                        </small>
                        <CreatableSelect
                            options={Genre}
                            value={{ value: props.genre, label: props.genre }}
                            onChange={(e) =>
                                handleChangeDropDown({ name: "genre", value: e.label })
                            }
                        />
                    </div>
                    <div className="form-group col-md-6 col-sm-12">
                        <label>Film / Production Type

                            <small style={{ fontSize: 10, color: "green", marginLeft: 12 }}>
                                (You can add if not found)
                            </small>
                        </label>

                        <CreatableSelect
                            options={filmType}
                            isMulti
                            value={_.map(props.filmType, (val) => {
                                if (val) {
                                    return ({
                                    label: val,
                                    value: val,
                                })
                                }
                        })}
                            onChange={(e) =>
                                handleChangeDropDown({
                                    name: "filmType",
                                    value: _.map(e, (val) => val.label),
                                })
                            }
                        />
                    </div>
                    <div className="form-group col-md-6 col-sm-12">
                        <label>Film Industry
                            <small style={{ fontSize: 10, color: "green", marginLeft: 12 }}>
                                (You can add if not found)
                            </small>
                        </label>
                        <CreatableSelect
                            options={filmIndustry}
                            isMulti
                            value={_.map(props.filmIndustry, (val) => {
                                if (val) {
                                    return ({
                                        label: val,
                                        value: val,
                                    })
                                }
                            })}
                            onChange={(e) =>
                                handleChangeDropDown({
                                    name: "filmIndustry",
                                    value: _.map(e, (val) => val.label),
                                })
                            }
                        />
                    </div>

                    {props.category === 'Artist or Actor or Talent' && (
                        <div className="mid_head col-sm-12">
                            <h6>Physical Traits</h6>
                            <hr />
                            <div className="row">
                                <div className="form-group col-md-6 double_field">
                                    <label className="control-label">Height</label>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Ft"
                                                name="minHeightFeet"
                                                value={props.minHeightFeet || ""}
                                                onChange={(e) => {
                                                    vlidateNumberField(e) && handleChangeSingle(e);
                                                }}
                                            />
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="In"
                                                name="minHeightInch"
                                                value={props.minHeightInch || ""}
                                                onChange={(e) => {
                                                    vlidateNumberField(e) && handleChangeSingle(e);
                                                }}
                                            />
                                        </div>
                                        <label className="center_label">To</label>
                                        <div className="col-sm-6">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Ft"
                                                name="maxHeightFeet"
                                                value={props.maxHeightFeet || ""}
                                                onChange={(e) => {
                                                    vlidateNumberField(e) && handleChangeSingle(e);
                                                }}
                                            />
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="In"
                                                name="maxHeightInch"
                                                value={props.maxHeightInch || ""}
                                                onChange={(e) => {
                                                    vlidateNumberField(e) && handleChangeSingle(e);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group col-md-6">
                                    <label className="control-label">Weight</label>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Min in KG"
                                                name="minWeight"
                                                value={props.minWeight || ""}
                                                onChange={(e) => {
                                                    vlidateNumberField(e) && handleChangeSingle(e);
                                                }}
                                            />
                                        </div>

                                        <div className="col-sm-6">
                                            <input
                                                type="text"
                                                name="maxWeight"
                                                className="form-control"
                                                placeholder="Max in KG"
                                                value={props.maxWeight || ""}
                                                onChange={(e) => {
                                                    vlidateNumberField(e) && handleChangeSingle(e);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group col-md-6">
                                    <label className="control-label">Physique</label>
                                    <Select
                                        options={physique}
                                        //isMulti
                                        value={{
                                            value: props.physique,
                                            label: props.physique,
                                        }}
                                        onChange={(e) =>
                                            handleChangeDropDown({
                                                name: "physique",
                                                value: e.label,
                                            })
                                        }
                                    />
                                </div>
                                {props.category === 'Artist or Actor or Talent' && (
                                    <div className="form-group col-md-6">
                                        <label className="control-label">Unique Traits</label>
                                        <Select
                                            options={uniqueTraits}
                                            //isMulti
                                            value={{
                                                value: props.uniqueTraits,
                                                label: props.uniqueTraits,
                                            }}
                                            onChange={(e) =>
                                                handleChangeDropDown({
                                                    name: "uniqueTraits",
                                                    value: e.label,
                                                })
                                            }
                                        />
                                    </div>
                                )}
                                <div className="form-group col-md-12">
                                    <label className="control-label">
                                        Appearance or D/C{" "}
                                    </label>
                                    <input
                                        type="text"
                                        name="appearance"
                                        className="form-control"
                                        placeholder="Ethnic Wear, Western Wear, Sports Wear, Salwar Suits, Kurtas, Sarees ...etc"
                                        value={props.appearance}
                                        onChange={handleChangeSingle}
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="mid_head col-sm-12">
                        <h6>Other Information</h6>
                        <hr />
                        <div className="row">
                            <div className="form-group col-md-12">
                                <p>
                                    <strong>Lanugages</strong>
                                </p>
                                <div className="row">
                                    <div className="col-md-4">
                                        <label>Speak</label>
                                        <AsyncSelect
                                            cacheOptions
                                            defaultOptions={defaultOption}
                                            loadOptions={loadOptionsResolveLanguage}
                                            {...defaultProps}
                                            isMulti
                                            value={_.map(props.languagesRead, (val) => ({
                                                label: val,
                                                value: val,
                                            }))}
                                            onChange={(e) =>
                                                handleChangeDropDown({
                                                    name: "languagesRead",
                                                    value: _.map(e, (val) => val.label),
                                                })
                                            }
                                        />
                                    </div>

                                    <div className="col-md-4">
                                        <label>Read</label>
                                        <AsyncSelect
                                            cacheOptions
                                            defaultOptions={defaultOption}
                                            loadOptions={loadOptionsResolveLanguage}
                                            {...defaultProps}
                                            isMulti
                                            value={_.map(props.languagesSpeak, (val) => ({
                                                label: val,
                                                value: val,
                                            }))}
                                            onChange={(e) =>
                                                handleChangeDropDown({
                                                    name: "languagesSpeak",
                                                    value: _.map(e, (val) => val.label),
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <label>Write</label>
                                        <AsyncSelect
                                            cacheOptions
                                            defaultOptions={defaultOption}
                                            loadOptions={loadOptionsResolveLanguage}
                                            {...defaultProps}
                                            isMulti
                                            value={_.map(props.languagesWrite, (val) => ({
                                                label: val,
                                                value: val,
                                            }))}
                                            onChange={(e) =>
                                                handleChangeDropDown({
                                                    name: "languagesWrite",
                                                    value: _.map(e, (val) => val.label),
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group col-md-12">
                                <label className="control-label">Must Have</label>
                                <input
                                    type="text"
                                    name="mustHave"
                                    className="form-control"
                                    placeholder="Ex. Theatre Background or Feature Film Experience or NSD or FTI"
                                    value={props.mustHave}
                                    onChange={handleChangeSingle}
                                />
                            </div>

                            <div className="form-group col-md-6">
                                <label className="control-label">
                                    Mandatory Conditions
                                </label>
                                <input
                                    type="text"
                                    name="mandatoryConditions"
                                    className="form-control"
                                    placeholder="Ex. Only For Mumbai Based Actors  "
                                    value={props.mandatoryConditions}
                                    onChange={handleChangeSingle}
                                />
                            </div>



                           { props.category === 'Artist or Actor or Talent' && <>  <div className="form-group col-md-6">
                                <label className="control-label">
                                    Specific Role Criteria
                                </label>
                                <input
                                    type="text"
                                    name="specificRoleCriteria"
                                    className="form-control"
                                    placeholder="Ex. Swimming or Football player or Dance"
                                    value={props.specificRoleCriteria}
                                    onChange={handleChangeSingle}
                                />
                            </div>

                            <div className="form-group col-md-6">
                                <label className="control-label">
                                    Competitor Brands (if any)
                                </label>
                                <input
                                    type="text"
                                    name="competitorBrands"
                                    className="form-control"
                                    placeholder="Enter brand"
                                    value={props.brand}
                                    onChange={handleChangeSingle}
                                />
                            </div>
                            </>}

                            <div className="form-group col-md-6">
                                <label className="control-label">Shoot Locations</label>
                                <input
                                    type="text"
                                    name="shootLocations"
                                    className="form-control"
                                    placeholder="Mumbai, Jaipur, Newyork, Washington...etc"
                                    value={props.shootLocations}
                                    onChange={handleChangeSingle}
                                />
                            </div>

                            <div className="form-group col-sm-12">
                                <div className="row">
                                    {!props.discloseLaterAuditionDate &&
                                        <>

                                            <div className="col-md-4 col-sm-6 form-group">
                                                <strong>Shoot Start Date</strong>
                                                <input
                                                    type="date"
                                                    className={`form-control ${props.shootStartDate ? "" : "date-time-picker"}`}
                                                    name="shootStartDate"
                                                    value={props.shootStartDate}
                                                    onChange={handleChangeSingle}
                                                />
                                            </div>

                                            {/* // props.shootStartDate && */}
                                            <div className="col-md-4 col-sm-6 form-group">
                                                <strong>Shoot End Date</strong>
                                                <input
                                                    type="date"
                                                    placeholder=""
                                                    className={`form-control ${props.shootEndDate ? "" : "date-time-picker"}`}
                                                    name="shootEndDate"
                                                    value={props.shootEndDate}
                                                    onChange={(e) => {
                                                        if (props.shootStartDate < e.target.value) {
                                                            handleChangeSingle(e)
                                                        }
                                                        else {
                                                            alert('Shoot end date can not be less than start date')
                                                        }

                                                    }}
                                                />
                                            </div>
                                        </>
                                    }
                                    <div className="col-md-4 col-sm-12" style={{ marginTop: '18px' }}>
                                        <label className="check_bx">
                                            <input
                                                type="checkbox"
                                                value="checkbx"
                                                name="checkField"
                                                onClick={() =>
                                                    handleChangeSingle({
                                                        target: { value: !props.discloseLaterAuditionDate, name: "discloseLaterAuditionDate" },
                                                    })
                                                }
                                            />
                                            Disclose Latter
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>


                            <div className="form-group col-md-4 col-sm-12">
                                <label className="control-label">Job Nature </label>
                                <Select
                                    options={jobNature}
                                    //isMulti
                                    value={{
                                        value: props.jobNature,
                                        label: props.jobNature,
                                    }}
                                    onChange={(e) =>
                                        handleChangeDropDown({
                                            name: "jobNature",
                                            value: e.label,
                                        })
                                    }
                                />
                            </div>
                            <div className="col-md-6 col-sm-12 form-group radio_btn inline_feild">
                                <p>
                                    {" "}
                                    <strong>Passport </strong>
                                </p>
                                <div className="form-group toggle_input">

                                    <label
                                        for="yes"
                                        onClick={() =>
                                            handleChangeSingle({
                                                target: { name: "passportRequired", value: true },
                                            })
                                        }
                                    >
                                        Yes
                                        <div className="switch">
                                            {/* {props.uploadImageNeeded ? ( */}
                                                <input
                                                    type="radio"
                                                    id="yes"
                                                    value="hr"
                                                    name="btns"
                                                    defaultChecked={props.passportRequired}
                                                />
                                            {/* ) : (
                                                <input type="radio" id="yes" value="hr" name="btns" />
                                            )} */}
                                            <span class="slider_check round"></span>
                                        </div>
                                    </label>
                                    <label
                                        for="no"
                                        onClick={() =>
                                            handleChangeSingle({
                                                target: { name: "passportRequired", value: false },
                                            })
                                        }
                                    >
                                        No
                                        <div className="switch">
                                            <input type="radio" id="no" value="fixed" name="btns" defaultChecked={!props.passportRequired}/>
                                            <span class="slider_check round"></span>
                                        </div>
                                    </label>
                                </div>
                            </div>

                            {props.category === 'Artist or Actor or Talent' && (
                                <>
                                    <div className="col-md-4 col-sm-12 form-group radio_btn inline_feild">
                                        <p>
                                            {" "}
                                            <strong>Introduction Video Needed </strong>
                                        </p>
                                        <label className="check_bx">
                                            {props.introductionVideoNeeded ? (
                                                <input
                                                    type="radio"
                                                    value="introVideoTrue"
                                                    name="checkField"
                                                    onClick={() =>
                                                        handleChangeSingle({
                                                            target: {
                                                                value: true,
                                                                name: "introductionVideoNeeded",
                                                            },
                                                        })
                                                    }
                                                />

                                            ) : (
                                                <input
                                                    type="radio"
                                                    value="introVideoTrue"
                                                    name="checkField"
                                                    onClick={() =>
                                                        handleChangeSingle({
                                                            target: {
                                                                value: true,
                                                                name: "introductionVideoNeeded",
                                                            },
                                                        })
                                                    }
                                                />
                                            )}
                                            Yes
                                            <span className="checkmark"></span>
                                        </label>
                                        <label className="check_bx">
                                            <input
                                                type="radio"
                                                value="introVideoFalse"
                                                name="checkField"
                                                onClick={() =>
                                                    handleChangeSingle({
                                                        target: {
                                                            value: false,
                                                            name: "introductionVideoNeeded",
                                                        },
                                                    })
                                                }
                                            />
                                            No
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    {props.introductionVideoNeeded && (

                                        <div className="form-group">
                                            {/* <label className="control-label">Video Link</label>
                                            <input
                                                type="text"
                                                name="introductionVideoLink"
                                                className="form-control"
                                                placeholder="Enter Url"
                                                value={props.introductionVideoLink}
                                                onChange={handleChangeSingle}
                                            /> */}
                                        </div>
                                    )}

                                    {props.introductionVideoNeeded && (
                                        <>
                                        <div className='col-md-12'>
                                            <div className='row'>
                                            <div className="form-group col-md-6">
                                                <label className="control-label">Email Id</label>
                                                <input
                                                    type="email"
                                                    name="introVideoEmail"
                                                    className="form-control"
                                                    placeholder="Email id to get candidate's video url"
                                                    value={props.introVideoEmail}
                                                    onChange={handleChangeSingle}
                                                />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label className="control-label">Whatsapp</label>
                                                <input
                                                    type="text"
                                                    name="introVideoWhatsappNumber"
                                                    className="form-control"
                                                    placeholder="Enter whatsapp"
                                                    value={props.introVideoWhatsappNumber || ""}
                                                    onChange={(e) => {
                                                        vlidateNumberField(e) && handleChangeSingle(e);
                                                    }}
                                                />
                                            </div>
                                            </div>
                                            </div>
                                        </>
                                    )}

                                    <div className="form-group upload_resume_field col-sm-6 col-md-4">
                                        <p>
                                            <strong>Provide Script</strong>
                                        </p>
                                        <br />
                                        <label
                                            htmlFor="upload_script"
                                            className="control-label stroke_btn"
                                        >
                                            Attach Script
                                        </label>

                                        <input
                                            type="file"
                                            name="script"
                                            id='upload_script'
                                            className="form-control"
                                            onChange={(e) => { props.fileSelectedHandler(e) }}
                                        />
                                        <span>
                                            {props.script && <>
                                                <a href={config.FILE_URL + props.script} target="_blank">
                                                    <img src={`${process.env.PUBLIC_URL}images/Downloads-icon.png`} style={{ height: 40, borderRadius: 10 }} /><br />
                                                </a>
                                            </>}
                                        </span>
                                    </div>
                                    <div className="col-md-4 col-sm-12 form-group radio_btn inline_feild">
                                        <p>
                                            {" "}
                                            <strong>Attached Script Video Needed</strong>
                                        </p>
                                        <label className="check_bx">
                                            <input
                                                type="radio"
                                                value="checkbx1"
                                                name="checkField"

                                                onClick={() =>
                                                    handleChangeSingle({
                                                        target: { value: true, name: "videoForScript" },
                                                    })
                                                }
                                            />
                                            Yes
                                            <span className="checkmark"></span>
                                        </label>
                                        <label className="check_bx">
                                            <input
                                                type="radio"
                                                value="checkbx"
                                                name="checkField"
                                                onClick={() =>
                                                    handleChangeSingle({
                                                        target: {
                                                            value: false,
                                                            name: "videoForScript",
                                                        },
                                                    })
                                                }
                                            />
                                            No
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>

                                    {props.videoForScript && (
                                        <>
                                            {/* <div className="form-group col-md-12">
                                                <label className="control-label">Video Link</label>
                                                <input
                                                    type="text"
                                                    name="videoLinkForScript"
                                                    className="form-control"
                                                    placeholder="Enter Url"
                                                    value={props.videoLinkForScript}
                                                    onChange={handleChangeSingle}
                                                />
                                            </div> */}
                                            <div className="form-group col-md-6">
                                                <label className="control-label">Email Id</label>
                                                <input
                                                    type="email"
                                                    name="scriptVideoEmail"
                                                    className="form-control"
                                                    placeholder="Email id to get candidate's video url"
                                                    value={props.scriptVideoEmail}
                                                    onChange={handleChangeSingle}
                                                />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label className="control-label">Whatsapp</label>
                                                <input
                                                    type="text"
                                                    name="scriptVideoWhatsappNumber"
                                                    className="form-control"
                                                    placeholder="Enter whatsapp"
                                                    value={props.scriptVideoWhatsappNumber || ""}
                                                    onChange={(e) => {
                                                        vlidateNumberField(e) && handleChangeSingle(e);
                                                    }}
                                                />
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                            <div className="form-group col-md-6 col-sm-12">
                                <label className="control-label">
                                    Highest Qualification{" "}
                                </label>
                                <small style={{ fontSize: 10, color: "green", marginLeft: 12 }}>
                                    (You can add if not found)
                                </small>
                                <CreatableSelect
                                    options={postGraduation}
                                    //isMulti
                                    value={{
                                        value: props.highestQualification,
                                        label: props.highestQualification,
                                    }}
                                    onChange={(e) =>
                                        handleChangeDropDown({
                                            name: "highestQualification",
                                            value: e.label,
                                        })
                                    }
                                />
                            </div>


                           {props.category === 'Artist or Actor or Talent' &&  <div className="form-group toggle_input col-sm-12">
                                <p>
                                    <strong>Upload your 3 different images as per character brief?</strong>
                                </p>
                                <label
                                    for="uploadImagesNeeded_yes"
                                    onClick={() =>
                                        handleChangeSingle({
                                            target: { name: "uploadImagesNeeded", value: true },
                                        })
                                    }
                                >
                                    Yes
                                    <div className="switch">
                                        {/* {props.uploadImageNeeded ? ( */}
                                            <input
                                                type="radio"
                                                id="uploadImagesNeeded_yes"
                                                value="hr"
                                                name="uploadImagesNeeded"
                                                checked={props.uploadImagesNeeded}
                                            />
                                        {/* ) : (
                                            <input type="radio" id="uploadImagesNeeded_yes" value="hr" name="uploadImagesNeeded" />
                                         )} */}
                                        <span class="slider_check round"></span>
                                    </div>
                                </label>
                                <label
                                    for="uploadImagesNeeded_no"
                                    onClick={() =>
                                        handleChangeSingle({
                                            target: { name: "uploadImagesNeeded", value: false },
                                        })
                                    }
                                >
                                    No
                                    <div className="switch">
                                        <input type="radio" id="uploadImagesNeeded_no" value="fixed" name="uploadImagesNeeded" checked={!props.uploadImagesNeeded} />
                                        <span class="slider_check round"></span>
                                    </div>
                                </label>
                            </div>}
                        </div>
                    </div>

                    <div className="mid_head col-sm-12">
                        <h6>Screening Questions</h6>
                        <p>Add screening questions and/or require a cover letter</p>
                        <hr />
                        <div className="screen_ques">
                            {(screeningQuestions[0] &&
                                _.map(screeningQuestions, (val, index) => (
                                    <input
                                        onChange={(e) =>
                                            onChangeQuestions({ index, value: e.target.value })
                                        }
                                        style={{ marginTop: 10, marginRight: 10 }}
                                        value={val}
                                        type="text"
                                        className="form-control"
                                        placeholder="Write your own question here"
                                    />
                                ))) || (
                                    <input
                                        onChange={(e) =>
                                            onChangeQuestions({ index: 0, value: e.target.value })
                                        }
                                        style={{ marginTop: 10, marginRight: 10 }}
                                        type="text"
                                        className="form-control"
                                        placeholder="Write your own question here"
                                    />
                                )}
                            <span onClick={() => addNewQuestionRow()}>
                                <FaPlus />
                            </span>
                            <span
                                style={{ marginLeft: 10 }}
                                onClick={() =>
                                    removeQuestionRow(screeningQuestions.length - 1)
                                }
                            >
                                <FaMinus />
                            </span>
                        </div>

                        {/* <div className="suggested_ques">
                            <p>
                                <strong>Suggested</strong>
                            </p>
                            <label className="check_bx">
                                <input
                                    onChange={() =>
                                        onAddingSuggestedRow(
                                            "Mention about yourself and your work experience ?"
                                        )
                                    }
                                    type="checkbox"
                                    value="ques1"
                                    checked={_.find(
                                        otherQuestions,
                                        "Mention about yourself and your work experience ?"
                                    )}
                                />
                                Mention about yourself and your work experience ?
                                <span className="checkmark"></span>
                            </label>

                            <label className="check_bx">
                                <input
                                    onChange={() =>
                                        onAddingSuggestedRow(
                                            "Any privious experience in adventure movie ?"
                                        )
                                    }
                                    type="checkbox"
                                    value="ques1"
                                    checked={_.find(
                                        otherQuestions,
                                        "Any privious experience in adventure movie ?"
                                    )}
                                />
                                Any privious experience in adventure movie ?
                                <span className="checkmark"></span>
                            </label>

                            <label className="check_bx">
                                <input
                                    onChange={() =>
                                        onAddingSuggestedRow("Have you performed lead role ?")
                                    }
                                    type="checkbox"
                                    value="ques1"
                                    checked={_.find(
                                        otherQuestions,
                                        "Have you performed lead role ?"
                                    )}
                                />
                                Have you performed lead role ?
                                <span className="checkmark"></span>
                            </label>
                        </div> */}
                    </div>

                    <div className="col-sm-12">
                        <br />
                        <hr />
                        <br />
                        <button className="stroke_btn" onClick={prevStep}>Back</button>
                        <button style={{ marginLeft: "15px" }}
                            type="submit"
                            className="stroke_btn"
                            onClick={(e) => PostJobSubmit(e, false)}
                        >
                            {" "}
                            Save{" "}
                        </button>

                        <button className="blue_btn pull-right" onClick={() => nextStep()} >Continue</button>


                    </div>
                </div>
            </div>
        </>
    )
}

export default DetailsAndDescription