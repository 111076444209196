import React, { Component } from 'react';
import PostJobPage from "../../pages/PostJob/PostJobPage";
import { jobData } from './jobPostConst';
import { postJob, getJobPostDetail, uploadFiles } from '../../actions/index';
import _ from 'lodash';
import swal from 'sweetalert';
class PostJobContainer extends Component {
    state = { ...jobData, step: 1 };

    componentDidMount() {
        if (this.props.location ?.state ?.id) {
            getJobPostDetail(this.props.location.state.id)
                .then(res => this.setState({ ...res.data.data, _id: this.props.location.state.new ? null : res.data.data ?._id }));
        }
    };

    PostJobSubmit = (e, published) => {
        const { screeningQuestions, otherQuestions } = this.state;
        e.preventDefault();
        postJob({ ...this.state, published, screeningQuestions: _.compact([...otherQuestions, ...screeningQuestions]) })
            .then(res => {
                if(published) this.props.history.push('/JobList')
                else if(res && res.data.status){
                    swal("Updated", { buttons: false, timer: 2000, icon: 'success' });
                }
            })

    };

    validateForm = () => {
        if (this.state.step === 1) {
          const { title, category, jobTitle } = this.state;
          this.setState({
            errors: {
                title: title || "",
                category: category || "",
                jobTitle: jobTitle || "",
            },
          });
          if (title && category && jobTitle) return true;
          else return false;
        }else{return true}
    };

    nextStep = (stepNumber) =>{
        if (!this.validateForm()) {
            return;
          }
    this.setState(state => ({ step: stepNumber ? stepNumber : state.step + 1 }))
} ;

    prevStep = () => this.setState(state => ({ step: state.step - 1 }));

    handleChangeDropDown = e => this.setState({ [e.name]: e.value });

    handleChangeSingle = e => this.setState({ [e.target.name]: e.target.value });

    addNewQuestionRow = () => this.setState(state => ({ screeningQuestions: [...state.screeningQuestions, ''] }))

    removeQuestionRow = ind => {
        let screeningQuestions = _.cloneDeep(this.state.screeningQuestions);
        screeningQuestions = _.filter(screeningQuestions, (val, index) => index !== ind);
        this.setState({ screeningQuestions });
    }


    onChangeQuestions = e => {
        console.log(e, 'dvsgvs')
        let { screeningQuestions } = this.state;
        screeningQuestions[e.index] = e.value;
        this.setState({ screeningQuestions });
    }


    onAddingSuggestedRow = value => {
        let { otherQuestions } = this.state;
        if (otherQuestions.includes(value)) {
            const index = _.indexOf(otherQuestions, value);
            console.log(otherQuestions, value, index, 'dfsds');
            otherQuestions[index] = null;
            otherQuestions = _.compact(otherQuestions);
            console.log(otherQuestions, 'hgfsdf')
        }
        else {
            otherQuestions.push(value);
        }
        this.setState({ otherQuestions });
    }

    defaultProps = {
        placeholder: "Type Languages"
    }
    fileSelectedHandler = async(e) => {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        const configs = {
          headers: {
            'content-type': 'multipart/form-data'
          }
        };
        const targetName = e.target.name;
        try {
          const res = await uploadFiles(formData,configs)
          if (res && res.data && res.data.data) {
            this.setState({ [targetName]: res.data.data.filename });
          }
        } catch (ex) {
          console.log('upload error', ex);
        }
      }

    render() {
        console.log(this.state, this.props, "dwjdwhuhw");
        return <PostJobPage
            {...this.state}
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            defaultProps={this.defaultProps}
            handleChangeSingle={this.handleChangeSingle}
            handleChangeDropDown={this.handleChangeDropDown}
            PostJobSubmit={this.PostJobSubmit}
            addNewQuestionRow={this.addNewQuestionRow}
            onChangeQuestions={this.onChangeQuestions}
            onAddingSuggestedRow={this.onAddingSuggestedRow}
            removeQuestionRow={this.removeQuestionRow}
            fileSelectedHandler={this.fileSelectedHandler}
        />

    }
}

//const mapDispatchToProps = dispatch => ({jobPostCall : (jobData) => dispatch(Actions.postJob(jobData))});
export default PostJobContainer;