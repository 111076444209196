import React, { Component } from "react";
import UserSideAuditionPage from '../../pages/UserSideAudition/UserSideAuditionPage';
import { getAuditionDetails } from '../../actions/index';
class UserSideAuditionContainer extends Component {

  state = { AuditionDetail: {} };

  componentDidMount() {
    if (this.props.match ?.params ?.id) {
      getAuditionDetails(this.props.match.params.id)
        .then(res => this.setState({ AuditionDetail: res.data ?.data }));
    }
  }

  render() {
    return <UserSideAuditionPage {...this.state} {...this.props} />
  }
}

export default UserSideAuditionContainer

