import React, { Component } from "react";
import SubmitApplicationPage from "../../components/SubmitApplication/Index";
import { getJobPostDetail, submitApplication, uploadFiles } from '../../actions/index';
import _ from 'lodash';
class SubmitApplicationContainer extends Component {

  state = { jobPostDetail: {} };

  componentDidMount() {
    if (this.props.match ?.params ?.id) {
      getJobPostDetail(this.props.match.params.id)
        .then(res => this.setState({ jobPostDetail: res.data ?.data }));
    }
  }

  handleChangeValues = e => {
    if (e.index || e.index === 0) {
      const answers = _.cloneDeep(this.state.answers) || [];
      answers[e.index] = e.value;
      this.setState({ answers });
    }
    else {
      this.setState({ [e.name]: e.value })
    }
  };


  handleSubmit = () => {
    submitApplication(this.state)
      .then(res => this.props.history.push('/SearchJob'));
  }
  fileSelectedHandler = async(e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    const configs = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    const targetName = e.target.name;
    try {
      const res = await uploadFiles(formData,configs)
      if (res && res.data && res.data.data) {
        this.setState({ [targetName]: res.data.data.filename });
      }
    } catch (ex) {
      console.log('upload error', ex);
    }
  }

  render() {
    console.log(this.state, "kedjeijde");
    return <SubmitApplicationPage
      {...this.state}
      handleChangeValues={this.handleChangeValues}
      handleSubmit={this.handleSubmit}
      fileSelectedHandler={this.fileSelectedHandler}
    />
  }
}

export default SubmitApplicationContainer;
