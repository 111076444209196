import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight, FaTimes } from "react-icons/fa";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import '../../scss/celebrityManagement.scss'
import _ from "lodash";

class PromotionalStrategy extends Component {

  render() {
    return (
      <>
        <Header />
        <SubHeader /> 
          <section id="inner_celeb_banner">
            <div class="container">
              <div class="row">
                <div class="col-sm-6 col-xs-8 inner_celebrity_info">
                  <h1>PROMOTIONAL STRATEGY BUILDING</h1>
                  <p align="justify"> When you are looking for a <strong>Promotional strategy building</strong> for your business then every marketing type is useful for you. BollywoodisGood has expert team that helps in the building of <strong>promotional strategies</strong>. From years of experience and the expertise we make sure that the client gets the best services from us. </p>
                
              <Link to="/ReachUs" class="stroke_btn">Contact US</Link>
                </div>
                <div class="col-sm-6 inner_celebrity_img">
                <img src="images/promotional-strategy-building.webp" alt="BollywoodisGood is best Celebrity Management Company in India" width="699" height="450" class="wow fadeInDown" data-wow-delay="0.3s" />	 
                </div>
              </div>
            </div>
          </section>

          <section id="inner_about">
          <div class="container">
          <div class="row">
          <div class="col-sm-5">
          <img src="images/strategy-building-company.webp"  width="458" height="458" alt="Celebrity Management Services in India" />
          </div>
          <div class="col-sm-7 inner_about_info">
          <div class="inner_head">
          <h2>Promotional Strategy Building Company</h2>
          </div>
          <p align="justify">By the techniques and the plan that is executed in your marketing plan a <strong>promotion strategy</strong> is known, in order to enhance the demand for your service and products. A vital role has been played by the promotional strategies in the marketing mix (product, placement, price and promotion), and they rotate around:</p>
          <br />
        <ul>
          <li><strong>Target audience</strong> – who are your audience for whom you are selling and what are their interests</li>
          <br />
        <li><strong>Budget</strong> – the investment you want to do for your promotion</li>
        <br />
        <li><strong>Plan of action</strong> – what are the strategies that you are using to reach your purpose and make a profit.</li>
        </ul>      
         <br />
         
          <p align="justify">So these are few aspects that we will ask your team, so that we can mutually build the worthy strategy for your business. Now we will see what sort of marketing we will be using for your <strong>promotional strategy building. </strong></p>
          </div>
          </div>
          </div>
          </section>

          <section id="our_role">
          <div class="container">
          <div class="row">
          <div class="col-sm-7 role_info">          
          <div class="inner_head">
          <h2>Content Marketing</h2>
          </div>
          <p align="justify"> It is one of the most popular strategies that are being used for the promotion of any brand or business. Content marketing is king and BollywoodisGood has to sync with the king to get the positive result from your <strong>promotional marketing.</strong> Through content tell the audience about the benefits of your brand and usage of your services or products. If you give something in writing that will surely build the trust.</p>
          <br />
          <p align="justify">So we will be building the trust of the audience as well as connecting them with you directly through the blogs. It can be a two way communication that gives information about you the audience as well as give them a platform to comment and ask their query. And content marketing is not limited to blogging, it’s needed in social platforms online paid advertising or sponsored ads, website, and many other. So if you are looking for the <strong>promotional strategy building company</strong>
,BollywoodisGood can help you with this.</p>
          
          </div>
          <div class="col-sm-5">
          <img src="images/promotional-marketing-strategy.webp"  loading="lazy" width="458" height="459" alt="Artist Management Services" />
          </div>
          </div>
          </div>
          </section>  

          <section id="inner_services">
          <div class="container">
          <div class="text-center inner_head">
        <h2>Social Media and E-mail marketing</h2>
        </div>
          <p align="justify">No we all are well aware with Social media and e-mail marketing. So in this part we make sure that your presence is string in the social media platforms like Facebook, Instagram, Pinterest, Twitter, Linkedin etc. People are getting too much involved with social media and somehow trust the brands and companies who have good social media presence. Hence, BollywoodisGood will build the profiles and make you popular on social media. The e-mail marketing is another very strong platform as many businessman as well as professionals read there mails and are very much active on emails, so to grab the attention of this section audience, e-mail marketing is a very good option.</p>
        </div>
        
          </section> 

          <section id="inner_about">
          <div class="container">
          <div class="row">
          <div class="col-sm-5">
          <img src="images/branding-strategy-building.webp"  loading="lazy" width="458" height="459" alt="Artist Management Services" />
          </div>
          <div class="col-sm-7 role_info">         
          <div class="inner_head">
          <h2>Influencer Marketing </h2>
          </div>
          <p align="justify">This is the oldest marketing type but these days have gained the limelight. Most of the big brand gets associated with big celebrities and let them endorse their brand or company. This way the followers of the influencer will start building the trust on your brand and you get the fame in very short span of time. It’s a very vital promotional strategy and works all the time. Hence, to give your brand or company a kick start and gain the trust of the customers, BollywoodisGood will help you to get the influencer that will endorse your brand and give you the customers you need. </p>
                    <br />
           <div class="inner_head"><h2>Rewards and Sales Coupons </h2></div>
           <p align="justify">The most ancient but always worthy marketing strategy is rewards and sales coupons. Every buyer is looking for sale and rewards when he/she thinks of buying. Hence, we will make sure that as per the details, market and competitors of your brand or products and services, we will arrange rewards and sales coupon to attract the customers. </p>
          </div>
          </div>
          </div>
          </section>  

          

          <section id="last_info">
          <div class="container">
          <div class="row">
          <div class="col-xs-12">
          <blockquote> 
          <p align="justify">BollywoodisGood will use more <strong>promotional strategies</strong> to make your brand or company shine bright in the market. Therefore, if you are looking for someone who can help you to boost your brand or business, then BollywoodisGood is the right choice for you. 

          </p>
          </blockquote>
          </div>
          </div>
          </div>
          </section>  

          

        
         
        <Footer />
      </>
    );
  }
}

export default PromotionalStrategy;
