import React, { Component } from 'react';
import {  getCurrentUser, getUserList, getUserTransactions } from '../../actions/index';
import _ from 'lodash';
import UserWalletPage from '../../pages/UserWallet/UserWalletPage';
import moment from 'moment';

class UserWalletContainer extends Component {
 allData=[];
    state = {
        filters: [{ type: 'status', value: 'All' }],
        search: '',
        
    };
    componentDidMount() {
         this.getCurrentUser();
    };

    getCurrentUser = () => {
        getCurrentUser((user) => {
            this.setState({ currentUser: user, appliedJobs: user.appliedJobs, savedJobs: user.savedJobs });
            this.getPaymentList(user._id)
        })
    }
    getPaymentList = (userId) => {
        getUserTransactions(userId)
            .then(res => {
                const list = []
                if (res.data?.data) {
                    getUserList().then((res1)=>{
                        (res.data?.data).forEach((e)=>{
                           list.push({...e, tnxType: userId === e.payee ? "Credit" : "Debit"})
                           
                       })
                          const newList = res.data?.data.map(re => {
                            const toUserId = re.payee === userId? re.payer: re.payee;
                            console.log(toUserId)
                        const udetails = res1.data.data.find(u=> u._id === toUserId);
                        
                            return {...re,otherPartyName: udetails?.userName}
                        })
                        console.log(newList)
                       this.setState({ data:newList })
                       this.allData = newList;
                    })
                }
            })
    }


    onChangeFilter = e => {
        console.log(e)
        let { filters, search } = this.state;
        const alreadyPresent = _.find(filters, { ...e });
        if (_.isEmpty(alreadyPresent)) {
            this.setState({ filters: _.uniqBy([e, ...filters], 'type') }, () => {});
        }
        else {
            const newFilters =  _.filter(filters, val => val.type != e.type)
            this.setState({ filters: newFilters}, () => {});
        }
        if (e.type === 'fromDate' || e.type === 'toDate' ) {
            const filteredData = e.type === 'fromDate' ? this.allData.filter(f => moment(f.updatedAt).isSameOrAfter(moment(e.value),'date')) : this.allData.filter(f =>moment(f.updatedAt).isSameOrBefore(moment(e.value),'date') )
            this.setState({data: filteredData})
        }else{

            if (e.value !== "All") {
                const filteredData = this.allData.filter(f => f.status === e.value )
            this.setState({data: filteredData})
            }else{
                this.setState({data: this.allData})
        }
        }
        
    }
clearFilter = ()=>{
    this.setState({filters:[{ type: 'status', value: 'All' }]});
    this.setState({data: this.allData})
}

    render() {
        return <UserWalletPage
            {...this.state}
            {...this.props}
            onChangeFilter={this.onChangeFilter}
            allData={this.allData}
            clearFilter={this.clearFilter}
        />
    }
}
export default UserWalletContainer;
