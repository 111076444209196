import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import _ from "lodash";
import { vlidateNumberField } from "../../Utilities/validateInputField";
import { loadOptionsResolveLanguage, defaultOption } from "../../Constants.js/languageConst";
// import filmCourseOptions from '../CreateUserProfile/filmCoursesConst';
import AsyncSelect from "react-select/async";
import { ActorType, Genre } from "../JobPost/jobPostConst";
import { roleOption, filmIndustry, filmType, uniqueTraits, physique, postGraduation, jobNature, workingStyle, schedule, abilityToCommut, supplementalPayTypes } from "../CreateUserProfile/registerConst";
import { FaPlus, FaMinus, } from "react-icons/fa";
import { Placeholder } from '../../commons/ReactSelectNoOptionsMessage';
import { config } from '../../actions/config';

const InfliuencerSocialMedia = ({ 
    handleChangeDropDown,
    handleChangeSingle,
    defaultProps,  
    prevStep,
    nextStep,   
    ...props
}) => {
    const { t } = useTranslation();

    const [sliderMale, setSliderMale] = useState(0); 
    const [sliderFemale, setSliderFemale] = useState(0); 
    const [sliderOthers, setSliderOthers] = useState(0); 
  
  const updateSliderMale = (event) => {
    const value = event.target.value;
    const percentage = (value / 100) * 100; 
    setSliderMale(percentage.toFixed(0));
  }
  const updateSliderFemale= (event) => {
    const value = event.target.value;
    const percentage = (value / 100) * 100; 
    setSliderFemale(percentage.toFixed(0));
  }
  const updateSliderOthers = (event) => {
    const value = event.target.value;
    const percentage = (value / 100) * 100; 
    setSliderOthers(percentage.toFixed(0));
  }
    return (
        <>
           
           <div className="tab_head">
                <h4>Social Media Insights</h4>
                <hr />
               </div>

               <div className='row'>  
               <div className="form-group col-md-6 col-sm-12">
                    <label>Content Type</label>
                    <CreatableSelect
                        components={{ Placeholder }}
                        placeholder={'Select content'}
                        options={''}
                        />
                </div>
                <div className="form-group col-md-6 col-sm-12">
                    <label>Content Categories</label>
                    <CreatableSelect
                        components={{ Placeholder }}
                        placeholder={'Select categories'}
                        options={''}
                        />
                </div>
                <div className="form-group col-md-4 col-sm-12">
                    <label> Content Style</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Educational, Entertaining, Storytelling, etc."
                        name="Engagement"
                    />
                </div>
   
                <div className="form-group col-md-4 col-sm-12">
                    <label>Age</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder=" "
                        name="jobTitle"
                        maxLength={3}
                        onKeyDown={(e) => {
                            if (!(e.key >= '0' && e.key <= '9') && e.keyCode !== 8) {
                                e.preventDefault();
                            }
                        }}
                    />
                </div>

                <div className="form-group col-md-4 col-sm-12">
                    <label>Location</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder=" "
                        name="location"
                    />
                </div>

                <div className="form-group col-md-12 col-sm-12">
                    <p style={{ paddingBottom: "8px" }}><strong>Gender demographics</strong></p>
                   
                   <div className="row">
                        <div className="col-md-4 col-sm-12">
                        <label>Male</label>
                        <div class="range-slider">
                        <input class="range-slider__range" type="range"  min="0" max="100"
                         onInput={updateSliderMale} value={sliderMale}/>
                        <span class="range-slider__value">{sliderMale}%</span>
                        </div>
                        </div>

                        <div className="col-md-4 col-sm-12">
                        <label>Female</label>
                        <div class="range-slider">
                        <input class="range-slider__range" type="range" min="0" max="100"
                        onInput={updateSliderFemale} value={sliderFemale}/>
                        <span class="range-slider__value">{sliderFemale}%</span>
                        </div>
                        </div>

                        <div className="col-md-4 col-sm-12">
                        <label>Other</label>
                        <div class="range-slider">
                        <input class="range-slider__range" type="range" min="0" max="100"
                         onInput={updateSliderOthers} value={sliderOthers}/>
                         <span class="range-slider__value">{sliderOthers}%</span>
                        </div>
                        </div>

                    </div>
                </div>
                          

                <div className="form-group col-md-12">
                                <p>
                                    <strong>Lanugages</strong>
                                </p>
                                <div className="row">
                                    <div className="col-md-4">
                                        <label>Speak</label>
                                        <AsyncSelect
                                            cacheOptions
                                            defaultOptions={defaultOption}
                                            loadOptions={loadOptionsResolveLanguage}
                                            {...defaultProps}
                                            isMulti
                                            value={_.map(props.languagesRead, (val) => ({
                                                label: val,
                                                value: val,
                                            }))}
                                            onChange={(e) =>
                                                handleChangeDropDown({
                                                    name: "languagesRead",
                                                    value: _.map(e, (val) => val.label),
                                                })
                                            }
                                        />
                                    </div>

                                    <div className="col-md-4">
                                        <label>Read</label>
                                        <AsyncSelect
                                            cacheOptions
                                            defaultOptions={defaultOption}
                                            loadOptions={loadOptionsResolveLanguage}
                                            {...defaultProps}
                                            isMulti
                                            value={_.map(props.languagesSpeak, (val) => ({
                                                label: val,
                                                value: val,
                                            }))}
                                            onChange={(e) =>
                                                handleChangeDropDown({
                                                    name: "languagesSpeak",
                                                    value: _.map(e, (val) => val.label),
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <label>Write</label>
                                        <AsyncSelect
                                            cacheOptions
                                            defaultOptions={defaultOption}
                                            loadOptions={loadOptionsResolveLanguage}
                                            {...defaultProps}
                                            isMulti
                                            value={_.map(props.languagesWrite, (val) => ({
                                                label: val,
                                                value: val,
                                            }))}
                                            onChange={(e) =>
                                                handleChangeDropDown({
                                                    name: "languagesWrite",
                                                    value: _.map(e, (val) => val.label),
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
    
                            <div className="form-group col-md-6 col-sm-12">
                    <label>Follower Count per Platform</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        name="Follower"
                    />
                </div>
                <div className="form-group col-md-6 col-sm-12">
                    <label>Engagement Rates</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        name="Engagement"
                    />
                </div>

                <div className="form-group col-md-6 col-sm-12">
                    <label>Age Range</label>
                    <CreatableSelect
                        components={{ Placeholder }}
                        placeholder={''}
                        options={''}
                        />
                </div>
                <div className="form-group col-md-6 col-sm-12">
                    <label>Location Distribution</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        name="Engagement"
                    />
                </div>
                <div className="col-sm-12">
                        <hr />
                        <button className="stroke_btn" onClick={prevStep}>Back</button>                    

                        <button className="blue_btn pull-right" onClick={() => nextStep()} >Continue</button>


                    </div>
                </div>
        </>
    )
}

export default InfliuencerSocialMedia