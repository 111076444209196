import React from 'react';
import { globalStyles } from '../../core/theme/Styles';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Card, CardContent } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.page.background.color,
        padding: '20px'
    }

}));
function NotFound(props) {
    const { t } = useTranslation();
    const classes = useStyles();
    const globalStyle = globalStyles();
    return (
        <Box display="flex"
            width="100wh"
            height="100vh"
            alignItems="center"
            justifyContent="center">
            <Card className={classes.root}>
                <CardContent>
                    <Typography className={globalStyle.pageHeaderLabel}>
                        {t('ErrorPageMessage')}
                    </Typography>
                </CardContent>
            </Card>
        </Box>

    );
}

export default NotFound;
