import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight, FaTimes } from "react-icons/fa";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import '../../scss/celebrityManagement.scss'
import _ from "lodash";

class FashionInfluencer extends Component {

  render() {
    return (
      <>
        <Header />
        <SubHeader /> 
          <section id="inner_celeb_banner">
            <div class="container">
              <div class="row">
                <div class="col-sm-6 col-xs-8 inner_celebrity_info">
                  <h1>FASHION INFLUENCER MARKETING</h1>
                  <p align="justify">The fashion industry is growing day by day and following the latest trend and comforting the customers both have become significant. Hence, competing in this fashion industry is a hard job. But with fashion influencer marketing this can be achieved.</p>
                  <Link to="/ReachUs" class="stroke_btn">Contact US</Link>
                </div>
                <div class="col-sm-6 inner_celebrity_img">
                <img src="images/fashion-influencer-marketing.webp" alt="BollywoodisGood is best Celebrity Management Company in India" width="699" height="450" class="wow fadeInDown" data-wow-delay="0.3s" />	 
                </div>
              </div>
            </div>
          </section>

          <section id="inner_about">
          <div class="container">
          <div class="row">
          <div class="col-sm-5">
          <img src="images/mens-fashion-influencer-marketing.webp"  width="458" height="458" alt="Celebrity Management Services in India" />
          </div>
            <div className="col-sm-7">
            <div class="inner_head">
          <h2>Build Your Brand</h2>
          </div>
          <p align="justify">We at BollywoodisGood have the team that has expertise and knowledge in the field of <strong>fashion influencer marketing</strong> and will help you achieve your goal. As a fashion influencer agency we work keeping in mind the industry, trend and product and services you offer in the market. After the complete research and understanding of your goal, we plan the strategies and start the search for the right influencer.</p>
          <br />
          <p align="justify">When you opt for the most latest and effectual marketing tool influencer marketing, you just have to deal with the already engaging audience and followers. Choosing the right influencer as per your aims and industry is very important and as influencer have their followers and engagement, hence it becomes easy to get the potential customers, brand awareness and enhancement in the ROI. </p>
          </div>          
        
          </div>
          </div>
          </section>

          <section id="our_role">
          <div class="container">
          <div class="row">
          <div class="col-sm-7 role_info">          
          <div class="inner_head">
          <h2>Fashion Influencer Management Services</h2>
          </div>
         <p align="justify">We work on securing the right influencers for your men’s fashion brand we work with them according to the trend and market demand. We continually observe the behavior of your audience, decodes what motivates them, whilst creating the most engaging stories about your business/brand that will be loved by your audience. Basically, as continuously generating the effectual and effective techniques that would give benefit to your brand/business, we’re even educating your audience on how and why to trust your brand and great awareness about your brand/business through influencer marketing.</p>
          <br />           
          <p align="justify">Over the years we have worked with thousands of influencers and created the most effective campaign for the big brands, from notable influencers whom everyone loves and trust. We even do our study regarding the fashion market altogether, making sure that our strategies and ideas are persistently original and that the brands we work for are always ahead of the curve from their competitors.</p>
       </div>
          <div class="col-sm-5">
          <img src="images/women-fashion-influencer-marketin.webp"  loading="lazy" width="458" height="400" alt="Artist Management Services" />
          </div>
          </div>
          </div>
          </section>  

          <section id="inner_services">
          <div class="container">     
          <div class="col-sm-12">
          <div class="text-center inner_head">
          <h2>Specialized Team to help you select the best Influencer.</h2>
          </div>
          <p align="justify">Fashion is a highly competitive and growing industry and following the trend and giving comfort to the customers is a hard job to do. With the increasing competition in the market, choosing the correct marketing tool is very much essential and can be done by an experienced company like BollywoodisGood.</p>
          <br/>
          <p align="justify">The most effectual and efficient marketing tool that will give you instant results is influencer marketing. As a fashion influencer agency, we at BollywoodisGood conduct complete research work and deeply understand the goal of the brand/business, target audience, competitors, etc and then plan the strategy for influencer marketing.</p>
          <br />
          <p align="justify">Our team of highly professional and experienced experts will search and find the best influencer that will endorse your brand/business and will increase the ROI plus brand awareness. Capturing the market of fashion is not at all an easy task; you need a quality product and the latest trend plus the most successful influencer marketing campaign. BollywoodisGood team will do everything for you, from creating a successful campaign to finding the influencer and giving you the progress report.</p>
           </div>
          </div>        
          </section> 

          <section id="last_info">
          <div class="container">
          <div class="row">
          <div class="col-xs-12">
          <blockquote>
          <p align="justify">The best part of influencer marketing is that when you choose the correct influencer, you already have an engaging audience and plenty of followers who trust the influencer. And fashion products will get a lot of attention through influencer marketing. Depending on the goal of the brand/business the influencer works as per that, so it becomes easy on what to display on the page. 
          <br /><br />
          With the increasing social media users, it’s very important to grab the social media market and work with the influencer. The influencer will convey the message of your brand/business and will surely be going to enhance the reputation and trust of the brand. BollywoodisGood will find the influencer, coordinate with the influencer, and collaborate with the influencer, you just need to trust us and we will build a market for you and make you a successful brand. 
          <br /><br />
          From creativity to the best influencer you get the most effective results from the BollywoodisGood team. We not only help in finding the influencer for promoting or endorsing your brand but help you in the collaboration that is a success and design the most efficient campaign for influencer marketing as well. The result of the campaign as well the work done by the influencer will be shown to you via a report that will be created as per the engagement, popularity and increase in your ROI.
          </p>
          </blockquote>
          </div>
          </div>
          </div>
          </section>

           
        
           
        <Footer />
      </>
    );
  }
}

export default FashionInfluencer;
