import React, { Component } from "react";
import _ from "lodash";

const HireTalentSidebar = ({ user }) => {
  return (
    <div className="col-md-4 col-sm-5 side_details hire_talent_side">
      <div className="shadow_bg clearfix ">
        <div className="about_profile">
          <ul>
            <li>
              <span>Rs 100k+ </span> <p>Total Earnings</p>
            </li>
            <li>
              <span>36 </span> <p>Total Jobs</p>
            </li>
            <li>
              <span>68</span> <p>Total Hours</p>
            </li>
          </ul>
        </div>

        <div className="people_contact profile_info_bx">
          <h6>Rate</h6>
          <h5>Rs {user ?.additionalInfo ?.currentCompensationAmount}/{user ?.additionalInfo ?.currentCompensationType}</h5>
        </div>

        <div className="people_contact profile_info_bx">
          <h6>Availability</h6>
          {user ?.additionalInfo ?.currentWorkStatus}
          <p>
            {user ?.additionalInfo ?.tillDate
              ? "Till Date : " + user ?.additionalInfo ?.tillDate
                : user ?.additionalInfo ?.fromAvailableHours
                  ? user ?.additionalInfo ?.fromAvailableHours + "to" + user ?.additionalInfo ?.toAvailableHours
                    : ""}
          </p> <br />
          {user ?.additionalInfo ?.currentWorkStatus == "Partially Available " ?
            <p>
              <p> <strong>Hours: </strong> {user ?.additionalInfo ?.hoursPerDay}</p>
              <p> <strong>Days:</strong> {_.map(user ?.additionalInfo ?.days, e=>(
                (<>
                  <span>{e +', '}</span>
                  </>)
              ))}</p>
            </p> :null
          }
 
         
          {/* <p>3+ days response time </p>                   */}
        </div>
        <div className="people_contact profile_info_bx">
          <h6>Education</h6>
          {_.map(user ?.education, (val) => (
            <>
              <p>
                <strong>{val.collegeUniversity}  </strong>
                | {val.location}
              </p>
              <p>{val.course}</p>
              {val.courseDuration &&
                < p > {val.courseDuration} Months</p>
              }
              <p>{val.passingYear}</p>
              <br />
            </>
          ))}
        </div>
      </div>
    </div >
  );
};
export default HireTalentSidebar;
