import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FaPencilAlt, FaTimes, FaRecycle, FaEyeSlash, FaUser, FaCheck } from 'react-icons/fa';

const AuditionDetailSidebar = ({ AuditionDetail, removeAudition ,usersRoleDetails}) => {
    const user = localStorage.getItem('user');
	const myId = user ? JSON.parse(user)?._id:'';
    return(
    <div className="col-md-3 col-sm-4 detail_sidebar text-center">
        <ul>
        <li> {
            // AuditionDetail?.appliedUsers && AuditionDetail?.appliedUsers.find(e => e === AuditionDetail.userId) ? 
            // <span  style={{color: '#ff3838'}}>Applied</span>
            // :
         (AuditionDetail?.userId !==myId && !(AuditionDetail?.appliedUsers?.map(ad=> ad._id)?.includes(myId))) ? <Link className="stroke_btn" to={`/UserSideAudition/${AuditionDetail._id}`}> Apply</Link> : AuditionDetail?.userId !==myId ? <span className="stroke_btn" style={{color: '#ff3838', borderColor:'#ff3838'}}>Already Applied {" "}<FaCheck /></span>: ''
            }</li>
            {AuditionDetail?.userId ===myId ?<>
            <li><Link to={{ pathname: "/CreateAudition", state: { id: AuditionDetail ?._id} }} className="stroke_btn"><FaPencilAlt /> Edit Posting</Link></li>
            <li onClick={removeAudition}><Link to="/" className="stroke_btn"><FaTimes /> Remove Posting</Link></li>
            <li><Link to={{ pathname: "/CreateAudition", state: { id: AuditionDetail ?._id , new: true } }} className="stroke_btn"><FaRecycle /> Reuse Posting</Link></li>
            </>:null
        }
            {/* <li><Link to="/" className="stroke_btn"><FaEyeSlash /> Make Private</Link></li> */}
            {/* <li><Link to="/" className="stroke_btn"><FaUser /> Applied Users</Link></li> */}
        </ul>
    </div>
)};
export default AuditionDetailSidebar;