import React, { useState } from "react";
import _ from "lodash";
import { vlidateNumberField } from "../../Utilities/validateInputField";

const OrganiserDetails = ({ handleChangeDropDown, handleChangeSingle, prevStep,nextStep, PostAuditionSubmit, ...props }) => {
   
   
   
    const [emailError, setEmailError] = useState('');

    const handleBlurEmail = (e) => {
      const { value } = e.target;
      const emailRegExp = /^\S+@\S+\.\S+$/;
  
      if (!emailRegExp.test(value)) {
        setEmailError('Invalid email address');
      } else {
        setEmailError('');
      }
    }
    
    return (
        <>
            <div >
                <div className="tab_head">
                    <h4>Organiser Details / Casting By</h4>
                    <hr />
                </div>
                <div className="row">
                    <div className="form-group col-md-6">
                        <label className="control-label">Name</label>
                        <input
                            type="text"
                            name="organiserName"
                            className="form-control"
                            placeholder="Enter name"
                            value={props.organiserName}
                            onChange={handleChangeSingle}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <label className="control-label">Designation</label>
                        <input
                            type="text"
                            name="designation"
                            className="form-control"
                            placeholder="Casting Director or Assistant Director or Agent"
                            value={props.designation}
                            onChange={handleChangeSingle}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <label className="control-label">
                            Company or Agency Name{" "}
                        </label>
                        <input
                            type="text"
                            name="companyName"
                            className="form-control"
                            placeholder="Enter name"
                            value={props.companyName}
                            onChange={handleChangeSingle}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <label className="control-label">Production House </label>
                        <input
                            type="text"
                            name="productionHouse"
                            className="form-control"
                            placeholder="Enter name"
                            value={props.productionHouse}
                            onChange={handleChangeSingle}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <label className="control-label">Email Id </label>
                        <input
                            type="email"
                            name="emailId"
                            className="form-control"
                            placeholder="Enter email"
                            value={props.emaiId}
                            onChange={handleChangeSingle}
                            onBlur={handleBlurEmail}
                        />
                        {emailError && <p style={{ color: 'red',fontSize:'12px' }}>{emailError}</p>}
                    </div>
                    <div className="form-group col-md-6">
                        <label className="control-label">Contact Number </label>
                        <input
                            type="tel"
                            name="contactNumber"
                            className="form-control"
                            placeholder="Enter number"
                            value={props.contactNumber || ""}
                            maxLength={10}
                            onChange={(e) => {
                                vlidateNumberField(e) && handleChangeSingle(e);
                            }}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <label className="control-label">WhatsApp </label>
                        <input
                            type="tel"
                            name="whatsappNumber"
                            className="form-control"
                            placeholder="Enter number"
                            value={props.whatsappNumber || ""}
                            maxLength={10}
                            onChange={(e) => {
                                vlidateNumberField(e) && handleChangeSingle(e);
                            }}
                        />
                    </div>
                    <div className="col-sm-12">
                        <hr />
                        <button className="stroke_btn" onClick={prevStep}>Back</button>
                        {/* <button className="blue_btn pull-right" onClick={(e) => PostAuditionSubmit(e, true)}>Submit</button> */}
                        <button className="default_btn pull-right" onClick={() => nextStep()} >Next</button>
                        <button className="default_btn pull-right" style={{ marginRight: "10px" }} onClick={(e) => PostAuditionSubmit(e, false)}>Save as Draft</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OrganiserDetails;