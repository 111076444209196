import React, { useState,useEffect } from "react";
import { Link } from 'react-router-dom';
import { FaRegEdit, FaSearch, FaTimes } from 'react-icons/fa';
import { FaAngleLeft, FaAngleRight, } from 'react-icons/fa';
import _, { isArray } from 'lodash';
import moment from 'moment';
import Select from "react-select";
import Pagination from "../Pagination/Pagination";

const sortOptions = [
    {
        label: 'Latest',
        value: 'updatedAt',
        order: -1
    },
    {
        label: 'Title',
        value: 'title',
        order: 1
    },
    {
        label: 'Relavence',
        value: 'category',
        order: 1
    },
]
const AllPost = ({ onChangeSearch, removePost, publishPost, data,onChangeSort,setToggleFilter,user, ...props }) => {
  let pageSize = 5;
  const [newList, setNewList] = useState([])
  const [pageIndex, setPageIndex] = useState(1);

useEffect(() => {
  if(data){
    let d = data.slice(0,pageSize)
    setNewList(d)
  }
  }, [data])
  const pageChangeHandler = (index) => {
    console.log(index)
    const slicedList = data.slice((index - 1) * pageSize, (index * pageSize))
    setNewList(slicedList)
    setPageIndex(index)
  
  }
   return (
     <div className="col-md-9 col-sm-8 post_result" onClick={()=>{setToggleFilter(true)}}>
      <div className="row">
       <div className="form-group search_field col-sm-9 col-xs-8 full">
         <input
           type="search"
           placeholder="Search job postings"
           className="form-control"
           onChange={(e) => onChangeSearch(e.target.value)}
         />
         <button type="button" className="default_btn">
           <FaSearch />
         </button>
       </div>
      
         <div className="form-group col-sm-3 col-xs-4 full">
           <Select
             placeholder="Sort By"
             options={sortOptions}
             onChange={(e) => onChangeSort(e)}
           />
         </div>
       </div>
       <div className="shadow_bg clearfix">
         <ul>
           {_.map(newList, (val) => (
             <li>
               <div className="breakdown_casting_info">
                <div className="flex_bx clearfix">
                 <div className="casting_list_head clearfix">
                   <h3>{val.title}</h3>
                   <div className="disc_brief">
                     {val.jobTitle &&  <span>
                       <strong>Job Title :</strong>{" "}
                       {val.jobTitle}
                     </span>}
                     
                   {val.filmType &&  <span>
                       <strong>Production Type :</strong>{" "}
                       {val.filmType?.join(", ")}
                     </span>}
                    {val.talentType  && val.talentType.filter(e=> e.length).length> 0 ? <span>
                       <strong> Character type : </strong>{" "}
                       {val.talentType?.join(", ")}{" "}
                     </span>: ''}
                     {val.workStyle &&  <span>
                       <strong>Work Style :</strong>{" "}
                       {val.workStyle}
                     </span>}
                     {val.productionHouse &&  <span>
                       <strong>Production House :</strong>{" "}
                       {val.productionHouse}
                     </span>}
                     {val.benefits &&  val.benefits.length ?<span>
                       <strong>benefits :</strong>{" "}
                       {isArray(val.benefits) ? val.benefits?.join(", "): val.benefits}
                     </span>:''}
                    {val.currentCity &&  <span>
                       <strong>City : </strong> {val.currentCity}
                     </span>}
                    {val.minAge &&  <span>
                       <strong>Age: </strong> {val.minAge} - {val.maxAge}
                     </span>}
                     <span>
                       <strong>Posted on : </strong>{" "}
                       {moment(val.createdAt).format("YYYY-MM-DD")}
                     </span>
                   </div>
                   {val.description && <span className="discrption">
                     <strong>Charecter Breif / Sketch : </strong>
                     {val.description}
                   </span>}
                   {val.jobDescription && <span className="discrption">
                     <strong>Job Description : </strong>
                     {val.jobDescription}
                   </span>}{" "}
                  {val.languagesSpeak && val.languagesSpeak.length ? <span>
                     <strong>Language Speak : </strong>
                     {val.languagesSpeak?.join(", ")}
                   </span> : ''}
                   {/* <p><strong>{val.filmType}</strong> | <strong>{val.genre}</strong> | <span><strong>Hourly: {val.hourlyRate}</strong></span> | <span><strong>{val.experienceLevel}</strong></span> | <span>{moment(val.createdAt).format('LL') }</span> |  <span><strong>Mumbai</strong></span></p> */}
                 </div>

                 <div className="left_casting_btn">
                   <Link
                     to={{
                       pathname: "/PostJob",
                       state: { id: val._id, new: true },
                     }}
                     className="stroke_btn">
                     Reuse Post
                   </Link>
                   
                   {user && user._id === val.userId ?  <>
                       <Link
                         className="message stroke_btn"
                         to={{ pathname: "/PostJob", state: { id: val._id } }}>
                         {" "}
                         <FaRegEdit /> Edit Post
                       
                       </Link>

                       <Link
                         className="message stroke_btn"
                         to="/JobList"
                         onClick={() => removePost(val._id)}>
                         {" "}
                         <FaTimes />  Delete Post
                       
                       </Link>
                     </> :null}
                 </div>
                 </div>
                 <div className="casting_list_btn clearfix">
                   <Link to={`/PostJobDetail/${val._id}`}>More Details</Link>
                   {/* {!val.published && (
                     <Link onClick={() => val._id} className="stroke_btn">
                       publish Post
                     </Link>
                   )} */}
                   <div className="action_btn">
                     <div className="job_action">
                       <ul>
                         <li>
                           <strong>{val.appliedUsers?.length}</strong>{" "}
                           Applications
                         </li>
                         <li>
                           <strong>0</strong> Messaged
                         </li>
                         <li>
                           <strong>{val.hiredUsers?.length}</strong> Hired
                         </li>
                       </ul>
                     </div>

                   </div>
                 </div>
               </div>
             </li>
           ))}
         </ul>
         <div class="pagination_part clearfix text-right">
         <Pagination totalCount={data?.length || 0} pageSize={pageSize} onPageChange={pageChangeHandler} currentPage={pageIndex} />
         </div>
       </div>
     </div>
   );
};

export default AllPost;