import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight, FaTimes } from "react-icons/fa";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import '../../scss/celebrityManagement.scss'
import _ from "lodash";

class BabyProductsInfluencer extends Component {

  render() {
    return (
      <>
        <Header />
        <SubHeader /> 
          <section id="inner_celeb_banner">
            <div class="container">
              <div class="row">
                <div class="col-sm-6 col-xs-8 inner_celebrity_info">
                  <h1>TOYS & BABY PRODUCTS INFLUENCER MARKETING</h1>
                  <p align="justify">Everything related to kid’s fashion, baby products, or even toys are needed to be very creative and of good quality. Marketing this range of products is quite challenging but interesting as well. As per the current market, there are some giants sitting out there and successfully increasing their ROI and beating them is very hard.</p>
                  <Link to="/ReachUs" class="stroke_btn">Contact US</Link>
                </div>
                <div class="col-sm-6 inner_celebrity_img">
                <img src="images/toys-baby-products-influencer-marketing.webp" alt="BollywoodisGood is best Celebrity Management Company in India" width="699" height="450" class="wow fadeInDown" data-wow-delay="0.3s" />	 
                </div>
              </div>
            </div>
          </section>

          <section id="inner_about">
          <div class="container">
          <div class="row">
          <div class="col-sm-5">
          <img src="images/kids-fashion-influencer.webp"  width="458" height="458" alt="Celebrity Management Services in India" />
          </div>
            <div className="col-sm-7">
            <div class="inner_head">
          <h2>Product Branding</h2>
          </div>
          <p align="justify">As social media influencing our life hence brand/business can opt for influencer marketing for this range of products. People spend most of the time searching for brands on social media and following a bunch of influencers whom they trust. Hence, BollywoodisGood helps in creating the campaign for influencer marketing and search the right influencer for your product range.</p>
          <br />
          <p align="justify">To collaborate with the influencer and to create the campaign it is very much important to deeply understand the in and out of the brand/business, so after conducting a research on what your brand/business is offering, what is your target audience and who are your competitors, our team will coordinate and collaborate with the influencer and make the idea and plan clear so that he/she can work accordingly, as well as this research will help in designing a successful campaign. Our team of expert professionals knows the process of influencer marketing and will do the best to expand and grow your brand/business.</p>
          </div>          
        
          </div>
          </div>
          </section>

          <section id="last_info">
          <div class="container">
          <div class="row">
          <div class="col-xs-12">
          <blockquote>
          <p align="justify">When we choose the influencer for you we make sure that the followers and engagement of that influencer are huge and can attract more potential customers for your brand/business. The followers of the influencer will automatically get attracted to your products because they already have trust towards the influencer, so whatever he/she will endorse or promote will gain popularity and sales. The main focus of our team is to offer the best services and enhance your return on investment through our successful campaign. Hence, BollywoodisGood is considered the most trusted influencer marketing agency that focuses on making the brand/business huge.</p>
          </blockquote>
          </div>
          </div>
          </div>
          </section>
        <Footer />
      </>
    );
  }
}

export default BabyProductsInfluencer;
