import React, { useEffect, useState } from "react";
// import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import _ from 'lodash';
import moment from "moment";
import axios from "axios";

const NotificationDropdown = ({ notifications=[], redirectPage }) => {
  // const { t } = useTranslation();
  const [notificationList, setNotificationList] = useState([]);
  useEffect(() => {
    setNotificationList(notifications)
    // let loggedInUserStr = localStorage.getItem('user');
    // const loggedInUser = loggedInUserStr ? JSON.parse(loggedInUserStr): {}
    // const allowNotifications = loggedInUser?.additionalInfo?.allowPushNotification;
    // let filters = [];
    // filters = loggedInUser?.additionalInfo?.pushNotificationFilter || [];

    // if(!allowNotifications){
    //   setNotificationList([])
    // }else{
    //   const noti_list = []
    //   notifications.forEach((d)=>{
    //     if(d.mainCategory === "Audition" && d.subCategory === 'AuditionApplication' && d.filter){
    //       let allow = false
    //       d.filter.forEach((fltr)=>{
    //         if(filters.includes(fltr)){
    //           allow = true;    
    //         }
    //       })
    //       if(allow)noti_list.push(d)
    //     }else{
    //       noti_list.push(d)
    //     }
    //   })
    //   setNotificationList(noti_list)
    // }
  }, [notifications]);

  return (
    <>
      <ul className="dropdown-menu notifications" role="menu" aria-labelledby="dLabel">

        <div className="notification-heading">
          <h4 className="menu-title">Notifications</h4>
          <Link to="/Notification">
            <h4 className="menu-title pull-right">View all <i className="glyphicon glyphicon-circle-arrow-right"></i></h4>
          </Link>
        </div>
        <div className="notifications-wrapper">
          <h6>Job <span className="badge">{_.filter(notificationList, val => val.mainCategory === 'jobPost').length}</span></h6>

          {
            _.map(_.filter(notificationList, val => val.mainCategory === 'jobPost')?.slice(0, 3) , val => (
              <a key={val.id} className="content" onClick={() => redirectPage(val)}>
                <div className="notification_img"> <img src="images/fill_user.png" alt="img" /></div>
                <div className="notification-item">
                  <h4 className="item-title">{val.content}</h4>
                  <small>{moment(new Date(val.createdAt.seconds * 1000)).format('ll HH:MM A')}</small>  </div>
              </a>
            ))
          }

          {/* <h6>My Film/Team <span className="badge">{_.filter(notifications, val => val.mainCategory === 'makeMyFilm')}</span></h6>


          {
            _.map(_.filter(notificationList, val => val.mainCategory === 'makeMyFilm')?.slice(0, 3) , val => (
              <a key={val.id} className="content" onClick={() => redirectPage(val)}>
                <div className="notification_img"> <img src="images/fill_user.png" alt="img" /></div>
                <div className="notification-item">
                  <h4 className="item-title">{val.content}</h4>
                  <small>{moment(new Date(val.createdAt.seconds * 1000)).format('ll HH:MM A')}</small>  </div>
              </a>
            ))
          } */}

          <h6>Auditions <span className="badge">{_.filter(notificationList, val => val.mainCategory === 'Audition')?.length}</span></h6>

          {
            _.map(_.filter(notificationList, val => val.mainCategory === 'Audition') ?.slice(0, 3) , val => (
              <a key={val.id} className="content" onClick={() => redirectPage(val)}>
                <div className="notification_img"> <img src="images/fill_user.png" alt="img" /></div>
                <div className="notification-item">
                  <h4 className="item-title">{val.content}</h4>
                  <small>{moment(new Date(val.createdAt.seconds * 1000)).format('ll HH:MM A')}</small>  </div>
              </a>
            ))
          }


        </div>

      </ul>
    </>
  );
};

export default NotificationDropdown;
