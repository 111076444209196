import axios from "axios";
import { API } from '../constants';

const apiMiddleware = ({ dispatch }) => next => action => {
    next(action);

    if (action.type !== API) return;
    console.log("apiMiddleware");
    const {
        url,
        method,
        data,
        accessToken,
        onRequestStart,
        onRequestEnd,
        onSuccess,
        onFailure,
        headers

    } = action.payload;
    const dataOrParams = ["GET"].includes(method) ? "params" : "data";

    // axios default configs
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL || "";
    axios.defaults.headers.common["Content-Type"] = "application/json";
    let header1;
    if (accessToken)
        header1 = { Authorization: `Bearer ${accessToken}` }

    if (onRequestStart) dispatch(onRequestStart()); // Invoke action to update state in store that api request begins.

    axios
        .request({
            url,
            method,
            headers: header1,
            [dataOrParams]: data
        })
        .then(({ data }) => {
            console.log("onSuccess");
            dispatch(onSuccess(data));
        })
        .catch(error => {
            dispatch(onFailure(error));
        })
        .finally(() => {
            if (onRequestEnd) dispatch(onRequestEnd()); // Invoke action to update state in store that api request ends.
        });
};

export default apiMiddleware;
