import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    headerContainer: {
        marginTop: 10,
        padding:3
    },
    billFrom: {
        marginTop: 0,
        paddingBottom: 3,
        fontFamily: 'Helvetica-BoldOblique',
        fontSize:12
    },
    blankSpace:{
        height:10
    },
    address:{
      fontSize:"10px",
        fontFamily:'Helvetica'
    }
  });


  const BillFrom = ({ invoice }) => {
    const aggregatorData = invoice.payee
    return (
      <View style={styles.headerContainer}>
        <Text style={styles.billFrom}>From,(aggregator)</Text>
        <Text style={styles.address}>{aggregatorData.name}</Text>
        <Text style={styles.address}>{aggregatorData.address}</Text>
        <View style={{ fontSize: "10px", marginTop: 5 }}>
          <Text style={{}}>Email : {aggregatorData.email}</Text>
          <Text>Contact No : {aggregatorData.phone}</Text>
          <Text>GST : {aggregatorData.gstNo}</Text>
          <Text>PAN : {aggregatorData.pan}</Text>
        </View>
      </View>
    );};
  
  export default BillFrom