import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#555'
const styles = StyleSheet.create({
    row: {
        flexDirection: "column",
        borderBottomColor: borderColor,
        fontFamily:'Helvetica',
        borderBottomWidth: 1,
        alignItems: "center",
        fontStyle: "bold",
        flexGrow: 1,
        width:'60%',
        border:1,
        marginLeft:'20%',
        marginBottom: 10,
        marginTop: 10
    },
   
    bottomTable:{
        border:1,

    },
    topRow:{
        borderBottomWidth:1,
        flexDirection:'row',

    },
    leftCol:{
        width: "80%",
        borderRightWidth: 1
    },
    rightCol:{
        width: "20%",
        paddingLeft:15
    },
    total:{
        fontFamily:'Helvetica-Bold',
    }

  });


const FooterDetailsTable = ({items}) => {
    return (
      <>
        <View style={styles.row}>
          <View style={styles.topRow}>
            <Text style={styles.leftCol}>
              Subtotal of Fees Amount
            </Text>
            <Text style={styles.rightCol}>8000</Text>
          </View>
          <View style={styles.topRow}>
            <Text style={styles.leftCol}>CGST</Text>
            <Text style={styles.rightCol}>720</Text>
          </View>
          <View style={styles.topRow}>
            <Text style={styles.leftCol}>
              SGST
            </Text>
            <Text style={styles.rightCol}>720</Text>
          </View>
          <View style={styles.topRow}>
            <Text style={{...styles.leftCol,...styles.total}}>Subtotal of taxes</Text>
            <Text style={{...styles.rightCol,...styles.total}}>1440</Text>
          </View>
        </View>
        
      </>
    );
};
  
  export default FooterDetailsTable