import React, { Component } from "react";
import UserApplicationPage from "../../pages/UserApplication/UserApplicationPage";
import { getJobApplicationByJob, withDrawApplication, userJobDetail, getCurrentUser, editJobApplication } from '../../actions/index';
import swal from 'sweetalert';
import _ from 'lodash';
import { ToastContainer, toast } from "react-toastify";
class UserApplicationContainer extends Component {
  state = { jobPostDetail: {} };

  componentDidMount() {
this.getDetails()
  }
getDetails(){
  if (this.props.match ?.params ?.id) {
    userJobDetail(this.props.match.params.id)
      .then(res => {
        getCurrentUser((user) => {
          // this.setState({ currentUser: user, appliedJobs: user.appliedJobs, savedJobs: user.savedJobs });
          const currentUser = user
          const jobApplication = _.find(res.data ?.data.job.applications , { userId: currentUser._id })
          this.setState({ jobPostDetail: res.data ?.data , jobApplication, currentUser })
      })
      });
  }
}
  handleChangeValues = e => {
    let job = this.state.jobApplication;
    job= {...job,  [e.name]: e.value}
    this.setState({ jobApplication: job })
    
  };


  handleEditSubmit = () => {
    editJobApplication(this.state.jobApplication)
      .then(res => {
        this.getDetails()
        toast.success('updated')
      }).catch((err)=>{
        toast.error('Something went wrong')
      });
  }
  withDrawApplication = (id) => {
    withDrawApplication(id)
      .then(res => {
        toast.success('Application withdrawn')
        this.props.history.push('/SearchJob')
      }).catch((e)=>{
        toast.error('Something went wrong')
      })

  }


  render() {
    console.log(this.state, "dewdjowejdew");
    return(
      <>
      <ToastContainer />
       <UserApplicationPage
      {...this.state}
      withDrawApplication={this.withDrawApplication}
      getDetails={this.getDetails}
      handleChangeValues={this.handleChangeValues}
      handleEditSubmit={this.handleEditSubmit}
    />
      </>
    )
  }
}

export default UserApplicationContainer;
