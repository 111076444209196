import React, { Component, useState } from 'react';
import InfliuencersFormPage from "../../pages/InfliuencersForm/InfliuencersFormPage";
import _ from 'lodash'

const InfliuencersFormContainer  =() => {   
    const [step, setStep]=useState(1);
    const nextStep = (stepNumber) => {setStep(stepNumber ? stepNumber : step + 1)};

    const prevStep = () => {setStep(step-1)};
        
        return <InfliuencersFormPage 
        nextStep={nextStep}
        prevStep={prevStep}
        step={step}
        />


}

export default InfliuencersFormContainer;