import React, {Component} from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Slider from './Slider';

class boxOffice extends Component{

  state = {};

    render(){
        return(
          <div> 
 {/* banner section start */}
 <section id="top_banner">
 <div className="container side_space">
   <div className="row">
     <div className="col-sm-12 main_head">
       <h2>Creating a project? Find talent</h2>      
     </div>
   <div className="col-sm-12">
           <div id="owl-demo">
           <Slider />
           </div>
       </div>
     </div> 
   </div>
</section>
{/* banner section end */}
</div>
        );
    }
}

export default boxOffice;