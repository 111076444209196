import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { awardYear } from "./registerConst";
import Select from "react-select";
import _ from "lodash";
import { config } from "../../actions/config";
import FileInput from "../FileCropper/FileInput";
import ImageCropper from "../FileCropper/FileCropper";

const Award = ({
  handleChangeDropDown,
  awards,
  handleChange,
  nextStep,
  prevStep,
  handleChangeNestedValue,
  addMoreFields,
  removeFields,
  submitForm,
  fileSelectedHandler,
  ...props
}) => {
  const { t } = useTranslation();
  const [image, setImage] = useState("");
  const [imageName, setImageName] = useState("");
  const [currentPage, setCurrentPage] = useState("choose-img");
const [imgIndex, setImgIndex] = useState(null)
  // Invoked when new image file is selected
  const onImageSelected = (selectedImg,name, index=null) => {
    setImage(selectedImg);
    setCurrentPage("crop-img");
    setImageName(name)
    setImgIndex(index)
  };
  // Handle Cancel Button Click
  const onCropCancel = () => {
    setCurrentPage("choose-img");
    setImage("");
  };
  const onCropDone = (img) => {
    fileSelectedHandler({target:{name: imageName, files:[img]}},{index:imgIndex})
    setCurrentPage("choose-img");
  }
  return (
    <div className="setup-content row" id="step-3">
      {_.map(awards, (val, index) => (
        <>
          <div className="form-group col-sm-6" key={val.name+ 'award-sec'}>
            <label className="control-label">{t("SignUpAwardNameLabel")}</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter award name"
              name="awardName"
              value={val ?.awardName}
              onChange={(e) =>
                handleChangeNestedValue({
                  name: "awardName",
                  value: e.target.value,
                  key: "awards",
                  index: index,
                })
              }
            />
          </div>
          <div className="form-group col-sm-6">
            <label className="control-label">{t("SignUpAwardForLabel")}</label>
            <input
              type="text"
              className="form-control"
              placeholder="Ex. movie, TV show, music etc."
              value={val ?.awardFor}
              onChange={(e) =>
                handleChangeNestedValue({
                  name: "awardFor",
                  value: e.target.value,
                  key: "awards",
                  index: index,
                })
              }
            />
          </div>
          <div className="form-group col-sm-6">
            <label className="control-label">
              {t("SignUpAwardNominateLabel")}{" "}
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Ex. best actor, best signer, best singer, etc."
              value={val ?.nominatedFor}
              onChange={(e) =>
                handleChangeNestedValue({
                  name: "nominatedFor",
                  value: e.target.value,
                  key: "awards",
                  index: index,
                })
              }
            />
          </div>
          <div className="form-group col-sm-6">
            <label className="control-label">{t("SignUpAwardYrLabel")}</label>

            <Select
              options={awardYear}
              //isMulti
              value={{
                label: val ?.awardYear,
                value: val ?.awardYear,
              }}
              onChange={(e) =>
                handleChangeNestedValue({
                  name: "awardYear",
                  value: e.label,
                  key: "awards",
                  index: index,
                })
              }
            />
          </div>

          <div className="form-group col-sm-12">
            <label className="control-label">
              {t("SignUpAwardVidLabel")} &nbsp;{" "}
            </label>
            <input
              type="text"
              name="awardVedioLink"
              className="form-control"
              placeholder="Enter Url"
              value={val ?.awardVedioLink}
              onChange={(e) =>
                handleChangeNestedValue({
                  name: "awardVedioLink",
                  value: e.target.value,
                  key: "awards",
                  index: index,
                })
              }
            />
          </div>
          <div className="form-group col-sm-6">
            <label className="control-label">
              On what time you appeared in video
            </label>
            <input
              type="text"
              name="videoTime"
              className="form-control"
              placeholder='HH:MM:SS'
              value={val ?.appearanceTime}
              onChange={(e) =>
                handleChangeNestedValue({
                  name: "appearanceTime",
                  value: e.target.value,
                  key: "awards",
                  index: index,
                })
              }
            />
          </div>
          <div className="form-group upload_resume_field col-sm-3">
            <p>
              <strong>{t("SignUpAwardImgLabel")} &nbsp; </strong>
            </p>
            <br />

           
            <label htmlFor={val.awardName+index+"awardImage"} className="control-label stroke_btn">
              
            {currentPage === "choose-img" ? (
                <FileInput
                  setImage={setImage}
                  onImageSelected={onImageSelected}
                  label={"Upload Image"}
                  name={"awardImage"}
                  index={index}
                />
              ) : currentPage === "crop-img" ? (
                <ImageCropper
                  image={image}
                  onCropDone={onCropDone}
                  onCropCancel={onCropCancel}
                  
                />
              ) : (
                ""
              )}
            </label>
            {/* <input
            id={val.awardName+index+"awardImage"}
              type="file"
              name="awardImage"
              className="form-control"
              accept="image/*"
              onChange={(e)=>{fileSelectedHandler(e,{index})}}
            /> */}
            
          </div>
          {val ?.awardImage && 
           <div className="form-group upload_resume_field col-sm-3">
             <a href={config.FILE_URL+val ?.awardImage || ''} target="_blank" rel="noopener noreferrer">
             <img src={config.FILE_URL+val ?.awardImage || ''}  style={{height:100,borderRadius:10}}/>
             </a>
           </div>
           }

          <div className="col-sm-12 text-right">
            <a onClick={() => removeFields('awards', index)} >
              Remove award</a>
            <hr />
          </div>
        </>
      ))}

      <div className="col-sm-12">
        <div className="form-group text-right">
          <a style={{ cursor: 'pointer' }} onClick={() => addMoreFields('awards')}>
            <i className="fa fa-plus" /> {t("SignupAddMoreAward")}
          </a>
        </div>
        <button
          className="btn btn-primary nextBtn btn-lg stroke_btn"
          onClick={prevStep}
        >
          {t("PrevBtn")}
        </button>
        <button
          className="btn btn-primary nextBtn btn-lg blue_btn pull-right"
          onClick={nextStep}
        >
          {t("ContinueBtn")}
        </button>

        <button
          className="btn btn-primary nextBtn btn-lg blue_btn pull-right" style={{ background: "#1683f3", marginRight: "20px" }} onClick={submitForm}>
          Save changes
          </button>
      </div>
    </div>
  );
};

export default Award;
