import React from "react";
import { Link } from 'react-router-dom';

const JobListHeader = props => (
        <div className="inner_page_head">
            <div className="row">
                <div className="col-sm-12 heading header_btn">
                        <h2>List of job post</h2>
                        <button className="blue_btn" onClick={()=> window.history.back()}>Back</button> 
                </div>
              
            </div>
        </div>
);

export default JobListHeader;