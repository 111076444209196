import React from "react";
import { useTranslation } from "react-i18next";
import moment from 'moment';
import {
  filmIndustry,
  projectDuration,
  roleOption,
  profession,
  workExp,
  filmType,
  Genres,
  jobNature,
} from "./registerConst";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { loadOptionsResolveLocation, defaultOptions } from "../../Constants.js/districtConst";
import NoOptionsMessage from '../../commons/ReactSelectNoOptionsMessage';
import _ from "lodash";
import { formatMs } from "@material-ui/core";

const Experience = ({
  handleChangeSingle,
  handleChangeDropDown,
  workAndAwards,
  handleChange,
  nextStep,
  prevStep,
  handleChangeNestedValue,
  addMoreFields,
  removeFields,
  isValidHttpUrl,
  defaultProps,
  defaultPlaceHolder,
  submitForm,
  ...props
}) => {

  const { t } = useTranslation();


  return (
    <>
      <div className="setup-content row" id="step-1">
      <div className="form-group col-sm-6">
              <label className="control-label"> Total Relevant Work Experience</label>
              <Select
                options={workExp}
                value={{
                  label: props.totalrelevantexp,
                  value: props.totalrelevantexp,
                }}
                onChange={(e) =>
                  handleChangeDropDown({
                    name: "totalrelevantexp",
                    value: e.value,
                  })
                }
              />
            </div>
        {_.map(workAndAwards, (val, index) => (
          <>
            <div className="form-group col-sm-12">
              <label className="control-label">
                Job Category
              </label>
              <Select
                options={profession}
                value={{
                  label: val ?.profession,
                  value: val ?.profession,
                }}
                onChange={(e) =>
                  handleChangeNestedValue({
                    name: "profession",
                    value: e.label,
                    key: "workAndAwards",
                    index: index,
                  })
                }
              />
            </div>
            {/* <div className="form-group col-sm-6">
              <label className="control-label"> Work Experience</label>
              <Select
                options={workExp}
                value={{
                  label: val ?.workExperience,
                  value: val ?.workExperience,
                }}
                onChange={(e) =>
                  handleChangeNestedValue({
                    name: "workExperience",
                    value: e.label,
                    key: "workAndAwards",
                    index: index,
                  })
                }
              /> */}

            {/* <span style={{ color: "red" }}>
                {this.state.errors["totalWorkExp"]}
              </span> */}
            {/* </div> */}

            <div className="form-group col-sm-6">
              <label className="control-label">Project / Company Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Movies, Web series, TV Episode, Short Film etc."
                value={val ?.projectCompanyName}
                onChange={(e) =>
                  handleChangeNestedValue({
                    name: "projectCompanyName",
                    value: e.target.value,
                    key: "workAndAwards",
                    index: index,
                  })
                }
                name="projectCompanyName"
                required
              />
              {/* <span style={{ color: "red" }}>
            {this.state.errors["cntProject"]}
          </span> */}
            </div>

            <div className="form-group col-sm-6">
              <label className="control-label">Project Location</label>
              <AsyncSelect
                components={{ NoOptionsMessage }}
                cacheOptions
                placeholder={"Search Country...."}
                defaultOptions={[]}
                loadOptions={loadOptionsResolveLocation}
                value={{
                  label: val ?.location,
                  value: val ?.location,
                }}
                onChange={(e) =>
                  handleChangeNestedValue({
                    name: "location",
                    value: e.label,
                    key: "workAndAwards",
                    index: index,
                  })
                }
              />
              {/* <span style={{ color: "red" }}>
            {this.state.errors["location"]}
          </span> */}
            </div>

            <div className="form-group col-sm-12">
              <p>
                <strong>{t("SignUpWrkSinceLabel")}</strong>
              </p>
              <div className="row">
                <div className="col-sm-6">
                  <label className="control-label">
                    {t("SignUpFromDateLabel")}
                  </label>

                  <input
                    type="date"
                    className={`form-control form-group ${val.fromDate ? "" : "date-time-picker"}`}
                    value={val ?.fromDate}
                    onChange={(e) =>
                      handleChangeNestedValue({
                        name: "fromDate",
                        value: e.target.value,
                        key: "workAndAwards",
                        index: index,
                      })
                    }
                    required
                  />
                  {/* <span style={{ color: "red" }}>
                {this.state.errors["cntProject"]}
              </span> */}
                </div>
                {
                  // !(val ?.presentlyWorking) &&
                  <div className="col-sm-6 form-group">
                    <label className="control-label">
                      {t("SignUpToDateLabel")}
                    </label>
                    <input
                      type="date"
                      className={`form-control ${val.toDate ? "" : "date-time-picker"}`}
                      value={val ?.toDate}
                      min={val.fromDate}
                      onChange={(e) =>
                        handleChangeNestedValue({
                          name: "toDate",
                          value: e.target.value,
                          key: "workAndAwards",
                          index: index,
                        })
                      }
                      required
                    />
                  </div>
                }

                <div className="col-sm-6">
                  <label className="check_bx">
                    <input
                      type="checkbox"
                      className="form-control"
                      value={val ?.presentlyWorking}
                      onChange={(e) =>
                        handleChangeNestedValue({
                          name: "presentlyWorking",
                          value: !val ?.presentlyWorking,
                          key: "workAndAwards",
                          index: index,
                        })
                      }
                    />
                    <span className="checkmark"></span>
                    Presently Working
                  </label>
                </div>
              </div>
            </div>
            <div className="form-group col-sm-6">
              <label className="control-label">Film / Production Type</label>
              <Select
                options={filmType}
                value={{
                  label: val ?.filmType,
                  value: val ?.filmType,
                }}
                onChange={(e) =>
                  handleChangeNestedValue({
                    name: "filmType",
                    value: e.label,
                    key: "workAndAwards",
                    index: index,
                  })
                }
              />
            </div>
            <div className="form-group col-sm-6">
              <label className="control-label">
                {t("SignUpProjectDurLabel")}
              </label>
              <Select
                options={projectDuration}
                //isMulti
                value={{
                  label: val ?.duration,
                  value: val ?.duration,
                }}
                onChange={(e) =>
                  handleChangeNestedValue({
                    name: "duration",
                    value: e.label,
                    key: "workAndAwards",
                    index: index,
                  })
                }
              />
            </div>

            <div className="form-group col-sm-6">
              <label className="control-label">
                {t("SignUpFilmIndustryLabel")}
              </label>
              <Select
                options={filmIndustry}
                value={{
                  label: val ?.filmIndustry,
                  value: val ?.filmIndustry,
                }}
                onChange={(e) =>
                  handleChangeNestedValue({
                    name: "filmIndustry",
                    value: e.label,
                    key: "workAndAwards",
                    index: index,
                  })
                }
              />
            </div>
            {/*<div className="form-group col-sm-12">
          <input
            maxLength={1indexindex}
            type="text"
            className="form-control"
            placeholder="Other"
            name="otherFilmIndustry"
            value={props.otherFilmIndustry}
            onChange={handleChange}
          />
            </div> */}
            <div className="form-group col-sm-6">
              <label className="control-label">Genres</label>
              <Select
                options={Genres}
                value={{
                  label: val ?.genre,
                  value: val ?.genre,
                }}
                onChange={(e) =>
                  handleChangeNestedValue({
                    name: "genre",
                    value: e.label,
                    key: "workAndAwards",
                    index: index,
                  })
                }
              />
            </div>

          {val?.profession === 'Artist or Actor or Talent' && <div className="form-group col-sm-12">
            <label className="control-label">{t("SignUpRoleTypLabel")}</label>
            <Select
              options={roleOption}
              value={{
                label: val ?.roleType,
                value: val ?.roleType,
              }}
              onChange={(e) =>
                handleChangeNestedValue({
                  name: "roleType",
                  value: e.label,
                  key: "workAndAwards",
                  index: index,
                })
              }
            />
          </div>}

            <div className="form-group col-sm-12">
              <label className="control-label">
                {val?.profession === 'Artist or Actor or Talent'? t("SignUpRoleDetailLabel") :'Job Description (Optional)'}
              </label>
              <textarea
                className="form-control"
                placeholder="Type here"
                name="roleDetail"
                value={val ?.roleDetail}
                onChange={(e) =>
                  handleChangeNestedValue({
                    name: "roleDetail",
                    value: e.target.value,
                    key: "workAndAwards",
                    index: index,
                  })
                }
              />
              <small>Type min 100 and max 500 characters</small>
            </div>
            <div className="form-group col-sm-6">
              <label className="control-label">Work Link</label>
              <input
                type="text"
                name="videoLink"
                className="form-control"
                placeholder="Enter Url"
                value={val ?.videoLink}
                onChange={(e) => {
                  handleChangeNestedValue({
                    name: "videoLink",
                    value: e.target.value,
                    key: "workAndAwards",
                    index: index,
                  })
                }

                }
              />
            </div>
           {val?.profession === 'Artist or Actor or Talent' && <div className="form-group col-sm-6">
              <label className="control-label">
                On what time you appeared in video
              </label>
              <input
                type="text"
                name="videoTime"
                className="form-control"
                placeholder='HH:MM:SS'
                value={val ?.appearanceTime}
                onChange={(e) =>
                  handleChangeNestedValue({
                    name: "appearanceTime",
                    value: e.target.value,
                    key: "workAndAwards",
                    index: index,
                  })
                }
              />
            </div>}
            {val?.profession !== 'Artist or Actor or Talent' && <div className="form-group col-sm-6">
              <label className="control-label">Job Nature </label>
              <Select
                options={jobNature}
                value={{
                  label: val ?.jobNature,
                  value: val ?.jobNature,
                }}
                onChange={(e) =>
                  handleChangeNestedValue({
                    name: "jobNature",
                    value: e.label,
                    key: "workAndAwards",
                    index: index,
                  })
                }
              />
            </div>}
            <div className="form-group col-sm-12">
              <label className="control-label">
                {'Profile Summary'}
              </label>
              <textarea
                className="form-control"
                placeholder="Type here"
                name="profileSummary"
                value={val?.profileSummary}
                onChange={(e) =>
                  handleChangeNestedValue({
                    name: "profileSummary",
                    value: e.target.value,
                    key: "workAndAwards",
                    index: index,
                  })
                }
              />
              <small>Type min 50 and max 1000 characters</small>
            </div>
            {/* <div className="form-group col-sm-3">
              <label className="control-label"> Total Work Experience</label>
              <Select
                options={workExp}
                value={{
                  label: val ?.workExperience,
                  value: val ?.workExperience,
                }}
                onChange={(e) =>
                  handleChangeNestedValue({
                    name: "workExperience",
                    value: e.label,
                    key: "workAndAwards",
                    index: index,
                  })
                }
              />
            </div> */}

            <div className="col-sm-12 text-right">
              <a onClick={() => removeFields('workAndAwards', index)} >
                Remove experience
            </a>
              <hr />
            </div>
          </>
        ))}

        <div className="form-group col-sm-12 text-right">
          <a style={{ cursor: 'pointer' }} onClick={() => addMoreFields('workAndAwards')}>
            <i className="fa fa-plus" /> {t("SignupAddMoreWork")}
          </a>
        </div>
        <div className="col-sm-12">
          <button
            className="btn btn-primary nextBtn btn-lg stroke_btn"
            onClick={prevStep}
          >
            {t("PrevBtn")}
          </button>
          <button
            className="btn btn-primary nextBtn btn-lg blue_btn  pull-right"
            onClick={nextStep}
          >
            {t("ContinueBtn")}
          </button>
          <button
            className="btn btn-primary nextBtn btn-lg blue_btn pull-right" style={{ background: "#1683f3", marginRight: "20px" }} onClick={submitForm}>
            Save changes
          </button>
        </div>
      </div>
    </>
  );
};
export default Experience;
