/* eslint-disable no-unused-expressions */
import React,{ useState, useRef } from "react";
import { useEffect } from "react"
import { useGetEventChat, useSaveChat } from "../../../hooks/useEventChat";
// import { useEventStore, useWalletStore } from "../../Store";
import {config} from '../../../actions/config'
import ReactTimeAgo from "react-time-ago";

import './index.css'
import { getAppliedAuditions, getCurrentUser, getUserList } from "../../../actions";
const Chat = (props) => {
	const user = localStorage.getItem('user');
	const msgSender = user ? JSON.parse(user):{}
	const myId = msgSender?._id || '';
    const [chatList, setChatList] = useState([]);
    const [appliedUsers, setAppliedUsers] = useState([]);
	const [newMsg, setNewMsg] = useState('');
	const [msgReceiver, setMsgReceiver] = useState({});
	// const [msgSender, setMsgSender] = useState(user ? JSON.parse(user):{});
	// const search = window.location.search;
	const params = new URLSearchParams(props.location.search);
	const receiverId = params.get('receiverId');
	const auditionId = params.get('auditionId');
	
    const chatData = useGetEventChat(auditionId,myId);
    const chatResponce = useSaveChat();

    const messagesEndRef = useRef(null);
    useEffect(() => {
        if (chatData && chatData.data  && chatData?.data?.data) {
			console.log(chatData)
            setChatList(chatData?.data?.data || [])
            messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
        }
    }, [chatData])
    const sendMessage = () => {
        if (!newMsg || !myId || !auditionId) {
            return;
        }
        const msg = {
            "auditionId": auditionId,
            "toUserId": msgReceiver?._id,
			"fromUserId": myId,
            "message": newMsg
        }
        // const newChat = { ...msg, createdOn: new Date.now };
        // const updatedList = [...chatList, newChat]
        chatResponce.mutate(msg, {
            onSuccess: () => {
                // setChatList(updatedList);
                chatData.refetch()
                setNewMsg('')
                messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })

            }
        })
    }
	useEffect(() => {
		getAppliedJobs()
	}, []);
	const getAppliedJobs = () => {
		getCurrentUser((user) => {
			// const appliedAuditions = user?.appliedAuditions ||[]
			getUserList().then((res1)=>{
					const userlist = res1.data?.data?.filter(e => e?.appliedAuditions?.includes(auditionId))
					let u0 = receiverId ? res1.data?.data?.filter(e => e._id === receiverId) : userlist && userlist.length ? userlist[0]:{}
					
					const u1 = Array.isArray(u0) ? u0[0] :u0
					setAppliedUsers(receiverId ? u0 : userlist);
					setMsgReceiver(u1)
					console.log('setAppliedUsers',appliedUsers)
					messagesEndRef.current.scrollIntoView() 
				})
        })
    }
    return (
        <>
            {/* <div className="flex flex-col sm:flex-row ">
                <div className="w-2/5">
                  
                </div>
                <div className="w-3/5 flex flex-col ">
                    <div className="">
                       
                        <section className={'msger'} >
                            <header className="msger-header">
                                <div className="msger-header-title">
                                    <i className="fas fa-comment-alt"></i> Event Chat
                                </div>

                            </header>

                            <main className="msger-chat" >
                                {
                                    chatList.map((msg, i) => {
                                        return (
                                            <>
                                                {msg.userId?.toLowerCase() === walletId?.toLowerCase() ? <div className="msg right-msg" key={i}>
                                                    <div
                                                        className="msg-img"
                                                        style={{ backgroundImage: `url(${process.env.PUBLIC_URL}icons/avatar.jpg)` }}
                                                    ></div>

                                                    <div className="msg-bubble">
                                                        <div className="msg-info">
                                                            <div className="msg-info-name">{msg.userId.slice(0, 4) + "..." + msg.userId.slice(-4).toLowerCase()}</div>
                                                            <div className="msg-info-time">
                                                                {msg.createdOn && <ReactTimeAgo date={new Date(msg.createdOn).getTime()} locale="en-US" />}
                                                            </div>
                                                        </div>

                                                        <div className="msg-text">
                                                            {msg.message}
                                                        </div>
                                                    </div>
                                                </div>
                                                    :
                                                    <div className="msg left-msg">
                                                        <div
                                                            className="msg-img"
                                                            style={{ backgroundImage: `url(${process.env.PUBLIC_URL}icons/avatar.jpg)` }}
                                                        >
                                                        </div>

                                                        <div className="msg-bubble">
                                                            <div className="msg-info">
                                                                <div className="msg-info-name">{msg.userId.slice(0, 4) + "..." + msg.userId.slice(-4).toLowerCase()}</div>
                                                                <div className="msg-info-time">
                                                                    {msg.createdOn && <ReactTimeAgo date={new Date(msg.createdOn).getTime()} locale="en-US" />}
                                                                </div>
                                                            </div>

                                                            <div className="msg-text">
                                                                {msg.message}
                                                            </div>
                                                        </div>
                                                    </div>}
                                                <div ref={messagesEndRef} />
                                            </>
                                        )
                                    })
                                }
                            </main>
                                <div className="msger-inputarea">
                                    <input type="text" className="msger-input" value={chatText} onChange={(e) => { setChatText(e.target.value) }} placeholder="Enter your message..." onKeyDown={(e) => { e.code === "Enter" && sendMessage() }} />
                                    <button className="msger-send-btn" onClick={(e) => { sendMessage() }}>
                                        <img src={`${process.env.PUBLIC_URL}icons/send.png`} style={{ height: '32px' }} />
                                    </button>
                                </div>

                        </section>
                    </div>
                </div>
            </div> */}
            <div id="container">
	<aside>
    <header>
			<img className="people_img" src={`${config.FILE_URL}${msgSender?.profilePic}`} alt="images/fill_user.png" />
			<div>
				<div className="my-name"> {msgSender?.userName}</div>
			</div>
		</header>
		<ul>
			{appliedUsers.map((usr)=> {
				return(
					<li onClick={()=>{setMsgReceiver(usr)}}>
						<img src={`${config.FILE_URL}${usr?.profilePic}`} alt="images/fill_user.png" />
						<div>
							<h2>{usr.userName}</h2>
							{/* <h3>
								<span class="status orange"></span>
								offline
							</h3> */}
						</div>
					</li>
				)
			}) }
						
		</ul>
	</aside>
	<main>
		<header>
			<img src={`${config.FILE_URL}${msgReceiver?.profilePic}`} alt='' style={{height:50, width:50}} />
			<div>
				<h2>Chat with <i> {msgReceiver?.userName}</i></h2>

			</div>
		</header>
		<ul id="chat">

			{chatList.map(msg=>{
			return(
			myId === msg.fromUserId ?<li class="me">
				<div class="entete">
					<span class="status blue"></span>
					{/* <h2>Vincent</h2> */}
					<h3><ReactTimeAgo date={new Date(msg.createdAt)} /></h3>
				</div>
				{/* <div class="triangle"></div> */}
				<div class="message">
					{msg.message}
				</div>
			</li>:
			<li class="you">
				<div class="entete">
					<h3><ReactTimeAgo date={new Date(msg.createdAt)} /></h3>
					<span class="status green"></span>
					{/* <h2>Vincent</h2> */}
				</div>
				<div class="triangle"></div>
				<div class="message">
				{msg.message}
				</div>
			</li>
			)
			})
			}
			<li ref={messagesEndRef}>  </li>
			
		</ul>
		<footer>
			<input placeholder="Type your message" value={newMsg} onChange={(e)=>{setNewMsg(e.target.value)}}></input>
			<button className="blue_btn" onClick={()=>sendMessage()}>Send</button>
		</footer>
	</main>
</div>
        </>
    );
}

export default Chat;