import React, { Component } from "react";
import LoginPage from "../../pages/Login/LoginPage";
import swal from 'sweetalert';
import { loginUser } from "../../actions/index";

class LoginPageContainer extends Component {

  state = { emailId: "", password: "" };
componentDidMount = ()=>{
  const accessToken =localStorage.getItem('accessToken')
  if (accessToken) {
    this.props.history.push('/home');
  }
}
  LoginSubmit = e => {
    console.log(e, 'sfdd')
    e.preventDefault();
    if(this.state.tnc){
    loginUser({ ...this.state })
      .then(res => {
        if (res && res.data && res.data.data) {
          localStorage.setItem('accessToken', res.data.data.accessToken);
          localStorage.setItem('user', JSON.stringify(res.data.data.user));
          this.props.history.push('/home');
          window.location.reload();
        }

      })

      .catch(e => {
        if (e && e.response ) {
          swal(e.response.data.Error, { buttons: false, timer: 2000, icon: 'error' })
        }
      })
    }
  };

  handleOnChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }


  render() { return <LoginPage {...this.state} handleOnChange={this.handleOnChange} LoginSubmit={this.LoginSubmit} /> }
}


export default LoginPageContainer;