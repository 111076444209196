import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight, FaTimes } from "react-icons/fa";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import '../../scss/celebrityManagement.scss'
import _ from "lodash";

class VideoProduction extends Component {

  render() {
    return (
      <>
        <Header />
        <SubHeader /> 
          <section id="inner_celeb_banner">
            <div class="container">
              <div class="row">
                <div class="col-sm-6 col-xs-8 inner_celebrity_info">
                  <h1>VIDEO PRODUCTION COMPANY</h1>
                  <p align="justify">We at BollywoodisGood start every project with a complete examination of the needs and carry out comprehensive market research, offering us the essential details to make appropriate decisions. Hire best Video Production Company.</p>
                
              <Link to="/ReachUs" class="stroke_btn">Contact US</Link>
                </div>
                <div class="col-sm-6 inner_celebrity_img">
                <img src="images/video-production-services.webp" alt="BollywoodisGood is best Celebrity Management Company in India" width="699" height="450" class="wow fadeInDown" data-wow-delay="0.3s" />	 
                </div>
              </div>
            </div>
          </section>

          <section id="inner_about">
          <div class="container">
          <div class="row">
          <div class="col-sm-5">
          <img src="images/video-production-company.webp"  width="458" height="458" alt="Celebrity Management Services in India" />
          </div>
          <div class="col-sm-7 inner_about_info">
          <div class="inner_head">
          <h2>Video Production Services</h2>
          </div>
          <p align="justify">We depend on study to recognize your customers, since we are very much aware of the impact videos can have the audience. We attempt to discover answers to questions such as what they need and what they find interesting. As a most important video production company our reputation is because of the creativeness in our plot, rapidity in our implementation and the achievement in convert target audience to customers.</p>
         <br />
          <p align="justify">BollywoodisGood has skilled team who are proficient in managing the high-tech technology and assets, which make us proficient to create videos, which are the best. We offer one of the finest animation video production services because if the exceptional qualities that we refined over the years with our determination to offer the premium to our clients.</p>
        <br />
        <p align="justify">We make certain that our videos have a tale your customers can connect to, which is implemented in the preferred methods and can induce your customers of your how praiseworthy your services or products are. Our final goal is for your clients to offer your brand an opportunity after watching our ads. </p>
          </div>
          </div>
          </div>
          </section>

          <section id="our_role">
          <div class="container">
          <div class="row">
          <div class="col-sm-7 role_info">          
          <div class="inner_head">
          <h2>VIDEO PRODUCTION AGENCY</h2>
          </div>
          <ul>
            <li>
              <p align="justify"><strong>Promotional videos</strong> : Ever since social media platforms have taken a place globally, promotional videos have turned a great number of heads. We make short and pleasurable promotional videos with persuasive content with the use of 2D, 3D, or whiteboard animation according to the clients need.</p>
              </li>
              <br/>
              <li>
              <p align="justify"><strong>Commercial videos</strong> : BollywoodisGood create commercial videos that are depict in mainstream platforms such as movie theatres and TV channels to endorse your products and services and assist you construct your brand image and connect with your customers. BiG’s skilled video production team offers commercial video making services in live action, 2D and 3D.</p>
              </li>
              <br />
              <li>
              <p align="justify"><strong>Explainer Videos</strong> : At BollywoodisGood, we built appealing 2D, 3D and motion graphics to give details of the innovative features in your products to clients or to pitch your thoughts to your investors. We utilize screen capture techniques and professionally recorded voiceover to put in more worth to your video.</p>
              </li>
          </ul>
           </div>
          <div class="col-sm-5">
          <img src="images/audio-video-producction.webp"  loading="lazy" width="458" height="459" alt="Artist Management Services" />
          </div>
          </div>
          </div>
          </section>  

          <section id="inner_services">
          <div class="container">
          <div class="text-center inner_head">
<h2>Walkthroughs</h2>
</div>
<p align="justify">If you are an interior designer, real estate agency or a builder, you will be fascinated in depicting your plans of a gated society, rendition or villa of your interior design plan with the use of 3D walkthroughs. We have assisted renowned firms endorse their plan and we can do the same for you. Get in touch with the video production agency to create your brand walkthroughs.
</p>

          </div>
          </section>     

          <section id="inner_about">
          <div class="container">
          <div class="row">
          <div class="col-sm-5">
          <img src="images/video-styling.webp"  loading="lazy" width="458" height="459" alt="Artist Management Services" />
          </div>
          <div class="col-sm-7 role_info">
          <div class="inner_head">
          <h2>Video Style</h2>
          </div>
         <ul>
          <li>
            <strong>Typography</strong> <br/> 
          <p align="justify">Typography is a skill of organizing type to make written language clear, readable, and attractive when exhibited.</p>
          </li>
          <br />
          <li>
            <strong>Live Action</strong> <br/> 
          <p align="justify">It is utilized in photography instead of animation and even utilized the live clips and built a live-action animated film.</p>
          </li>
          <br />
          <li>
            <strong>Graphic Animation</strong> <br/> 
          <p align="justify">It is the piece of digital footage that makes the delusion of motion and is frequently connects with audio.</p>
          </li>
          <br />
          <li>
            <strong>Character Animation</strong> <br/> 
          <p align="justify">It is the dedicated animation process, which includes bringing animated characters to life.</p>
          </li>
          </ul>
         </div>         
          
          </div>
          </div>
          </section> 
          
           
          <section id="our_role">
          <div class="container">
          <div class="row">         
          <div class="col-sm-7 role_info">
         <ul>
          <li>
            <strong>Line Art</strong> <br/> 
          <p align="justify">Drawing an image that includes separate straight or curved lines located next to a background, with no gradations in shade.</p>
          </li>
          <br />
          <li>
            <strong>Whiteboard Animation</strong> <br/> 
          <p align="justify">This video style depicts the audience still images being drawn on the screen.</p>
          </li>
          <br />
          <li>
            <strong>HUD</strong> <br/> 
          <p align="justify">It is a translucent display that is there data with no need of users to gaze away from their typical viewpoints.</p>
          </li>
          <br />
          <li>
            <strong>2D Animation</strong> <br/> 
          <p align="justify">It entails that the object is 2 dimensional and every move of the character has to be shaped structure by structure with hand-drawing</p>
          </li>
          <br />
          <li>
            <strong>Screencast</strong> <br/> 
          <p align="justify">Of the computer screen it is a digital video recording and frequently involves the audio narration to educate or share thoughts.</p>
          </li>
          <br />
          <li>
            <strong>Infographic</strong> <br/> 
          <p align="justify">It is a Graphic visual depiction of data, knowledge or information planned to present details fast and evidently.</p>
          </li>
          </ul>
         </div>         
         <div class="col-sm-5">
          <img src="images/video-production-agency.webp"  loading="lazy" width="458" height="459" alt="Artist Management Services" />
          </div>
          </div>
          </div>
          </section>
        <Footer />
      </>
    );
  }
}

export default VideoProduction;
