import React from "react";
import { Page, Document, Image, StyleSheet, View } from "@react-pdf/renderer";
import InvoiceTitle from "./InvoiceTitle";
import BillTo from "./BillTo";
import InvoiceNo from "./InvoiceNo";
import InvoiceItemsTable from "./InvoiceItemsTable";
import FooterDetailsTable from './footerTable'
import BillFrom from "./BillFrom";
// import logo from "../../../src/logo.png";

const styles = StyleSheet.create({
  page: {
    // fontFamily: "Helvetica-Bold",
    fontSize: 10,
    paddingTop: 60,
    paddingLeft: 30,
    paddingRight: 30,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  logo: {
    width: 74,
    height: 66,
    marginLeft: "auto",
    marginRight: "auto",
  },
});
const Invoice = ({ invoice }) => {
  console.log(invoice)
  return (
    <Document>
      <Page size="A4" style={styles.page} orientation="portrait">
        <View style={{ border: "1px solid #555" }}>
          {/* <Image style={styles.logo} src={logo} /> */}
          <InvoiceTitle title="Invoice" />
          {/* <View style={{ marginTop: 18,flexDirection:'row' }}>
            <View style={{ borderBottomWidth: 1,borderRightWidth:1, width: '50%' }}>
              <BillFrom invoice={invoice} />
            </View>
            <View style={{ borderBottomWidth: 1,width: '50%' }}>
              <InvoiceNo invoice={invoice} />
            </View>
          </View>
            <BillTo invoice={invoice} /> */}
              <InvoiceNo invoice={invoice} />
          <View style={{ flexDirection: "row" }}>
            <View style={{ width: "60%" }}>
              <BillFrom invoice={invoice} />
            </View>
            <View style={{ width: "40%" }}>
              <BillTo invoice={invoice} />
            </View>
          </View>
          <InvoiceItemsTable invoice={invoice} />
          {/* <FooterDetailsTable invoice={invoice} /> */}
        </View>
      </Page>
    </Document>
  );
};

export default Invoice;
