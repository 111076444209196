import React, { Component } from 'react';
import SearchJobPage from "../../pages/SearchJob/SearchJobPage";
import { getJobListUserSide, saveJobs, getSavedJobs, getAppliedobs, getCurrentUser } from '../../actions/index';
import _ from 'lodash';
import swal from 'sweetalert';
let timer = "";

class SearchJobPageContainer extends Component {

    state = {
        savedJobs: [],
        appliedJobs: [],
        filters: [ { type: 'visibility', value: 'All' },{ type: 'type', value: 'All' }, { type: 'status', value: 'All' }],
        search: '',
        token: localStorage.getItem('accessToken')
    };

    resetAllFilters = () => {
        this.setState({ filters: [{ type: 'visibility', value: 'All' }, { type: 'type', value: 'All' }, { type: 'status', value: 'All' }], search: '' }, () => this.getJobList(""));

    }

    onChangeSearch = search => {
        this.setState({ search });
        clearTimeout(timer);
        timer = setTimeout(() => {
            this.getJobList(search);
        }, 300);
    }


    componentDidMount() {
        this.getJobList("");
        if (this.state.token) {
            this.getCurrentUser()
        }
    }

    getJobList = search => {
        getJobListUserSide({ filters: JSON.stringify(_.filter(this.state.filters, val => val.value != 'All')), search: search })
            .then(res => {
                this.setState({ data: res.data ?.data[0] ?.search });
            })
    }


    onChangeFilter = (e,multiselect) => {
        console.log('e   ' , e)
        console.log('multiselect    ' , multiselect)
        var statename;   if(e.type == "state") { statename  = e.value}  else { statename = this.state.stateval }
        let { filters, search } = this.state;
        // const alreadyPresent = _.find(filters, { ...e });

        const alreadyPresent = multiselect != 'multiselect'?_.find(filters, { ...e }) : null;

        const alreadyPresent2 = filters.find((val)=>  val.type == e.type )

        if(multiselect == 'multiselect' && alreadyPresent2){
            let response =  alreadyPresent2.value.find((data)=>  data == e.value )
            if(response){
                e.value = alreadyPresent2.value.filter((data)=> data != response )
            }else{
                e.value = [...alreadyPresent2?.value,...e.value]
            }
        }


        if (_.isEmpty(alreadyPresent)) {
            this.setState({ filters: _.uniqBy([e, ...filters], 'type'), stateval:statename }, () => this.getJobList(search));

        }
        else {
            this.setState({ filters: _.filter(filters, val => val.type != e.type) }, () => this.getJobList(search));
        }
    }

    saveJobs = id => {
        saveJobs(id)
            .then(res => {
                localStorage.setItem('user', JSON.stringify(res.data.data))
                this.getJobList(this.state.search);
                if (this.state.token) {
                    this.getCurrentUser()
                }
                swal(`Saved`, { buttons: false, timer: 1700, icon: 'success' });
            });
    }

    getCurrentUser = () => {
        // getSavedJobs()
        //  .then(res => this.setState({savedJobs : res.data.data}));
        getCurrentUser((user) => {
            this.setState({ currentUser: user, appliedJobs: user.appliedJobs, savedJobs: user.savedJobs });
        })
    }

    getAppliedJobs = () => {
        getAppliedobs()
            .then(res => {
                this.setState({ appliedJobs: res.data.data });
                //this.getAppliedJobs();
            });
    }

    resetAllFilters = () => {
        this.setState({ filters: [{ type: 'visibility', value: 'All' }, { type: 'type', value: 'All' }, { type: 'status', value: 'All' }], search: '' }, () => this.getJobList(''));
        this.setState({resetFilters: true}, ()=>{
           
            this.getJobList('');
            this.setState({ resetFilters: false });
            
        })

    }

    render() {
        console.log(this.state, "dkejdedei");
        return <SearchJobPage
            {...this.state}
            {...this.props}
            getSavedJobs={this.getSavedJobs}
            getAppliedJobs={this.getAppliedJobs}
            onChangeFilter={this.onChangeFilter}
            onChangeSearch={this.onChangeSearch}
            saveJobs={this.saveJobs}
            resetAllFilters={this.resetAllFilters}
        />
    }
}
export default SearchJobPageContainer;
