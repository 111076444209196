import React, {Component} from 'react';
import { Link } from 'react-router-dom';

class makeFilm extends Component{
    constructor(props){
    super(props);
    this.state = {}
    }
    render(){
        return(
          <div>              
              {/* make film section start */}
 <section id="make_film_section"  className="mid_info dark_clr">
 <div className="container side_space">
   <div className="row">
     <div className="col-md-6 col-sm-7 make_film_info">
       <h2>Makeing Team/Film? Search our casting calls and jobs</h2>
       <div className="form_part">
        <div className='form-group'>
          <select className='form-control'>
            <option>All Production</option>
            <option>Theatre</option>
            <option>Film</option>
            <option>TV/Series</option>
          </select>
        </div>
        <div className='form-group'>
          <select className='form-control'>
            <option>Location</option>
            <option>Panchkula, Haryana</option>
            <option>Toronto, ON</option>
            <option>Las Vegas, NV</option>
          </select>
        </div>
        
       </div>
       <div className='form-group'>
         <button type="submit" className='blue_btn'>Search</button>
        </div>
       <div className="film_cate_part">
        <h6>POPULAR SEARCHES</h6>
        <span>Film</span>
        <span>TV Auditions</span>
        <span>Theatre</span>
        <span>Modeling</span>
        <span>Commercial</span>
         <span>Script Writers</span>
         <span>Music</span>         
         <Link to="#" className="link_clr">More</Link>
       </div>
      
     </div>
     <img src="images/make_film_movies.png" alt="img" />
   </div>
 </div>
</section>
{/* make film section ends */}
</div>
        );
    }
}

export default makeFilm;