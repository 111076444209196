import React, { Component } from "react";
import ForgotPassPage from "../../components/ForgotPassword/ForgotPassPage";
import swal from 'sweetalert';
import { forgetPasswordOtp, forgotPasswordWithOtp } from '../../actions/index';
import _ from 'lodash';
class ForgotPassPageContainer extends Component {

  state = { emailId: "", otpScreen: false, otp: '      ', confirmPassword: '' };


  handleChangeSingle = e => this.setState({ [e.target.name]: e.target.value });


  submitForm = e => {
    e.preventDefault();
    forgetPasswordOtp(_.pickBy(_.omit(this.state, ['errors', 'otpScreen']), _.identity))
      .then(res => {
        this.setState({ otpScreen: true });
        swal("OTP sent", { buttons: false, timer: 2000, icon: 'success' });
      })
  };

  submitOtp = (e) => {
    console.log(e, "fdsss")
    e.preventDefault();
    const { password, confirmPassword, otp } = this.state;
    if ((password === confirmPassword) && password) {
      forgotPasswordWithOtp(_.pickBy(_.omit(this.state, ['errors', 'otpScreen']), _.identity))
        .then(res => {
          swal("Successfully changed password", { buttons: false, timer: 2000, icon: 'success' });
          this.props.history.push('/');
        })
        .catch(e => swal(e.response.data.Error, { buttons: false, timer: 2000, icon: 'error' }))
    }
    else {
      swal("Password and confirm password does not match", { buttons: false, timer: 2000, icon: 'error' });
    }
  }

  onChangeOtp = (value, index) => {
    let otp = _.cloneDeep(this.state.otp);
    otp = otp.split('');
    if (value !== undefined) {
      console.log(value, index, "idejodijj");
      otp[index] = value;
    }
    else {
      otp[index] = ' ';
    }
    otp = _.reduce(otp, (acc, val) => acc + val);
    //otp.replaceAll('undefined' , ' ')
    this.setState({ otp });
    this.changeFocus(index)
  }

  changeFocus = (index) => {
    const element = document.getElementById(index + 1);
    if (element) {
      element.focus();
    }
  }

  render() {
    console.log(this.state, "ewudewdewu");
    return <ForgotPassPage
      {...this.state}
      handleChangeSingle={this.handleChangeSingle}
      submitForm={this.submitForm}
      submitOtp={this.submitOtp}
      onChangeOtp={this.onChangeOtp}
    />
  }
}

export default ForgotPassPageContainer;
