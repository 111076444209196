import { combineReducers } from 'redux';
import signupReducer from '../pages/SignUp/redux/reducer';
import {firestoreReducer} from 'redux-firestore';

const appReducer = combineReducers({
    signup: signupReducer,
    firestore: firestoreReducer
});
// const rootReducers = (state, action) => {
//     if (action.type === constants.RESET_STORE) {
//         state.signup = undefined;
//     }

//     return appReducer(state, action);
// };
export default appReducer;

