import React from "react";
import Header from "../../components/shared/header/Header";
import { Link } from "react-router-dom";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import MakeFilmHeader from "../../components/MakeFilm/MakeFilmHeader";
import MakeFilmPopup from "../../components/MakeFilm/MakeFilmPopup";
import AllTeamContact from "../../components/MakeFilm/AllTeamContact";
// const {foo} = props.location.state

// console.log(foo) // "bar"


const MakeFilmContactPage = ( { ...props}) => (
  
  <>
    <Header {...props} />
    <SubHeader />
      {/* CTA section start*/}
      <section id="cta" className="mid_info dark_clr inner_banner">
          <div className="container side_space">
          <div class="home_grey_bx clearfix"><img src="images/post_audition.png" alt="img" class="right_pattern" />
            <div className="row">   
            <div className="col-md-9 cta_info">
            <h2>BiG Casting Calls: Your Bollywood Break Awaits!</h2>
            <p>Find and apply casting calls, auditions and roles on Bollywood is Good (BiG), the most trusted platform for every artist and creator. Get started today!!</p>
            </div>
            </div>
            </div>
          </div>
      </section>
      {/* CTA section ends */}
    <section id="make_film" className="dark_clr">
      <img src="images/make_film_bg.png" alt="img" className="bg_img" />
      <div className="container">
        <MakeFilmHeader />
        <AllTeamContact {...props} />
      </div>
    </section>
    <Footer />
    {/* <MakeFilmPopup /> */}
  </>
);
export default MakeFilmContactPage;
