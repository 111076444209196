import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import SmallThumbnail from '../movieStatus/SmallThumbnail';
import BigThumbnail from '../movieStatus/BigThumbnail';


class movieStatus extends Component{
    constructor(props){
    super(props);
    this.state = {}
    }
    render(){
        return(
          <div> 
              <section id="movie_status">
<div className="container side_space">
  <div className="row">
    <div className="col-md-12 main_head">
      <h2>Latest Auditions</h2>     
    </div>
   
      <div className="col-md-12">
        <div className="row">
        <BigThumbnail {...this.props}/>
        </div>
      </div> 
    
          <div className="col-md-12 btn_part text-center">
          {/* <Link to="/CreateUserProfile" className="blue_btn">Join Big Today</Link> */}
          <Link to="/SearchAudition" className="stroke_btn">Find More Auditions</Link>
            </div>

  </div>
  </div>
  </section>
  </div>
        );
    }
}

export default movieStatus;