import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SidebarCheckbox from '../people/SidebarCheckbox';

class SidebarList extends Component {
   constructor(props) {
     super(props)
     this.state = {
       stars: [
         {id: 1, value: "Actor", isChecked: false},
         {id: 2, value: "Actress", isChecked: false},
         {id: 3, value: "Director", isChecked: true},
         {id: 4, value: "Writer", isChecked: true},
         {id: 4, value: "Producer", isChecked: false},
       ]
     }  
   }
   
   handleAllChecked = (event) => {
      let stars= this.state.stars
      stars.forEach(star => star.isChecked = event.target.checked)
      this.setState({stars: stars})
   }

   handleCheckChieldElement = (event) =>{
      let stars= this.state.stars
      stars.forEach(star => {
         if (star.value === event.target.value)
         star.isChecked =  event.target.checked
      })
      this.setState({stars: stars})
   } 

   render() {
     return (
       <>
         {
           this.state.stars.map((star) => {
             return (<SidebarCheckbox handleCheckChieldElement={this.handleCheckChieldElement} {...star} />)
           })
         }        
       </>
     );
   }
 }
 
 export default SidebarList



