import React from 'react';
import { FaAngleRight } from 'react-icons/fa';
import { status, workExp, workExpMax, } from "../CreateUserProfile/registerConst";
import Select from "react-select";
import _ from 'lodash';
// import { profession, filmType, filmIndustry, jobNature, roleOption, payout, gender, audition } from '../CreateUserProfile/registerConst';

const HiredContractsFilter = ({ onChangeFilter, filters, showFilter }) => {
    return (
      <div
        id="filter-sidebar"
        className={`col-md-3 col-sm-4 sliding-sidebar ${showFilter ? 'px-0 visible-sm visible-md visible-lg collapse' : ''}`}>
        <div className="list_bg clearfix">
          <h3>Filter By</h3>
          <div className="filter_points">
            <h6
              data-toggle="collapse"
              data-target="#group-1"
              aria-expanded="true">
              <FaAngleRight />
              Status
            </h6>
            <div id="group-1" className="list-group collapse in">
              {_.map(status, (val) => (
                <p className="list-group-item">
                  <label className="check_bx">
                    <input
                      type="checkbox"
                      name="field"
                      checked={_.find(filters, {
                        type: "auditionType",
                        value: val.label,
                      })}
                      onChange={(e) =>
                        onChangeFilter({
                          type: "auditionType",
                          value: val.label,
                        })
                      }
                    />
                    {val.label}
                    <span className="checkmark" />
                  </label>
                </p>
              ))}
            </div>
          </div>
          <div className="filter_points">
            <h6
              data-toggle="collapse"
              data-target="#group-2"
              aria-expanded="true">
              <FaAngleRight />
              Start Date
            </h6>
            <div id="group-2" className="list-group collapse in">
              <input
                className="form-control"
                type={"date"}
                placeholder="Select Start Date"
                onChange={(e) =>
                  onChangeFilter({ type: "fromDate", value: e.target.value })
                }
              />
            </div>
          </div>
          <div className="filter_points">
            <h6
              data-toggle="collapse"
              data-target="#group-3"
              aria-expanded="true">
              <FaAngleRight />
              End Date
            </h6>
            <div id="group-3" className="list-group collapse in">
              <input
                className="form-control"
                type={"date"}
                placeholder="Select Start Date"
                onChange={(e) =>
                  onChangeFilter({ type: "toDate", value: e.target.value })
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
}
export default HiredContractsFilter;
