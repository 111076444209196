import React from "react";
import Select from "react-select";
import _ from "lodash";
import { workExp, workExpMax } from "../../components/CreateUserProfile/registerConst";
import CreatableSelect from "react-select/creatable";

const Expertise = ({
    handleChangeDropDown,
    handleChangeSingle,
    prevStep,
    nextStep,
    PostJobSubmit,
    ...props
}) => {

    return (
        <>
            <div >
                <div className="tab_head">
                    <h4>Expertise</h4>
                    <hr />
                </div>

                <div class="row">
                    <div className="form-group col-sm-12">
                        <label>Skills 
                    <small style={{ fontSize: 10, color: "green", marginLeft: 12 }}> (You can add if not found) </small>
                        </label>
                    <CreatableSelect
                            options={[{label:'', value:''}]}
                            isMulti
                            value={_.map(props.skills, (val) => {
                                if (val) {
                                    return ({
                                        label: val,
                                        value: val,
                                    })
                                }
                            })}
                            onChange={(e) =>
                                handleChangeDropDown({
                                    name: "skills",
                                    value: _.map(e, (val) => val.label),
                                })
                            }
                        />
                    </div>
                    <div className="clearfix"></div>
                    <div className="form-group col-sm-6">
                        <label>Minimum Experience</label>
                        <Select
                            options={workExp}
                            value={{
                                value: props.minExperience,
                                label: props.minExperience,
                            }}
                            onChange={(e) =>
                                handleChangeDropDown({
                                    name: "minExperience",
                                    value: e.value,
                                })
                            }
                        />
                    </div>
                    <div className="form-group col-sm-6">
                        <label>Maximum Experience</label>
                        <Select
                            options={workExpMax}
                            value={{
                                value: props.maxExperience,
                                label: props.maxExperience,
                            }}
                            onChange={(e) =>
                                handleChangeDropDown({
                                    name: "maxExperience",
                                    value: e.label,
                                })
                            }
                        />
                            {props.maxExperience < props.minExperience && <p style={{ color: 'red',fontSize:'12px',marginLeft:"30px" }}>MaxExperience is not less than MinExperience</p>}
                    </div>
                    <div className="col-sm-12">
                        <hr />
                        <br />
                        <button className="stroke_btn" onClick={prevStep}>Back</button>
                        <button style={{ marginLeft: "15px" }}
                            type="submit"
                            className="stroke_btn"
                            onClick={(e) => PostJobSubmit(e, false)}
                        >
                            {" "}
                            Save{" "}
                        </button>

                        <button className="blue_btn pull-right" onClick={() => nextStep()} >Continue</button>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Expertise;