import React from 'react';
import { useTranslation } from "react-i18next";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import _ from "lodash";
import { vlidateNumberField } from "../../Utilities/validateInputField";
import { loadOptionsResolveLanguage, defaultOption } from "../../Constants.js/languageConst";
// import filmCourseOptions from '../CreateUserProfile/filmCoursesConst';
import AsyncSelect from "react-select/async";
import { ActorType, Genre } from "../JobPost/jobPostConst";
import { roleOption, filmIndustry, filmType, uniqueTraits, physique, postGraduation, jobNature, workingStyle, schedule, abilityToCommut, supplementalPayTypes } from "../CreateUserProfile/registerConst";
import { FaPlus, FaMinus, } from "react-icons/fa";
import { Placeholder } from '../../commons/ReactSelectNoOptionsMessage';
import { config } from '../../actions/config';

const BrandIntegration = ({ 
    handleChangeDropDown,
    handleChangeSingle,
    defaultProps,  
    prevStep,
    nextStep,   
    ...props
}) => {
    const { t } = useTranslation();
    return (
        <>
           
           <div className="tab_head">
                <h4>Brand Integration Details</h4>
                <hr />
              </div>
              <div className='row'>                
                <div className="form-group col-md-6 col-sm-12">
                    <label>Campaign Name</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        name=""
                    />
                </div>
                <div className="form-group col-md-6 col-sm-12">
                    <label>Campaign Objective</label>
                    <CreatableSelect
                        components={{ Placeholder }}
                        placeholder={''}
                        options={''}
                        />
                </div>
                <div className="form-group col-md-6 col-sm-12">
                    <label>Campaign Timeline</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        name=""
                    />
                </div>
                <div className="form-group col-md-6 col-sm-12">
                    <label>Target Audience Demographics</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        name=""
                    />
                </div>
                <div className="form-group col-md-6 col-sm-12">
                    <label>Target Audience Interests</label>
                    <CreatableSelect
                        components={{ Placeholder }}
                        placeholder={''}
                        options={''}
                        />
                </div>
                <div className="form-group col-md-6 col-sm-12">
                    <label>Geographical Targeting</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        name=""
                    />
                </div>
                <div className="form-group col-md-6 col-sm-12">
                    <label>Campaign Budget</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        name=""
                    />
                </div>
                <div className="form-group col-md-6 col-sm-12">
                    <label>Compensation Structure</label>
                    <CreatableSelect
                        components={{ Placeholder }}
                        placeholder={''}
                        options={''}
                        />
                </div>
                <div className="form-group col-md-6 col-sm-12">
                    <label>Negotiation Flexibility</label>
                    <CreatableSelect
                        components={{ Placeholder }}
                        placeholder={''}
                        options={''}
                        />
                </div>
                <div className="form-group col-md-6 col-sm-12">
                    <label>Influencer Follower Range</label>
                    <CreatableSelect
                        components={{ Placeholder }}
                        placeholder={''}
                        options={''}
                        />
                </div>
                <div className="form-group col-md-6 col-sm-12">
                    <label>Influencer Engagement Rate Range</label>
                    <CreatableSelect
                        components={{ Placeholder }}
                        placeholder={''}
                        options={''}
                        />
                </div>
                <div className="form-group col-md-6 col-sm-12">
                    <label>Content Preference</label>
                    <CreatableSelect
                        components={{ Placeholder }}
                        placeholder={''}
                        options={''}
                        />
                </div>
                <div className="form-group col-md-6 col-sm-12">
                    <label>Collaboration Expectations</label>
                    <textarea
                            className="form-control"
                            placeholder=""
                            
                            name="description"
                            onChange={''}
                    />
                </div>
                <div className="form-group col-md-6 col-sm-12">
                    <label>Specific Deliverables</label>
                    <textarea
                            className="form-control"
                            placeholder=""
                            
                            name="description"
                            onChange={''}
                    />
                </div>
                <div className="col-md-6 col-sm-12 radio_btn inline_feild">
                  <p>
                      {" "}
                      <strong>Exclusivity Requirements</strong>
                  </p>
                  <label className="check_bx">
                      <input
                          type="radio"
                          value="checkbx1"
                          name="checkField"
                      />
                      Yes
                      <span className="checkmark"></span>
                  </label>
                  <label className="check_bx">
                      <input
                          type="radio"
                          value="checkbx"
                          name="checkField"                                               
                      />
                      No
                      <span className="checkmark"></span>
                  </label>
              </div>
              <div className="col-sm-12">
                        <hr />
                        <button className="stroke_btn" onClick={prevStep}>Back</button>                    

                        <button className="blue_btn pull-right" onClick={() => nextStep()} >Continue</button>


                    </div>
              </div>

        </>
    )
}

export default BrandIntegration