import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight, FaTimes } from "react-icons/fa";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import '../../scss/celebrityManagement.scss'
import _ from "lodash";

class TechInfluencer extends Component {

  render() {
    return (
      <>
        <Header />
        <SubHeader /> 
          <section id="inner_celeb_banner">
            <div class="container">
              <div class="row">
                <div class="col-sm-6 col-xs-8 inner_celebrity_info">
                  <h1>TECH INFLUENCER MARKETING</h1>
                  <p align="justify">For both businesses and consumers, the technology sector is almost certainly more vivacious than it ever has been. It looks like new technology products like mobiles, computers, laptops, ipads, and other gadgets, are released and making the industry magnificently unpredictable.
CONTACT US</p>
                  <Link to="/ReachUs" class="stroke_btn">Contact US</Link>
                </div>
                <div class="col-sm-6 inner_celebrity_img">
                <img src="images/tech-influencer-marketing.webp" alt="BollywoodisGood is best Celebrity Management Company in India" width="699" height="450" class="wow fadeInDown" data-wow-delay="0.3s" />	 
                </div>
              </div>
            </div>
          </section>

          <section id="inner_about">
          <div class="container">
          <div class="row">
          <div class="col-sm-5">
          <img src="images/technology-influencers.webp"  width="458" height="458" alt="Celebrity Management Services in India" />
          </div>
            <div className="col-sm-7">
            <div class="inner_head">
          <h2>Mobile Influencer Marketing</h2>
          </div>
          <p align="justify">Though, all of these inventions and the release of new gadgets and technology raises the question that how you are going to compete with all your competitors. Fortunately, we are living in a socially powerful society where influencers are gaining the charm and enhancing the trust and love for your brand in the eyes of the audience. Hence, BollywoodisGood is the one that can help you with the strategies building and creating the technology influencer campaign for your brand/business.</p>
          <br />
          <p align="justify">Every new technology and gadget needs a new style of marketing and as a renowned technology influencer agency, we at BollywoodisGood will find the right influencer that will endorse your brand/business and will enhance the return on investment. Our team of professionals will research and scrutinize the whole industry as per your brand and will find out the best plan to boost your sale, spread the brand awareness through the correct influencer and ideal campaign.</p>
          </div>          
        
          </div>
          </div>
          </section>

          <section id="our_role">
          <div class="container">
          <div class="row">
          <div class="col-sm-7 role_info">          
          <div class="inner_head">
          <h2>Techology Influencer Marketing Services</h2>
          </div>
         <p align="justify">With the increase in social media users and influencers in the industry, influencer marketing has taken a front seat and making the brands famous and popular. From choosing the right influencer, coordinating with them and making collaboration success is taken care of by BollywoodisGood. We ensure that the process of making your brand/business popular and reaching it to the right customers through influencer marketing.</p>
          <br />           
          <p align="justify">The campaign result and the work that has been done by the influencer will be shown to you with the help of the record that we will fetch. With BollywoodisGood you are not only enhancing your brand's fame and online presence but making it expand and grow through influencer marketing.</p>
         <br />
         <p align="justify">The only way to beat the competitors of mobile, computers, laptops and other gadgets is through influencer marketing. You must have seen on the social media platform that famous and popular influencers endorse and promote brands and people trust them and their followers start following your brand/business. Hence, it is the fact that influencer marketing is doing wonders for the technology and gadgets sector as well.</p>
       </div>
          <div class="col-sm-5">
          <img src="images/tech-influencer-marketing-services.webp"  loading="lazy" width="458" height="400" alt="Artist Management Services" />
          </div>
          </div>
          </div>
          </section>  

          <section id="inner_services">
          <div class="container">     
          <div class="col-sm-12">
          <div class="text-center inner_head">
          <h2>Complete End to End Services</h2>          
          <p>BollywoodisGood as a technology influencer agency helps to make your brand/business popular and will attract the potential customers who will not only invest in your brand and will popularize it as well.</p>
          </div>
           </div>
          </div>        
          </section> 

           
        
           
        <Footer />
      </>
    );
  }
}

export default TechInfluencer;
