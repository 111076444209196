import React from "react";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import AuditionApplicationDetail from "../../components/AuditionApplicationView/AuditionApplicationDetail";
import UserTopHeader from "../../components/AuditionApplicationView/UserTopHeader";

const AuditionApplicationViewPage = (props) => {
  return (
    <>
      <Header {...props} />
      <SubHeader />
      <section id="postjob_detail" className="default_bg">
        <img src="images/make_film_bg.png" alt="img" className="bg_img" />
        <div className="container">
          
          <div className="row">
          <UserTopHeader {...props} />
            <AuditionApplicationDetail {...props} />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default AuditionApplicationViewPage;
