import React from "react";
import { useTranslation } from "react-i18next";
import { functionalArea, filmIndustry, funtionalOption, roleOption } from './registerConst';
import Select from 'react-select';

const Experience = ({ handleChangeDropDown, handleChange, nextStep, prevStep, ...props }) => {

  const { t } = useTranslation();



  return (

    <>
      <div className="setup-content" id="step-1">
        <div className="form-group">
          <label className="control-label">{t("SignUpProfessionLabel")}</label>
          <input
            maxLength={100}
            type="text"
            name="profession"
            className="form-control"
            placeholder="Add Title/Role"
            value={props.profession}
            onChange={handleChange}
            required
          />
          {/* <span style={{ color: "red" }}>
            {this.state.errors["profession"]}
          </span> */}
        </div>
        <div className="form-group">
          <label className="control-label"> {t("SignUpWrkExEmail")}</label>
          <input
            maxLength="100"
            type="text"
            className="form-control"
            placeholder="5 years"
            value={props.totalWorkExperience}
            onChange={handleChange}
            required
            name="totalWorkExperience"
          />
          {/* <span style={{ color: "red" }}>
            {this.state.errors["totalWorkExp"]}
          </span> */}
        </div>
        <div className="form-group">
          <label className="control-label">{t("SignUpCntryWorkLabel")}</label>
          <input
            maxLength="100"
            name="countryWorks"
            type="text"
            className="form-control"
            placeholder=""
            value={props.countryWorks}
            onChange={handleChange}
            required
          />
          {/* <span style={{ color: "red" }}>
            {this.state.errors["countryWorks"]}
          </span> */}
        </div>
        <div className="form-group">
          <label className="control-label">{t("SignUpCntryNameLabel")}</label>
          <input
            maxLength="100"
            type="text"
            name="countryName"
            className="form-control"
            value={props.countryName}
            placeholder="India, Australia"
            onChange={handleChange}
            required
          />
          {/* <span style={{ color: "red" }}>
            {this.state.errors["countryNames"]}
          </span> */}
        </div>
        <div className="form-group">
          <label className="control-label">{t("SignUpCntProjectLabel")}</label>
          <input
            maxLength={100}
            type="text"
            className="form-control"
            placeholder="Movies, Web series, TV Episode, Short Film etc."
            value={props.currentProject}
            onChange={handleChange}
            name="currentProject"
            required
          />
          {/* <span style={{ color: "red" }}>
            {this.state.errors["cntProject"]}
          </span> */}
        </div>
        <div className="form-group">
          <p>
            <strong>{t("SignUpWrkSinceLabel")}</strong>
          </p>
          <div className="row">
            <div className="col-sm-6">
              <label className="control-label">
                {t("SignUpFromDateLabel")}
              </label>
              <input
                type="date"
                className="form-control"
                placeholder="Movies, Web series, TV Episode, Short Film etc."
                value={props.workingFromDate}
                name="workingFromDate"
                onChange={handleChange}
                required
              />
              {/* <span style={{ color: "red" }}>
                {this.state.errors["cntProject"]}
              </span> */}
            </div>
            <div className="col-sm-6">
              <label className="control-label">{t("SignUpToDateLabel")}</label>
              <input
                type="date"
                className="form-control"
                placeholder="Movies, Web series, TV Episode, Short Film etc."
                name="workingToDate"
                value={props.workingToDate}
                onChange={handleChange}
                required
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <label className="control-label">{t("SignUpProjectDurLabel")}</label>
          <input
            maxLength={100}
            type="text"
            className="form-control"
            placeholder="Enter Duration"
            name="projectDuration"
            value={props.projectDuration}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label className="control-label">
            {t("SignUpFilmIndustryLabel")}
          </label>
          <Select
            options={filmIndustry}
            //isMulti
            value={props.filmIndustry}
            onChange={e => handleChangeDropDown({ name: 'filmIndustry', value: e })}
          />
        </div>
        <div className="form-group">
          <input
            maxLength={100}
            type="text"
            className="form-control"
            placeholder="Other"
            name="otherFilmIndustry"
            value={props.otherFilmIndustry}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label className="control-label">
            {t("SignUpFunctionalAreaLabel")}
          </label>
          <Select
            options={functionalArea}
            isMulti
            value={props.functionalArea}
            onChange={e => handleChangeDropDown({ name: 'functionalArea', value: e })}
          />
        </div>

        {props.functionalArea === "Other" && (
          <div className="form-group">
            <input
              maxLength={100}
              type="text"
              className="form-control"
              placeholder="Other"
              name="otherFunctionalArea"
              value={props.otherFunctionalArea}
              onChange={handleChange}
            />
          </div>
        )}

        <div className="form-group">
          <label className="control-label" required>
            {t("SignUpRoleLabel")}
          </label>
          <Select
            options={roleOption}
            isMulti
            value={props.role}
            onChange={e => handleChangeDropDown({ name: 'role', value: e })}
          />
        </div>
        <div className="form-group">
          <label className="control-label">{t("SignUpRoleTypLabel")}</label>
          <input
            type="text"
            className="form-control"
            placeholder="Lead, Suporting, Stunt, Understudy etc."
            name="roleType"
            value={props.roleType}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label className="control-label">{t("SignUpRoleDetailLabel")}</label>
          <textarea
            className="form-control"
            placeholder="Type Here"
            name="roleDetails"
            value={props.roleDetails}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label className="control-label">{t("SignUpVideoLabel")}</label>
          <input
            type="text"
            name="vedioLink"
            className="form-control"
            placeholder="Enter Url"
            value={props.vedioLink}
            onChange={handleChange}
          />
        </div>
        <div className="form-group text-right">
          <a href="#">
            <i className="fa fa-plus" /> {t("SignupAddMoreWork")}
          </a>
          <hr />
        </div>
        <button
          className="btn btn-primary nextBtn btn-lg blue_btn"
          onClick={nextStep}
        >
          {t("ContinueBtn")}
        </button>
        <button
          className="btn btn-primary nextBtn btn-lg stroke_btn pull-right"
          onClick={prevStep}
        >
          {t("PrevBtn")}
        </button>
      </div>
    </>
  );
}
export default Experience;
