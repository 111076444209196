import React from 'react';


const JobFilter = (props) => {
    return (
        <div className="search_job">
            <button type="button" className="stroke_btn" data-toggle="collapse" data-target="#job_filter">
                Filter
            </button>

            <div id="job_filter" className="list-group collapse">
                <hr />
                <div className="row">
                    <div className="col-md-3 filter_point">
                        <h6>Earned amount</h6>
                        <div className="radio_btn">
                            <label className="check_bx">
                                <input type="radio" name="field" value="1" checked />
                                Any amount earned
                            <span className="checkmark" />
                            </label>
                            <label className="check_bx">
                                <input type="radio" name="field" value="2" />
                                Rs 5k+ earned
                            <span className="checkmark" />
                            </label>
                            <label className="check_bx">
                                <input type="radio" name="field" value="3" />
                                Rs 25k+ earned
                            <span className="checkmark" />
                            </label>
                            <label className="check_bx">
                                <input type="radio" name="field" value="4" />
                                Rs 50k+ earned
                            <span className="checkmark" />
                            </label>
                            <label className="check_bx">
                                <input type="radio" name="field" value="5" />
                                No earnings yet
                            <span className="checkmark" />
                            </label>
                        </div>
                    </div>

                    <div className="col-md-3 filter_point">
                        <h6>Job Success</h6>
                        <div className="radio_btn">
                            <label className="check_bx">
                                <input type="radio" name="field" value="6" checked />
                                Any job success
                            <span className="checkmark" />
                            </label>
                            <label className="check_bx">
                                <input type="radio" name="field" value="7" />
                                80% & up
                            <span className="checkmark" />
                            </label>
                            <label className="check_bx">
                                <input type="radio" name="field" value="8" />
                                90% & up
                            <span className="checkmark" />
                            </label>
                            <label className="check_bx">
                                <input type="radio" name="field" value="9" />
                                Top Rated
                            <span className="checkmark" />
                            </label>
                            <label className="check_bx">
                                <input type="radio" name="field" value="10" />
                                Rising Talent
                            <span className="checkmark" />
                            </label>
                        </div>
                    </div>

                    <div className="col-md-3 filter_point">
                        <h6>Hourly Rate</h6>
                        <div className="radio_btn">
                            <label className="check_bx">
                                <input type="radio" name="field" value="11" checked />
                                Any hourly rate
                            <span className="checkmark" />
                            </label>
                            <label className="check_bx">
                                <input type="radio" name="field" value="12" />
                                Rs 200 and below
                            <span className="checkmark" />
                            </label>
                            <label className="check_bx">
                                <input type="radio" name="field" value="13" />
                                Rs 200 - Rs 500
                            <span className="checkmark" />
                            </label>
                            <label className="check_bx">
                                <input type="radio" name="field" value="4" />
                                Rs 500 - Rs 1000
                            <span className="checkmark" />
                            </label>
                            <label className="check_bx">
                                <input type="radio" name="field" value="5" />
                                Rs 1000 & above
                            <span className="checkmark" />
                            </label>
                        </div>
                    </div>

                    <div className="col-md-3 filter_point">
                        <h6>Hours billed</h6>
                        <div className="radio_btn">
                            <label className="check_bx">
                                <input type="radio" name="field" value="1" checked />
                                Any hours
                            <span className="checkmark" />
                            </label>
                            <label className="check_bx">
                                <input type="radio" name="field" value="2" />
                                1+ hours billed
                            <span className="checkmark" />
                            </label>
                            <label className="check_bx">
                                <input type="radio" name="field" value="3" />
                                100+ hours billed
                            <span className="checkmark" />
                            </label>
                            <label className="check_bx">
                                <input type="radio" name="field" value="4" />
                                1,000+ hours billed
                            <span className="checkmark" />
                            </label>
                        </div>
                    </div>

                    <div className="col-md-3 filter_point">
                        <h6>Talent Type</h6>
                        <div className="radio_btn">
                            <label className="check_bx">
                                <input type="radio" name="field" value="1" checked />
                                People & Agencies
                            <span className="checkmark" />
                            </label>
                            <label className="check_bx">
                                <input type="radio" name="field" value="2" />
                                People
                            <span className="checkmark" />
                            </label>
                            <label className="check_bx">
                                <input type="radio" name="field" value="3" />
                                Agencies
                            <span className="checkmark" />
                            </label>

                        </div>
                    </div>

                    <div className="col-md-3 filter_point">
                        <h6>Last Activity</h6>
                        <div className="radio_btn">
                            <label className="check_bx">
                                <input type="radio" name="field" value="1" checked />
                                Any time
                            <span className="checkmark" />
                            </label>
                            <label className="check_bx">
                                <input type="radio" name="field" value="2" />
                                Within 2 weeks
                            <span className="checkmark" />
                            </label>
                            <label className="check_bx">
                                <input type="radio" name="field" value="3" />
                                Within 1 month
                            <span className="checkmark" />
                            </label>
                            <label className="check_bx">
                                <input type="radio" name="field" value="3" />
                                Within 2 months
                            <span className="checkmark" />
                            </label>
                        </div>
                    </div>

                    <div className="col-md-3 filter_point">
                        <h6>Experience Level</h6>
                        <div className="radio_btn">
                            <label className="check_bx">
                                <input type="radio" name="field" value="1" checked />
                                Expert
                            <span className="checkmark" />
                            </label>
                            <label className="check_bx">
                                <input type="radio" name="field" value="2" />
                                Intermediate
                            <span className="checkmark" />
                            </label>
                            <label className="check_bx">
                                <input type="radio" name="field" value="3" />
                                Fresher
                            <span className="checkmark" />
                            </label>

                        </div>
                    </div>



                </div>

            </div>
        </div>
    );
}
export default JobFilter;