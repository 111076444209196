const countries = [

   { "label": "Afghanistan", "value": "Afghanistan" },
   { "label": "Aland Islands", "value": "Aland Islands" },
   { "label": "Albania", "value": "Albania" },
   { "label": "Algeria", "value": "Algeria" },
   { "label": "American Samoa", "value": "American Samoa" },
   { "label": "Andorra", "value": "Andorra" },
   { "label": "Angola", "value": "Angola" },
   { "label": "Anguilla", "value": "Anguilla" },
   { "label": "Antarctica", "value": "Antarctica" },
   { "label": "Antigua and Barbuda", "value": "Antigua and Barbuda" },
   { "label": "Argentina", "value": "Argentina" },
   { "label": "Armenia", "value": "Armenia" },
   { "label": "Aruba", "value": "Aruba" },
   { "label": "Australia", "value": "Australia" },
   { "label": "Austria", "value": "Austria" },
   { "label": "Azerbaijan", "value": "Azerbaijan" },
   { "label": "Bahamas", "value": "Bahamas" },
   { "label": "Bahrain", "value": "Bahrain" },
   { "label": "Bangladesh", "value": "Bangladesh" },
   { "label": "Barbados", "value": "Barbados" },
   { "label": "Belarus", "value": "Belarus" },
   { "label": "Belgium", "value": "Belgium" },
   { "label": "Belize", "value": "Belize" },
   { "label": "Benin", "value": "BJ" },
   { "label": "Bermuda", "value": "BM" },
   { "label": "Bhutan", "value": "BT" },
   { "label": "Bolivia", "value": "BO" },
   { "label": "Bosnia and Herzegovina", "value": "BA" },
   { "label": "Botswana", "value": "BW" },
   { "label": "Bouvet Island", "value": "BV" },
   { "label": "Brazil", "value": "BR" },
   { "label": "British Indian Ocean Territory", "value": "IO" },
   { "label": "Brunei Darussalam", "value": "BN" },
   { "label": "Bulgaria", "value": "BG" },
   { "label": "Burkina Faso", "value": "BF" },
   { "label": "Burundi", "value": "BI" },
   { "label": "Cambodia", "value": "KH" },
   { "label": "Cameroon", "value": "CM" },
   { "label": "Canada", "value": "CA" },
   { "label": "Cape Verde", "value": "CV" },
   { "label": "Cayman Islands", "value": "KY" },
   { "label": "Central African Republic", "value": "CF" },
   { "label": "Chad", "value": "TD" },
   { "label": "Chile", "value": "CL" },
   { "label": "China", "value": "CN" },
   { "label": "Christmas Island", "value": "CX" },
   { "label": "Cocos (Keeling) Islands", "value": "CC" },
   { "label": "Colombia", "value": "CO" },
   { "label": "Comoros", "value": "KM" },
   { "label": "Congo", "value": "CG" },
   { "label": "Congo, The Democratic Republic of the", "value": "CD" },
   { "label": "Cook Islands", "value": "CK" },
   { "label": "Costa Rica", "value": "CR" },
   { "label": "Cote D'Ivoire", "value": "CI" },
   { "label": "Croatia", "value": "HR" },
   { "label": "Cuba", "value": "CU" },
   { "label": "Cyprus", "value": "CY" },
   { "label": "Czech Republic", "value": "CZ" },
   { "label": "Denmark", "value": "DK" },
   { "label": "Djibouti", "value": "DJ" },
   { "label": "Dominica", "value": "DM" },
   { "label": "Dominican Republic", "value": "DO" },
   { "label": "Ecuador", "value": "EC" },
   { "label": "Egypt", "value": "EG" },
   { "label": "El Salvador", "value": "SV" },
   { "label": "Equatorial Guinea", "value": "GQ" },
   { "label": "Eritrea", "value": "ER" },
   { "label": "Estonia", "value": "EE" },
   { "label": "Ethiopia", "value": "ET" },
   { "label": "Falkland Islands (Malvinas)", "value": "FK" },
   { "label": "Faroe Islands", "value": "FO" },
   { "label": "Fiji", "value": "FJ" },
   { "label": "Finland", "value": "FI" },
   { "label": "France", "value": "FR" },
   { "label": "French Guiana", "value": "GF" },
   { "label": "French Polynesia", "value": "PF" },
   { "label": "French Southern Territories", "value": "TF" },
   { "label": "Gabon", "value": "GA" },
   { "label": "Gambia", "value": "GM" },
   { "label": "Georgia", "value": "GE" },
   { "label": "Germany", "value": "DE" },
   { "label": "Ghana", "value": "GH" },
   { "label": "Gibraltar", "value": "GI" },
   { "label": "Greece", "value": "GR" },
   { "label": "Greenland", "value": "GL" },
   { "label": "Grenada", "value": "GD" },
   { "label": "Guadeloupe", "value": "GP" },
   { "label": "Guam", "value": "GU" },
   { "label": "Guatemala", "value": "GT" },
   { "label": "Guernsey", "value": "GG" },
   { "label": "Guinea", "value": "GN" },
   { "label": "Guinea-Bissau", "value": "GW" },
   { "label": "Guyana", "value": "GY" },
   { "label": "Haiti", "value": "HT" },
   { "label": "Heard Island and Mcdonald Islands", "value": "HM" },
   { "label": "Holy See (Vatican City State)", "value": "VA" },
   { "label": "Honduras", "value": "HN" },
   { "label": "Hong Kong", "value": "HK" },
   { "label": "Hungary", "value": "HU" },
   { "label": "Iceland", "value": "IS" },
   { "label": "India", "value": "India" },
   { "label": "Indonesia", "value": "ID" },
   { "label": "Iran, Islamic Republic Of", "value": "IR" },
   { "label": "Iraq", "value": "IQ" },
   { "label": "Ireland", "value": "IE" },
   { "label": "Isle of Man", "value": "IM" },
   { "label": "Israel", "value": "IL" },
   { "label": "Italy", "value": "IT" },
   { "label": "Jamaica", "value": "JM" },
   { "label": "Japan", "value": "JP" },
   { "label": "Jersey", "value": "JE" },
   { "label": "Jordan", "value": "JO" },
   { "label": "Kazakhstan", "value": "KZ" },
   { "label": "Kenya", "value": "KE" },
   { "label": "Kiribati", "value": "KI" },
   { "label": "Korea, Democratic People'S Republic of", "value": "KP" },
   { "label": "Korea, Republic of", "value": "KR" },
   { "label": "Kuwait", "value": "KW" },
   { "label": "Kyrgyzstan", "value": "KG" },
   { "label": "Lao People'S Democratic Republic", "value": "LA" },
   { "label": "Latvia", "value": "LV" },
   { "label": "Lebanon", "value": "LB" },
   { "label": "Lesotho", "value": "LS" },
   { "label": "Liberia", "value": "LR" },
   { "label": "Libyan Arab Jamahiriya", "value": "LY" },
   { "label": "Liechtenstein", "value": "LI" },
   { "label": "Lithuania", "value": "LT" },
   { "label": "Luxembourg", "value": "LU" },
   { "label": "Macao", "value": "MO" },
   { "label": "Macedonia, The Former Yugoslav Republic of", "value": "MK" },
   { "label": "Madagascar", "value": "MG" },
   { "label": "Malawi", "value": "MW" },
   { "label": "Malaysia", "value": "MY" },
   { "label": "Maldives", "value": "MV" },
   { "label": "Mali", "value": "ML" },
   { "label": "Malta", "value": "MT" },
   { "label": "Marshall Islands", "value": "MH" },
   { "label": "Martinique", "value": "MQ" },
   { "label": "Mauritania", "value": "MR" },
   { "label": "Mauritius", "value": "MU" },
   { "label": "Mayotte", "value": "YT" },
   { "label": "Mexico", "value": "MX" },
   { "label": "Micronesia, Federated States of", "value": "FM" },
   { "label": "Moldova, Republic of", "value": "MD" },
   { "label": "Monaco", "value": "MC" },
   { "label": "Mongolia", "value": "MN" },
   { "label": "Montserrat", "value": "MS" },
   { "label": "Morocco", "value": "MA" },
   { "label": "Mozambique", "value": "MZ" },
   { "label": "Myanmar", "value": "MM" },
   { "label": "Namibia", "value": "NA" },
   { "label": "Nauru", "value": "NR" },
   { "label": "Nepal", "value": "NP" },
   { "label": "Netherlands", "value": "NL" },
   { "label": "Netherlands Antilles", "value": "AN" },
   { "label": "New Caledonia", "value": "NC" },
   { "label": "New Zealand", "value": "NZ" },
   { "label": "Nicaragua", "value": "NI" },
   { "label": "Niger", "value": "NE" },
   { "label": "Nigeria", "value": "NG" },
   { "label": "Niue", "value": "NU" },
   { "label": "Norfolk Island", "value": "NF" },
   { "label": "Northern Mariana Islands", "value": "MP" },
   { "label": "Norway", "value": "NO" },
   { "label": "Oman", "value": "OM" },
   { "label": "Pakistan", "value": "PK" },
   { "label": "Palau", "value": "PW" },
   { "label": "Palestinian Territory, Occupied", "value": "PS" },
   { "label": "Panama", "value": "PA" },
   { "label": "Papua New Guinea", "value": "PG" },
   { "label": "Paraguay", "value": "PY" },
   { "label": "Peru", "value": "PE" },
   { "label": "Philippines", "value": "PH" },
   { "label": "Pitcairn", "value": "PN" },
   { "label": "Poland", "value": "PL" },
   { "label": "Portugal", "value": "PT" },
   { "label": "Puerto Rico", "value": "PR" },
   { "label": "Qatar", "value": "QA" },
   { "label": "Reunion", "value": "RE" },
   { "label": "Romania", "value": "RO" },
   { "label": "Russian Federation", "value": "RU" },
   { "label": "RWANDA", "value": "RW" },
   { "label": "Saint Helena", "value": "SH" },
   { "label": "Saint Kitts and Nevis", "value": "KN" },
   { "label": "Saint Lucia", "value": "LC" },
   { "label": "Saint Pierre and Miquelon", "value": "PM" },
   { "label": "Saint Vincent and the Grenadines", "value": "VC" },
   { "label": "Samoa", "value": "WS" },
   { "label": "San Marino", "value": "SM" },
   { "label": "Sao Tome and Principe", "value": "ST" },
   { "label": "Saudi Arabia", "value": "SA" },
   { "label": "Senegal", "value": "SN" },
   { "label": "Serbia and Montenegro", "value": "CS" },
   { "label": "Seychelles", "value": "SC" },
   { "label": "Sierra Leone", "value": "SL" },
   { "label": "Singapore", "value": "SG" },
   { "label": "Slovakia", "value": "SK" },
   { "label": "Slovenia", "value": "SI" },
   { "label": "Solomon Islands", "value": "SB" },
   { "label": "Somalia", "value": "SO" },
   { "label": "South Africa", "value": "ZA" },
   { "label": "South Georgia and the South Sandwich Islands", "value": "GS" },
   { "label": "Spain", "value": "ES" },
   { "label": "Sri Lanka", "value": "LK" },
   { "label": "Sudan", "value": "SD" },
   { "label": "Suriname", "value": "SR" },
   { "label": "Svalbard and Jan Mayen", "value": "SJ" },
   { "label": "Swaziland", "value": "SZ" },
   { "label": "Sweden", "value": "SE" },
   { "label": "Switzerland", "value": "CH" },
   { "label": "Syrian Arab Republic", "value": "SY" },
   { "label": "Taiwan, Province of China", "value": "TW" },
   { "label": "Tajikistan", "value": "TJ" },
   { "label": "Tanzania, United Republic of", "value": "TZ" },
   { "label": "Thailand", "value": "TH" },
   { "label": "Timor-Leste", "value": "TL" },
   { "label": "Togo", "value": "TG" },
   { "label": "Tokelau", "value": "TK" },
   { "label": "Tonga", "value": "TO" },
   { "label": "Trinidad and Tobago", "value": "TT" },
   { "label": "Tunisia", "value": "TN" },
   { "label": "Turkey", "value": "TR" },
   { "label": "Turkmenistan", "value": "TM" },
   { "label": "Turks and Caicos Islands", "value": "TC" },
   { "label": "Tuvalu", "value": "TV" },
   { "label": "Uganda", "value": "UG" },
   { "label": "Ukraine", "value": "UA" },
   { "label": "United Arab Emirates", "value": "AE" },
   { "label": "United Kingdom", "value": "GB" },
   { "label": "United States", "value": "US" },
   { "label": "United States Minor Outlying Islands", "value": "UM" },
   { "label": "Uruguay", "value": "UY" },
   { "label": "Uzbekistan", "value": "UZ" },
   { "label": "Vanuatu", "value": "VU" },
   { "label": "Venezuela", "value": "VE" },
   { "label": "Viet Nam", "value": "VN" },
   { "label": "Virgin Islands, British", "value": "VG" },
   { "label": "Virgin Islands, U.S.", "value": "VI" },
   { "label": "Wallis and Futuna", "value": "WF" },
   { "label": "Western Sahara", "value": "EH" },
   { "label": "Yemen", "value": "YE" },
   { "label": "Zambia", "value": "ZM" },
   { "label": "Zimbabwe", "value": "ZW" }
];



export default ([
   {
      "state": "Andhra Pradesh",
      "districts": [
         "Anantapur",
         "Chittoor",
         "East Godavari",
         "Guntur",
         "Krishna",
         "Kurnool",
         "Nellore",
         "Prakasam",
         "Srikakulam",
         "Visakhapatnam",
         "Vizianagaram",
         "West Godavari",
         "YSR Kadapa"
      ]
   },
   {
      "state": "Arunachal Pradesh",
      "districts": [
         "Tawang",
         "West Kameng",
         "East Kameng",
         "Papum Pare",
         "Kurung Kumey",
         "Kra Daadi",
         "Lower Subansiri",
         "Upper Subansiri",
         "West Siang",
         "East Siang",
         "Siang",
         "Upper Siang",
         "Lower Siang",
         "Lower Dibang Valley",
         "Dibang Valley",
         "Anjaw",
         "Lohit",
         "Namsai",
         "Changlang",
         "Tirap",
         "Longding"
      ]
   },
   {
      "state": "Assam",
      "districts": [
         "Baksa",
         "Barpeta",
         "Biswanath",
         "Bongaigaon",
         "Cachar",
         "Charaideo",
         "Chirang",
         "Darrang",
         "Dhemaji",
         "Dhubri",
         "Dibrugarh",
         "Goalpara",
         "Golaghat",
         "Hailakandi",
         "Hojai",
         "Jorhat",
         "Kamrup Metropolitan",
         "Kamrup",
         "Karbi Anglong",
         "Karimganj",
         "Kokrajhar",
         "Lakhimpur",
         "Majuli",
         "Morigaon",
         "Nagaon",
         "Nalbari",
         "Dima Hasao",
         "Sivasagar",
         "Sonitpur",
         "South Salmara-Mankachar",
         "Tinsukia",
         "Udalguri",
         "West Karbi Anglong"
      ]
   },
   {
      "state": "Bihar",
      "districts": [
         "Araria",
         "Arwal",
         "Aurangabad",
         "Banka",
         "Begusarai",
         "Bhagalpur",
         "Bhojpur",
         "Buxar",
         "Darbhanga",
         "East Champaran (Motihari)",
         "Gaya",
         "Gopalganj",
         "Jamui",
         "Jehanabad",
         "Kaimur (Bhabua)",
         "Katihar",
         "Khagaria",
         "Kishanganj",
         "Lakhisarai",
         "Madhepura",
         "Madhubani",
         "Munger (Monghyr)",
         "Muzaffarpur",
         "Nalanda",
         "Nawada",
         "Patna",
         "Purnia (Purnea)",
         "Rohtas",
         "Saharsa",
         "Samastipur",
         "Saran",
         "Sheikhpura",
         "Sheohar",
         "Sitamarhi",
         "Siwan",
         "Supaul",
         "Vaishali",
         "West Champaran"
      ]
   },
   {
      "state": "Chandigarh (UT)",
      "districts": [
         "Chandigarh"
      ]
   },
   {
      "state": "Chhattisgarh",
      "districts": [
         "Balod",
         "Baloda Bazar",
         "Balrampur",
         "Bastar",
         "Bemetara",
         "Bijapur",
         "Bilaspur",
         "Dantewada (South Bastar)",
         "Dhamtari",
         "Durg",
         "Gariyaband",
         "Janjgir-Champa",
         "Jashpur",
         "Kabirdham (Kawardha)",
         "Kanker (North Bastar)",
         "Kondagaon",
         "Korba",
         "Korea (Koriya)",
         "Mahasamund",
         "Mungeli",
         "Narayanpur",
         "Raigarh",
         "Raipur",
         "Rajnandgaon",
         "Sukma",
         "Surajpur  ",
         "Surguja"
      ]
   },
   {
      "state": "Dadra and Nagar Haveli (UT)",
      "districts": [
         "Dadra & Nagar Haveli"
      ]
   },
   {
      "state": "Daman and Diu (UT)",
      "districts": [
         "Daman",
         "Diu"
      ]
   },
   {
      "state": "Delhi (NCT)",
      "districts": [
         "Central Delhi",
         "East Delhi",
         "New Delhi",
         "North Delhi",
         "North East  Delhi",
         "North West  Delhi",
         "Shahdara",
         "South Delhi",
         "South East Delhi",
         "South West  Delhi",
         "West Delhi"
      ]
   },
   {
      "state": "Goa",
      "districts": [
         "North Goa",
         "South Goa"
      ]
   },
   {
      "state": "Gujarat",
      "districts": [
         "Ahmedabad",
         "Amreli",
         "Anand",
         "Aravalli",
         "Banaskantha (Palanpur)",
         "Bharuch",
         "Bhavnagar",
         "Botad",
         "Chhota Udepur",
         "Dahod",
         "Dangs (Ahwa)",
         "Devbhoomi Dwarka",
         "Gandhinagar",
         "Gir Somnath",
         "Jamnagar",
         "Junagadh",
         "Kachchh",
         "Kheda (Nadiad)",
         "Mahisagar",
         "Mehsana",
         "Morbi",
         "Narmada (Rajpipla)",
         "Navsari",
         "Panchmahal (Godhra)",
         "Patan",
         "Porbandar",
         "Rajkot",
         "Sabarkantha (Himmatnagar)",
         "Surat",
         "Surendranagar",
         "Tapi (Vyara)",
         "Vadodara",
         "Valsad"
      ]
   },
   {
      "state": "Haryana",
      "districts": [
         "Ambala",
         "Bhiwani",
         "Charkhi Dadri",
         "Faridabad",
         "Fatehabad",
         "Gurgaon",
         "Hisar",
         "Jhajjar",
         "Jind",
         "Kaithal",
         "Karnal",
         "Kurukshetra",
         "Mahendragarh",
         "Mewat",
         "Palwal",
         "Panchkula",
         "Panipat",
         "Rewari",
         "Rohtak",
         "Sirsa",
         "Sonipat",
         "Yamunanagar"
      ]
   },
   {
      "state": "Himachal Pradesh",
      "districts": [
         "Bilaspur",
         "Chamba",
         "Hamirpur",
         "Kangra",
         "Kinnaur",
         "Kullu",
         "Lahaul &amp; Spiti",
         "Mandi",
         "Shimla",
         "Sirmaur (Sirmour)",
         "Solan",
         "Una"
      ]
   },
   {
      "state": "Jammu and Kashmir",
      "districts": [
         "Anantnag",
         "Bandipore",
         "Baramulla",
         "Budgam",
         "Doda",
         "Ganderbal",
         "Jammu",
         "Kargil",
         "Kathua",
         "Kishtwar",
         "Kulgam",
         "Kupwara",
         "Leh",
         "Poonch",
         "Pulwama",
         "Rajouri",
         "Ramban",
         "Reasi",
         "Samba",
         "Shopian",
         "Srinagar",
         "Udhampur"
      ]
   },
   {
      "state": "Jharkhand",
      "districts": [
         "Bokaro",
         "Chatra",
         "Deoghar",
         "Dhanbad",
         "Dumka",
         "East Singhbhum",
         "Garhwa",
         "Giridih",
         "Godda",
         "Gumla",
         "Hazaribag",
         "Jamtara",
         "Khunti",
         "Koderma",
         "Latehar",
         "Lohardaga",
         "Pakur",
         "Palamu",
         "Ramgarh",
         "Ranchi",
         "Sahibganj",
         "Seraikela-Kharsawan",
         "Simdega",
         "West Singhbhum"
      ]
   },
   {
      "state": "Karnataka",
      "districts": [
         "Bagalkot",
         "Ballari (Bellary)",
         "Belagavi (Belgaum)",
         "Bengaluru (Bangalore) Rural",
         "Bengaluru (Bangalore) Urban",
         "Bidar",
         "Chamarajanagar",
         "Chikballapur",
         "Chikkamagaluru (Chikmagalur)",
         "Chitradurga",
         "Dakshina Kannada",
         "Davangere",
         "Dharwad",
         "Gadag",
         "Hassan",
         "Haveri",
         "Kalaburagi (Gulbarga)",
         "Kodagu",
         "Kolar",
         "Koppal",
         "Mandya",
         "Mysuru (Mysore)",
         "Raichur",
         "Ramanagara",
         "Shivamogga (Shimoga)",
         "Tumakuru (Tumkur)",
         "Udupi",
         "Uttara Kannada (Karwar)",
         "Vijayapura (Bijapur)",
         "Yadgir"
      ]
   },
   {
      "state": "Kerala",
      "districts": [
         "Alappuzha",
         "Ernakulam",
         "Idukki",
         "Kannur",
         "Kasaragod",
         "Kollam",
         "Kottayam",
         "Kozhikode",
         "Malappuram",
         "Palakkad",
         "Pathanamthitta",
         "Thiruvananthapuram",
         "Thrissur",
         "Wayanad"
      ]
   },
   {
      "state": "Lakshadweep (UT)",
      "districts": [
         "Agatti",
         "Amini",
         "Androth",
         "Bithra",
         "Chethlath",
         "Kavaratti",
         "Kadmath",
         "Kalpeni",
         "Kilthan",
         "Minicoy"
      ]
   },
   {
      "state": "Madhya Pradesh",
      "districts": [
         "Agar Malwa",
         "Alirajpur",
         "Anuppur",
         "Ashoknagar",
         "Balaghat",
         "Barwani",
         "Betul",
         "Bhind",
         "Bhopal",
         "Burhanpur",
         "Chhatarpur",
         "Chhindwara",
         "Damoh",
         "Datia",
         "Dewas",
         "Dhar",
         "Dindori",
         "Guna",
         "Gwalior",
         "Harda",
         "Hoshangabad",
         "Indore",
         "Jabalpur",
         "Jhabua",
         "Katni",
         "Khandwa",
         "Khargone",
         "Mandla",
         "Mandsaur",
         "Morena",
         "Narsinghpur",
         "Neemuch",
         "Panna",
         "Raisen",
         "Rajgarh",
         "Ratlam",
         "Rewa",
         "Sagar",
         "Satna",
         "Sehore",
         "Seoni",
         "Shahdol",
         "Shajapur",
         "Sheopur",
         "Shivpuri",
         "Sidhi",
         "Singrauli",
         "Tikamgarh",
         "Ujjain",
         "Umaria",
         "Vidisha"
      ]
   },
   {
      "state": "Maharashtra",
      "districts": [
         "Ahmednagar",
         "Akola",
         "Amravati",
         "Aurangabad",
         "Beed",
         "Bhandara",
         "Buldhana",
         "Chandrapur",
         "Dhule",
         "Gadchiroli",
         "Gondia",
         "Hingoli",
         "Jalgaon",
         "Jalna",
         "Kolhapur",
         "Latur",
         "Mumbai City",
         "Mumbai Suburban",
         "Nagpur",
         "Nanded",
         "Nandurbar",
         "Nashik",
         "Osmanabad",
         "Palghar",
         "Parbhani",
         "Pune",
         "Raigad",
         "Ratnagiri",
         "Sangli",
         "Satara",
         "Sindhudurg",
         "Solapur",
         "Thane",
         "Wardha",
         "Washim",
         "Yavatmal"
      ]
   },
   {
      "state": "Manipur",
      "districts": [
         "Bishnupur",
         "Chandel",
         "Churachandpur",
         "Imphal East",
         "Imphal West",
         "Jiribam",
         "Kakching",
         "Kamjong",
         "Kangpokpi",
         "Noney",
         "Pherzawl",
         "Senapati",
         "Tamenglong",
         "Tengnoupal",
         "Thoubal",
         "Ukhrul"
      ]
   },
   {
      "state": "Meghalaya",
      "districts": [
         "East Garo Hills",
         "East Jaintia Hills",
         "East Khasi Hills",
         "North Garo Hills",
         "Ri Bhoi",
         "South Garo Hills",
         "South West Garo Hills ",
         "South West Khasi Hills",
         "West Garo Hills",
         "West Jaintia Hills",
         "West Khasi Hills"
      ]
   },
   {
      "state": "Mizoram",
      "districts": [
         "Aizawl",
         "Champhai",
         "Kolasib",
         "Lawngtlai",
         "Lunglei",
         "Mamit",
         "Saiha",
         "Serchhip"
      ]
   },
   {
      "state": "Nagaland",
      "districts": [
         "Dimapur",
         "Kiphire",
         "Kohima",
         "Longleng",
         "Mokokchung",
         "Mon",
         "Peren",
         "Phek",
         "Tuensang",
         "Wokha",
         "Zunheboto"
      ]
   },
   {
      "state": "Odisha",
      "districts": [
         "Angul",
         "Balangir",
         "Balasore",
         "Bargarh",
         "Bhadrak",
         "Boudh",
         "Cuttack",
         "Deogarh",
         "Dhenkanal",
         "Gajapati",
         "Ganjam",
         "Jagatsinghapur",
         "Jajpur",
         "Jharsuguda",
         "Kalahandi",
         "Kandhamal",
         "Kendrapara",
         "Kendujhar (Keonjhar)",
         "Khordha",
         "Koraput",
         "Malkangiri",
         "Mayurbhanj",
         "Nabarangpur",
         "Nayagarh",
         "Nuapada",
         "Puri",
         "Rayagada",
         "Sambalpur",
         "Sonepur",
         "Sundargarh"
      ]
   },
   {
      "state": "Puducherry (UT)",
      "districts": [
         "Karaikal",
         "Mahe",
         "Pondicherry",
         "Yanam"
      ]
   },
   {
      "state": "Punjab",
      "districts": [
         "Amritsar",
         "Barnala",
         "Bathinda",
         "Faridkot",
         "Fatehgarh Sahib",
         "Fazilka",
         "Ferozepur",
         "Gurdaspur",
         "Hoshiarpur",
         "Jalandhar",
         "Kapurthala",
         "Ludhiana",
         "Mansa",
         "Moga",
         "Muktsar",
         "Nawanshahr (Shahid Bhagat Singh Nagar)",
         "Pathankot",
         "Patiala",
         "Rupnagar",
         "Sahibzada Ajit Singh Nagar (Mohali)",
         "Sangrur",
         "Tarn Taran"
      ]
   },
   {
      "state": "Rajasthan",
      "districts": [
         "Ajmer",
         "Alwar",
         "Banswara",
         "Baran",
         "Barmer",
         "Bharatpur",
         "Bhilwara",
         "Bikaner",
         "Bundi",
         "Chittorgarh",
         "Churu",
         "Dausa",
         "Dholpur",
         "Dungarpur",
         "Hanumangarh",
         "Jaipur",
         "Jaisalmer",
         "Jalore",
         "Jhalawar",
         "Jhunjhunu",
         "Jodhpur",
         "Karauli",
         "Kota",
         "Nagaur",
         "Pali",
         "Pratapgarh",
         "Rajsamand",
         "Sawai Madhopur",
         "Sikar",
         "Sirohi",
         "Sri Ganganagar",
         "Tonk",
         "Udaipur"
      ]
   },
   {
      "state": "Sikkim",
      "districts": [
         "East Sikkim",
         "North Sikkim",
         "South Sikkim",
         "West Sikkim"
      ]
   },
   {
      "state": "Tamil Nadu",
      "districts": [
         "Ariyalur",
         "Chennai",
         "Coimbatore",
         "Cuddalore",
         "Dharmapuri",
         "Dindigul",
         "Erode",
         "Kanchipuram",
         "Kanyakumari",
         "Karur",
         "Krishnagiri",
         "Madurai",
         "Nagapattinam",
         "Namakkal",
         "Nilgiris",
         "Perambalur",
         "Pudukkottai",
         "Ramanathapuram",
         "Salem",
         "Sivaganga",
         "Thanjavur",
         "Theni",
         "Thoothukudi (Tuticorin)",
         "Tiruchirappalli",
         "Tirunelveli",
         "Tiruppur",
         "Tiruvallur",
         "Tiruvannamalai",
         "Tiruvarur",
         "Vellore",
         "Viluppuram",
         "Virudhunagar"
      ]
   },
   {
      "state": "Telangana",
      "districts": [
         "Adilabad",
         "Bhadradri Kothagudem",
         "Hyderabad",
         "Jagtial",
         "Jangaon",
         "Jayashankar Bhoopalpally",
         "Jogulamba Gadwal",
         "Kamareddy",
         "Karimnagar",
         "Khammam",
         "Komaram Bheem Asifabad",
         "Mahabubabad",
         "Mahabubnagar",
         "Mancherial",
         "Medak",
         "Medchal",
         "Nagarkurnool",
         "Nalgonda",
         "Nirmal",
         "Nizamabad",
         "Peddapalli",
         "Rajanna Sircilla",
         "Rangareddy",
         "Sangareddy",
         "Siddipet",
         "Suryapet",
         "Vikarabad",
         "Wanaparthy",
         "Warangal (Rural)",
         "Warangal (Urban)",
         "Yadadri Bhuvanagiri"
      ]
   },
   {
      "state": "Tripura",
      "districts": [
         "Dhalai",
         "Gomati",
         "Khowai",
         "North Tripura",
         "Sepahijala",
         "South Tripura",
         "Unakoti",
         "West Tripura"
      ]
   },
   {
      "state": "Uttarakhand",
      "districts": [
         "Almora",
         "Bageshwar",
         "Chamoli",
         "Champawat",
         "Dehradun",
         "Haridwar",
         "Nainital",
         "Pauri Garhwal",
         "Pithoragarh",
         "Rudraprayag",
         "Tehri Garhwal",
         "Udham Singh Nagar",
         "Uttarkashi"
      ]
   },
   {
      "state": "Uttar Pradesh",
      "districts": [
         "Agra",
         "Aligarh",
         "Allahabad",
         "Ambedkar Nagar",
         "Amethi (Chatrapati Sahuji Mahraj Nagar)",
         "Amroha (J.P. Nagar)",
         "Auraiya",
         "Azamgarh",
         "Baghpat",
         "Bahraich",
         "Ballia",
         "Balrampur",
         "Banda",
         "Barabanki",
         "Bareilly",
         "Basti",
         "Bhadohi",
         "Bijnor",
         "Budaun",
         "Bulandshahr",
         "Chandauli",
         "Chitrakoot",
         "Deoria",
         "Etah",
         "Etawah",
         "Faizabad",
         "Farrukhabad",
         "Fatehpur",
         "Firozabad",
         "Gautam Buddha Nagar",
         "Ghaziabad",
         "Ghazipur",
         "Gonda",
         "Gorakhpur",
         "Hamirpur",
         "Hapur (Panchsheel Nagar)",
         "Hardoi",
         "Hathras",
         "Jalaun",
         "Jaunpur",
         "Jhansi",
         "Kannauj",
         "Kanpur Dehat",
         "Kanpur Nagar",
         "Kanshiram Nagar (Kasganj)",
         "Kaushambi",
         "Kushinagar (Padrauna)",
         "Lakhimpur - Kheri",
         "Lalitpur",
         "Lucknow",
         "Maharajganj",
         "Mahoba",
         "Mainpuri",
         "Mathura",
         "Mau",
         "Meerut",
         "Mirzapur",
         "Moradabad",
         "Muzaffarnagar",
         "Pilibhit",
         "Pratapgarh",
         "RaeBareli",
         "Rampur",
         "Saharanpur",
         "Sambhal (Bhim Nagar)",
         "Sant Kabir Nagar",
         "Shahjahanpur",
         "Shamali (Prabuddh Nagar)",
         "Shravasti",
         "Siddharth Nagar",
         "Sitapur",
         "Sonbhadra",
         "Sultanpur",
         "Unnao",
         "Varanasi"
      ]
   },
   {
      "state": "West Bengal",
      "districts": [
         "Alipurduar",
         "Bankura",
         "Birbhum",
         "Burdwan (Bardhaman)",
         "Cooch Behar",
         "Dakshin Dinajpur (South Dinajpur)",
         "Darjeeling",
         "Hooghly",
         "Howrah",
         "Jalpaiguri",
         "Kalimpong",
         "Kolkata",
         "Malda",
         "Murshidabad",
         "Nadia",
         "North 24 Parganas",
         "Paschim Medinipur (West Medinipur)",
         "Purba Medinipur (East Medinipur)",
         "Purulia",
         "South 24 Parganas",
         "Uttar Dinajpur (North Dinajpur)"
      ]
   }
]
)

export { countries }