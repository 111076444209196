import React from "react";
import { Link } from 'react-router-dom';
import { FaEye } from 'react-icons/fa';
import _ from 'lodash'
import moment from 'moment';

const MyProjects = ({ allFilms }) => (
  <div className="row">
    <div className="col-md-12 table_info_bx project_list">
      <div className="shadow_bg clearfix">
        <div className="table_head">
          <h5>Project List</h5>
        </div>
        <div className="table-responsive">
          <table className="table people_table">
            <thead>
              <tr>
                <th>Project</th>
                <th>Creation Date</th>
                <th>Type</th>
                <th>Team</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>

              {
                _.map(allFilms, val => (
                  <tr>
                    <td><strong>{val.title}</strong></td>
                    <td>{moment(val.createdAt).format('ll')}</td>
                    <td>{val.filmType}</td>
                    <td>{val.team?.length}</td>
                    <td><p className="label label-info">Completed</p></td>
                    <td><Link to={{ pathname: "/MakeFilm",state: { id : val._id } }}><FaEye /> View</Link></td>
                  </tr>
                ))
              }

            </tbody>
          </table>

        </div>
      </div>


    </div>


  </div>
);

export default MyProjects;