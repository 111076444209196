import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

class Slider extends Component {  
    state={
        item: [
        {url:'images/making_film.jpg', about:'Cast actors and on-screen talent', title:'Film, Video + TV Production'},
        {url:'images/voice_prod.jpg', about:'Find and manage voice talent', title:'Voiceover Production'},
        {url:'images/brand_ad.jpg', about:'Cast stage talent', title:'Theater + Performing Arts'},
        {url:'images/theatre.jpg', about:'Connect with on-brand talent', title:'Commercial + Branded Content'},
        {url:'images/influencer.jpg', about:'Search the largest Database of people', title:'Influencers '},
        {url:'images/making_film.jpg', about:'Cast actors and on-screen talent', title:'Film, Video + TV Production'},
        {url:'images/voice_prod.jpg', about:'Find and manage voice talent', title:'Voiceover Production'},
        ]        
}

render(){
    const {item} = this.state;
    const state= {
      responsive:{
          0: {
              items: 2,
          },
          450: {
              items: 3,
          },
          600: {
              items: 4,
          },
          1000: {
              items: 5,
          },
      },
    }
    return(
              <OwlCarousel
               className="owl-theme owl-carousel"
               loop
               margin={7}
               items={5}
               style={{
                 opacity: 1,
                 display: 'block'
               }}
               //  {...options}
               nav
               autoplay={true}
               autospeed="true"
               responsive={state.responsive}
             >


        {item.map(item =>   
            <div className="item">
                 <div className="gallery_info">
                   <a href="#">
                     <div className="movie_img">
                       <img src={item.url} alt="img" />
                     </div>
                   </a>
                   <div className="inside_info">
                     
                     <a href="title_detail.php"><h5>{item.title}</h5></a>
                     <p>{item.about}</p>
                   </div>
                 </div>
               </div>  
        )
        }          
        </OwlCarousel>
    )
}

}

export default Slider