import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight, FaTimes } from "react-icons/fa";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import '../../scss/celebrityManagement.scss'
import _ from "lodash";

class BeautyHealthInfluencer extends Component {

  render() {
    return (
      <>
        <Header />
        <SubHeader /> 
          <section id="inner_celeb_banner">
            <div class="container">
              <div class="row">
                <div class="col-sm-6 col-xs-8 inner_celebrity_info">
                  <h1>BEAUTY & HEALTH INFLUENCER MARKETING</h1>
                  <p align="justify">It has been seen and noticed that influencer marketing works wonder for beauty products, heath like diet and nutrition as well as grocery these days. People love to invest a lot of time on social media and if you want your product range to beat the competition and emerge as a star then influencer marketing is the best option.</p>
                  <Link to="/ReachUs" class="stroke_btn">Contact US</Link>
                </div>
                <div class="col-sm-6 inner_celebrity_img">
                <img src="images/beauty-health-influencer-marketing.webp" alt="BollywoodisGood is best Celebrity Management Company in India" width="699" height="450" class="wow fadeInDown" data-wow-delay="0.3s" />	 
                </div>
              </div>
            </div>
          </section>

          <section id="inner_about">
          <div class="container">
          <div class="row">
          <div class="col-sm-5">
          <img src="images/health-influencer-marketing.webp"  width="458" height="458" alt="Celebrity Management Services in India" />
          </div>
            <div className="col-sm-7">
            <div class="inner_head">
          <h2>Product Branding</h2>
          </div>
          <p align="justify">BollywoodisGood as the most reputed consumer goods influencer agency and lifestyle influencer agency make sure that the brand/business that is connected with us gain the maximum from our services. Finding the right influencer for your brand/business is very important and that will be done by our team of experts. Products like luxury beauty, make-up, health and personal care, diet and nutrition and grocery and gourmet foods have a huge market, therefore gaining the position there and making your presence becomes very difficult, as it’s hard to beat the competition but influencer marketing can make it happen.</p>
          <br />
          <p align="justify">Our experienced team will deeply research the products you are offering, what sort of target audience you are looking for, what your competitors are doing and create the most efficient influencer campaign. From finding the influencer, coordinating and collaboration, every aspect will be covered by us.</p>
          </div>          
        
          </div>
          </div>
          </section>

          <section id="last_info">
          <div class="container">
          <div class="row">
          <div class="col-xs-12">
          <blockquote>
          <p align="justify">
              BollywoodisGood has a huge reputation in making the brand popular and big through its successful influencer marketing strategies and techniques. The years of experience have made us perfect that we know what sort of influencer is right for your brand/business and according to your product range and company’s aim, we move forward with the whole process. <br /><br />With BollywoodisGood you don’t have to think or worry, we will handle everything for you and a report will be shared with you that will depict how effective was our designed influencer marketing campaign and what the influencer does for your brand/business. The whole process will increase your return on investment and sales as well as the popularity in the market.  
             </p>
          </blockquote>
          </div>
          </div>
          </div>
          </section>
        <Footer />
      </>
    );
  }
}

export default BeautyHealthInfluencer;
