import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaFacebookF, FaGoogle, FaUser } from 'react-icons/fa';
import { OldSocialLogin as SocialLogin} from "react-social-login";
import { config } from "../../actions/config";
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";
import { auth2Login } from "../../actions";
import { useHistory } from "react-router-dom";
import FacebookLogin from 'react-facebook-login';

const LoginForm = ({ handleOnChange, LoginSubmit, ...props }) => {
  const { t } = useTranslation();
  const location = useHistory();
  const handleSocialLogin = (user, err) => {
    console.log(user);
    console.log(err);
  };
  const hangleGoogleLogin =(cred)=>{
    const userdetail = jwt_decode(cred.credential);
    console.log(userdetail)
    const  newUser = {
       emailId: userdetail.email,
        password: "default", 
        phoneNumber: "0000000000", 
        userName: userdetail.name, 
        userType: 'Independent',  
        activationStatus: true,
        // profilePic: userdetail.picture
      };
      auth2Login(newUser).then((res)=>{
        if (res && res.data && res.data.data) {
          localStorage.setItem('accessToken', res.data.data.accessToken);
          localStorage.setItem('user', JSON.stringify(res.data.data.user));
          location.push('/home');
          window.location.reload();
        }
      }).catch((e)=> console.log(e))
    
  }
  return (
    <>
      <div className="logo">
        <Link to="/">
          <img src="images/dark_logo.png" alt="img" />
        </Link>
      </div>
      <form onSubmit={LoginSubmit} className="form-space">
        <div className="form_head_part">
          <h4>{t("LoginPageTitle")}</h4>
          <br />
        </div>
        <div className="form-group">
          <label>Email/Mobile</label>
          <input
            className="form-control"
            name="emailId"
            onChange={handleOnChange}
            required
          />
        </div>
        <div className="form-group">
          <label>{t("LoginPasswordLabel")}</label>
          <input
            type="password"
            className="form-control"
            name="password"
            onChange={handleOnChange}
            required
          />
  
        </div>
        <div className="form-group">
          <input style={{height: 20, width:20}} type="checkbox" name="tnc" id="tnc" onChange={(e)=>handleOnChange({target:{name:'tnc', value: e.target.checked}})}/> Agree to Privacy Policy
        </div>
          <div className="text-right">
            <Link to="/forgotPassword">{t("Forgot Password?")}</Link>
          </div>
      

        <div className="btn_action_btn">
          <div className="left_side_btn">
            <button type="submit" className="blue_btn btn" defaultValue="Login" disabled={!props.tnc}>
              {t("LoginBtn")}
            </button>
            {/* <Link to="/" className="guest_login">Use OTP to login</Link> */}
          </div>
          <Link to="/NewSignup" className="stroke_btn btn">
            {t("SignupBtn")}
          </Link>
        </div>
        <div className="other_option"><span>Or</span></div>
        <div className="social_login">
          <GoogleLogin auto_select size="medium"
            onSuccess={credentialResponse => {
             hangleGoogleLogin(credentialResponse);
            }}
            onError={() => {
              console.log('Login Failed');
            }}
          />
          {/* <div>
            <SocialLogin
              provider="facebook"
              appId={'1088597931155576'}
              callback={handleSocialLogin}
              
            >
              <Link to="" className="facebook_login"> <FaFacebookF /> Login with Facebook</Link>
            </SocialLogin>
          
          </div> */}

        {/* <SocialLogin
      provider="google"
      appId={config.GOOGLE_OAUTH_CLIENT_ID}
      callback={handleSocialLogin}
      scope='email'
    >
     <Link to="" className="google_login">  <FaGoogle /> Login </Link>
    </SocialLogin> */}
          {/* <Link to="" className="google_login">  <FaGoogle /> Login </Link> */}
          {/* <Link to="" className="facebook_login"> <FaFacebookF /> Login </Link> */}
          {/* <Link to="" className="default_btn"> <FaUser /> Guest Login  </Link> */}
          
        </div>

      </form>
    </>
  );
};

export default LoginForm;
