import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import MyProjects from "../../components/MakeFilm/MyProjects";
import { getAllFilms } from "../../actions/index";
import _ from "lodash";

class ProjectHistoryPage extends Component {
  state = { allFilms: [] };

  componentDidMount() {
    getAllFilms().then((res) => {
      this.setState({ allFilms: res.data.data });
    });
  }

  render() {
    return (
      <>
        <Header {...this.props} />
        <SubHeader />
        {/* CTA section start*/}
       <section id="cta" className="mid_info dark_clr inner_banner">
          <div className="container side_space">
          <div class="home_grey_bx clearfix"><img src="images/hire.png" alt="img" class="right_pattern" />
            <div className="row">   
            <div className="col-md-9 cta_info">
            <h2>Need to hire talent for your next project?</h2>
            <p>Access the largest network of Producers, Directors, Writers, Artists, models, freelancers, and crew members to find the perfect creative talent for your next project.</p>
             </div>
            </div>
            </div>
          </div>
      </section>
      {/* CTA section ends */}
        <section id="make_film" className="dark_clr">
          <img src="images/make_film_bg.png" alt="img" className="bg_img" />
          <div className="container">
            <div className="row">
              <div className="col-sm-7 inner_head">
                <div className="top_left_info clearfix">
                  <h2>Make My Team / Film </h2>
                </div>
              </div>
              <div className="col-sm-5 head_right_part text-right">
                <Link to="/MakeFilm" className="blue_btn">
                  Make New Team/Film
                </Link>
              </div>
            </div>
            <MyProjects {...this.state} {...this.props} />
          </div>
        </section>
        <Footer />
      </>
    );
  }
}

export default ProjectHistoryPage;
