import React from 'react';
import { vlidateNumberField } from '../../Utilities/validateInputField';

const UserApplicationPopup = ({currentUser,jobPostDetail,jobApplication,...props}) => {
  const {job} = jobPostDetail;
    return(
        <>
    <div class="modal fade" id="change_term" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
          <h4 class="modal-title">Your proposed terms</h4>
        </div>
        <div class="modal-body terms_bx">
        <p>Your profile rate: <strong>{currentUser?.additionalInfo?.expectedCompensationAmount} {currentUser?.additionalInfo?.expectedCompensationType} </strong></p>
               <p>Client budget: <strong>Rs {job?.minAmount || 0} - Rs {job?.maxAmount} {job?.payType }</strong></p>
               <hr />            
               <ul>
              <li>
               <label>
                  <strong>{currentUser?.additionalInfo?.expectedCompensationType || 'Hourly'} Rate</strong>
                  <p>Total amount the client will see on your application</p> 
               </label>
               <div className="input_part">
               <input
                                                type="text"
                                                className="form-control"
                                                placeholder="rate"
                                                name="hourlyBidRate"
                                                value={jobApplication?.hourlyBidRate || currentUser?.additionalInfo?.expectedCompensationAmount || ""}
                                                onChange={(e) => {
                                                    vlidateNumberField(e) && props.handleChangeValues({name : 'hourlyBidRate' , value : e.target.value });
                                                }}
                                            /><span style={{whiteSpace:'nowrap'}}>{job?.payType || '/hr'}</span>
               </div>
              </li>
              <li>
               <label>
               <strong>2% Big Service Fees</strong>
               </label>
               <div className="input_part">
                   <input type="text" className="form-control" value={jobApplication?.hourlyBidRate ? jobApplication?.hourlyBidRate *2 /100 : currentUser?.additionalInfo?.expectedCompensationAmount ? currentUser?.additionalInfo?.expectedCompensationAmount * 2/ 100 :'0'} disabled /> {job?.payType  || '/hr'}
               </div>
              </li>
              <li>
               <label>
               <strong>You'll Receive</strong>
                  <p>The estimated amount you'll receive after service fees</p> 
               </label>
               <div className="input_part">
                   <input type="text" className="form-control" value={jobApplication?.hourlyBidRate ? (jobApplication?.hourlyBidRate - (jobApplication?.hourlyBidRate *2 /100)) :currentUser?.additionalInfo?.expectedCompensationAmount ? (currentUser?.additionalInfo?.expectedCompensationAmount - (currentUser?.additionalInfo?.expectedCompensationAmount * 2 /100)): '0'} disabled/> /hr
               </div>
              </li>
            </ul>
          </div>         
          <div class="modal-footer application_btn">
        <button type="submit" className="blue_btn" onClick={props.handleEditSubmit}>Submit</button>
            <button type="button" className="stroke_btn" data-dismiss="modal">Cancel</button>
        </div>
        </div>       
        </div>        
      </div>

      <div class="modal fade" id="withdraw" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
          <h4 class="modal-title">Withdraw Application</h4>
        </div>
        <div class="modal-body terms_bx">
            <p>We will politely notify the client that you are not interested. The client will be able to view the reason you've withdrawn your application.</p>
            <br />
            <strong>Reason</strong>
            <div className="form-group radio_btn">
          <label className="check_bx">
              <input type="radio" name="field" value="" checked /> 
              Applied by mistake
              <span className="checkmark" />
          </label>
          <label className="check_bx">
              <input type="radio" name="field" value="" /> 
              Rate too low
              <span className="checkmark" />
          </label> 
          <label className="check_bx">
              <input type="radio" name="field" value="" /> 
              Scheduling conflict with client
              <span className="checkmark" />
          </label>
          <label className="check_bx">
              <input type="radio" name="field" value="" /> 
              Unresponsive client
              <span className="checkmark" />
          </label>  
          <label className="check_bx">
              <input type="radio" name="field" value="" /> 
              Inappropriate client behavior
              <span className="checkmark" />
          </label>
          <label className="check_bx">
              <input type="radio" name="field" value="" /> 
              Other
              <span className="checkmark" />
          </label>              
          </div>
          <div className="form-group">
          <br />
          <label><strong>Message</strong> </label>    
          <p>Add an optional message to share with the client when we notify them that this application has been withdrawn.</p>       
           <br />
            <textarea className="form-control"></textarea>
          </div>
          </div>         
          <div class="modal-footer application_btn" >
            <button style={{marginRight:10}} type="button" className="stroke_btn" data-dismiss="modal">Cancel</button>
        <button type="submit" className="blue_btn">Submit</button>
        </div>
        </div>       
        </div>        
      </div>

</>
);
}

export default UserApplicationPopup;