import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight, FaTimes } from "react-icons/fa";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import '../../scss/celebrityManagement.scss'
import _ from "lodash";

class EntertainmenInfluencer extends Component {

  render() {
    return (
      <>
        <Header />
        <SubHeader /> 
          <section id="inner_celeb_banner">
            <div class="container">
              <div class="row">
                <div class="col-sm-6 col-xs-8 inner_celebrity_info">
                  <h1>MOVIES, MUSIC AND VIDEO GAMES</h1>
                  <p align="justify">The music, movie and video games industry is a wide one and needs lots and lots of marketing strategies to reach the right audience. Not only the industry is huge but the audience is also very versatile and enormous. Hence, if you need instant results from your marketing strategy then influencer marketing is one of the most trending, beneficial, and result-driven marketing tools.</p>
                  <Link to="/ReachUs" class="stroke_btn">Contact US</Link>
                </div>
                <div class="col-sm-6 inner_celebrity_img">
                <img src="images/entertainment-influencer-marketing.webp" alt="BollywoodisGood is best Celebrity Management Company in India" width="699" height="450" class="wow fadeInDown" data-wow-delay="0.3s" />	 
                </div>
              </div>
            </div>
          </section>

          <section id="inner_about">
          <div class="container">
          <div class="row">
          <div class="col-sm-5">
          <img src="images/movie-promotions.webp"  width="458" height="458" alt="Celebrity Management Services in India" />
          </div>
            <div className="col-sm-7">
            <div class="inner_head">
          <h2>Product Branding</h2>
          </div>
          <p align="justify">BollywoodisGood as one of the most reputed <strong>influencer marketing agency</strong> knows how to promote your brand/business in this industry. Before starting with anything our team of professional work on the details and research a lot regarding the brand/business like goal, vision of the brand, audience that the brand wants to target, what products are you offering, who are your competitors and later we design strategies for promoting your brand/business. </p>
          <br />
          <p align="justify">We at BollywoodisGood work to make the brand popular and enhance their ROI, hence we search, choose, coordinate and collaborate with the right influencer who can endorse your brand/business and has huge followers and engagement. We will do everything that will expand your business and make you a brand. As day by day, there is an increase in the users of social media and people trust influencer therefore influencer marketing has gained so much popularity in the market. </p>
          </div>          
        
          </div>
          </div>
          </section>

          <section id="last_info">
          <div class="container">
          <div class="row">
          <div class="col-xs-12">
          <blockquote>
          <p align="justify">This marketing tool is result-oriented and thus gives positive results. Our team will not only create the campaign from the scratch and choose the influencer but also share the report that will show you the result of the campaign as well as the benefit that the influencer is adding in your brand/business. 
          <br /><br />
          Influencer marketing shows the real impact on the audience that helps in generating more sales and brand awareness. Platforms like Instagram, Facebook, Snapchat, etc. are perfect for influencer marketing and offer a great number of audience. Hence, influencer marketing is the correct tool when you want to stand out in the market. 
          </p>
          </blockquote>
          </div>
          </div>
          </div>
          </section>
           
        <Footer />
      </>
    );
  }
}

export default EntertainmenInfluencer;
