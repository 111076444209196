import React from "react";
import { Link } from 'react-router-dom';
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import PostJobForm from "../../components/JobPost/PostJobForm";
import PostJobBanner from "../../components/JobPost/PostJobBanner";

const PostJobPage = (props) => (
  <>
    <Header {...props} />
    <SubHeader />
     {/* CTA section start*/}
     <section id="cta" className="mid_info dark_clr inner_banner">
          <div className="container side_space">
          <div class="home_grey_bx clearfix"><img src="images/search_job.png" alt="img" class="right_pattern" />
            <div className="row">   
            <div className="col-md-9 col-sm-8 col-xs-8 cta_info full">
            <h2>Ready to make your mark in the film industry?</h2>
            <p>Be a part of the creative process by posting job opportunities on Bollywood is Good!</p>
               </div>
            </div>
            </div>
          </div>
      </section>
      {/* CTA section ends */}

    <section id="track_page_tab" className="dark_clr">
      <div className="container">     
        <PostJobBanner />
        <div className="row">
          <PostJobForm {...props} />
        </div>
      </div>
    </section>
    <Footer />
  </>
);

export default PostJobPage;
