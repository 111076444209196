import React, { Component } from "react";
import CreateUserProfilePage from "../../pages/CreateUserProfile/CreateUserProfilePage";
import { updateProfile, getUserInformation, uploadFiles } from '../../actions/index';
import swal from 'sweetalert';
import registrationConst from './registrationConst';
import _ from 'lodash';
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader"
import currency from "../../components/CreateUserProfile/currency";

export class CreateUserProfileContainer extends Component {

  state = { ...registrationConst, errors: {}, othersActivated: false, agreed: false, countryError: "", stateError: "", cityError: "",};

  nextStep = () => this.setState(state => ({ step: state.step + 1 })); // Proceed to next step

  prevStep = () => this.setState(state => ({ step: state.step - 1 })); //Prev step

  changeRegisterStep = event => this.setState(state => ({ step: event }));

  handleChangeSingle = e => this.setState({ [e.target.name]: e.target.value }, () => this.isValidHttpUrl(e)); // Handle fields change

  isValidHttpUrl = e => {
    const url = e.value;
    const errors = _.cloneDeep(this.state.errors);
    if (['videoLink'].includes(e.name)) {
      // alert("dd")
      let a = document.createElement('a');
      a.href = url;
      if (a.host && a.host != window.location.host) {
        errors.e.target.name = null;
      }
      else {
        errors.e.target.name = "Not a valid URL";
      }
    }
    this.setState({ errors });
  }

  handleChangeNestedValue = e => {
    // console.log(e, "dejdoejdej");
    const data = _.cloneDeep(this.state);
    console.log(data, e, "data");
    if (e.oneOrder) {
      if (!data[e.key][e.name]) {
        data[e.key][e.name] = {};
      }
      data[e.key][e.name][e.oneOrder] = e.value;
      this.setState({ ...data });
      return;
    }
    if (e.twoOrder) {
      if (data[e.key][e.name] && !data[e.key][e.name][e.twoOrder]) {
        data[e.key][e.name][e.twoOrder] = { [e.thirdKey]: '' };
      }
      if (!data[e.key][e.name]) {
        data[e.key] = { [e.name]: { [e.twoOrder]: { [e.thirdKey]: '' } } }
      }
      data[e.key][e.name][e.twoOrder][e.thirdKey] = e.value;
      this.setState({ ...data });
      return;

    }
    if (e.index || e.index === 0) {
      if (!data[e.key][e.index]) {
        data[e.key][e.index] = {};
      }

      data[e.key][e.index][e.name] = e.value;
    }
    else {
      if (!data[e.key]) {
        data[e.key] = {};
      }
      data[e.key][e.name] = e.value;
    }
    this.setState({ ...data });
    if (e.value === 'Others' && e.name === 'collegeUniversity' && e.drop) {
      this.setState({ othersActivated: true });
    }
    if (this.state.othersActivated && e.drop && e.value !== 'Others') {
      this.setState({ othersActivated: false });
    }
    // this.isValidHttpUrl(e)

  }

  handleChangeDropDown = e => {
    this.setState({ [e.name]: e.value })
  if (e.name === 'country') {
    const currencyDatails = currency.find(c => c.country === e.value)
    this.setState({currency: currencyDatails?.currency || 'USD'})
  }
  }; // Handle fields change

  addMoreFields = type => {
    const data = _.cloneDeep(this.state);
    data[type].push({});
    console.log(data, type, data[type], "OPOPOPO")
    console.log(data[type], 'gfdsd')
    this.setState({ ...data });
  }

  removeFields = (type, ind) => {
    const data = _.cloneDeep(this.state);
    data[type] = _.filter(data[type], (val, index) => ind !== index)
    console.log(data, type, data[type], "OPOPOPO")
    this.setState({ ...data });
  }

  componentDidMount() {  
    this.changeRegisterStep(localStorage.getItem('profileStep') ? +(localStorage.getItem('profileStep')) :1)
    const userStr = localStorage.getItem("user");
    const user = userStr ? JSON.parse(userStr):null
    if (user && user._id){
      getUserInformation(user._id)
      .then(res => {
        const data = res.data.data;
        //const {physicalTraits , additionalInfo , eduction , awards , workAndAwards} = data ;
        if (data.workAndAwards ?.length <= 0 ) {
          data.workAndAwards = [{}]
        }

        if (data.awards ?.length <= 0 ) {
          data.awards = [{}]
        }
        this.setState({ ...this.state, ...data });
      });
    }
  }
// componentDidUpdate(){
//     this.setState({ step: 3})
// }
  isvalid() {
    let { country, state, city } = this.state;
    if (!country && !state && !city) {
      this.setState({
        countryError: "Country is required",
        stateError: "state is required",
        cityError: "City is required"
      })
    }
    else {
      return true
    }
  }

  submitForm = (e, published) => {
    e.preventDefault()
    localStorage.removeItem('profileStep')
    if (this.state._id || this.state.agreed) {
      updateProfile(_.pickBy(_.omit(this.state, published, ['errors', 'agreed']), _.identity))
        .then(res => this.props.history.push(`/userProfile/${JSON.parse(localStorage.getItem('user'))._id}`))
        .catch(e => {
          if (e ?.response ?.data ?.Error ?.errors) {
            let errors = {}
            _.map(e.response.data.Error.errors, (val, key) => {
              errors[key] = val.message;
            })
            this.setState({ errors });

          }
          else {
            swal(e.response.data.Error, { buttons: false, timer: 2000, icon: 'error' });
          }
        })

    }
    else {
      alert('Please agree to the terms to continue');
    }
  };


  defaultProps = {
    placeholder: "Type Languages"
  }

  fileSelectedHandler = async(e, nested={}) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    const configs = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    const targetName = e.target.name;
    try {
      const res = await uploadFiles(formData,configs)
      if (res && res.data && res.data.data) {
        if (nested && nested.index >=0) {
          this.handleChangeNestedValue({
            name: targetName,
            value: res.data.data.filename,
            key: "awards",
            index: nested.index,
          })
        }else{
          this.setState({ [targetName]: res.data.data.filename });
        }
      }
    } catch (ex) {
      console.log('upload error', ex);
    }
  }


  render() {
    console.log(this.state, "kedled");
    return (
      <>
        <Header />
        <SubHeader />
        <CreateUserProfilePage
          {...this.state}
          changeRegisterStep={this.changeRegisterStep}
          handleChange={this.handleChangeSingle}
          handleChangeDropDown={this.handleChangeDropDown}
          prevStep={this.prevStep}
          nextStep={this.nextStep}
          submitForm={this.submitForm}
          handleChangeNestedValue={this.handleChangeNestedValue}
          addMoreFields={this.addMoreFields}
          removeFields={this.removeFields}
          isValidHttpUrl={this.isValidHttpUrl}
          defaultPlaceHolder={this.defaultPlaceHolder}
          defaultProps={this.defaultProps}
          fileSelectedHandler={this.fileSelectedHandler}
        />
      </>
    )
  }
}


export default CreateUserProfileContainer;

