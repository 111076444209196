import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { workStatus, payout, filmType } from "./registerConst";
import cityStateConst from "./cityStateConst";
import { vlidateNumberField } from "../../Utilities/validateInputField";
import _ from "lodash";
import AsyncSelect from "react-select/async";
import { loadOptionsResolveLocation } from "../../Constants.js/districtConst";
import NoOptionsMessage from '../../commons/ReactSelectNoOptionsMessage';
import { loadOptionsResolveLanguage } from "../../Constants.js/languageConst";
import moment from 'moment';

const AdditionalInfo = ({
  handleChangeDropDown,
  submitForm,
  handleChange,
  nextStep,
  prevStep,
  handleChangeNestedValue,
  additionalInfo,
  defaultProps,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="setup-content row" id="step-2">
        <div className="form-group col-md-12">
          <label className="control-label">
            Desired Role You Are Looking For
          </label>
          <textarea
            className="form-control"
            name="desiredRole"
            placeholder="Enter your desired role"
            value={additionalInfo ?.desiredRole}
            onChange={(e) => {
              handleChangeNestedValue({
                name: "desiredRole",
                value: e.target.value,
                key: "additionalInfo",
              });
            }}
          />
          <small>Type min 100 characters</small>
        </div>

        <div className="form-group col-md-4">
          <label className="control-label">Preferred Location</label>
          <AsyncSelect
            components={{ NoOptionsMessage }}
            cacheOptions
            defaultOptions={[]}
            loadOptions={loadOptionsResolveLocation}
            value={{
              label: additionalInfo ?.location,
              value: additionalInfo ?.location,
            }}
            onChange={(e) =>
              handleChangeNestedValue({
                name: "location",
                value: e.label,
                key: "additionalInfo",
              })
            }
          />
        </div>

        <div className="col-md-4">
          <label className="control-label">Current Compensation</label>
          <div className="row">
            <div className="form-group col-sm-6">
              <input
                name="currentCompensationAmount"
                type="text"
                className="form-control"
                placeholder="Enter amount"
                value={additionalInfo ?.currentCompensationAmount}
                onChange={(e) => {
                  handleChangeNestedValue({
                    name: "currentCompensationAmount",
                    value: e.target.value,
                    key: "additionalInfo",
                  });
                }}
              />
            </div>
            <div className="form-group col-sm-6 left_space">
              <Select
                options={payout}
                //isMulti
                value={{
                  label: additionalInfo ?.currentCompensationType,
                  value: additionalInfo ?.currentCompensationType,
                }}
                onChange={(e) =>
                  handleChangeNestedValue({
                    name: "currentCompensationType",
                    value: e.label,
                    key: "additionalInfo",
                  })
                }
              />
            </div>
          </div>
          {/*} <input
            type="text"
            className="form-control"
            name="expectedPayout"
            placeholder="Enter amount"
            value={additionalInfo?.expectedPayout || ""}
            onChange={(e) => {
              vlidateNumberField(e) &&
                handleChangeNestedValue({
                  name: "expectedPayout",
                  value: e.target.value,
                  key: "additionalInfo",
                });
            }}
          /> */}
        </div>
        {/* {additionalInfo?.currentCompensationType && (
          <div className="col-md-6 form-group">
             <label className="control-label">Enter Current Compensation / Payout</label>
            <input
              name="currentCompensationAmount"
              type="text"
              className="form-control"
              placeholder="Enter amount"
              value={additionalInfo?.currentCompensationAmount}
              onChange={(e) => {
                handleChangeNestedValue({
                  name: "currentCompensationAmount",
                  value: e.target.value,
                  key: "additionalInfo",
                });
              }}
            />
          </div>
            )} */}

        <div className="col-md-4">
          <label className="control-label">
            Expected compensation
          </label>
          <div className="row">
            <div className="form-group col-sm-6">
              <input
                name="expectedCompensationAmount"
                type="text"
                className="form-control"
                placeholder="Enter amount"
                value={additionalInfo ?.expectedCompensationAmount}
                onChange={(e) => {
                  handleChangeNestedValue({
                    name: "expectedCompensationAmount",
                    value: e.target.value,
                    key: "additionalInfo",
                  });
                }}
              />
            </div>
            <div className="form-group col-sm-6 left_space">
              <Select
                options={payout}
                //isMulti
                value={{
                  label: additionalInfo ?.expectedCompensationType,
                  value: additionalInfo ?.expectedCompensationType,
                }}
                onChange={(e) =>
                  handleChangeNestedValue({
                    name: "expectedCompensationType",
                    value: e.label,
                    key: "additionalInfo",
                  })
                }
              />
            </div>
          </div>

        </div>
        {/*
          additionalInfo?.expectedCompensationType && 
          <div className="col-md-6 form-group">
          <label className="control-label">Enter Expected Compensation / Payout</label>
          <input
            name="expectedCompensationAmount"
            type="text"
            className="form-control"
            placeholder="Enter amount"
            value={additionalInfo?.expectedCompensationAmount}
            onChange={(e) => {
              handleChangeNestedValue({
                name: "expectedCompensationAmount",
                value: e.target.value,
                key: "additionalInfo",
              });
            }}
          />
        </div>
          */}


        <div className="form-group col-md-12">
          <label className="control-label">
            Looking for to work with any specific individuals
          </label>
          <input
            type="text"
            name="personToWorkWith"
            className="form-control"
            placeholder="Ex. I would like to work with Amitabh Bachchan or Raju Hirani"
            value={additionalInfo ?.specificIndividual}
            onChange={(e) => {
              handleChangeNestedValue({
                name: "specificIndividual",
                value: e.target.value,
                key: "additionalInfo",
              });
            }}
          />
        </div>
        <div className="form-group col-md-12">
          <div className="row">
            <div className="col-md-4">
              <label className="control-label">Current Work Status </label>
              <Select
                options={workStatus}
                //isMulti
                value={{
                  label: additionalInfo ?.currentWorkStatus,
                  value: additionalInfo ?.currentWorkStatus,
                }}
                onChange={(e) =>
                  handleChangeNestedValue({
                    name: "currentWorkStatus",
                    value: e.label,
                    key: "additionalInfo",
                  })
                }
              />
            </div>

            <div className="col-md-4">
              {additionalInfo ?.currentWorkStatus ?.includes("Till") ? (
                <>
                  <label className="control-label">Enter till date</label>
                  <input
                    name="tillDate"
                    type="date"
                    className="form-control"
                    placeholder="Enter till date"
                    value={additionalInfo ?.tillDate}
                    onChange={(e) => {
                      handleChangeNestedValue({
                        name: "tillDate",
                        value: e.target.value,
                        key: "additionalInfo",
                      });
                    }}
                  />
                </>
              ) : null}
            </div>

            <dvi className="col-md-8">
              {additionalInfo ?.currentWorkStatus === "Partially Available " ? (
                <>
                  <div className="">
                    <div className="col-sm-4">
                      <label>Available Hours</label>
                      <input
                        name="hoursPerDay"
                        type="text"
                        className="form-control"
                        placeholder="Enter no of hours eg.3.5"
                        value={additionalInfo ?.hoursPerDay}
                        onChange={(e) => {
                          vlidateNumberField(e) &&
                            handleChangeNestedValue({
                              name: "hoursPerDay",
                              value: e.target.value,
                              key: "additionalInfo",
                            });
                        }}

                      />
                    </div>
                    <div className="col-sm-8 days_list">
                      <p><strong>Days</strong></p>
                      <label className="check_bx">
                        <input
                          type="checkbox"
                          value=""
                          onChange={(e) => {
                            vlidateNumberField(e) &&
                              handleChangeNestedValue({
                                name: "days",
                                value: !additionalInfo ?.days ? ['Mon'] : (additionalInfo ?.days ?.includes('Mon') ? _.filter(additionalInfo ?.days , val => val != 'Mon') : [...additionalInfo ?.days , 'Mon']),
                                key: "additionalInfo",
                              });
                          }}
                          checked={(additionalInfo ?.days ?.includes('Mon'))}
                          name="mon"
                        />
                        <span className="checkmark" /> Mon
                  </label>

                      <label className="check_bx">
                        <input
                          type="checkbox"
                          value=""
                          onChange={(e) => {
                            vlidateNumberField(e) &&
                              handleChangeNestedValue({
                                name: "days",
                                value: !additionalInfo ?.days ? ['Tue'] : (additionalInfo ?.days ?.includes('Tue') ? _.filter(additionalInfo ?.days , val => val != 'Tue') : [...additionalInfo ?.days , 'Tue']),
                                key: "additionalInfo",
                              });
                          }}
                          name="Tue"
                        />
                        <span className="checkmark" /> Tue
                  </label>

                      <label className="check_bx">
                        <input
                          type="checkbox"
                          value=""
                          onChange={(e) => {
                            vlidateNumberField(e) &&
                              handleChangeNestedValue({
                                name: "days",
                                value: !additionalInfo ?.days ? ['Wed'] : (additionalInfo ?.days ?.includes('Wed') ? _.filter(additionalInfo ?.days , val => val != 'Wed') : [...additionalInfo ?.days , 'Wed']),
                                key: "additionalInfo",
                              });
                          }}
                          name="Wed"
                          checked={(additionalInfo ?.days ?.includes('Wed'))}
                        />
                        <span className="checkmark" /> Wed
                  </label>

                      <label className="check_bx">
                        <input
                          type="checkbox"
                          value=""
                          onChange={(e) => {
                            vlidateNumberField(e) &&
                              handleChangeNestedValue({
                                name: "days",
                                value: !additionalInfo ?.days ? ['Thu'] : (additionalInfo ?.days ?.includes('Thu') ? _.filter(additionalInfo ?.days , val => val != 'Thu') : [...additionalInfo ?.days , 'Thu']),
                                key: "additionalInfo",
                              });
                          }}
                          name="Thu"
                          checked={(additionalInfo ?.days ?.includes('Thu'))}
                        />
                        <span className="checkmark" /> Thu
                  </label>

                      <label className="check_bx">
                        <input
                          type="checkbox"
                          value=""
                          onChange={(e) => {
                            vlidateNumberField(e) &&
                              handleChangeNestedValue({
                                name: "days",
                                value: !additionalInfo ?.days ? ['Fri'] : (additionalInfo ?.days ?.includes('Fri') ? _.filter(additionalInfo ?.days , val => val != 'Fri') : [...additionalInfo ?.days , 'Fri']),
                                key: "additionalInfo",
                              });
                          }}
                          name="Fri"
                          checked={(additionalInfo ?.days ?.includes('Fri'))}
                        />
                        <span className="checkmark" /> Fri
                  </label>

                      <label className="check_bx">
                        <input
                          type="checkbox"
                          value=""
                          onChange={(e) => {
                            vlidateNumberField(e) &&
                              handleChangeNestedValue({
                                name: "days",
                                value: !additionalInfo ?.days ? ['Sat'] : (additionalInfo ?.days ?.includes('Sat') ? _.filter(additionalInfo ?.days , val => val != 'Sat') : [...additionalInfo ?.days , 'Sat']),
                                key: "additionalInfo",
                              });
                          }}
                          name="Sat"
                          checked={(additionalInfo ?.days ?.includes('Sat'))}
                        />
                        <span className="checkmark" /> Sat
                  </label>

                      <label className="check_bx">
                        <input
                          type="checkbox"
                          value=""
                          onChange={(e) => {
                            vlidateNumberField(e) &&
                              handleChangeNestedValue({
                                name: "days",
                                value: !additionalInfo ?.days ? ['Sun'] : (additionalInfo ?.days ?.includes('Sun') ? _.filter(additionalInfo ?.days , val => val != 'Sun') : [...additionalInfo ?.days , 'Sun']),
                                key: "additionalInfo",
                              });
                          }}
                          name="Sun"
                          checked={(additionalInfo ?.days ?.includes('Sun'))}
                        />
                        <span className="checkmark" /> Sun
                  </label>
                    </div>
                  </div>


                  {/* <label>Enter to time</label>
                  <input
                    name="toAvailableHours"
                    type="time"
                    className="form-control"
                    placeholder="Enter till date"
                    value={additionalInfo?.toAvailableHours}
                    onChange={(e) => {
                      handleChangeNestedValue({
                        name: "toAvailableHours",
                        value: e.target.value,
                        key: "additionalInfo",
                      });
                    }}
                  /> */}
                </>
              ) : null}
            </dvi>
          </div>
        </div>

        <div className="form-group col-md-12">
          <label className="control-label">
            Looking for guidence / training / coach / courses We will be happy
            to contribute to your career.
          </label>
          <input
            name="guidence"
            type="text"
            className="form-control"
            placeholder="Enter details"
            value={additionalInfo ?.training}
            onChange={(e) => {
              handleChangeNestedValue({
                name: "training",
                value: e.target.value,
                key: "additionalInfo",
              });
            }}
          />
        </div>
{/* social media links start */}
<div className="form-group col-md-6">
          <label className="control-label">
           Facebook Profile
          </label>
          <input
            name="fbProfile"
            type="text"
            className="form-control"
            placeholder="Paste you Facebook profile link"
            value={additionalInfo?.fbProfile}
            onChange={(e) => {
              handleChangeNestedValue({
                name: "fbProfile",
                value: e.target.value,
                key: "additionalInfo",
              });
            }}
          />
        </div>
        <div className="form-group col-md-6">
          <label className="control-label">
           Instagram Profile
          </label>
          <input
            name="instagramProfile"
            type="text"
            className="form-control"
            placeholder="Paste you Instagram profile link"
            value={additionalInfo?.instagramProfile}
            onChange={(e) => {
              handleChangeNestedValue({
                name: "instagramProfile",
                value: e.target.value,
                key: "additionalInfo",
              });
            }}
          />
        </div>
        <div className="form-group col-md-6">
          <label className="control-label">
           Twitter Profile
          </label>
          <input
            name="TwitterProfile"
            type="text"
            className="form-control"
            placeholder="Paste you Twitter profile link"
            value={additionalInfo?.twitterProfile}
            onChange={(e) => {
              handleChangeNestedValue({
                name: "twitterProfile",
                value: e.target.value,
                key: "additionalInfo",
              });
            }}
          />
        </div>
        <div className="form-group col-md-6">
          <label className="control-label">
           Linkedin Profile
          </label>
          <input
            name="LinkedinProfile"
            type="text"
            className="form-control"
            placeholder="Paste you Linkedin profile link"
            value={additionalInfo?.linkedinProfile}
            onChange={(e) => {
              handleChangeNestedValue({
                name: "linkedinProfile",
                value: e.target.value,
                key: "additionalInfo",
              });
            }}
          />
        </div>
        <div className="form-group col-md-6">
          <label className="control-label">
           Youtube Channel
          </label>
          <input
            name="YoutubeProfile"
            type="text"
            className="form-control"
            placeholder="Paste you Youtube channel link"
            value={additionalInfo?.youtubeProfile}
            onChange={(e) => {
              handleChangeNestedValue({
                name: "youtubeProfile",
                value: e.target.value,
                key: "additionalInfo",
              });
            }}
          />
        </div>
{/* social media links end */}

        <div className="col-sm-4 form-group radio_btn inline_feild">
                        <p>
                            {" "}
                            <strong>Allow Push Notification</strong>
                        </p>
                        <label className="check_bx">
                            <input
                                type="radio"
                                name="allowPushNotification"
                                checked={additionalInfo?.allowPushNotification}
                                onClick={()=>
                                  handleChangeNestedValue({
                                    value: true, 
                                    name: 'allowPushNotification',
                                    type: 'radio', 
                                    key: "additionalInfo",
                                  })
                                }
                            />
                            Yes
                    <span className="checkmark"></span>
                        </label>
                        <label className="check_bx">
                            <input
                                type="radio"
                                name="allowPushNotification"
                               checked={!additionalInfo?.allowPushNotification}
                               onClick={()=>
                                handleChangeNestedValue({
                                  value: false, 
                                  name: 'allowPushNotification',
                                  type: 'radio', 
                                  key: "additionalInfo",
                                })
                            }
                            />
                            No
                    <span className="checkmark"></span>
                        </label>
                    </div>
       {additionalInfo?.allowPushNotification ?
       <div className="col-sm-12 form-group">
        
                            {" "}
                            <label>Push Notification Filters</label>
                       
              <AsyncSelect
                cacheOptions
                defaultOptions={filmType}
                // components={{ NoOptionsMessage }}
                // loadOptions={loadOptionsResolveLanguage}
                {...defaultProps}
                isMulti
                value={_.map(additionalInfo?.pushNotificationFilter, (val) => ({
                  label: val,
                  value: val,
                }))}
                onChange={(e) =>
                 { handleChangeNestedValue({
                  name: "pushNotificationFilter",
                  key: "additionalInfo",
                    value: _.map(e, (val) => val.label),
                  });
                  console.log(additionalInfo)
                }
                }
              />
            </div> : null}
       {moment().diff(props.dateOfBirth, 'years') < 18 && <div className="form-group col-md-12 col-sm-12">
          <label className="check_bx">
            <input
              type="checkbox"
              name="lessthan18"
            />
            <span className="checkmark" />
            <div > I am filling this form under the supervision of my parents/guardian.</div>
          </label>
        </div>}

       {!props._id && <div className="form-group col-md-12 col-sm-12">
          <label className="check_bx">
            <input
              type="checkbox"
              checked={props.agreed}
              name="agreeTermsAndCondition"
              onClick={() => handleChange({ target: { name: 'agreed', value: !props.agreed } })}
            />
            <span className="checkmark" />
            <div > {t("SignUpAgreementLabel")}    </div>
          </label>
          <a style={{ color: 'blue', cursor: 'pointer', fontSize: 13, marginTop: 10, marginLeft: 25 }} data-toggle="modal" data-target="#terms"> * View Terms and condition</a>
        </div>}

        <div className="col-md-12 col-sm-12">
          <button
            className="btn btn-primary nextBtn btn-lg stroke_btn"
            onClick={prevStep}
          >
            {t("PrevBtn")}
          </button>
          <button
            type="submit"
            className="btn btn-primary nextBtn btn-lg blue_btn  pull-right"
            onClick={(e) => submitForm(e, true)}
          >
            {t("SubmitBtn")}
          </button>

          <button
            className="btn btn-primary nextBtn btn-lg blue_btn pull-right" style={{ background: "#1683f3", marginRight: "20px" }}
            onClick={(e) => submitForm(e, false)}>
            Save changes
          </button>


        </div>
      </div>
    </div>
  );
};

export default AdditionalInfo;
