import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight, FaTimes } from "react-icons/fa";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import '../../scss/celebrityManagement.scss'
import _ from "lodash";

class PortfolioPhotoshoot extends Component {

  render() {
    return (
      <>
        <Header />
        <SubHeader /> 
          <section id="inner_celeb_banner">
            <div class="container">
              <div class="row">
                <div class="col-sm-6 col-xs-8 inner_celebrity_info">
                  <h1>PHOTOSHOOT SERVICES</h1>
                  <p align="justify"> BollywoodisGood is one of the finest Portfolio Photoshoot Service providers in India which include arrangements of camera, Studio, planning of platform to numerous other arrangements.</p>
                
              <Link to="/ReachUs" class="stroke_btn">Contact US</Link>
                </div>
                <div class="col-sm-6 inner_celebrity_img">
                <img src="images/portfolio-photoshoot-services.webp" alt="BollywoodisGood is best Celebrity Management Company in India" width="699" height="450" class="wow fadeInDown" data-wow-delay="0.3s" />	 
                </div>
              </div>
            </div>
          </section>

          <section id="inner_about">
          <div class="container">
          <div class="row">
          <div class="col-sm-5">
          <img src="images/model-photo-shoot-service.webp"  width="458" height="458" alt="Celebrity Management Services in India" />
          </div>
          <div class="col-sm-7 inner_about_info">
          <div class="inner_head">
          <h2>Portfolio photoshoot services</h2>
          </div>
          <p align="justify">Over the past few years, we have coordinated with various artists in different parts of India and guarantee them of appropriate performance of the essential tasks. BiG’s brand name and valued work is accepted by our customers and have involved numerous high class artists to hire us for their assignments. A Professional portfolio Photo Service is appropriate for those very serious in following modeling or acting as a career, and who wish to revise their current portfolios with new and innovative looks. Blending your thoughts and dream plus our imagination and expertise will attain that premium, professional look you are looking for. This offers you numerous of choices for marketing yourself to targeted companies, brands or people. This will need a pre-discussion to make a decision regarding perfect look and theme.</p>
         <br />
          <p align="justify">To BollywoodisGood, celebrity endorsement services come naturally. They are considered as one of the top notch celebrity brand endorsement companies and are vying for best online company in India tag.</p>

          </div>
          </div>
          </div>
          </section>

          <section id="our_role">
          <div class="container">
          <div class="row">
          <div class="col-sm-7 role_info">          
          <div class="inner_head">
          <h2>Portfolio Photoshoot company</h2>
          </div>
          <p align="justify">The celebrity brand endorsement services of BollywoodisGood are incomparable and trusted by many clients. Similarly our services are also in good demand and now we are dealing with some leading brands as our clients, therefore our clientele is ever expanding. Many top celebrities have hired us for endorsement of brands and are now our esteemed clients. The BollywoodisGood Portfolio photo service has been created to provide for both aspiring and professional methods. The shoot itself integrate a number of modifications of clothing with the photo session durable just about 1.5 to 2 hours.
By our photographer the photo shoot is carried out, the photographer takes a huge deal of care to create a working professional relationship where you are at ease and calm. Our photographer will counsel you on expressions and poses at the time of the photo session in order to assist to make your perfect portfolio to support your career, or to merely maintain yourself and fortune.</p>
          </div>
          <div class="col-sm-5">
          <img src="images/photo-shoot-services-company.webp"  loading="lazy" width="458" height="459" alt="Artist Management Services" />
          </div>
          </div>
          </div>
          </section>  

          <section id="inner_services">
          <div class="container">
          <div class="text-center inner_head">
<h2>Model Portfolio Photo Service</h2>
</div>
<p align="justify">The style and nature of your photo shoot, all along with the restrictions you desire to have in place, are talk about earlier, so no force is felt throughout the shoot to do no matter which you may not feel at ease doing. Certainly, some models just desire for stylish clothing, others for boudoir, and a number of for nude with imaginative lighting. Everybody  is dissimilar and each one is provided for with the greatest professional carefulness. A huge number of shots will be taken with all the pictures from the shoot provide to you as proofs on a CD that has watermark – for you to analyze. Then decide your favorite, which will be completely airbrushed and complete as high resolution files that are prepared to print.
If you desire to make additional atmosphere or a particular look and feel to your portfolio, then upon request location shoots are also available. 

</p>

          </div>
          </section>     

          <section id="inner_about">
          <div class="container">
          <div class="row">
          <div class="col-sm-7 role_info">
          <div class="inner_head">
          <h2>Model Portfolio Photoshoot Service</h2>
          </div>
          <p align="justify">BollywoodisGood take a pride on being one of the most competent portfolio photo service providers that delivers exceptional photography services to the fashion clients, creative industries and luxury brands. BiG’s renowned internal photographer has amazing expertise in editorials, photography, fashion advertising, PR, casting portfolios, actor headshots, premium portraiture, and portfolio shoots of model & talent.</p>
          <br />
          <p align="justify"><strong>Here is a variety of the industry leading services we provide:</strong></p>
          <ul>
          <li>Model portfolio shoots</li>
          <li>Fashion shoots and advertising campaigns</li>
          <li>Kid’s portrait shoots</li>
          <li>High-end portrait photography</li>
          <li>Actor headshot photography & casting portfolios</li>
          <li>Talent portfolio shoots & Musician portraits</li>
          </ul>
          <br />
          <p> So , If  you are looking for <strong>Creative Ad Agency</strong> then BollywoodisGood is the best option for you.</p>
          </div>
          
          <div class="col-sm-5">
          <img src="images/portfolio-photoshoot-kit.webp"  loading="lazy" width="458" height="459" alt="Artist Management Services" />
          </div>
          </div>
          </div>
          </section>  
          <section id="last_info" >
<div class="container">
<div class="row">
<div class="col-xs-12">
<blockquote>
<p align="justify"> Get in touch with BollywoodisGood to experience the best services in this field.
</p>
</blockquote>
</div>
</div>
</div>
</section>  
        <Footer />
      </>
    );
  }
}

export default PortfolioPhotoshoot;
