export default ([
   { label: "AAFT - Asian Academy of Film and Television", value: "AAFT - Asian Academy of Film and Television" },
   { label: "AAFT University of Media And Arts", value: "AAFT University of Media And Arts" },
   { label: " Actor Prepares, Mumabi", value: " Actor Prepares, Mumabi" },
   { label: "Adarsha Film Institute, Bangalore", value: "Adarsha Film Institute, Bangalore" },
   { label: " Advent School of Communication", value: " Advent School of Communication" },
   { label: " Aimfill International", value: " Aimfill International" },
   { label: "AISFM - Annapurna International School of Film and Media, Hyderabad", value: "AISFM - Annapurna International School of Film and Media, Hyderabad" },
   { label: "Amity University", value: "Amity University" },
   { label: "Amrita School of Arts and Sciences - Amrita University (ASAS Kochi)", value: "Amrita School of Arts and Sciences - Amrita University (ASAS Kochi)" },
   { label: " Apparel Training and Design Centre", value: " Apparel Training and Design Centre" },
   { label: "Aradhna College of Film and Art (ACOFA)", value: "Aradhna College of Film and Art (ACOFA)" },
   { label: "Arena Animation", value: "Arena Animation" },
   { label: "Arena Animation, Fort", value: "Arena Animation, Fort" },
   { label: "Arena Animation, Gwalior", value: "Arena Animation, Gwalior" },
   { label: "'Arena Animation, Vadodara'", value: "'Arena Animation, Vadodara'" },
   { label: "Arena Animation, Vivek Vihar", value: "Arena Animation, Vivek Vihar" },
   { label: "Atharva Institute of Film and Television (AIFT)", value: "Atharva Institute of Film and Television (AIFT)" },
   { label: "Athena Animation Academy", value: "Athena Animation Academy" },
   { label: "Axis Colleges", value: "Axis Colleges" },
   { label: "Bharati Vidyapeeth’s School of Photography", value: "Bharati Vidyapeeth’s School of Photography" },
   { label: "Biju Pattanaik Film & Television Institute of Odisha", value: "Biju Pattanaik Film & Television Institute of Odisha" },
   { label: "Brilliko Institute of Multimedia", value: "Brilliko Institute of Multimedia" },
   { label: "Center for Research in Art of Film and Television, Delhi", value: "Center for Research in Art of Film and Television, Delhi" },
   { label: " Chandigarh University", value: " Chandigarh University" },
   { label: "Charlie Media Institute", value: "Charlie Media Institute" },
   { label: "Clusters Institute of Media and Technology", value: "Clusters Institute of Media and Technology" },
   { label: "CMI - Calcutta Media Institute", value: "CMI - Calcutta Media Institute" },
   { label: "College of Creative Studies, Vidya Jyoti Educational Society", value: "College of Creative Studies, Vidya Jyoti Educational Society" },
   { label: "Creative Mentors Animation and Gaming College", value: "Creative Mentors Animation and Gaming College" },
   { label: "CREO Valley School of Creativity, Design and Management", value: "CREO Valley School of Creativity, Design and Management" },
   { label: "CREO Valley School of Film and Television", value: "CREO Valley School of Film and Television" },
   { label: "Datasoft, Jhansi", value: "Datasoft, Jhansi" },
   { label: " Deccan Education Society’s Institute Of Film & Television", value: " Deccan Education Society’s Institute Of Film & Television" },
   { label: "Design Media and Edutainment Solutions", value: "Design Media and Edutainment Solutions" },
   { label: "Deviprasad Goenka Management College of Media Studies", value: "Deviprasad Goenka Management College of Media Studies" },
   { label: "DICE VFS School of Entertainment Arts", value: "DICE VFS School of Entertainment Arts" },
   { label: "Digiquest Institute of Creative Arts and Design", value: "Digiquest Institute of Creative Arts and Design" },
   { label: "Dream Zone School of Creative Studies, Alambagh", value: "Dream Zone School of Creative Studies, Alambagh" },
   { label: "Dream Zone School of Creative Studies, Indira Nagar", value: "Dream Zone School of Creative Studies, Indira Nagar" },
   { label: "DreamZone School of Creative Studies, Ultadanga", value: "DreamZone School of Creative Studies, Ultadanga" },
   { label: "FA School of Media & Films", value: "FA School of Media & Films" },
   { label: "Faculty of Liberal Arts, MIT World Peace University (MIT WPU)", value: "Faculty of Liberal Arts, MIT World Peace University (MIT WPU)" },
   { label: "Fast Track Photography Institute", value: "Fast Track Photography Institute" },
   { label: "Film and Television Institute (FTI)", value: "Film and Television Institute (FTI)" },
   { label: "FTIH - Film And Television Institute of Hyderabad", value: "FTIH - Film And Television Institute of Hyderabad" },
   { label: "FX School", value: "FX School" },
   { label: "Garware Institute of Career Education and Development, University of Mumbai", value: "Garware Institute of Career Education and Development, University of Mumbai" },
   { label: "Goodness Institute of Film and Television", value: "Goodness Institute of Film and Television" },
   { label: "Government Film and Television Institute, Bangalore", value: "Government Film and Television Institute, Bangalore" },
   { label: "HighFlyer Animation", value: "HighFlyer Animation" },
   { label: "Himgiri Zee University", value: "Himgiri Zee University" },
   { label: "Hue N Rig Institute of Art and Design", value: "Hue N Rig Institute of Art and Design" },
   { label: "ICE Balaji Telefilms - Institute of Creative Excellence", value: "ICE Balaji Telefilms - Institute of Creative Excellence" },
   { label: "IGNTU - Indira Gandhi National Tribal University", value: "IGNTU - Indira Gandhi National Tribal University" },
   { label: "IIFA Multimedia, Bengaluru", value: "IIFA Multimedia, Bengaluru" },
   { label: " iLEAD Institute of Leadership, Entrepreneurship and Development", value: " iLEAD Institute of Leadership, Entrepreneurship and Development" },
   { label: "India Today Media Institute - ITMI", value: "India Today Media Institute - ITMI" },
   { label: "Indore Professional Studies Academy", value: "Indore Professional Studies Academy" },
   { label: "Innovative Film Academy", value: "Innovative Film Academy" },
   { label: "Institute for Creative Excellence", value: "Institute for Creative Excellence" },
   { label: "Institute of Mass Communication Film and Television Studies (IMCFTS)", value: "Institute of Mass Communication Film and Television Studies (IMCFTS)" },
   { label: "Jagran Lakecity University - JLU", value: "Jagran Lakecity University - JLU" },
   { label: "Jahangirabad Institute of Technology", value: "Jahangirabad Institute of Technology" },
   { label: "JNU - Jawaharlal Nehru University", value: "JNU - Jawaharlal Nehru University" },
   { label: "Jyoti Chitraban Film and Television Institute, Assam, India", value: "Jyoti Chitraban Film and Television Institute, Assam, India" },
   { label: "K. R. Narayanan National Institute of Visual Science and Arts (KRNNIVSA)", value: "K. R. Narayanan National Institute of Visual Science and Arts (KRNNIVSA)" },
   { label: "Kalapurnam Institute of Visual effects and Animation MAYA", value: "Kalapurnam Institute of Visual effects and Animation MAYA" },
   { label: "Kerala Chalachitra academy Trivandrum", value: "Kerala Chalachitra academy Trivandrum" },
   { label: "Kishinchand Chellaram College", value: "Kishinchand Chellaram College" },
   { label: "L. J. Institute of Media and Communications", value: "L. J. Institute of Media and Communications" },
   { label: "L.V Prasad Film Institute, Chennai and  Trivandrum", value: "L.V Prasad Film Institute, Chennai and  Trivandrum" },
   { label: "Lovely Professional University", value: "Lovely Professional University" },
   { label: "MAAC Thane", value: "MAAC Thane" },
   { label: "Mahatma Jyoti Rao Phoole University", value: "Mahatma Jyoti Rao Phoole University" },
   { label: "Maya Academy of Advanced Cinematics, Andheri West", value: "Maya Academy of Advanced Cinematics, Andheri West" },
   { label: "Maya Academy of Advanced Cinematics, Bhopal", value: "Maya Academy of Advanced Cinematics, Bhopal" },
   { label: "Maya Academy of Advanced Cinematics, Camp", value: "Maya Academy of Advanced Cinematics, Camp" },
   { label: "Maya Academy of Advanced Cinematics, Dehradun", value: "Maya Academy of Advanced Cinematics, Dehradun" },
   { label: "Maya Academy of Advanced Cinematics, Dunlop", value: "Maya Academy of Advanced Cinematics, Dunlop" },
   { label: " Maya Academy of Advanced Cinematics, Dwarka", value: " Maya Academy of Advanced Cinematics, Dwarka" },
   { label: "Maya Academy of Advanced Cinematics, Gorepeth", value: "Maya Academy of Advanced Cinematics, Gorepeth" },
   { label: " Maya Academy of Advanced Cinematics, Jhansi", value: " Maya Academy of Advanced Cinematics, Jhansi" },
   { label: "Maya Academy of Advanced Cinematics, Nagpur", value: "Maya Academy of Advanced Cinematics, Nagpur" },
   { label: "Maya Academy of Advanced Cinematics, Vadodara (MAAC)", value: "Maya Academy of Advanced Cinematics, Vadodara (MAAC)" },
   { label: "Maya Academy of Advanced Cinematics, Vijayawada", value: "Maya Academy of Advanced Cinematics, Vijayawada" },
   { label: "MGM's College of Journalism and Mass Communication", value: "MGM's College of Journalism and Mass Communication" },
   { label: "MGR Government Film and Television Institute", value: "MGR Government Film and Television Institute" },
   { label: "MIT School of Film and Television", value: "MIT School of Film and Television" },
   { label: "MSE Institute of Film and Media", value: "MSE Institute of Film and Media" },
   { label: " MU - Mewar University", value: " MU - Mewar University" },
   { label: " MV Media Institute", value: " MV Media Institute" },
   { label: "National Academy of Media and Events", value: "National Academy of Media and Events" },
   { label: "National Film Development Corporation of India", value: "National Film Development Corporation of India" },
   { label: "National Institute of Creative Communication", value: "National Institute of Creative Communication" },
   { label: "National School Of Drama (NSD), Agartala", value: "National School Of Drama (NSD), Agartala" },
   { label: "National School Of Drama (NSD), Bangalore", value: "National School Of Drama (NSD), Bangalore" },
   { label: "National School Of Drama (NSD), Delhi", value: "National School Of Drama (NSD), Delhi" },
   { label: "National School Of Drama (NSD), Gangtok", value: "National School Of Drama (NSD), Gangtok" },
   { label: "National School Of Drama (NSD), Varanasi", value: "National School Of Drama (NSD), Varanasi" },
   { label: "New York Film Academy | Mumbai, India", value: "New York Film Academy | Mumbai, India" },
   { label: "NMIMS School of Branding and Advertising", value: "NMIMS School of Branding and Advertising" },
   { label: "NRAI School of Mass Communication", value: "NRAI School of Mass Communication" },
   { label: "NSHM Knowledge Campus, Kolkata", value: "NSHM Knowledge Campus, Kolkata" },
   { label: "Opulent School of Acting and Fine Arts", value: "Opulent School of Acting and Fine Arts" },
   { label: "Padarpan Films and Theatre Institute", value: "Padarpan Films and Theatre Institute" },
   { label: "Pearl Academy, Mumbai", value: "Pearl Academy, Mumbai" },
   { label: "Picasso International Animation – VFX – Gaming College", value: "Picasso International Animation – VFX – Gaming College" },
   { label: "Pondicherry University Community College, Pondicherry University", value: "Pondicherry University Community College, Pondicherry University" },
   { label: "Prime Focus Academy of Media and Entertainment Studies, Goregaon", value: "Prime Focus Academy of Media and Entertainment Studies, Goregaon" },
   { label: "Prime Focus Academy, Delhi", value: "Prime Focus Academy, Delhi" },
   { label: "Punjabi University B.Tech in Filmmaking", value: "Punjabi University B.Tech in Filmmaking" },
   { label: "Rachna Sansad Film Institute", value: "Rachna Sansad Film Institute" },
   { label: "Ramesh Sippy Academy of Cinema and Entertainment", value: "Ramesh Sippy Academy of Cinema and Entertainment" },
   { label: "Ramoji Academy of Film and Television (RAFT), Hyderabad", value: "Ramoji Academy of Film and Television (RAFT), Hyderabad" },
   { label: "Ramoji Krian Universe", value: "Ramoji Krian Universe" },
   { label: "Rayat - Bahra University", value: "Rayat - Bahra University" },
   { label: "Reliance Education : VFX and Animation Academ", value: "Reliance Education : VFX and Animation Academ" },
   { label: "Reliance Education, Bhubaneswar", value: "Reliance Education, Bhubaneswar" },
   { label: "Reliance Education, Brigade Road", value: "Reliance Education, Brigade Road" },
   { label: "Reliance Education, F.C. Road", value: "Reliance Education, F.C. Road" },
   { label: "Reliance Education, Malleswaram", value: "Reliance Education, Malleswaram" },
   { label: "Satyajit Ray Film and Television Institute (SRFTI)", value: "Satyajit Ray Film and Television Institute (SRFTI)" },
   { label: "School of Humanities and Social Science, Jain", value: "School of Humanities and Social Science, Jain" },
   { label: " School of Media Activity Research and Technology", value: " School of Media Activity Research and Technology" },
   { label: "School of Science Studies, CMR University", value: "School of Science Studies, CMR University" },
   { label: "Seamedu - School of Pro-Expressionism", value: "Seamedu - School of Pro-Expressionism" },
   { label: "Seamedu School of Pro-Exprerssionism, Mohali", value: "Seamedu School of Pro-Exprerssionism, Mohali" },
   { label: "SGT University", value: "SGT University" },
   { label: "Shaft Academy of Media Arts", value: "Shaft Academy of Media Arts" },
   { label: "Shemaroo Institute of Film and Technology", value: "Shemaroo Institute of Film and Technology" },
   { label: "Shiv Nadar University", value: "Shiv Nadar University" },
   { label: "Sixteen By Nine Media", value: "Sixteen By Nine Media" },
   { label: "Sri Aurobindo Centre for Arts and Communication", value: "Sri Aurobindo Centre for Arts and Communication" },
   { label: "Srishti Institute of Art, Design and Technology", value: "Srishti Institute of Art, Design and Technology" },
   { label: "SRM University Ramapuram - SRM Institute of Science and Technology", value: "SRM University Ramapuram - SRM Institute of Science and Technology" },
   { label: "SSS - Symbiosis Summer School", value: "SSS - Symbiosis Summer School" },
   { label: " St. Pauls Institute of Communication Education", value: " St. Pauls Institute of Communication Education" },
   { label: "Talsdo - The Film Institute", value: "Talsdo - The Film Institute" },
   { label: "TGC Animation and Multimedia", value: "TGC Animation and Multimedia" },
   { label: "The Arohan Media School", value: "The Arohan Media School" },
   { label: "The Department of Film Studies at Jadavpur University", value: "The Department of Film Studies at Jadavpur University" },
   { label: " The National Institute  of  Design (NID) Ahmadabad", value: " The National Institute  of  Design (NID) Ahmadabad" },
   { label: "The One School Goa", value: "The One School Goa" },
   { label: "Trytoon Academy", value: "Trytoon Academy" },
   { label: "Udaan School of Photography", value: "Udaan School of Photography" },
   { label: "VCD College of Designing", value: "VCD College of Designing" },
   { label: "Whistling Woods International Institute of Film", value: "Whistling Woods International Institute of Film" },
   { label: "WWI Virtual Academy", value: "WWI Virtual Academy" },
   { label: "X-Gen College of Visual Art", value: "X-Gen College of Visual Art" },
   { label: "YMCA New Delhi ", value: "YMCA New Delhi " },
   { label: "YWCA Women’s Training institute", value: "YWCA Women’s Training institute" },
   { label: "Zee Institute of Creative Art (ZICA), Indore", value: "Zee Institute of Creative Art (ZICA), Indore" },
   { label: " Zee Institute of Creative Art, Chandigarh", value: " Zee Institute of Creative Art, Chandigarh" },
   { label: "Others", value: "Others" }

]
)