import React,{useState} from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaFacebookF, FaGoogle, FaUser } from "react-icons/fa";
import Select from "react-select";
import Countdown from 'react-countdown';

const NewSignupForm = ({ handleOnChange, emailExist, submitForm, ...props }) => {
  const { t } = useTranslation();
  const [enableOTPButton, setEnableOTPButton] = useState(false);
  const [timer, setTimer] = useState(Date.now() + 59000)
  const renderer = ({ hours, minutes, seconds, completed }) => {
    // if (completed) {
    //   if (!enableOTPButton) {
    //     setEnableOTPButton(true)
    //   }
    //   return<></>
    // } else {
      return <span> <span style={{fontSize: '10px'}}></span> <strong>00 : {seconds}</strong> </span>;
    // }
  };
  return (
    <>
    <div className="logo"> <a href="/"><img src="images/dark_logo.png" alt="img" /></a> </div>
      <form onSubmit={submitForm} className="form-space">
        {props.otpScreen ? (
          <div className="form-group">
            <label>Enter OTP which is sent on email</label>
            <input
              type="text"
              className="form-control"
              name="otp"
              onChange={handleOnChange}
              required
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <button
                style={{ marginTop: 10 }}
                type="submit"
                className="blue_btn btn"
                defaultValue="Login"
              >
                Submit
              </button>
               <div style={{ marginTop: 10 ,display: "flex",justifyContent: "space-between", alignItems:'center' }}>
              {!enableOTPButton && <Countdown date={timer} renderer={renderer} onComplete={()=>{ setEnableOTPButton(true)}}/>}
                <button
                className="blue_btn btn pl-4"
                style={{ marginLeft: 10 }}
                  disabled={!enableOTPButton}
                  onClick={(e) => {setTimer(Date.now() + 59000);setEnableOTPButton(false); submitForm(e)}}
                >
                  Resend
                </button>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="form_head_part">
              <h4>Register as a Professional</h4>
              <br />
            </div>
            <div className="form-group">
              <label>Full Name</label>
              <input
                type="text"
                className="form-control"
                name="userName"
                onChange={handleOnChange}
                required
              />
            </div>
            <div className="row">
              <div className="form-group col-sm-6">
                <label>Email Id</label>
                <input
                  type="email"
                  className="form-control"
                  name="emailId"
                  onChange={handleOnChange}
                  required
                />
                {emailExist && (
                  <span style={{ color: "red" }}>Email already exist</span>
                )}
              </div>
              <div className="form-group col-sm-6">
                <label>Mobile Number</label>
                <input
                  type="tel"
                  className="form-control"
                  name="phoneNumber"
                  onChange={handleOnChange}
                  maxLength={10}
                  onKeyDown={(e) => {
                    if (!(e.key >= '0' && e.key <= '9') && e.keyCode !== 8) {
                        e.preventDefault();
                    }
                }}
                  required
                />
              </div>
            </div>
            <div className="form-group">
              <label>{t("LoginPasswordLabel")}</label>
              <input
                type="password"
                className="form-control"
                name="password"
                onChange={handleOnChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Confirm Password</label>
              <input
                type="password"
                className="form-control"
                name="confirmPassword"
                onChange={handleOnChange}
                required
              />
            </div>
            <div className="form-group">
          <input style={{height: 20, width:20}} type="checkbox" name="tnc" id="tnc" onChange={(e)=>handleOnChange({target:{name:'tnc', value: e.target.checked}})}/> Agree to Privacy Policy
        </div>

            {/* <div className="row">
              <div className="col-sm-12 form-group radio_btn inline_feild">
                <p>
                  <strong>Sign Up as</strong>
                </p>
                <label className="check_bx">
                  <input
                    type="radio"
                    value="checkbx"
                    name="field"
                    id="1"
                    onChange={() =>
                      handleOnChange({
                        target: { value: "Agency", name: "userType" },
                      })
                    }
                  />
                  Agency
                  <span className="checkmark"></span>
                </label>
                <label className="check_bx">
                  <input
                    type="radio"
                    value="checkbx"
                    name="field"
                    id="2"
                    checked
                    onChange={() =>
                      handleOnChange({
                        target: { value: "Independent", name: "userType" },
                      })
                    }
                  />
                  Individual
                  <span className="checkmark"></span>
                </label>
              </div>
                  </div> */}

            <div className="btn_action_btn">
              <div className="left_side_btn">
                <button
                  type="submit"
                  className="blue_btn btn"
                  defaultValue="Login"
                  disabled={!props.tnc}
                >
                  Sign Up
                </button>
              </div>
              <p>
                Already an account?{" "}
                <Link to="/Login">
                  {" "}
                  <strong>login</strong>
                </Link>
              </p>
            </div>
          </>
        )}
      </form>
    </>
  );
};

export default NewSignupForm;
