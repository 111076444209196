import React from "react";
import Select from "react-select";
import _ from "lodash";
import { vlidateNumberField } from "../../Utilities/validateInputField";
import { uniqueTraits, physique } from "../CreateUserProfile/registerConst";

const PersonalTraits = ({ handleChangeDropDown, handleChangeSingle, nextStep, prevStep, PostAuditionSubmit, ...props }) => {

    return (
        <>
            <div >
                <div className="tab_head">
                    <h4>Physical Traits</h4>
                    <hr />
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 double_field">
                        {/* <label className="control-label">Height</label> */}
                        <div className="row">
                            <div className="form-group col-sm-6 col-xs-6">
                            <label className="control-label">Height(Min)</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Ft"
                                    name="minHeightFeet"
                                    value={props.minHeightFeet || ""}
                                    onChange={(e) => {
                                        vlidateNumberField(e) && handleChangeSingle(e);
                                    }}
                                />
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="In"
                                    name="minHeightInch"
                                    value={props.minHeightInch || ""}
                                    onChange={(e) => {
                                        vlidateNumberField(e) && handleChangeSingle(e);
                                    }}
                                />
                            </div>
                            <label className="center_label">To</label>
                            <div className="form-group col-sm-6 col-xs-6">
                            <label className="control-label">Height(Max)</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Ft"
                                    name="maxHeightFeet"
                                    value={props.maxHeightFeet || ""}
                                    onChange={(e) => {
                                        vlidateNumberField(e) && handleChangeSingle(e);
                                    }}
                                />
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="In"
                                    name="maxHeightInch"
                                    value={props.maxHeightInch || ""}
                                    onChange={(e) => {
                                        vlidateNumberField(e) && handleChangeSingle(e);
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="form-group col-lg-4 col-md-6">
                        <label className="control-label">Weight</label>
                        <div className="row">
                            <div className="col-sm-6 col-xs-6">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Min in KG"
                                    name="minWeight"
                                    value={props.minWeight || ""}
                                    onChange={(e) => {
                                        vlidateNumberField(e) && handleChangeSingle(e);
                                    }}
                                />
                            </div>

                            <div className="col-sm-6 col-xs-6">
                                <input
                                    type="text"
                                    name="maxWeight"
                                    className="form-control"
                                    placeholder="Max in KG"
                                    value={props.maxWeight || ""}
                                    onChange={(e) => {
                                        vlidateNumberField(e) && handleChangeSingle(e);
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="form-group col-md-4">
                        <label className="control-label">Physique</label>
                        <Select
                            options={physique}
                            //isMulti
                            value={{
                                value: props.physique,
                                label: props.physique,
                            }}
                            onChange={(e) =>
                                handleChangeDropDown({
                                    name: "physique",
                                    value: e.label,
                                })
                            }
                        />
                    </div>

                    <div className="form-group col-md-12">
                        <label className="control-label">Must Have</label>
                        <input
                            type="text"
                            name="mustHave"
                            className="form-control"
                            placeholder="Ex. Theatre Background or Feature Film Experience or NSD or FTI"
                            value={props.mustHave}
                            onChange={handleChangeSingle}
                        />
                    </div>

                    <div className="form-group col-md-6">
                        <label className="control-label">Mandatory Conditions</label>
                        <input
                            type="text"
                            name="mandatoryConditions"
                            className="form-control"
                            placeholder="Ex. Only For Mumbai Based Actors  "
                            value={props.mandatoryConditions}
                            onChange={handleChangeSingle}
                        />
                    </div>

                    <div className="form-group col-md-6">
                        <label className="control-label">Unique Traits</label>
                        <Select
                            options={uniqueTraits}
                            //isMulti
                            value={{
                                value: props.uniqueTraits,
                                label: props.uniqueTraits,
                            }}
                            onChange={(e) =>
                                handleChangeDropDown({
                                    name: "uniqueTraits",
                                    value: e.label,
                                })
                            }
                        />
                    </div>

                    <div className="form-group col-md-12">
                        <label className="control-label">Appearance or D/C </label>
                        <input
                            type="text"
                            name="appearance"
                            className="form-control"
                            placeholder="Ethnic Wear, Western Wear, Sports Wear, Salwar Suits, Kurtas, Sarees ...etc"
                            value={props.appearance}
                            onChange={handleChangeSingle}
                        />
                    </div>
                    <div className="col-sm-12">
                        <hr />
                        <button className="stroke_btn" onClick={prevStep}>Back</button>
                        <button className="default_btn pull-right" onClick={() => nextStep()} >Next</button>
                        <button className="default_btn pull-right" style={{ marginRight: "10px" }} onClick={(e) => PostAuditionSubmit(e, false)}>Save as Draft</button>
                    </div>
                </div>
            </div>

        </>
    )
}

export default PersonalTraits;