import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaHeart, FaAngleLeft, FaAngleRight} from 'react-icons/fa';
import _ from 'lodash';
import { config } from '../../actions/config';
import Pagination from '../Pagination/Pagination';

const AuditionInvitedPeople = ({ AuditionDetail, ...props }) => {
    let pageSize = 8;

    const [newList, setNewList] = useState([])
    const [pageIndex, setPageIndex] = useState(1);

    
  useEffect(() => {
    if(AuditionDetail.invites){
      let d = AuditionDetail.invites.slice(0,pageSize)
      setNewList(d)
    }
    }, [AuditionDetail.invites])
    const pageChangeHandler = (index) => {
      console.log(index)
      const slicedList = AuditionDetail.invites.slice((index - 1) * pageSize, (index * pageSize))
      setNewList(slicedList)
      setPageIndex(index)
    
    }
    return (
        <div className="tab-pane" id="list_tab2">
            <div className="row">


                {
                    _.map(newList, val => (
                        <div className="col-md-3 col-sm-4 col-xs-6 full">
                            <div className="celebrity_detail text-center clearfix">
                                <div className="people_img">
                                    <img src={val.profilePic ? config.FILE_URL+val.profilePic : 'images/fill_user.png'} alt="img" />
                                    {/* <span className="online"><div className="circle_icon"></div> </span> */}
                                </div>
                                <div className="people_list">
                                    <div className="people_list_info clearfix">

                                        <h6>{val.userName}</h6>
                                        <div className="people_short_info">
                                            <span>{val?.physicalTraits?.jobType}</span>
                                            <span>{val?.cntLocation}</span>
                                        </div>
                                        {/* <span className="cele_price">Rs {val?.additionalInfo?.expectedCompensationAmount} / {val?.additionalInfo?.expectedCompensationType}</span> */}
                                    </div>
                                    {/* <div className="end_info">
                                        <div className="icon"><FaHeart /></div>
                                        <a style={{ cursor: "pointer" }} className="stroke_btn">Send a Message</a>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    ))
                }


            </div>
            <div class="pagination_part clearfix text-right">
                <br />
            <Pagination totalCount={AuditionDetail?.invites?.length || 0} pageSize={pageSize} onPageChange={pageChangeHandler} currentPage={pageIndex} />
            </div>
        </div>
    );
}
export default AuditionInvitedPeople;