
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaTwitter, FaLinkedin, FaPinterest, FaInstagram } from 'react-icons/fa';
import { useTranslation } from "react-i18next";

function FormFooter() {
  const { t } = useTranslation();

  return (
    <div className="login_footer">
      <div className="social_icons">
        <ul>
        <li><Link to="/">{t("HomeLink")}</Link></li>
          <li><Link to="/privacy">{t("FormFooterPrivacyLink")}</Link></li>
          <li><Link to="/ReachUs">{t("FormFooterHelpLink")}</Link></li>
          <li><a target='_blank' href="https://www.facebook.com/people/Bollywood-is-Good-BiG/100075786801309/"><FaFacebook /></a></li>
          <li><a target='_blank' href="https://twitter.com/is_bollywood"><FaTwitter /></a></li>
          <li><a target='_blank' href="https://www.linkedin.com/in/bollywood-is-good-big/"><FaLinkedin /></a></li>
          <li><a target='_blank' href="https://www.instagram.com/bollywood_is_good_big/"><FaInstagram /></a></li>
        </ul>
      </div>
      {/* <div className="copyright"> 
                    <small>{t("CopyRight")}</small>       
    </div> */}
    </div>
  )
}

export default FormFooter  