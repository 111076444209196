import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

export default () => {
  const { pathname } = useLocation();
const history = useHistory()
  useEffect(() => {
//     const token = localStorage.removeItem('accessToken')
//    if(!token && pathname !== '/'){
//     localStorage.removeItem('accessToken')
//     localStorage.removeItem('user');
//     history.push('/login')
//    }
  }, [pathname]);

  return null;
}