import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
class AuditionPart extends Component {


  render() {
    const userStr = localStorage.getItem("user");
    const user = userStr ? JSON.parse(userStr):null
    return (
      <div>
         <section id="auditions">
          <div className="container">
            <div className="row">
              <div className="col-md-12 head_part">
                    <h2>Featured jobs</h2>
               </div>
            </div>
            <div className='row flex-row'>
              { _.map(this.props.jobs, (val, i) => (<div key={i+'djdbd'} className='col-lg-4 col-md-6 col-sm-6 info_bx'>
              <div className='bg_bx'>
              <div className='list_part'>
              {val.filmType.map(( ft,index) =>(index===0 ? <span>{ft}</span>:''))} 
              {_.map(val.talentType, tt =>(<span>{tt}</span>))}  
               {val?.currentCity && <span>Location : {val.currentCity ? val.currentCity : ' Yet to Submit' }</span>}
              <span>Posted :  {moment(val.createdAt).format('MMM DD, YYYY')}</span>
              </div>    
              {val.title && <Link to=""><h6>{val.title}</h6></Link>}
              <p>{val.description}</p>
             <Link to={user ? "/UserSideJob/"+val._id: '/login'} className="link_clr">View Details</Link>
              </div>
              </div>
              ))
              }

              {/* <div className='col-md-4'>
              <div className='bg_bx'>
              <div className='list_part'>
              <span>Feature Film</span> <span>Female</span>  <span>Location : Chandigarh</span><span>Posted : Dec 12, 2022</span>
              </div>    
              <Link to=""><h6>Assistant Director</h6></Link>
              <p>Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur</p>
             <Link to="" className="link_clr">View Details</Link>
              </div>
              </div>

              <div className='col-md-4'>
              <div className='bg_bx'>
              <div className='list_part'>
              <span>Feature Film</span> <span>Female</span>  <span>Location : Chandigarh</span><span>Posted : Dec 12, 2022</span>
              </div>    
              <Link to=""><h6>Assistant Director</h6></Link>
              <p>Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur</p>
             <Link to="" className="link_clr">View Details</Link>
              </div>
              </div>

              <div className='col-md-4'>
              <div className='bg_bx'>
              <div className='list_part'>
              <span>Feature Film</span> <span>Female</span>  <span>Location : Chandigarh</span><span>Posted : Dec 12, 2022</span>
              </div>    
              <Link to=""><h6>Assistant Director</h6></Link>
              <p>Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur</p>
             <Link to="" className="link_clr">View Details</Link>
              </div>
              </div>

              <div className='col-md-4'>
              <div className='bg_bx'>
              <div className='list_part'>
              <span>Feature Film</span> <span>Female</span>  <span>Location : Chandigarh</span><span>Posted : Dec 12, 2022</span>
              </div>    
              <Link to=""><h6>Assistant Director</h6></Link>
              <p>Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur</p>
             <Link to="" className="link_clr">View Details</Link>
              </div>
              </div>

              <div className='col-md-4'>
              <div className='bg_bx'>
              <div className='list_part'>
              <span>Feature Film</span> <span>Female</span>  <span>Location : Chandigarh</span><span>Posted : Dec 12, 2022</span>
              </div>    
              <Link to=""><h6>Assistant Director</h6></Link>
              <p>Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur</p>
             <Link to="" className="link_clr">View Details</Link>
              </div>
              </div> */}
            </div>

            <div className='row'>
              <div className='col-xs-12 text-center'>
              <Link to="/SearchJob" className="stroke_btn">Explore Jobs</Link>
              </div>
            </div>
         </div>
         </section>
      </div>
    );
  }
}

export default AuditionPart;