import React, { Component } from 'react';
import PostjobDetailPage from '../../pages/PostJobDetail/PostjobDetailPage';
import { getJobPostDetail, deleteJobPost, searchUser, sendInvitation, saveUser, shortlistUser, hireUser, getCurrentUser } from '../../actions/index';
import _ from 'lodash';
import swal from 'sweetalert';
let timer = "";
class PostjobDetailPageContainer extends Component {

    state = { jobPostDetail: {}, search: "", searchedData: [], currentUser: {} };

    componentDidMount() {
        if (this.props.match ?.params ?.id) {
            this.getJob();
            this.setCurrentUser(JSON.parse(localStorage.getItem('user')));
        }
    }

    setCurrentUser = currentUser => this.setState({ currentUser });

    getJob = () => {
        getJobPostDetail(this.props.match.params.id)
            .then(res => this.setState({ jobPostDetail: res.data ?.data }, () => this.onUserSearch("")));
    }
    onUserSearch = search => {
        this.setState({ search });
        clearTimeout(timer);
        timer = setTimeout(() => {
            this.getSearchedUser();
        }, 300);
    }

    sendInvitaion = userId => {
        sendInvitation({ jobId: this.props.match.params.id, userId })
            .then(res => {
                swal('Invitation sent', { buttons: false, timer: 2000, icon: 'success' })
                this.getJob()
            })
            .catch(err => swal('Something went wrong', { buttons: false, timer: 2000, icon: 'error' }));
    }

    saveUser = userId => {
        saveUser({ jobId: this.props.match.params.id, userId })
            .then(res => {
                swal('User Saved', { buttons: false, timer: 2000, icon: 'success' })
                this.getJob()
            })
            .catch(err => swal('Something went wrong', { buttons: false, timer: 2000, icon: 'error' }));
    }

    shortlistUser = userId => {
        shortlistUser({ jobId: this.props.match.params.id, userId })
            .then(res => {
                swal('User shortlisted', { buttons: false, timer: 2000, icon: 'success' })
                this.getJob()
            })
            .catch(err => swal('Something went wrong', { buttons: false, timer: 2000, icon: 'error' }));
    }


    hireUser = userId => {
        hireUser({ jobId: this.props.match.params.id, userId })
            .then(res => {
                swal('User hired', { buttons: false, timer: 2000, icon: 'success' })
                this.getJob();
                getCurrentUser()
                    .then(currentUser => this.setCurrentUser(currentUser));
            })
            .catch(err => swal('Something went wrong', { buttons: false, timer: 2000, icon: 'error' }));
    }

    getSearchedUser = () => {
        searchUser(this.state.search)
            .then(res => this.setState({ searchedData: [...res.data ?.data[0] ?.name , ...res.data ?.data[0] ?.name] }))
    }

    removePost = () => {
        deleteJobPost(this.state.jobPostDetail._id)
            .then(res => this.props.history.push('/JobList'))
            .catch(err => swal('Something went wrong', { buttons: false, timer: 2000, icon: 'error' }));
    }

    render() {
        return <PostjobDetailPage
            {...this.state}
            {...this.props}
            removePost={this.removePost}
            onUserSearch={this.onUserSearch}
            sendInvitaion={this.sendInvitaion}
            saveUser={this.saveUser}
            shortlistUser={this.shortlistUser}
            hireUser={this.hireUser}
        />
    }
}

export default PostjobDetailPageContainer