import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  headerContainer: {
    marginTop: 10,
    padding:3,
    fontSize: "10px"
},
billFrom: {
    marginTop: 0,
    paddingBottom: 3,
    fontFamily: 'Helvetica-BoldOblique',
    fontSize:12
},
blankSpace:{
    height:10
},
address:{
    fontSize:11,
    fontFamily:'Helvetica'
}
  });


  const BillTo = ({invoice}) => {
    const receiverData = invoice.payer
    return(
    <View style={styles.headerContainer}>
    <Text style={styles.billFrom}>To</Text>
    <Text style={styles.address}>{receiverData.name}</Text>
    <Text style={styles.address}>{receiverData.address}</Text>

    <Text  style={{marginTop: 5}}>Email : {receiverData.email}</Text>
    <Text>Contact No : {receiverData.phone}</Text>
    <Text>GSTIN : {receiverData.gstNo}</Text>
    <Text>PAN : {receiverData.pan}</Text>
  </View>
  )};
  
  export default BillTo