import React from "react";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import { Link } from 'react-router-dom';

const MarketingPage = (props) => (
  <>
    <Header />
    <SubHeader />
   {/* CTA section start*/}
   <section id="cta" className="mid_info dark_clr inner_banner">
          <div className="container side_space">
          <div class="home_grey_bx clearfix"><img src="images/hire.png" alt="img" class="right_pattern" />
            <div className="row">   
            <div className="col-md-9 cta_info">
            <h2>Need to hire talent for your next project?</h2>
            <p>Access the largest network of Producers, Directors, Writers, Artists, models, freelancers, and crew members to find the perfect creative talent for your next project.</p>
                 </div>
            </div>
            </div>
          </div>
      </section>
      {/* CTA section ends */}

      <section id="services_pages" className="dark_clr">
        <div className="container">
          <div className="row">
            <div className="col-md-12 head_part">
              <h2>Marketing Services</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-sm-6 col-xs-6 full">
              <div className="bx_bg">
              <Link to="/PromotionalStrategyBuilding">
              <img src="images/promotional-strategy-building.webp" alt="img" />
              <h6>Promotional Strategy</h6></Link>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-6 full">
              <div className="bx_bg">
              <Link to="/CampaignManagement">
              <img src="images/campaign-management-company.webp" alt="img" />
              <h6>Campaign Management</h6></Link>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-6 full">
              <div className="bx_bg">
              <Link to="/VideoMarketingCompany">
              <img src="images/video-marketing-company.webp" alt="img" />
              <h6>Video Marketing</h6></Link>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-6 full">
              <div className="bx_bg">
              <Link to="/PaidAdvertisingAgency">
              <img src="images/google-adv-agency.webp" alt="img" />
              <h6>Google Ads</h6></Link>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-6 full">
              <div className="bx_bg">
              <Link to="/MobileMarketingCompany">
              <img src="images/mobile-marketing-company.webp" alt="img" />
              <h6>Mobile Marketing</h6></Link>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-6 full">
              <div className="bx_bg">
              <Link to="/YoutubeMarketingAgency">
              <img src="images/youtube-marketing-company.webp" alt="img" />
              <h6>Youtube Marketing</h6></Link>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-6 full">
              <div className="bx_bg">
              <Link to="/OutdoorAdvertisingCompany">
              <img src="images/outdoor-advertising-company.webp" alt="img" />
              <h6>Outdoor Ads</h6></Link>
              </div>
            </div>
           
          </div>
        </div>
      </section>

    <Footer />
  </>
);

export default MarketingPage;
