import React, { Component } from "react";
import CreateAuditionPage from '../../components/createAudition/Index';
import _ from 'lodash'
import { createAudition, getAuditionDetails, uploadFiles } from '../../actions/index';
import axios from "axios";
import { config } from "../../actions/config";
import { ToastContainer, toast } from "react-toastify";


class CreateAudition extends Component {

  state = { step: 1 };

  componentDidMount() {
    if (this.props.location ?.state ?.id) {
      getAuditionDetails(this.props.location.state.id)
        .then(res => this.setState({ ...res.data.data, _id: this.props.location.state.new ? null : res.data.data ?._id }));
    }
  }


  PostAuditionSubmit = (e, published) => {
    e.preventDefault();
    createAudition({ ...this.state, published })
      .then(res => this.props.history.push('/SearchAudition'))
      .catch(err => { 
        console.log(err)
        if (err.response.data && err.response.data.Error && err.response.data.Error.errors) {
         let key=  Object.keys(err.response.data.Error.errors)[0]
         toast.error(err.response.data.Error.errors[key].message)
        }
      })
  };

  nextStep = (stepNumber) => this.setState(state => ({ step: stepNumber ? stepNumber : state.step + 1 }));

  prevStep = () => this.setState(state => ({ step: state.step - 1 }));

  handleChangeDropDown = e => {
    this.setState({ [e.name]: e.value });
  }

  handleChangeSingle = e => {
    console.log(e, 'dfgtd')
    if (e.target.type == 'radio' || e.target.type == 'checkbox') {
      this.setState({ [e.target.name]: e.target.checked });
    }else{
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  defaultProps = {
    placeholder: "Type Languages"
  };

  fileSelectedHandler = async(e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    const configs = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    const targetName = e.target.name;
    try {
      const res = await uploadFiles(formData,configs)
      if (res && res.data && res.data.data) {
        this.setState({ [targetName]: res.data.data.filename });
      }
    } catch (ex) {
      console.log('upload error', ex);
    }
  }

  render() {
    console.log(this.state, "Djdoe")
    return (
      <>
      <ToastContainer/>
      <CreateAuditionPage
      {...this.state}
      {...this.props}
      defaultProps={this.defaultProps}
      nextStep={this.nextStep}
      prevStep={this.prevStep}
      postJob={this.postJob}
      handleChangeSingle={this.handleChangeSingle}
      handleChangeDropDown={this.handleChangeDropDown}
      PostAuditionSubmit={this.PostAuditionSubmit}
      fileSelectedHandler={this.fileSelectedHandler}
      // imagePreview={this.state.imagePreview}
    />
      </>
    )
  }
}
export default CreateAudition;

