import React, { Component } from 'react';
import '../scss/index.scss';
import { connect } from 'react-redux';
import Routes from '../core/routing/routes';
import queryString from 'query-string';
import Cookies from 'js-cookie';
import { createBrowserHistory } from "history";
import ErrorBoundary from './ErrorBoundary';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import { config } from '../actions/config';
import {GoogleOAuthProvider} from '@react-oauth/google';


const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL
});
const queryClient = new QueryClient()
class App extends Component {
  constructor(props) {
    super(props);
    let params = queryString.parse(history.location.search);
    let headerAuth = Cookies.get('headerAuth');
    // if (process.env.REACT_APP_ENV === 'development') {
    //   headerAuth = headerAuth !== undefined ? headerAuth : process.env.REACT_APP_HEADERAUTH;
    // }
    this.globalProps = { params, headerAuth };
  }

  // getPageName = (location) => {
  //   let pathName = location.hash.split("#")[1];
  //   return pathName === "/" ? "/home" : pathName;
  // }
  componentDidMount() {
      //  if(!localStorage.getItem('accessToken')){
      //    window.location.href = '/'
      //  }
       
  }

  render() {
    ///if (!this.props.isErrorInFetchingData) {

    let globalProps = { ...this.globalProps };

    return (
      <div style={{ overflow: 'hidden' }}>
        <QueryClientProvider client={queryClient}>
          <GoogleOAuthProvider clientId={config.GOOGLE_OAUTH_CLIENT_ID}>
        <ErrorBoundary>
          <Routes history={history} globalProps={globalProps} />
        </ErrorBoundary>
        </GoogleOAuthProvider>
        </QueryClientProvider>
      </div >
    );
    // }
    // else {
    //   return <NotFound />
  }
}

const mapStateToProps = (state, ownProps) => {
  return {

  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {

  };
};

export default connect(
  mapStateToProps, mapDispatchToProps
)(App);

