import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  headerContainer: {
    marginTop: 10,
    padding: 3,
    fontSize: "10px",
  },
  billFrom: {
    marginTop: 0,
    paddingBottom: 3,
    fontFamily: "Helvetica-BoldOblique",
    fontSize: 12,
  },
  blankSpace: {
    height: 10,
  },
  address: {
    fontSize: "10px",
    fontFamily: "Helvetica",
  },
});

const BillTo = ({ invoice }) => {
  const receiverData = invoice.payer;
  return (
    <View style={styles.headerContainer}>
      <Text style={styles.billFrom}>To</Text>
      <View style={{ flexDirection: "row" }}>
        <Text>Artist Name: </Text>
        <Text style={styles.address}> {receiverData.name}</Text>
      </View>
      <View style={{ flexDirection: "row" }}>
        <Text>Artist Address: </Text>
        <Text style={styles.address}>{receiverData.address}</Text>
      </View>

      <Text style={{ marginTop: 5 }}>Email Address : {receiverData.email}</Text>
      <Text>Contact No : {receiverData.phone}</Text>
      <Text>GSTIN : {receiverData.gstNo}</Text>
      <Text>PAN : {receiverData.pan}</Text>
    </View>
  );
};

export default BillTo;
