import React from "react";
import FormFooter from "../register/FormFooter";
import ForgotPassForm from "./ForgotPassForm";
import ForgotPassOTP from "./ForgotPassOTP";

const ForgotPassPage = ({ otpScreen, ...props }) => (
  <section id="login">
    <img className="login_bg" alt="img" src="images/cinema.jpg" />
    <div className="container">
      <div className="row">
        <div className="col-md-6 login_form pull-right">
          <div className="bg_box clearfix">
            {otpScreen && <ForgotPassOTP {...props} /> || <ForgotPassForm {...props} />}
            <FormFooter />
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default ForgotPassPage;
