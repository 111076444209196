import React from "react";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import { Link } from 'react-router-dom';

const FilmProductionPage = (props) => (
  <>
    <Header />
    <SubHeader />
{/* CTA section start*/}
<section id="cta" className="mid_info dark_clr inner_banner">
          <div className="container side_space">
          <div class="home_grey_bx clearfix"><img src="images/hire.png" alt="img" class="right_pattern" />
            <div className="row">   
            <div className="col-md-9 cta_info">
            <h2>Need to hire talent for your next project?</h2>
            <p>Access the largest network of Producers, Directors, Writers, Artists, models, freelancers, and crew members to find the perfect creative talent for your next project.</p>
                 </div>
            </div>
            </div>
          </div>
      </section>
      {/* CTA section ends */}
 
      <section id="services_pages" className="dark_clr">
        <div className="container">
          <div className="row">
            <div className="col-md-12 head_part">
              <h2>Film Production Services</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-sm-6 col-xs-6 full">
              <div className="bx_bg">
              <Link to="/AdFilmMaking">
              <img src="images/film-making-services-company.webp" alt="img" />
              <h6>Ad Film Making</h6></Link>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-6 full">
              <div className="bx_bg">
              <Link to="/CreativeAdAgency">
              <img src="images/creative-ad-agency.webp" alt="img" />
              <h6>Creative Ad</h6></Link>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-6 full">
              <div className="bx_bg">
              <Link to="/AdvertisingAgency">
              <img src="images/adv-agency.webp" alt="img" />
              <h6>Ad Company</h6></Link>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-6 full">
              <div className="bx_bg">
              <Link to="/PortfolioPhotoshootServices">
              <img src="images/portfolio-photoshoot-services.webp" alt="img" />
              <h6>Portfolio Shoot</h6></Link>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-6 full">
              <div className="bx_bg">
              <Link to="/VideoProductionCompany">
              <img src="images/video-production-services.webp" alt="img" />
              <h6>Video Production</h6></Link>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-6 full">
              <div className="bx_bg">
              <Link to="/AnimatedVideoProductionCompany">
              <img src="images/animated-video-production-company.webp" alt="img" />
              <h6>Animated Video</h6></Link>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-6 full">
              <div className="bx_bg">
              <Link to="/MusicProductionCompany">
              <img src="images/music-production-company.webp" alt="img" />
              <h6>Music Production</h6></Link>
              </div>
            </div>
          </div>
        </div>
      </section>

    <Footer />
  </>
);

export default FilmProductionPage;
