import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight, FaTimes } from "react-icons/fa";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import '../../scss/celebrityManagement.scss'
import _ from "lodash";

class AutomotiveInfluencer extends Component {

  render() {
    return (
      <>
        <Header />
        <SubHeader /> 
          <section id="inner_celeb_banner">
            <div class="container">
              <div class="row">
                <div class="col-sm-6 col-xs-8 inner_celebrity_info">
                  <h1>AUTOMOTIVE INFLUENCER AGENCY</h1>
                  <p align="justify"> BollywoodisGood is known as the most reputed <strong>automotive influencer agency</strong> and with the rising demand and everyday new invention in the features of the automobile; we can help you in beating the competition and shinning high. With every campaign that we will run for you will make your car, motorbike, industrial supplies prominent in the market. The impact of our influencer marketing campaign will boost your sale and grow your business. </p>
                  <Link to="/ReachUs" class="stroke_btn">Contact US</Link>
                </div>
                <div class="col-sm-6 inner_celebrity_img">
                <img src="images/automotive-influencer.webp" alt="BollywoodisGood is best Celebrity Management Company in India" width="699" height="450" class="wow fadeInDown" data-wow-delay="0.3s" />	 
                </div>
              </div>
            </div>
          </section>

          <section id="inner_about">
          <div class="container">
          <div class="row">
          <div class="col-sm-5">
          <img src="images/bike-influencer.webp"  width="458" height="458" alt="Celebrity Management Services in India" />
          </div>
            <div className="col-sm-7">
            <div class="inner_head">
          <h2>Automotive Influencer Marketing</h2>
          </div>
          <h5>Product Branding</h5>
          <br />
          <p align="justify">Though it’s extremely difficult to beat the competition as there are big giants playing in the same field but with our expert team we will search the right influencer for your brand/business and will run the most successful campaign for you. BollywoodisGood is in this field for years and knows exactly how to attract the target audience and make your brand popular in the market.</p>
          <br />
          <p align="justify">With our <strong>Automative influencer marketing</strong> process, you will not only gain profits but the trust of the customers. We find the influencer for your brand/business, get in touch with him/her and collaborate on your behave. We will make sure that our campaign and influencer give your brand/business a huge return. With the rising presence of social media in our life, influencer marketing gives profits and show results in no time. The engagement and followers of the influencers are already high; we just need to choose the one that suits your range of products. </p>
          </div>          
        
          </div>
          </div>
          </section>

          <section id="last_info">
            <div class="container">
            <div class="row">
            <div class="col-xs-12">
            <blockquote>
            <p align="justify">Before selecting the influencer, a thorough study is done regarding the brand/business and the target audience and then we create the campaign as well as find the influencer. From building the campaign to making sure that the results are positive from the influencer side, we work with you throughout the process. BollywoodisGood believes in increasing the sales, enhancing the ROI, and growing the reputation of the brand/business in the market and for that, we have the best team to work for you and make it sure that you are rising high and beating the competition. 

            </p>
            </blockquote>
            </div>
            </div>
            </div>
            </section>
           
        <Footer />
      </>
    );
  }
}

export default AutomotiveInfluencer;
