import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

const theme = createMuiTheme({

    palette: {
        page: {
            background: {
                color: '#ececec',
            },
            header: {
                text: '#ececec',
            },
        },
        panel: {
            background: {
                color: '#fff',
            },
            header: {
                text: '#ececec',
            },
        },

        button: {
            highlight: {
                text: {
                    normal: '#ffffff',
                    hover: '#ffffff',
                    press: '#ffffff',
                    disabled: '#bebebe',
                },
                background: {
                    normal: '#508ee6',
                    hover: '#599dff',
                    press: '#477ecc',
                    disabled: '#dedede',
                },
            },

        },

        textArea: {
            text: {
                normal: '#404040',
                hover: '#404040',
                error: '#ff0000',

                //selectedAreaLabel: '#807f7f',
            },
            Stroke: {
                normal: '#808080',
                hover: '#599eff',
            },
            background: {
                normal: '#ffffff',
                hover: '#ffffff',
            },
        },

        linearProgress: {
            bar: {
                backgroundColor: '#508ee6'
            }
        }
    },
    typography: {
        h6: {
            lineHeight: 1,
        },
        button: {
            textTransform: 'capitalize',
        },
        graph: {
            axis: {
                fontSize: '8pt',
            },
        },
        fontSizeExtraSmall: '9pt',
        fontSizeSmall: '10.5pt',
        fontSizeRegular: '12pt',
        fontSizeMedium: '13.5pt',
        fontSizeLarge: '15pt',

        fontWeightSmall: 100,
        fontWeightLight: 300,
        fontWeightBold: 600,

        fontFamily: 'NotoSans',
    },
});
const defaulTheme = responsiveFontSizes(theme);
export default defaulTheme;
