import React from "react";
import { HashRouter as Router, Route, Switch, HashRouter } from "react-router-dom";
import HomePage from "../../pages/Home/HomePageContainer";
import NotFound from "../../pages/NotFound";
import TopStarMeter from "../../pages/People/TopStarMeter/TopStarMeter";
import LoginPageContainer from "../../Container/Login/LoginPageContainer";
import SignUpPageContainer from "../../Container/SignUp/SignUpPageContainer";
import NewSignupContainer from "../../Container/NewSignup/NewSignupContainer";
import ForgotPassPageContainer from "../../Container/forgotPassword/ForgotPassPageContainer";
import PostJobPageContainer from '../../Container/JobPost/index';
import JobListPageContainer from '../../Container/JobList/JobListPageContainer';
import PostjobDetailPageContainer from "../../Container/PostJobDetail/PostjobDetailPageContainer";
import UserSideJobPage from "../../Container/UserSideJob/Index";
import UserApplicationPage from "../../Container/UserApplication/UserApplicationContainer";
import ClientSideUserApplicationContainer from "../../Container/ClientSideApplication/ClientSideUserApplicationContainer";
import SearchJobPageContainer from "../../Container/SearchJob/SearchJobPageContainer";
import SubmitApplicationPage from "../../Container/SubmitApplication/SubmitApplicationContainer";
import UserProfileContainer from "../../Container/UserProfile/UserProfileContainer";
import MakeFilmPageContainer from "../../Container/MakeFilm/MakeFilmPageContainer";
import MakeFilmContactContainer from "../../Container/MakeFilmContact/MakeFilmContactContainer";
import ProjectHistoryPage from "../../Container/ProjectHistory/ProjectHistoryPage";
import CreateUserProfileContainer from "../../Container/CreateUserProfile/CreateUserProfileContainer";
import CreateAuditionPage from "../../Container/CreateAudition/Index";
import NotificationPage from "../../pages/Notification/NotificationPage";
import AuditionListContainer from '../../Container/AuditionDirectorSide/AuditionListContainer';
import AuditionDetailPageContainer from "../../Container/AuditionDetail/AuditionDetailPageContainer";
import SearchAuditionContainer from "../../Container/SearchAudition/SearchAuditionContainer";
import UserSideAuditionContainer from "../../Container/UserSideAudition/UserSideAuditionContainer";
import ApplyAuditionContainer from "../../Container/ApplyAudition/ApplyAuditionContainer";
import AuditionApplicationViewContainer from "../../Container/AuditionApplicationView/AuditionApplicationViewContainer";
import FindTalentPageContainer from "../../Container/FindTalent/FindTalentPageContainer";
import ArtistInvoiceContainer from "../../Container/ArtistInvoice/ArtistInvoiceContainer";
import AggregatorInvoiceToMMContainer from "../../Container/AggregatorInvoiceToMM/AggregatorInvoiceToMMContainer";
import AggregatorInvoiceToArtistContainer from "../../Container/AggregatorInvoiceToArtist/AggregatorInvoiceToArtistContainer";
import HireTalentContainer from "../../Container/HireTalent/HireTalentContainer";
import HiredContractContainer from "../../Container/HiredContracts/HiredContractContainer";
import UserWalletContainer from "../../Container/UserWallet/UserWalletContainer";
import Chat from "../../components/chat/AuditionChat/Chat";
import NewsFeedPageContainer from "../../Container/NewsFeed/NewsFeedPageContainer";
import NewsFeedDetails from "../../components/NewsFeed/NewsFeedDetails";
import CelebrityManagementCompany from "../../pages/CelebrityManagement/CelebrityManagementCompany";
import CelebrityEvent from "../../pages/CelebrityEventManagement/CelebrityEvent";
import CelebritySocialMedia from "../../pages/CelebritySocialMediaManagement/CelebritySocialMedia";
import BollywoodCelebrity from "../../pages/BollywoodCelebrityManagement/BollywoodCelebrity";
import CelebrityBrandEndorsement from "../../pages/CelebrityBrand/CelebrityBrandEndorsement";
import SportsCelebrityManagementCompany from "../../pages/SportsCelebrityManagement/SportsCelebrityManagementCompany";
import ModelingAgencyCompany from "../../pages/ModelingAgency/ModelingAgencyCompany";
import FilmTalent from "../../pages/FilmTalentManagement/FilmTalent";
import AdFilmCompany from "../../pages/AdFilmMaking/AdFilmCompany";
import AdAgency from "../../pages/CreativeAdAgency/AdAgency";
import AdvertisingAgencyCompany from "../../pages/AdvertisingAgency/AdvertisingAgencyCompany";
import PortfolioPhotoshoot from "../../pages/PortfolioPhotoshootServices/PortfolioPhotoshoot";
import VideoProduction from "../../pages/VideoProductionCompany/VideoProduction";
import AnimatedVideoProduction from "../../pages/AnimatedVideoProductionCompany/AnimatedVideoProduction";
import MusicProduction from "../../pages/MusicProductionCompany/MusicProduction";
import PromotionalStrategy from "../../pages/PromotionalStrategyBuilding/PromotionalStrategy";
import CampaignManagementPage from "../../pages/CampaignManagement/CampaignManagementPage";
import VideoMarketing from "../../pages/VideoMarketingCompany/VideoMarketing";
import AdvertisingAgency from "../../pages/PaidAdvertisingAgency/AdvertisingAgency";
import MobileMarketing from "../../pages/MobileMarketingCompany/MobileMarketing";
import YoutubeMarketing from "../../pages/YoutubeMarketingAgency/YoutubeMarketing";
import OutdoorAdvertising from "../../pages/OutdoorAdvertisingCompany/OutdoorAdvertising";
import InfluencerMarketing from "../../pages/InfluencerMarketingCompany/InfluencerMarketing";
import InfluencerAd from "../../pages/InfluencerAdNetwork/InfluencerAd";
import DigitalInfluencer from "../../pages/DigitalInfluencerAgency/DigitalInfluencer";
import TechInfluencer from "../../pages/TechInfluencerMarketing/TechInfluencer";
import SportsFitnessInfluencer from "../../pages/SportsFitnessInfluencerMarketing/SportsFitnessInfluencer";
import AutomotiveInfluencer from "../../pages/AutomotiveInfluencerCompany/AutomotiveInfluencer";
import EntertainmenInfluencer from "../../pages/EntertainmenInfluencerMarketing/EntertainmenInfluencer";
import HomeApplianceInfluencer from "../../pages/HomeApplianceInfluencerMarketing/HomeApplianceInfluencer";
import FashionInfluencer from "../../pages/FashionInfluencerMarketing/FashionInfluencer";
import AudioBooksInfluencer from "../../pages/AudioBooksInfluencerMarketing/AudioBooksInfluencer";
import BeautyHealthInfluencer from "../../pages/BeautyHealthInfluencerMarketing/BeautyHealthInfluencer";
import KitchenAppliancesInfluencer from "../../pages/KitchenAppliancesInfluencerMarketing/KitchenAppliancesInfluencer";
import BabyProductsInfluencer from "../../pages/BabyProductsInfluencerMarketing/BabyProductsInfluencer";
import ReachUsfFrom from "../../pages/ReachUs/ReachUsfFrom";
import CelebrityServicePage from "../../pages/CelebrityService/CelebrityServicePage";
import FilmProductionPage from "../../pages/FilmProduction/FilmProductionPage";
import MarketingPage from "../../pages/Marketing/MarketingPage";
import InfluencerServicesPage from "../../pages/InfluencerServices/InfluencerServicesPage";
import PrivacyPolicy from "../../pages/privacy-policy/PrivacyPolicy";
import AuthCheck from "../../commons/AuthCheck";
import InfliuencersFormContainer from '../../Container/InfliuencersForm/InfliuencersFormContainer';
import BrandIntegrationsContainer from '../../Container/BrandIntegrations/BrandIntegrationsContainer';


const Routes = (props) => {
  return (
    <div>
      <HashRouter>
      <AuthCheck />
      <Router history={props.history}>
        <Switch>
        <Route
            path="/BrandIntegrations"
            render={(localProps) => (
              <BrandIntegrationsContainer
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />
        <Route
            path="/InfliuencersForm"
            render={(localProps) => (
              <InfliuencersFormContainer
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />
        <Route
            exact={true}
            path="/"
            render={(localProps) => (
              <HomePage {...localProps} globalProps={props.globalProps} />
            )}
          />
          <Route
            exact
            path="/login"
            render={(localProps) => (
              <LoginPageContainer
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          /> 
           <Route
            path="/ReachUs"
            render={(localProps) => (
              <ReachUsfFrom
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />    
          
          <Route
            path="/InfluencerServices"
            render={(localProps) => (
              <InfluencerServicesPage
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />    
          <Route
            path="/Marketing"
            render={(localProps) => (
              <MarketingPage
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />    
          <Route
            path="/BabyProductsInfluencerMarketing"
            render={(localProps) => (
              <BabyProductsInfluencer
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />    
          <Route
            path="/CelebrityService"
            render={(localProps) => (
              <CelebrityServicePage
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />  
          <Route
            path="/FilmProduction"
            render={(localProps) => (
              <FilmProductionPage
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />   
          
          <Route
            path="/KitchenAppliancesInfluencerMarketing"
            render={(localProps) => (
              <KitchenAppliancesInfluencer
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />    
          <Route
            path="/BeautyHealthInfluencerMarketing"
            render={(localProps) => (
              <BeautyHealthInfluencer
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />
          <Route
            path="/AudioBooksInfluencerMarketing"
            render={(localProps) => (
              <AudioBooksInfluencer
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />
          <Route
            path="/HomeApplianceInfluencerMarketing"
            render={(localProps) => (
              <HomeApplianceInfluencer
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />
          <Route
            path="/EntertainmenInfluencerMarketing"
            render={(localProps) => (
              <EntertainmenInfluencer
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />
          <Route
            path="/AutomotiveInfluencerCompany"
            render={(localProps) => (
              <AutomotiveInfluencer
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />
          <Route
            path="/FashionInfluencerMarketing"
            render={(localProps) => (
              <FashionInfluencer
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />
          <Route
            path="/SportsFitnessInfluencerMarketing"
            render={(localProps) => (
              <SportsFitnessInfluencer
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />
          <Route
            path="/TechInfluencerMarketing"
            render={(localProps) => (
              <TechInfluencer
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />
          <Route
            path="/DigitalInfluencerAgency"
            render={(localProps) => (
              <DigitalInfluencer
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />
          <Route
            path="/InfluencerAdNetwork"
            render={(localProps) => (
              <InfluencerAd
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />
          <Route
            path="/InfluencerMarketingCompany"
            render={(localProps) => (
              <InfluencerMarketing
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />
          <Route
            path="/CampaignManagement"
            render={(localProps) => (
              <CampaignManagementPage
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />
          <Route
            path="/OutdoorAdvertisingCompany"
            render={(localProps) => (
              <OutdoorAdvertising
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />
          <Route
            path="/YoutubeMarketingAgency"
            render={(localProps) => (
              <YoutubeMarketing
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />
          
          <Route
            path="/PaidAdvertisingAgency"
            render={(localProps) => (
              <AdvertisingAgency
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />
          <Route
            path="/MobileMarketingCompany"
            render={(localProps) => (
              <MobileMarketing
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />
          
          <Route
            path="/VideoMarketingCompany"
            render={(localProps) => (
              <VideoMarketing
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />          
           <Route
            path="/PromotionalStrategyBuilding"
            render={(localProps) => (
              <PromotionalStrategy
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />
          <Route
            exact={true}
            path="/home"
            render={(localProps) => (
              <HomePage {...localProps} globalProps={props.globalProps} />
            )}
          />
          
          <Route
            exact={true}
            path="/HomePageVer"
            render={(localProps) => (
              <HomePage {...localProps} globalProps={props.globalProps} />
            )}
          />
          <Route
            exact
            path="/people"
            render={(localProps) => (
              <TopStarMeter {...localProps} globalProps={props.globalProps} />
            )}
          />
          <Route
            exact
            path="/Login"
            render={(localProps) => (
              <LoginPageContainer
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />
          <Route
            path="/SignUp"
            render={(localProps) => (
              <SignUpPageContainer
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />
          <Route
            path="/ForgotPassword"
            render={(localProps) => (
              <ForgotPassPageContainer
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />
          <Route
            path="/PostJob"
            render={(localProps) => (
              <PostJobPageContainer
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />
          <Route
            path="/JobList"
            render={(localProps) => (
              <JobListPageContainer
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />
          <Route
            path="/PostJobDetail/:id"
            render={(localProps) => (
              <PostjobDetailPageContainer
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />
          <Route
            path="/UserSideJob/:id"
            render={(localProps) => (
              <UserSideJobPage
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />
          <Route
            path="/UserApplication/:id"
            render={(localProps) => (
              <UserApplicationPage
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />

          <Route
            path="/ClientSideApplication/:id"
            render={(localProps) => (
              <ClientSideUserApplicationContainer
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />

          <Route
            path="/SearchJob"
            render={(localProps) => (
              <SearchJobPageContainer
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />

          <Route
            path="/SubmitApplication/:id"
            render={(localProps) => (
              <SubmitApplicationPage
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />

          <Route
            path="/UserProfile/:id"
            render={(localProps) => (
              <UserProfileContainer
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />

          <Route
            path="/MakeFilm"
            render={(localProps) => (
              <MakeFilmPageContainer
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />

          <Route
            path="/MakeFilmContact"
            render={(localProps) => (
              <MakeFilmContactContainer
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />

          <Route
            path="/AdFilmMaking"
            render={(localProps) => (
              <AdFilmCompany
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />
          <Route
            path="/MusicProductionCompany"
            render={(localProps) => (
              <MusicProduction
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />

          <Route
            path="/VideoProductionCompany"
            render={(localProps) => (
              <VideoProduction
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />
          <Route
            path="/AnimatedVideoProductionCompany"
            render={(localProps) => (
              <AnimatedVideoProduction
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />
          
          <Route
            path="/PortfolioPhotoshootServices"
            render={(localProps) => (
              <PortfolioPhotoshoot
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />
          <Route
            path="/AdvertisingAgency"
            render={(localProps) => (
              <AdvertisingAgencyCompany
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          /> 
          <Route
            path="/CreativeAdAgency"
            render={(localProps) => (
              <AdAgency
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />

          <Route
            path="/NewSignup"
            render={(localProps) => (
              <NewSignupContainer
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />

          <Route
            path="/ProjectHistory"
            render={(localProps) => (
              <ProjectHistoryPage
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />

          <Route
            path="/CreateUserProfile"
            render={(localProps) => (
              <CreateUserProfileContainer
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />

          <Route
            path="/CreateAudition"
            render={(localProps) => (
              <CreateAuditionPage
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />
          <Route
            path="/Notification"
            render={(localProps) => (
              <NotificationPage
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />

        <Route
            path="/CelebrityManagement"
            render={(localProps) => (
              <CelebrityManagementCompany
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />

      <Route
            path="/CelebrityEventManagement"
            render={(localProps) => (
              <CelebrityEvent
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />

          <Route
            path="/CelebritySocialMediaManagement"
            render={(localProps) => (
              <CelebritySocialMedia
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />
          <Route
            path="/BollywoodCelebrityManagement"
            render={(localProps) => (
              <BollywoodCelebrity
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />

          <Route
            path="/FilmTalentManagement"
            render={(localProps) => (
              <FilmTalent
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />          

          <Route
            path="/CelebrityBrand"
            render={(localProps) => (
              <CelebrityBrandEndorsement
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />

          <Route
            path="/SportsCelebrityManagement"
            render={(localProps) => (
              <SportsCelebrityManagementCompany
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />
          <Route
            path="/ModelingAgency"
            render={(localProps) => (
              <ModelingAgencyCompany
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />
                              

          <Route
            path="/AuditionDirectorSide"
            render={(localProps) => (
              <AuditionListContainer
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />

          <Route
            path="/AuditionDetail/:id"
            render={(localProps) => (
              <AuditionDetailPageContainer
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />

          <Route
            path="/SearchAudition"
            render={(localProps) => (
              <SearchAuditionContainer
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />

          <Route
            path="/UserSideAudition/:id"
            render={(localProps) => (
              <UserSideAuditionContainer
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />

          <Route
            path="/ApplyAudition/:id"
            render={(localProps) => (
              <ApplyAuditionContainer
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />

          <Route
            path="/AuditionApplicationView/:id"
            render={(localProps) => (
              <AuditionApplicationViewContainer
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />
          <Route
            path="/FindTalent"
            render={(localProps) => (
              <FindTalentPageContainer
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />
          <Route
            path="/HireTalent/:id"
            render={(localProps) => (
              <HireTalentContainer
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />
          {/* <Route
            path="/ArtistInvoice/:transactionId"
            render={(localProps) => (
              <ArtistInvoiceContainer
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          /> */}
          <Route
            path="/AggregatorMMInvoice/:transactionId"
            render={(localProps) => (
              <AggregatorInvoiceToMMContainer
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />
          <Route
            path="/AggregatorArtistInvoice/:transactionId"
            render={(localProps) => (
              <AggregatorInvoiceToArtistContainer
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />
          <Route
            path="/hiredContracts"
            render={(localProps) => (
              <HiredContractContainer
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />
           <Route
            path="/wallet"
            render={(localProps) => (
              <UserWalletContainer
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />
<Route
            path="/chat"
            render={(localProps) => (
              <Chat
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />
<Route
            path="/news"
            render={(localProps) => (
              <NewsFeedPageContainer
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />
          <Route
            path="/newsdetails"
            render={(localProps) => (
              <NewsFeedDetails
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />
             <Route
            path="/privacy"
            render={(localProps) => (
              <PrivacyPolicy
                {...localProps}
                globalProps={props.globalProps}
              />
            )}
          />
          <Route path="/error" component={NotFound} />
          <Route path="*" component={NotFound} />
        </Switch>
      </Router>

      </HashRouter>
    </div>
  );
};

export default Routes;
