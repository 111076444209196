import React from "react";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import UserSideAuditionDetail from "../../components/UserSideAudition/UserSideAuditionDetail";

const UserSideAuditionPage = (props) => (
  <>
    <Header />
    <SubHeader />
    <UserSideAuditionDetail {...props} />
    <Footer />
  </>
);
export default UserSideAuditionPage;
