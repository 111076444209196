import React from 'react'

export const CheckBox = props => {
    return (    
        <p className="list-group-item">
          <label className="check_bx">
            <input key={props.id}
            onClick={props.handleCheckChieldElement}
            type="checkbox" 
            checked={props.isChecked}
            value={props.value}
            /> 
            {props.value}  
            <span className="checkmark" />
            </label>
       </p>
      )
}

export default CheckBox