import React, { Component } from 'react';
import UserProfilePage from "../../pages/UserProfile/UserProfilePage";
import { getHiringAccessById, getUserInformation, getUsersRole, inviteForJob, loginUser, requestHiringAccess, undoJobinvite, updateUserReview } from '../../actions/index';
import _ from 'lodash';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class UserProfileContainer extends Component {
  state = { user: {}, step: 1, activeTab: 1, eligibleForHiring:false };

  componentDidMount() {
    const searchParam = this.props.location.search;
    const tab = new URLSearchParams(searchParam).get('tab');
    if(tab){
      this.setState({activeTab: +tab})
    }
    if (this.props.match?.params?.id) {
      getUserInformation(this.props.match?.params?.id).then((res) =>
        this.setState({ user: res.data.data })
      );
     
    }
    const userStr = localStorage.getItem("user");
    const user = userStr ? JSON.parse(userStr):null
    this.setState({ loginUser: user },()=>{
      getUsersRole(this.state.loginUser?._id).then(res =>{
        this.setState({ usersRoleDetails: res.data.data })
      })
      getHiringAccessById({userId:this.state.loginUser?._id}).then((ress)=>{
        if(ress.data && ress.data.data && ress.data.data.status ==='Approved'){
          this.setState({eligibleForHiring: true})
        }
      })
    });
  }
  handleChangeSingle = (e) =>{
    console.log(e)
    if (e.target.name === 'user') {
        
    }
    this.setState({ user:{...this.state.user,[e.target.name]: e.target.value} });
}
 handleSubmitReview =(e) => {
    e.preventDefault()
      updateUserReview(_.pickBy(_.omit(this.state.user, ['errors', 'agreed']), _.identity))
        .then(res =>  {
          if(res.status && res.data.data){
            if (this.props.match?.params?.id) {
              getUserInformation(this.props.match?.params?.id).then((res) =>
                this.setState({ user: res.data.data })
              );
            }
            toast('Thank you for your valuable review', { isLoading: true, timer: 2000, icon: 'success' })
          }else{
            toast('Something went wrong.', { buttons: false, timer: 2000, icon: 'error' });
          }
          })
    }
  inviteForJobHandler =(e)=>{
    e.preventDefault()
    inviteForJob(_.pickBy(_.omit(this.state.user, ['errors', 'agreed']), _.identity),this.state.loginUser?._id)
        .then(res =>  {
          if(res.status && res.data.data){

            if (this.props.match?.params?.id) {
              getUserInformation(this.props.match?.params?.id).then((res) =>
                this.setState({ user: res.data.data })
              );
            }
          }
          })
  }
  undoInviteHandler =(e)=>{
    e.preventDefault()
    undoJobinvite(_.pickBy(_.omit(this.state.user, ['errors', 'agreed']), _.identity),this.state.loginUser?._id)
        .then(res =>  {
          if(res.status && res.data.data){

            if (this.props.match?.params?.id) {
              getUserInformation(this.props.match?.params?.id).then((res) =>
                this.setState({ user: res.data.data })
              );
            }
          }
          })
  }
  requestForHiringAccess=()=>{
    const {emailId, userName, _id} = this.state.loginUser
    const payload ={
      id: _id, emailId, userName
    }
    requestHiringAccess(payload).then((res) =>{
    if(res.data && res.data.message === 'success'){
      toast('Request is submitted to admin.', { isLoading: true, timer: 2000, icon: 'success' })
    }else{
      toast('Something went wrong.', { isLoading: true, timer: 2000, icon: 'error' })
    }
  }
  ).catch((e)=>{
    toast('Something went wrong.', { isLoading: true, timer: 2000, icon: 'error' })
  });
  }

  render() {
    return (
      <>
      <ToastContainer />
      <UserProfilePage
        {...this.state}
        nextStep={this.nextStep}
        prevStep={this.prevStep}
        handleSubmitReview={this.handleSubmitReview}
        handleChangeSingle={this.handleChangeSingle}
        inviteForJob={this.inviteForJobHandler}
        requestHiringAccess={this.requestForHiringAccess}
        undoInviteHandler={this.undoInviteHandler}
      />
      </>
    );
  }
}
export default UserProfileContainer;
