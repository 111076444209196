import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getJobs, getLatestAuditions } from '../../actions/index';
import HomePage from './HomePage';
import * as constants from '../../core/constants';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from "redux";
import _ from 'lodash';
class HomePageContainer extends Component {

  state = {
    data: []
  }

  componentDidMount() {
    this.getJobList();
  }
  getJobList = search => {
    getLatestAuditions({ limit: 4 })
      .then(res => {
        this.setState({ data: res.data.data });
      })
      getJobs({ limit: 6 })
      .then(res => {
          this.setState({ jobs: res.data ?.data[0] ?.search });
      })
  }

  render() {
    return <HomePage {...this.props} {...this.state} />
  }
}
export default HomePageContainer;