import React, { Component } from 'react';
import AggregatorInvoiceToArtist from '../../pages/AggregatorInvoiceToArtist/AggregatorInvoiceToArtist';
import {AggregatorArtistInvoice} from '../../core/services/invoiceData'
import { getInvoiceData } from '../../actions';
class AggregatorInvoiceToArtistContainer extends Component {
    state={invoiceData:null}
    componentDidMount (){
        console.log(this.props.transactionId)
        getInvoiceData(this.props.transactionId).then( res =>{
            if (res && res.data && res.data.data) {
                console.log(res.data)
                this.setState({invoiceData :res.data.data.aggArtistInvoice})
            }
        })
    
    }
    render() {
        
        return (
            <div>
               {this.state.invoiceData && <AggregatorInvoiceToArtist invoice={this.state.invoiceData}/>}
            </div>
        );
    }
}

export default AggregatorInvoiceToArtistContainer;
