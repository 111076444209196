import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import _ from "lodash";
import { vlidateNumberField } from "../../Utilities/validateInputField";
import { loadOptionsResolveLanguage, defaultOption } from "../../Constants.js/languageConst";
// import filmCourseOptions from '../CreateUserProfile/filmCoursesConst';
import AsyncSelect from "react-select/async";
import { ActorType, Genre } from "../JobPost/jobPostConst";
import { roleOption, filmIndustry, filmType, uniqueTraits, physique, postGraduation, jobNature, workingStyle, schedule, abilityToCommut, supplementalPayTypes } from "../CreateUserProfile/registerConst";
import { FaPlus, FaMinus, } from "react-icons/fa";
import { Placeholder } from '../../commons/ReactSelectNoOptionsMessage';
import { config } from '../../actions/config';

const BrandInformation = ({    
    prevStep,
    nextStep,   
    ...props
}) => {
    const { t } = useTranslation();
    const [emailError, setEmailError] = useState('');
    const handleBlurEmail = (e) => {
        const { value } = e.target;
        const emailRegExp = /^\S+@\S+\.\S+$/;
    
        if (!emailRegExp.test(value)) {
          setEmailError('Invalid email address');
        } else {
          setEmailError('');
        }
      }

    return (
        <>         

        <div className="tab_head">
                <h4>Brand Information</h4>
                <hr />
            </div>
              <div className='row'>     
              <div className="form-group col-md-6 col-sm-12">
                    <label>Company Name</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        name="title"
                    />
                </div>
                <div className="form-group col-md-6 col-sm-12">
                    <label>Company Website</label>
                    <input
                        type="email"
                        className="form-control"
                        placeholder=""
                        name="title"
                    />
                </div>   
                <div className="form-group col-md-6 col-sm-12">
                    <label>Industry/Sector </label>
                    <CreatableSelect
                        components={{ Placeholder }}
                        placeholder={''}
                        options={''}
                        />
                </div> 
                <div className="form-group col-md-12 col-sm-12">
                    <label>Company Description</label>
                    <textarea
                            className="form-control"
                            placeholder=""
                            
                            name="description"
                            onChange={''}
                    />
                </div>

                <div className="form-group col-md-6 col-sm-12">
                    <label>Contact Person Name</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        name="title"
                    />
                </div>   
                              
                <div className="form-group col-md-6 col-sm-12">
                    <label>Contact Person Position </label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        name="title"
                    />
                </div>
                <div className="form-group col-md-4 col-sm-12">
                    <label>Email Address</label>
                    <input
                        type="email"
                        className="form-control"
                        placeholder=""
                        name="title"
                        onBlur={handleBlurEmail}
                        />
                        {emailError && <p style={{ color: 'red',fontSize:'12px' }}>{emailError}</p>}
                    
                </div>    
                <div className="form-group col-md-4 col-sm-12">
                    <label>Phone Number </label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        name="title"
                        maxLength={10}
                        onKeyDown={(e) => {
                            if (!(e.key >= '0' && e.key <= '9') && e.keyCode !== 8) {
                                e.preventDefault();
                            }
                        }}
                    />
                </div>
                <div className="form-group col-md-4 col-sm-12">
                    <label>Mailing Address</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        name="title"
                    />
                </div> 

                <div className="form-group col-md-12 col-sm-12">
                    <label>Brand Values and Messaging Guidelines</label>
                    <textarea
                            className="form-control"
                            placeholder=""
                            
                            name="description"
                            onChange={''}
                    />
                </div>   
                <div className="form-group col-md-6 col-sm-12">
                    <label>Media Kit or Brand Assets </label>
                    <input
                        type="file"
                        className="form-control"
                        placeholder=""
                        name=""
                    />
                </div>  
                <div className="col-md-12 col-sm-12">
                    <hr />
                    <button className="blue_btn pull-right" onClick={() => nextStep()}>Continue</button>
                </div>   
              </div>             
                      
        </>
    )
}

export default BrandInformation