/* eslint-disable no-whitespace-before-property */
import _ from 'lodash';
import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import swal from 'sweetalert';
import { generateInvoice, getCommissionRate, getGstDetails, getUserFunds, getUserInformation, getUsersRole, hireTalent, updateOrder, uploadFiles } from '../../actions/index';
import HireTalentPage from '../../pages/HireTalent/HireTalentPage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class HireTalentContainer extends Component {
  paymentDatails={}
  state = {
    step: 1,
    user: {},
    paymentOption: "fixed",
    depositeType: "milestoneWise",
    paymentStatus:"Pending",
    milestone: [{status: "Started",isPaymentDone:false},{status: "Started",isPaymentDone:false},{status: "Started",isPaymentDone:false}],
    wholePayment: [{status: "Started",isPaymentDone:false}],
    enableSubmit: false,
    companyAddress:{},
    walletBalance: 0
  };

  componentDidMount() {
    if (this.props.match?.params?.id) {
      getUserInformation(this.props.match?.params?.id).then((res) =>
        this.setState({ user: res.data.data })
      );
    // getUsersRole(this.props.match?.params?.id).then(res =>{
    //   this.setState({ usersRoleDetails: res.data.data })
    // })
    }
    const userStr = localStorage.getItem("user");
    const user = userStr ? JSON.parse(userStr):null
    this.setState({ loginUser: user },()=>{
      this.setState({hiringTeam: this.state.loginUser?.userName})
    });

    getGstDetails().then(res =>{
      this.setState({getGstDetails:res.data.data})
    }).catch(e=>{});

    getCommissionRate().then(res =>{
      this.setState({commissionRate: res.data &&  res.data.data && res.data.data.length && res.data.data[0] ? res.data.data[0]:{}})
    }).catch(e=>{})
    this.getMyFunds()
  }


  getMyFunds =()=>{
    const userString = localStorage.getItem('user')
    const user = userString? JSON.parse(userString):null
    getUserFunds(user?._id).then(res =>{
      if(res?.data?.data){
        this.setState({walletBalance: Number(res?.data?.data?.amount || 0)})
      }
    }).catch(e=>{
        console.log(e)
    })
}

  // componentDidUpdate(){
  //   if(this.state.milestone.length && this.state.milestone[0]?.milestoneAmount){
  //     this.setState({firstMilestoneAmount: this.state.milestone[0]?.milestoneAmount})
  //   }
  // }
  handleChangeSingle = (e) =>{
    // this.setState({errors:{[e.target.name]:e.target.value}})
    this.setState({ [e.target.name]: e.target.value });
}
  handleChangeNestedValue = (e) => {
    // console.log(e, "dejdoejdej");
    const data = _.cloneDeep(this.state);
    console.log(data, e, "data");
    // if (e.oneOrder) {
    //   if (!data[e.key][e.name]) {
    //     data[e.key][e.name] = {};
    //   }
    //   data[e.key][e.name][e.oneOrder] = e.value;
    //   this.setState({ ...data });
    //   return;
    // }
    // if (e.twoOrder) {
    //   if (data[e.key][e.name] && !data[e.key][e.name][e.twoOrder]) {
    //     data[e.key][e.name][e.twoOrder] = { [e.thirdKey]: '' };
    //   }
    //   if (!data[e.key][e.name]) {
    //     data[e.key] = { [e.name]: { [e.twoOrder]: { [e.thirdKey]: '' } } }
    //   }
    //   data[e.key][e.name][e.twoOrder][e.thirdKey] = e.value;
    //   this.setState({ ...data });
    //   return;

    // }
    if (e.index || e.index === 0) {
      if (!data[e.key][e.index]) {
        data[e.key][e.index] = {};
      }

      data[e.key][e.index][e.name] = e.value;
    } else {
      if (!data[e.key]) {
        data[e.key] = {};
      }
      data[e.key][e.name] = e.value;
    }
    this.setState({ ...data });
    if (e.value === "Others" && e.name === "collegeUniversity" && e.drop) {
      this.setState({ othersActivated: true });
    }
    if (this.state.othersActivated && e.drop && e.value !== "Others") {
      this.setState({ othersActivated: false });
    }
    // this.isValidHttpUrl(e)
  };
  addMoreFields = (type) => {
    const data = _.cloneDeep(this.state);
    data[type].push({status: "Started",isPaymentDone:false});
    this.setState({firstMilestoneAmount: data.milestone[0]?.milestoneAmount})
    this.setState({ ...data });
  };

  removeFields = (type, ind) => {
    const data = _.cloneDeep(this.state);
    data[type] = _.filter(data[type], (val, index) => ind !== index);
    this.setState({ ...data });
  };
  fileSelectedHandler = async (e, nested = {}) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    const configs = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const targetName = e.target.name;
    try {
      const res = await uploadFiles(formData, configs);
      if (res && res.data && res.data.data) {
        if (nested && nested.index >= 0) {
          this.handleChangeNestedValue({
            name: targetName,
            value: res.data.data.filename,
            key: "milestone",
            index: nested.index,
          });
        } else {
          this.setState({ [targetName]: res.data.data.filename });
        }
      }
    } catch (ex) {
      console.log("upload error", ex);
    }
  };
  onSubmit = () => {
       
    let { user, loginUser,getGstDetails,getCommissionRate,milestone, ...rest } = this.state;
    console.log(rest, "hire_talent");
    // if (rest?.depositeType === 'whole') {
    //   const restState = rest;
    //   restState.wholePayment[0]['paymentTransactionId']=this.paymentDatails.transactionId;
    //   restState.wholePayment[0]['amount']= this.paymentDatails?.maxFixedPay || 0;
    //   restState.wholePayment[0]['dueDate']= this.paymentDatails?.dueDate ;
    //   restState.wholePayment[0]['paymentDescription']= this.paymentDatails?.paymentDescription ;

    // }milestoneAmount
const newMilestones = milestone.filter((e) => e.milestoneAmount >=0);
    hireTalent({...rest,milestone:newMilestones, employerId:loginUser._id, employeeId: user._id, isActive: false})
      .then((res) => {
        this.setState({_id: res.data.data._id})
        
      })
      .catch((e) => {
        if (e?.response?.data?.Error?.errors) {
          // let errors = {};
          // _.map(e.response.data.Error.errors, (val, key) => {
          //   errors[key] = val.message;
          // });
          // this.setState({ errors });
        } else {
          swal(e.response.data.Error, {
            buttons: false,
            timer: 2000,
            icon: "error",
          });
        }
      });
  };
  updateCurrentOrder = (mode='') => {
    // if(!this.state.enableSubmit && mode === 'final'){return};
    let { user, loginUser,getGstDetails,getCommissionRate,milestone, ...rest } = this.state;
    console.log(rest, "hire_talent");
    // if (rest?.depositeType === 'whole') {
    //   const restState = rest;
    //   restState.wholePayment[0]['paymentTransactionId']=this.paymentDatails.transactionId;
    //   restState.wholePayment[0]['amount']= this.paymentDatails?.maxFixedPay || 0;
    //   restState.wholePayment[0]['dueDate']= this.paymentDatails?.dueDate ;
    //   restState.wholePayment[0]['paymentDescription']= this.paymentDatails?.paymentDescription ;

    // }
    const newMilestones = milestone.filter((e) => e.milestoneAmount >=0);
    updateOrder({...rest,milestone:newMilestones, employerId:loginUser._id,employeeId: user._id, isActive: mode === 'final' ? true : false})
      .then((res) => {
        console.log("res", res);
        if(mode === 'final'){
          // generateInvoice({...this.paymentDatails,transactionId:res.data.data.transactionId}).then(e =>{}).catch(e =>{})
          swal("Successful", { buttons: false, timer: 2000, icon: "success" });
          setTimeout(() => {
            // this.props.history.push("/FindTalent");
          }, 2000);
        }
      })
      .catch((e) => {
        if (e?.response?.data?.Error?.errors) {
          // let errors = {};
          // _.map(e.response.data.Error.errors, (val, key) => {
          //   errors[key] = val.message;
          // });
          // this.setState({ errors });
        } else {
          swal(e?.response?.data?.Error, {
            buttons: false,
            timer: 2000,
            icon: "error",
          });
        }
      });
  };
  
  nextStep = (stepNumber) => {
    if (!this.validateForm()) {
      return;
    }
    if (stepNumber < 3) {
      if (this.state._id) {
        this.updateCurrentOrder()
      }else{
        this.onSubmit('save')
      }
      this.setState({ step: this.state.step + 1 });
    }
  };
  prevStep = (stepNumber) => {
    if (stepNumber > 1) {
      this.setState({ step: this.state.step - 1 });
    }
  };
  validateForm = () => {
    if (this.state.step === 1) {
      const { hiringTeam, contractTitle, jobCategory } = this.state;
      this.setState({
        errors: {
          hiringTeam: hiringTeam || "",
          contractTitle: contractTitle || "",
          jobCategory: jobCategory || "",
        },
      });
      if (hiringTeam && contractTitle && jobCategory) return true;
      else return false;
    }else{return true}
//     if(this.state.step === 2){
//       const { milestone } = this.state;
//       milestone.forEach(el =>{
// if (el.milestoneDescription && el.dueDate && el.milestoneAmount) {
//   return true
// } else{
//   return false
// }
//       })
//     }
  };
  onPaymentDone =(paymentRes)=>{

    if(this.state.depositeType === 'milestoneWise'){
      const milestones = this.state.milestone;
      milestones[0].status= 'In Progress';
      milestones[0].isPaymentDone= true;
      milestones[0].paymentTransactionId = paymentRes.transactionId;
      this.setState({milestone: milestones})
    }else{
      const wholePayments = this.state.wholePayment;
      wholePayments[0].status= 'In Progress';
      wholePayments[0].isPaymentDone= true;
      wholePayments[0].paymentTransactionId = paymentRes?.transactionId;
      wholePayments[0].paymentDescription = this.state.paymentDescription;
      this.setState({wholePayments: wholePayments})
    }
    if(paymentRes?.transactionId){
      this.updateCurrentOrder('final')
    }
  //   const body={
  //     amount: paymentRes.amount,
  //     contractId: paymentRes.contractId,
  //     payee: paymentRes.payee,
  //     payer: paymentRes.payer,
  //     transactionId: paymentRes.transactionId
  // };
  // this.paymentDatails=body;
    // generateInvoice(this.paymentDatails).then(e =>{}).catch(e =>{})
    this.setState({paymentStatus: 'done',enableSubmit:true});
    toast('Thannks for making payment. Transaction ref. Id is -'+paymentRes?.transactionId, { isLoading: true, icon: 'success' })
  }
  handleChangeObjectValue =(e)=>{
    const data = _.cloneDeep(this.state);
    data[e.key][e.name] = e.value;
    this.setState({ ...data });
  }
  render() {
    return (
      <>
       <ToastContainer />
       <HireTalentPage
         {...this.state}
         nextStep={this.nextStep}
         prevStep={this.prevStep}
         handleChangeSingle={this.handleChangeSingle}
         handleChangeNestedValue={this.handleChangeNestedValue}
         fileSelectedHandler={this.fileSelectedHandler}
         removeFields={this.removeFields}
         addMoreFields={this.addMoreFields}
         onSubmit={this.onSubmit}
         onPaymentDone={this.onPaymentDone}
         updateCurrentOrder={this.updateCurrentOrder}
         handleChangeObjectValue={this.handleChangeObjectValue}
       />
      </>
    );
  }
}
export default HireTalentContainer;
