import React from 'react';
import { useTranslation } from "react-i18next";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import _ from "lodash";
import { Genre } from "./jobPostConst";
import { profession } from "../../components/CreateUserProfile/registerConst";
import { Placeholder } from '../../commons/ReactSelectNoOptionsMessage';

const TitleAndCategory = ({
    handleChangeDropDown,
    handleChangeSingle,
    nextStep,
    PostJobSubmit,
    ...props
}) => {
    const { t } = useTranslation();
    return (
        <div >
            <div className="tab_head">
                <h4>Title and Category</h4>
                <hr />
            </div>
            <div className="row">
            <div className="form-group col-md-6 col-sm-12">
                    <label>*Project Title</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Lead Actor, Director,  Cameraman, etc. "
                        name="title"
                        value={props.title}
                        onChange={handleChangeSingle}
                    />
                     {props?.errors?.title === '' ? <span className="text-danger" style={{ fontSize: 12 }}>title is required</span> : null}
                     <br />
                </div>
               
                <div className="form-group col-md-6 col-sm-12">
                    <label>*Job Category
                <small style={{ fontSize: 10, color: "green", marginLeft: 12 }}>
                            (You can add if not found)
                </small>
                    </label>
                    <CreatableSelect
                        components={{ Placeholder }}
                        placeholder={'custom placeholder component'}
                        options={profession}
                        value={{ value: props.category, label: props.category }}
                        onChange={(e) =>
                            handleChangeDropDown({ name: "category", value: e.label })
                        }
                    />
                    {props?.errors?.category === '' ? <span className="text-danger" style={{ fontSize: 12 }}>Job category is required</span> : null}
                    <br />
                </div>
                
              </div>
              <div className='row'> 
    
                <div className="form-group col-md-6 col-sm-12">
                    <label>Job Title</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder=" Enter Job Title"
                        name="jobTitle"
                        value={props.jobTitle}
                        onChange={handleChangeSingle}
                    />
                    {props?.errors?.jobTitle === '' ? <span className="text-danger" style={{ fontSize: 12 }}>Job title is required</span> : null}
                    <br />
                </div>
                <div className="form-group col-md-6 col-sm-12">
                    <label>Production House</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        name="productionHouse"
                        value={props.productionHouse}
                        onChange={handleChangeSingle}
                    />
                </div>
                </div>
                <div className='row'>
                <div className="col-md-12 col-sm-12">
                    <hr />
                    <br />
                    <button className="blue_btn pull-right" onClick={() => nextStep()} >Continue</button>

                    <button
                        type="submit"
                        className="stroke_btn"
                        onClick={(e) => PostJobSubmit(e, false)}
                    >
                        {" "}
                        Save{" "}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default TitleAndCategory