import React, { useState } from 'react';
import { FaAngleRight, FaBars, FaToggleOn } from 'react-icons/fa';
import { workExp, workExpMax, } from "../CreateUserProfile/registerConst";
import Select from "react-select";
import _ from 'lodash';
import { profession, filmType, filmIndustry, jobNature, roleOption, payout, gender, audition } from '../CreateUserProfile/registerConst';

const SearchAuditionFilter = ({ onChangeFilter, filters,showFilter }) => {
    


let maxAgeValue ;
let minAgeValue ;


filters.forEach(filter => {
    if (filter.type === "maxAge") {
        maxAgeValue = parseInt(filter.value); 
    } else if (filter.type === "minAge") {
        minAgeValue = parseInt(filter.value); 
    }
});

let maxExperienceValue ;
let minExperienceValue ;

filters.forEach(filter => {
    if (filter.type === "maxExperience") {
        maxExperienceValue = parseInt(filter.value); 
    } else if (filter.type === "minExperience") {
        minExperienceValue = parseInt(filter.value); 
    }
});

    return (
        <>
        
        <div id="filter-sidebar" className={`col-lg-3 col-md-4 col-sm-4 col-xs-6 sliding-sidebar ${showFilter ? 'px-0 visible-sm visible-md visible-lg collapse' : ''}`}>
            <div className="list_bg clearfix">
                <h3>Filter By</h3>
                {/* <div className="filter_points">
                    <h6 data-toggle="collapse" data-target="#group-0">
                        <FaAngleRight />
                        Job Category</h6>
                    <div id="group-0" className="list-group collapse">
                        {
                            _.map(profession, val => (
                                <p className="list-group-item">
                                    <label className="check_bx">
                                        <input type="checkbox"
                                            name="field"
                                            checked={_.find(filters, { type: 'professionType', value: val.label })}
                                            onChange={(e) => onChangeFilter({ type: 'professionType', value: val.label })} />
                                        {val.label}
                                        <span className="checkmark" />
                                    </label>
                                </p>
                            ))
                        }
                    </div>
                </div> */}
                <div className="filter_points">
                    <h6 data-toggle="collapse" data-target="#group-1">
                        <FaAngleRight />
                        Age
                </h6>
                    <div id="group-1" className="list-group collapse in">
                        <div className="list-group-item no_padding row">
                            <div className="col-xs-6">
                                <input type="text"
                                    className="form-control"
                                    placeholder="Min"
                                    name="minAge"

                                    onChange={e => onChangeFilter({ type: 'minAge', value: e.target.value })}
                                />
                            </div>
                            <div className="col-xs-6">
                                <input type="text"
                                    className="form-control"
                                    placeholder="Max"
                                    name="maxAge"

                                    onChange={e => onChangeFilter({ type: 'maxAge', value: e.target.value })}
                                />
                            </div>
                            
                            {maxAgeValue < minAgeValue && <p style={{ color: 'red',fontSize:'12px',marginLeft:"30px" }}>MaxAge is not less than MinAge</p>}

                        </div>
                    </div>
                </div>
                <div className="filter_points">
                    <h6 data-toggle="collapse" data-target="#group-2">
                        <FaAngleRight />
                        Location
                </h6>
                    <div id="group-2" className="list-group collapse in">
                        <p className="list-group-item full_field"><input onChange={e => onChangeFilter({ type: 'location', value: e.target.value })} type="search" placeholder="Search client location" className="form-control" /> </p>
                    </div>
                </div>
                {/*<div className="filter_points">
                <h6 data-toggle="collapse" data-target="#group-1" aria-expanded="true">
                    <FaAngleRight />
                Applicant needed
            </h6>
                <div id="group-1" className="list-group collapse in">
                    <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" checked={_.find(filters, { type: 'talentCount', value: [0,1] })} onChange={(e) => onChangeFilter({ type: 'talentCount', value: [0,1] })} />1  <span className="checkmark" /></label></p>
                    <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" checked={_.find(filters, { type: 'talentCount', value: [2,5] })} onChange={(e) => onChangeFilter({ type: 'talentCount', value: [2,5] })} />2 to 5  <span className="checkmark" /></label></p>
                    <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" checked={_.find(filters, { type: 'talentCount', value: [5,100000] })} onChange={(e) => onChangeFilter({ type: 'talentCount', value: [5,100000] })} />More than 5  <span className="checkmark" /></label></p>

                </div>
    </div> */}
                <div className="filter_points">
                    <h6 data-toggle="collapse" data-target="#group-3" aria-expanded="true">
                        <FaAngleRight />
                        Audition Type
              </h6>
                    <div id="group-3" className="list-group collapse in">
                        {
                            _.map(audition, val => (

                                <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" checked={_.find(filters, { type: 'auditionType', value: val.label })} onChange={(e) => onChangeFilter({ type: 'auditionType', value: val.label })} />{val.label}<span className="checkmark" /></label></p>
                            ))
                        }
                    </div>
                </div>
                <div className="filter_points">
                    <h6 data-toggle="collapse" data-target="#group-5" aria-expanded="true">
                        <FaAngleRight />
                        Actor
              </h6>
                    <div id="group-5" className="list-group collapse in">
                        {
                            _.map(gender, val => (

                                <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" checked={_.find(filters, { type: 'actorType', value: val.label })} onChange={(e) => onChangeFilter({ type: 'actorType', value: val.label })} />{val.label}<span className="checkmark" /></label></p>
                            ))
                        }
                    </div>
                </div>
                <div className="filter_points">
                    <h6 data-toggle="collapse" data-target="#group-4"> <FaAngleRight />  Experience  </h6>
                    <div id="group-4" className="list-group collapse in">
                        <div className="list-group-item no_padding row">
                            <div className="col-md-12">
                                <label>Min</label>
                                <Select
                                    options={workExp}

                                    onChange={e => onChangeFilter({ type: 'minExperience', value: e.label })}
                                />
                            </div>
                            <div className="col-md-12">
                                <label>Max</label>
                                <Select
                                    options={workExpMax}

                                    onChange={e => onChangeFilter({ type: 'maxExperience', value: e.label })}
                                />
                            </div>

                            {maxExperienceValue < minExperienceValue && <p style={{ color: 'red',fontSize:'12px',marginLeft:"30px" }}>MaxExperience is not less than MinExperience</p>}

                        </div>
                    </div>
                </div>


                <div className="filter_points">
                    <h6 data-toggle="collapse" data-target="#group-7">
                        <FaAngleRight />
                        Budget
                </h6>
                    <div id="group-7" className="list-group collapse">
                        <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" checked={_.find(filters, { type: 'totalBudget', value: 'Less than Rs 5000 ' })} onChange={(e) => onChangeFilter({ type: 'totalBudget', value: [0, 5000] })} /> Less than Rs 5000  <span className="checkmark" /></label></p>
                        <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" checked={_.find(filters, { type: 'totalBudget', value: 'Rs 5k to Rs 20k' })} onChange={(e) => onChangeFilter({ type: 'totalBudget', value: [5000, 20000] })} /> Rs 5k to Rs 20k  <span className="checkmark" /></label></p>
                        <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" checked={_.find(filters, { type: 'totalBudget', value: 'Rs 20k to Rs 35k' })} onChange={(e) => onChangeFilter({ type: 'totalBudget', value: [20000, 35000] })} /> Rs 20k to Rs 35k <span className="checkmark" /></label></p>
                        <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" checked={_.find(filters, { type: 'totalBudget', value: 'Rs 35k to Rs 50k' })} onChange={(e) => onChangeFilter({ type: 'totalBudget', value: [35000, 50000] })} /> Rs 35k to Rs 50k  <span className="checkmark" /></label></p>
                        <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" checked={_.find(filters, { type: 'totalBudget', value: 'Rs 50k+' })} onChange={(e) => onChangeFilter({ type: 'totalBudget', value: [35000, 10000000000000] })} /> Rs 50k+ <span className="checkmark" /></label></p>
                        {/* <p className="list-group-item half_fields"><input type="text" placeholder="Min" /> to <input type="text" placeholder="Max" /> <input type="button" className="stroke_btn" defaultValue="Go" /></p> */}
                    </div>
                </div>
                <div className="filter_points">
                    <h6 data-toggle="collapse" data-target="#group-8">
                        <FaAngleRight />
                        Role Type
                </h6>
                    <div id="group-8" className="list-group collapse">
                        {
                            _.map(roleOption, val => (
                                <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" checked={_.find(filters, { type: 'roleType', value: val.label })} onChange={(e) => onChangeFilter({ type: 'roleType', value: val.label })} />  {val.label}  <span className="checkmark" /></label></p>
                            ))
                        }

                    </div>
                </div>

                {/* <div className="filter_points">
                    <h6 data-toggle="collapse" data-target="#group-9">
                        <FaAngleRight />
                    Film / Production Type
                </h6>
                    <div id="group-9" className="list-group collapse">
                        {
                            _.map(filmType, val => (
                                <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" checked={_.find(filters, { type: 'filmType', value: val.label })} onChange={(e) => onChangeFilter({ type: 'filmType', value: val.label })} /> {val.label}  <span className="checkmark" /></label></p>
                            ))
                        }


                    </div>
                    </div> */}
                <div className="filter_points">
                    <h6 data-toggle="collapse" data-target="#group-10">
                        <FaAngleRight />
                        Film Industry
                </h6>
                    <div id="group-10" className="list-group collapse">
                        {
                            _.map(filmIndustry, val => (
                                <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" checked={_.find(filters, { type: 'filmIndustry', value: val.label })} onChange={(e) => onChangeFilter({ type: 'filmIndustry', value: val.label })} /> {val.label}  <span className="checkmark" /></label></p>
                            ))
                        }
                    </div>
                </div>


            </div>
        </div>
        </>
    )
}
export default SearchAuditionFilter;
