import React from "react";
import { Link } from 'react-router-dom';

const MakeFilmHeader = ({ onsendMultipleInvitation }) => (

    <div className="make_film_header">
        <div className="row">
            <div className="col-sm-7 inner_head">
                <div className="top_left_info clearfix">
                    <h2>Make My Team / Film </h2>
                </div>
            </div>
            <div className="col-sm-5 head_right_part text-right">
                <Link to="/ProjectHistory" className="stroke_btn">Previous Team</Link>
                <a className="blue_btn" style={{ cursor: 'pointer' }} onClick={onsendMultipleInvitation} >Send Invitations to All</a>
            </div>
        </div>
    </div>
);

export default MakeFilmHeader;