/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

const NewsFeed = () => {
  return (
    <div id="wrapper">
        {/* <header className="tech-header header">
            <div className="container-fluid">
                <nav className="navbar navbar-toggleable-md navbar-inverse fixed-top bg-inverse">
                    <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <a className="navbar-brand" href="tech-index.html"><img src="images/version/tech-logo.png" alt=""/></a>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item">
                                <a className="nav-link" href="tech-index.html">Home</a>
                            </li>
                            <li className="nav-item dropdown has-submenu menu-large hidden-md-down hidden-sm-down hidden-xs-down">
                                <a className="nav-link dropdown-toggle" href="#" id="dropdown01" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">News</a>
                                <ul className="dropdown-menu megamenu" aria-labelledby="dropdown01">
                                    <li>
                                        <div className="container">
                                            <div className="mega-menu-content clearfix">
                                                <div className="tab">
                                                    <button className="tablinks active" onclick="openCategory(event, 'cat01')">Science</button>
                                                    <button className="tablinks" onclick="openCategory(event, 'cat02')">Technology</button>
                                                    <button className="tablinks" onclick="openCategory(event, 'cat03')">Social Media</button>
                                                    <button className="tablinks" onclick="openCategory(event, 'cat04')">Car News</button>
                                                    <button className="tablinks" onclick="openCategory(event, 'cat05')">Worldwide</button>
                                                </div>

                                                <div className="tab-details clearfix">
                                                    <div id="cat01" className="tabcontent active">
                                                        <div className="row">
                                                            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                                                                <div className="blog-box">
                                                                    <div className="post-media">
                                                                        <a href="tech-single.html" title="">
                                                                            <img src="upload/tech_menu_01.jpg" alt="" className="img-fluid"/>
                                                                            <div className="hovereffect">
                                                                            </div>
                                                                            <span className="menucat">Science</span>
                                                                        </a>
                                                                    </div>
                                                                    <div className="blog-meta">
                                                                        <h4><a href="tech-single.html" title="">Top 10+ care advice for your toenails</a></h4>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                                                                <div className="blog-box">
                                                                    <div className="post-media">
                                                                        <a href="tech-single.html" title="">
                                                                            <img src="upload/tech_menu_02.jpg" alt="" className="img-fluid"/>
                                                                            <div className="hovereffect">
                                                                            </div>
                                                                            <span className="menucat">Science</span>
                                                                        </a>
                                                                    </div>
                                                                    <div className="blog-meta">
                                                                        <h4><a href="tech-single.html" title="">The secret of your beauty is handmade soap</a></h4>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                                                                <div className="blog-box">
                                                                    <div className="post-media">
                                                                        <a href="tech-single.html" title="">
                                                                            <img src="upload/tech_menu_03.jpg" alt="" className="img-fluid"/>
                                                                            <div className="hovereffect">
                                                                            </div>
                                                                            <span className="menucat">Science</span>
                                                                        </a>
                                                                    </div>
                                                                    <div className="blog-meta">
                                                                        <h4><a href="tech-single.html" title="">Benefits of face mask made from mud</a></h4>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                                                                <div className="blog-box">
                                                                    <div className="post-media">
                                                                        <a href="tech-single.html" title="">
                                                                            <img src="upload/tech_menu_04.jpg" alt="" className="img-fluid"/>
                                                                            <div className="hovereffect">
                                                                            </div>
                                                                            <span className="menucat">Science</span>
                                                                        </a>
                                                                    </div>
                                                                    <div className="blog-meta">
                                                                        <h4><a href="tech-single.html" title="">Relax with the unique warmth of candle flavor</a></h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="cat02" className="tabcontent">
                                                        <div className="row">
                                                            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                                                                <div className="blog-box">
                                                                    <div className="post-media">
                                                                        <a href="tech-single.html" title="">
                                                                            <img src="upload/tech_menu_05.jpg" alt="" className="img-fluid"/>
                                                                            <div className="hovereffect">
                                                                            </div>
                                                                            <span className="menucat">Technology</span>
                                                                        </a>
                                                                    </div>
                                                                    <div className="blog-meta">
                                                                        <h4><a href="tech-single.html" title="">2017 summer stamp will have design models here</a></h4>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                                                                <div className="blog-box">
                                                                    <div className="post-media">
                                                                        <a href="tech-single.html" title="">
                                                                            <img src="upload/tech_menu_06.jpg" alt="" className="img-fluid"/>
                                                                            <div className="hovereffect">
                                                                            </div>
                                                                            <span className="menucat">Technology</span>
                                                                        </a>
                                                                    </div>
                                                                    <div className="blog-meta">
                                                                        <h4><a href="tech-single.html" title="">Which color is the most suitable color for you?</a></h4>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                                                                <div className="blog-box">
                                                                    <div className="post-media">
                                                                        <a href="tech-single.html" title="">
                                                                            <img src="upload/tech_menu_07.jpg" alt="" className="img-fluid"/>
                                                                            <div className="hovereffect">
                                                                            </div>
                                                                            <span className="menucat">Technology</span>
                                                                        </a>
                                                                    </div>
                                                                    <div className="blog-meta">
                                                                        <h4><a href="tech-single.html" title="">Subscribe to these sites to keep an eye on the fashion</a></h4>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                                                                <div className="blog-box">
                                                                    <div className="post-media">
                                                                        <a href="tech-single.html" title="">
                                                                            <img src="upload/tech_menu_08.jpg" alt="" className="img-fluid"/>
                                                                            <div className="hovereffect">
                                                                            </div>
                                                                            <span className="menucat">Technology</span>
                                                                        </a>
                                                                    </div>
                                                                    <div className="blog-meta">
                                                                        <h4><a href="tech-single.html" title="">The most trends of this year with color combination</a></h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="cat03" className="tabcontent">
                                                        <div className="row">
                                                            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                                                                <div className="blog-box">
                                                                    <div className="post-media">
                                                                        <a href="tech-single.html" title="">
                                                                            <img src="upload/tech_menu_09.jpg" alt="" className="img-fluid"/>
                                                                            <div className="hovereffect">
                                                                            </div>
                                                                            <span className="menucat">Social Media</span>
                                                                        </a>
                                                                    </div>
                                                                    <div className="blog-meta">
                                                                        <h4><a href="tech-single.html" title="">I visited the architects of Istanbul for you</a></h4>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                                                                <div className="blog-box">
                                                                    <div className="post-media">
                                                                        <a href="tech-single.html" title="">
                                                                            <img src="upload/tech_menu_10.jpg" alt="" className="img-fluid"/>
                                                                            <div className="hovereffect">
                                                                            </div>
                                                                            <span className="menucat">Social Media</span>
                                                                        </a>
                                                                    </div>
                                                                    <div className="blog-meta">
                                                                        <h4><a href="tech-single.html" title="">Prepared handmade dish dish in the Netherlands</a></h4>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                                                                <div className="blog-box">
                                                                    <div className="post-media">
                                                                        <a href="tech-single.html" title="">
                                                                            <img src="upload/tech_menu_11.jpg" alt="" className="img-fluid"/>
                                                                            <div className="hovereffect">
                                                                            </div>
                                                                            <span className="menucat">Social Media</span>
                                                                        </a>
                                                                    </div>
                                                                    <div className="blog-meta">
                                                                        <h4><a href="tech-single.html" title="">I recommend you visit the fairy chimneys</a></h4>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                                                                <div className="blog-box">
                                                                    <div className="post-media">
                                                                        <a href="tech-single.html" title="">
                                                                            <img src="upload/tech_menu_12.jpg" alt="" className="img-fluid"/>
                                                                            <div className="hovereffect">
                                                                            </div>
                                                                            <span className="menucat">Social Media</span>
                                                                        </a>
                                                                    </div>
                                                                    <div className="blog-meta">
                                                                        <h4><a href="tech-single.html" title="">One of the most beautiful ports in the world</a></h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="cat04" className="tabcontent">
                                                        <div className="row">
                                                            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                                                                <div className="blog-box">
                                                                    <div className="post-media">
                                                                        <a href="tech-single.html" title="">
                                                                            <img src="upload/tech_menu_13.jpg" alt="" className="img-fluid"/>
                                                                            <div className="hovereffect">
                                                                            </div>
                                                                            <span className="menucat">Car News</span>
                                                                        </a>
                                                                    </div>
                                                                    <div className="blog-meta">
                                                                        <h4><a href="tech-single.html" title="">A collection of the most beautiful shop designs</a></h4>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                                                                <div className="blog-box">
                                                                    <div className="post-media">
                                                                        <a href="tech-single.html" title="">
                                                                            <img src="upload/tech_menu_14.jpg" alt="" className="img-fluid"/>
                                                                            <div className="hovereffect">
                                                                            </div>
                                                                            <span className="menucat">Car News</span>
                                                                        </a>
                                                                    </div>
                                                                    <div className="blog-meta">
                                                                        <h4><a href="tech-single.html" title="">America's and Canada's most beautiful wine houses</a></h4>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                                                                <div className="blog-box">
                                                                    <div className="post-media">
                                                                        <a href="tech-single.html" title="">
                                                                            <img src="upload/tech_menu_15.jpg" alt="" className="img-fluid"/>
                                                                            <div className="hovereffect">
                                                                            </div>
                                                                            <span className="menucat">Car News</span>
                                                                        </a>
                                                                    </div>
                                                                    <div className="blog-meta">
                                                                        <h4><a href="tech-single.html" title="">The most professional ways to color your walls</a></h4>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                                                                <div className="blog-box">
                                                                    <div className="post-media">
                                                                        <a href="tech-single.html" title="">
                                                                            <img src="upload/tech_menu_16.jpg" alt="" className="img-fluid"/>
                                                                            <div className="hovereffect">
                                                                            </div>
                                                                            <span className="menucat">Car News</span>
                                                                        </a>
                                                                    </div>
                                                                    <div className="blog-meta">
                                                                        <h4><a href="tech-single.html" title="">Stylish cabinet designs and furnitures</a></h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="cat05" className="tabcontent">
                                                        <div className="row">
                                                            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                                                                <div className="blog-box">
                                                                    <div className="post-media">
                                                                        <a href="tech-single.html" title="">
                                                                            <img src="upload/tech_menu_17.jpg" alt="" className="img-fluid"/>
                                                                            <div className="hovereffect">
                                                                            </div>
                                                                            <span className="menucat">Worldwide</span>
                                                                        </a>
                                                                    </div>
                                                                    <div className="blog-meta">
                                                                        <h4><a href="tech-single.html" title="">Grilled vegetable with fish prepared with fish</a></h4>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                                                                <div className="blog-box">
                                                                    <div className="post-media">
                                                                        <a href="tech-single.html" title="">
                                                                            <img src="upload/tech_menu_18.jpg" alt="" className="img-fluid"/>
                                                                            <div className="hovereffect">
                                                                            </div>
                                                                            <span className="menucat">Worldwide</span>
                                                                        </a>
                                                                    </div>
                                                                    <div className="blog-meta">
                                                                        <h4><a href="tech-single.html" title="">The world's finest and clean meat restaurants</a></h4>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                                                                <div className="blog-box">
                                                                    <div className="post-media">
                                                                        <a href="tech-single.html" title="">
                                                                            <img src="upload/tech_menu_19.jpg" alt="" className="img-fluid"/>
                                                                            <div className="hovereffect">
                                                                            </div>
                                                                            <span className="menucat">Worldwide</span>
                                                                        </a>
                                                                    </div>
                                                                    <div className="blog-meta">
                                                                        <h4><a href="tech-single.html" title="">Fried veal and vegetable dish</a></h4>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                                                                <div className="blog-box">
                                                                    <div className="post-media">
                                                                        <a href="tech-single.html" title="">
                                                                            <img src="upload/tech_menu_20.jpg" alt="" className="img-fluid"/>
                                                                            <div className="hovereffect">
                                                                            </div>
                                                                            <span className="menucat">Worldwide</span>
                                                                        </a>
                                                                    </div>
                                                                    <div className="blog-meta">
                                                                        <h4><a href="tech-single.html" title="">Tasty pasta sauces and recipes</a></h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="tech-category-01.html">Gadgets</a>
                            </li>                   
                            <li className="nav-item">
                                <a className="nav-link" href="tech-category-02.html">Videos</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="tech-category-03.html">Reviews</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="tech-contact.html">Contact Us</a>
                            </li>
                        </ul>
                        <ul className="navbar-nav mr-2">
                            <li className="nav-item">
                                <a className="nav-link" href="#"><i className="fa fa-rss"></i></a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#"><i className="fa fa-android"></i></a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#"><i className="fa fa-apple"></i></a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </header> */}

        <section className="section first-section">
            <div className="container-fluid">
                <div className="masonry-blog clearfix">
                    <div className="first-slot">
                        <div className="masonry-box post-media">
                             <img src="upload/tech_01.jpg" alt="" className="img-fluid"/>
                             <div className="shadoweffect">
                                <div className="shadow-desc">
                                    <div className="blog-meta">
                                        <span className="bg-orange"><a href="tech-category-01.html" title="">Technology</a></span>
                                        <h4><a href="tech-single.html" title="">Say hello to real handmade office furniture! Clean & beautiful design</a></h4>
                                        <small><a href="tech-single.html" title="">24 July, 2017</a></small>
                                        <small><a href="tech-author.html" title="">by Amanda</a></small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="second-slot">
                        <div className="masonry-box post-media">
                             <img src="upload/tech_02.jpg" alt="" className="img-fluid"/>
                             <div className="shadoweffect">
                                <div className="shadow-desc">
                                    <div className="blog-meta">
                                        <span className="bg-orange"><a href="tech-category-01.html" title="">Gadgets</a></span>
                                        <h4><a href="tech-single.html" title="">Do not make mistakes when choosing web hosting</a></h4>
                                        <small><a href="tech-single.html" title="">03 July, 2017</a></small>
                                        <small><a href="tech-author.html" title="">by Jessica</a></small>
                                    </div>
                                </div>
                             </div>
                        </div>
                    </div>

                    <div className="last-slot">
                        <div className="masonry-box post-media">
                             <img src="upload/tech_03.jpg" alt="" className="img-fluid"/>
                             <div className="shadoweffect">
                                <div className="shadow-desc">
                                    <div className="blog-meta">
                                        <span className="bg-orange"><a href="tech-category-01.html" title="">Technology</a></span>
                                        <h4><a href="tech-single.html" title="">The most reliable Galaxy Note 8 images leaked</a></h4>
                                        <small><a href="tech-single.html" title="">01 July, 2017</a></small>
                                        <small><a href="tech-author.html" title="">by Jessica</a></small>
                                    </div>
                                </div>
                             </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12">
                        <div className="page-wrapper">
                            <div className="blog-top clearfix">
                                <h4 className="pull-left">Recent News <a href="#"><i className="fa fa-rss"></i></a></h4>
                            </div>

                            <div className="blog-list clearfix">
                                <div className="blog-box row">
                                    <div className="col-md-4">
                                        <div className="post-media">
                                            <a href="tech-single.html" title="">
                                                <img src="upload/tech_blog_01.jpg" alt="" className="img-fluid"/>
                                                <div className="hovereffect"></div>
                                            </a>
                                        </div>
                                    </div>

                                    <div className="blog-meta big-meta col-md-8">
                                        <h4><a href="tech-single.html" title="">Top 10 phone applications and 2017 mobile design awards</a></h4>
                                        <p>Aenean interdum arcu blandit, vehicula magna non, placerat elit. Mauris et pharetratortor. Suspendissea sodales urna. In at augue elit. Vivamus enim nibh, maximus ac felis nec, maximus tempor odio.</p>
                                        <small className="firstsmall"><a className="bg-orange" href="tech-category-01.html" title="">Gadgets</a></small>
                                        <small><a href="tech-single.html" title="">21 July, 2017</a></small>
                                        <small><a href="tech-author.html" title="">by Matilda</a></small>
                                        <small><a href="tech-single.html" title=""><i className="fa fa-eye"></i> 1114</a></small>
                                    </div>
                                </div>

                                <hr className="invis"/>

                                <div className="blog-box row">
                                    <div className="col-md-4">
                                        <div className="post-media">
                                            <a href="tech-single.html" title="">
                                                <img src="upload/tech_blog_02.jpg" alt="" className="img-fluid"/>
                                                <div className="hovereffect"></div>
                                            </a>
                                        </div>
                                    </div>

                                    <div className="blog-meta big-meta col-md-8">
                                        <h4><a href="tech-single.html" title="">A device you can use both headphones and usb</a></h4>
                                        <p>Aenean interdum arcu blandit, vehicula magna non, placerat elit. Mauris et pharetratortor. Suspendissea sodales urna. In at augue elit. Vivamus enim nibh, maximus ac felis nec, maximus tempor odio.</p>
                                        <small className="firstsmall"><a className="bg-orange" href="tech-category-01.html" title="">Technology</a></small>
                                        <small><a href="tech-single.html" title="">21 July, 2017</a></small>
                                        <small><a href="tech-author.html" title="">by Matilda</a></small>
                                        <small><a href="tech-single.html" title=""><i className="fa fa-eye"></i> 4412</a></small>
                                    </div>
                                </div>

                                <hr className="invis"/>

                                <div className="blog-box row">
                                    <div className="col-md-4">
                                        <div className="post-media">
                                            <a href="tech-single.html" title="">
                                                <img src="upload/tech_blog_03.jpg" alt="" className="img-fluid"/>
                                                <div className="hovereffect"></div>
                                            </a>
                                        </div>
                                    </div>

                                    <div className="blog-meta big-meta col-md-8">
                                        <h4><a href="tech-single.html" title="">Two brand new laptop models from ABC computer</a></h4>
                                        <p>Aenean interdum arcu blandit, vehicula magna non, placerat elit. Mauris et pharetratortor. Suspendissea sodales urna. In at augue elit. Vivamus enim nibh, maximus ac felis nec, maximus tempor odio.</p>
                                        <small className="firstsmall"><a className="bg-orange" href="tech-category-01.html" title="">Development</a></small>
                                        <small><a href="tech-single.html" title="">20 July, 2017</a></small>
                                        <small><a href="tech-author.html" title="">by Matilda</a></small>
                                        <small><a href="tech-single.html" title=""><i className="fa fa-eye"></i> 2313</a></small>
                                    </div>
                                </div>

                                <hr className="invis"/>

                                <div className="row">
                                    <div className="col-lg-10 offset-lg-1">
                                        <div className="banner-spot clearfix">
                                            <div className="banner-img">
                                                <img src="upload/banner_02.jpg" alt="" className="img-fluid"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <hr className="invis"/>

                                <div className="blog-box row">
                                    <div className="col-md-4">
                                        <div className="post-media">
                                            <a href="tech-single.html" title="">
                                                <img src="upload/tech_blog_04.jpg" alt="" className="img-fluid"/>
                                                <div className="hovereffect"></div>
                                            </a>
                                        </div>
                                    </div>

                                    <div className="blog-meta big-meta col-md-8">
                                        <h4><a href="tech-single.html" title="">Applications for taking photos of nature in your mobile phones</a></h4>
                                        <p>Aenean interdum arcu blandit, vehicula magna non, placerat elit. Mauris et pharetratortor. Suspendissea sodales urna. In at augue elit. Vivamus enim nibh, maximus ac felis nec, maximus tempor odio.</p>
                                        <small className="firstsmall"><a className="bg-orange" href="tech-category-01.html" title="">Design</a></small>
                                        <small><a href="tech-single.html" title="">19 July, 2017</a></small>
                                        <small><a href="tech-author.html" title="">by Matilda</a></small>
                                        <small><a href="tech-single.html" title=""><i className="fa fa-eye"></i> 4441</a></small>
                                    </div>
                                </div>

                                <hr className="invis"/>

                                <div className="blog-box row">
                                    <div className="col-md-4">
                                        <div className="post-media">
                                            <a href="tech-single.html" title="">
                                                <img src="upload/tech_blog_05.jpg" alt="" className="img-fluid"/>
                                                <div className="hovereffect"></div>
                                            </a>
                                        </div>
                                    </div>

                                    <div className="blog-meta big-meta col-md-8">
                                        <h4><a href="tech-single.html" title="">Say hello to colored strap models in smart hours</a></h4>
                                        <p>Aenean interdum arcu blandit, vehicula magna non, placerat elit. Mauris et pharetratortor. Suspendissea sodales urna. In at augue elit. Vivamus enim nibh, maximus ac felis nec, maximus tempor odio.</p>
                                        <small className="firstsmall"><a className="bg-orange" href="tech-category-01.html" title="">Materials</a></small>
                                        <small><a href="tech-single.html" title="">18 July, 2017</a></small>
                                        <small><a href="tech-author.html" title="">by Matilda</a></small>
                                        <small><a href="tech-single.html" title=""><i className="fa fa-eye"></i> 33312</a></small>
                                    </div>
                                </div>

                                <hr className="invis"/>

                                <div className="blog-box row">
                                    <div className="col-md-4">
                                        <div className="post-media">
                                            <a href="tech-single.html" title="">
                                                <img src="upload/tech_blog_06.jpg" alt="" className="img-fluid"/>
                                                <div className="hovereffect"></div>
                                            </a>
                                        </div>
                                    </div>

                                    <div className="blog-meta big-meta col-md-8">
                                        <h4><a href="tech-single.html" title="">How about evaluating your old mobile phones in different ways?</a></h4>
                                        <p>Aenean interdum arcu blandit, vehicula magna non, placerat elit. Mauris et pharetratortor. Suspendissea sodales urna. In at augue elit. Vivamus enim nibh, maximus ac felis nec, maximus tempor odio.</p>
                                        <small className="firstsmall"><a className="bg-orange" href="tech-category-01.html" title="">Gadgets</a></small>
                                        <small><a href="tech-single.html" title="">17 July, 2017</a></small>
                                        <small><a href="tech-author.html" title="">by Matilda</a></small>
                                        <small><a href="tech-single.html" title=""><i className="fa fa-eye"></i> 4440</a></small>
                                    </div>
                                </div>

                                <hr className="invis"/>

                                <div className="blog-box row">
                                    <div className="col-md-4">
                                        <div className="post-media">
                                            <a href="tech-single.html" title="">
                                                <img src="upload/tech_blog_07.jpg" alt="" className="img-fluid"/>
                                                <div className="hovereffect"></div>
                                            </a>
                                        </div>
                                    </div>

                                    <div className="blog-meta big-meta col-md-8">
                                        <h4><a href="tech-single.html" title="">Drinking coffee at the computer rests the spirit</a></h4>
                                        <p>Aenean interdum arcu blandit, vehicula magna non, placerat elit. Mauris et pharetratortor. Suspendissea sodales urna. In at augue elit. Vivamus enim nibh, maximus ac felis nec, maximus tempor odio.</p>
                                        <small className="firstsmall"><a className="bg-orange" href="tech-category-01.html" title="">Technology</a></small>
                                        <small><a href="tech-single.html" title="">16 July, 2017</a></small>
                                        <small><a href="tech-author.html" title="">by Matilda</a></small>
                                        <small><a href="tech-single.html" title=""><i className="fa fa-eye"></i> 4412</a></small>
                                    </div>
                                </div>

                                <hr className="invis" />

                                <div className="blog-box row">
                                    <div className="col-md-4">
                                        <div className="post-media">
                                            <a href="tech-single.html" title="">
                                                <img src="upload/tech_blog_08.jpg" alt="" className="img-fluid"/>
                                                <div className="hovereffect"></div>
                                            </a>
                                        </div>
                                    </div>

                                    <div className="blog-meta big-meta col-md-8">
                                        <h4><a href="tech-single.html" title="">If you are considering buying a new safe for your mobile phone, be sure to read this article</a></h4>
                                        <p>Aenean interdum arcu blandit, vehicula magna non, placerat elit. Mauris et pharetratortor. Suspendissea sodales urna. In at augue elit. Vivamus enim nibh, maximus ac felis nec, maximus tempor odio.</p>
                                        <small className="firstsmall"><a className="bg-orange" href="tech-category-01.html" title="">Reviews</a></small>
                                        <small><a href="tech-single.html" title="">15 July, 2017</a></small>
                                        <small><a href="tech-author.html" title="">by Matilda</a></small>
                                        <small><a href="tech-single.html" title=""><i className="fa fa-eye"></i> 44123</a></small>
                                    </div>
                                </div>

                                <hr className="invis" />

                                <div className="blog-box row">
                                    <div className="col-md-4">
                                        <div className="post-media">
                                            <a href="tech-single.html" title="">
                                                <img src="upload/tech_blog_09.jpg" alt="" className="img-fluid" />
                                                <div className="hovereffect"></div>
                                            </a>
                                        </div>
                                    </div>

                                    <div className="blog-meta big-meta col-md-8">
                                        <h4><a href="tech-single.html" title="">Enjoy a summer with a colorful headset</a></h4>
                                        <p>Aenean interdum arcu blandit, vehicula magna non, placerat elit. Mauris et pharetratortor. Suspendissea sodales urna. In at augue elit. Vivamus enim nibh, maximus ac felis nec, maximus tempor odio.</p>
                                        <small className="firstsmall"><a className="bg-orange" href="tech-category-01.html" title="">Technology</a></small>
                                        <small><a href="tech-single.html" title="">14 July, 2017</a></small>
                                        <small><a href="tech-author.html" title="">by Matilda</a></small>
                                        <small><a href="tech-single.html" title=""><i className="fa fa-eye"></i> 2214</a></small>
                                    </div>
                                </div>

                                <hr className="invis" />

                                <div className="blog-box row">
                                    <div className="col-md-4">
                                        <div className="post-media">
                                            <a href="tech-single.html" title="">
                                                <img src="upload/tech_blog_10.jpg" alt="" className="img-fluid" />
                                                <div className="hovereffect"></div>
                                            </a>
                                        </div>
                                    </div>

                                    <div className="blog-meta big-meta col-md-8">
                                        <h4><a href="tech-single.html" title="">Google has developed a brand new algorithm. Forget all your knowledge!</a></h4>
                                        <p>Aenean interdum arcu blandit, vehicula magna non, placerat elit. Mauris et pharetratortor. Suspendissea sodales urna. In at augue elit. Vivamus enim nibh, maximus ac felis nec, maximus tempor odio.</p>
                                        <small className="firstsmall"><a className="bg-orange" href="tech-category-01.html" title="">Gadgets</a></small>
                                        <small><a href="tech-single.html" title="">13 July, 2017</a></small>
                                        <small><a href="tech-author.html" title="">by Matilda</a></small>
                                        <small><a href="tech-single.html" title=""><i className="fa fa-eye"></i> 3331</a></small>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr className="invis"/>

                        <div className="row">
                            <div className="col-md-12">
                                <nav aria-label="Page navigation">
                                    <ul className="pagination justify-content-start">
                                        <li className="page-item"><a className="page-link" href="#">1</a></li>
                                        <li className="page-item"><a className="page-link" href="#">2</a></li>
                                        <li className="page-item"><a className="page-link" href="#">3</a></li>
                                        <li className="page-item">
                                            <a className="page-link" href="#">Next</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                        <div className="sidebar">
                            <div className="widget">
                                <div className="banner-spot clearfix">
                                    <div className="banner-img">
                                        <img src="upload/banner_07.jpg" alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>

                            <div className="widget">
                                <h2 className="widget-title">Trend Videos</h2>
                                <div className="trend-videos">
                                    <div className="blog-box">
                                        <div className="post-media">
                                            <a href="tech-single.html" title="">
                                                <img src="upload/tech_video_01.jpg" alt="" className="img-fluid" />
                                                <div className="hovereffect">
                                                    <span className="videohover"></span>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="blog-meta">
                                            <h4><a href="tech-single.html" title="">We prepared the best 10 laptop presentations for you</a></h4>
                                        </div>
                                    </div>

                                    <hr className="invis" />

                                    <div className="blog-box">
                                        <div className="post-media">
                                            <a href="tech-single.html" title="">
                                                <img src="upload/tech_video_02.jpg" alt="" className="img-fluid" />
                                                <div className="hovereffect">
                                                    <span className="videohover"></span>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="blog-meta">
                                            <h4><a href="tech-single.html" title="">We are guests of ABC Design Studio - Vlog</a></h4>
                                        </div>
                                    </div>

                                    <hr className="invis" />

                                    <div className="blog-box">
                                        <div className="post-media">
                                            <a href="tech-single.html" title="">
                                                <img src="upload/tech_video_03.jpg" alt="" className="img-fluid" />
                                                <div className="hovereffect">
                                                    <span className="videohover"></span>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="blog-meta">
                                            <h4><a href="tech-single.html" title="">Both blood pressure monitor and intelligent clock</a></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="widget">
                                <h2 className="widget-title">Popular Posts</h2>
                                <div className="blog-list-widget">
                                    <div className="list-group">
                                        <a href="tech-single.html" className="list-group-item list-group-item-action flex-column align-items-start">
                                            <div className="w-100 justify-content-between">
                                                <img src="upload/tech_blog_08.jpg" alt="" className="img-fluid float-left" />
                                                <h5 className="mb-1">5 Beautiful buildings you need..</h5>
                                                <small>12 Jan, 2016</small>
                                            </div>
                                        </a>

                                        <a href="tech-single.html" className="list-group-item list-group-item-action flex-column align-items-start">
                                            <div className="w-100 justify-content-between">
                                                <img src="upload/tech_blog_01.jpg" alt="" className="img-fluid float-left" />
                                                <h5 className="mb-1">Let's make an introduction for..</h5>
                                                <small>11 Jan, 2016</small>
                                            </div>
                                        </a>

                                        <a href="tech-single.html" className="list-group-item list-group-item-action flex-column align-items-start">
                                            <div className="w-100 last-item justify-content-between">
                                                <img src="upload/tech_blog_03.jpg" alt="" className="img-fluid float-left" />
                                                <h5 className="mb-1">Did you see the most beautiful..</h5>
                                                <small>07 Jan, 2016</small>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="widget">
                                <h2 className="widget-title">Recent Reviews</h2>
                                <div className="blog-list-widget">
                                    <div className="list-group">
                                        <a href="tech-single.html" className="list-group-item list-group-item-action flex-column align-items-start">
                                            <div className="w-100 justify-content-between">
                                                <img src="upload/tech_blog_02.jpg" alt="" className="img-fluid float-left" />
                                                <h5 className="mb-1">Banana-chip chocolate cake recipe..</h5>
                                                <span className="rating">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </span>
                                            </div>
                                        </a>

                                        <a href="tech-single.html" className="list-group-item list-group-item-action flex-column align-items-start">
                                            <div className="w-100 justify-content-between">
                                                <img src="upload/tech_blog_03.jpg" alt="" className="img-fluid float-left" />
                                                <h5 className="mb-1">10 practical ways to choose organic..</h5>
                                                <span className="rating">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </span>
                                            </div>
                                        </a>

                                        <a href="tech-single.html" className="list-group-item list-group-item-action flex-column align-items-start">
                                            <div className="w-100 last-item justify-content-between">
                                                <img src="upload/tech_blog_07.jpg" alt="" className="img-fluid float-left" />
                                                <h5 className="mb-1">We are making homemade ravioli..</h5>
                                                <span className="rating">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="widget">
                                <h2 className="widget-title">Follow Us</h2>

                                <div className="row text-center">
                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                        <a href="#" className="social-button facebook-button">
                                            <i className="fa fa-facebook"></i>
                                            <p>27k</p>
                                        </a>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                        <a href="#" className="social-button twitter-button">
                                            <i className="fa fa-twitter"></i>
                                            <p>98k</p>
                                        </a>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                        <a href="#" className="social-button google-button">
                                            <i className="fa fa-google-plus"></i>
                                            <p>17k</p>
                                        </a>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                        <a href="#" className="social-button youtube-button">
                                            <i className="fa fa-youtube"></i>
                                            <p>22k</p>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="widget">
                                <div className="banner-spot clearfix">
                                    <div className="banner-img">
                                        <img src="upload/banner_03.jpg" alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {/* <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="widget">
                            <div className="footer-text text-left">
                                <a href="index.html"><img src="images/version/tech-footer-logo.png" alt="" className="img-fluid" /></a>
                                <p>Tech Blog is a technology blog, we sharing marketing, news and gadget articles.</p>
                                <div className="social">
                                    <a href="#" data-toggle="tooltip" data-placement="bottom" title="Facebook"><i className="fa fa-facebook"></i></a>              
                                    <a href="#" data-toggle="tooltip" data-placement="bottom" title="Twitter"><i className="fa fa-twitter"></i></a>
                                    <a href="#" data-toggle="tooltip" data-placement="bottom" title="Instagram"><i className="fa fa-instagram"></i></a>
                                    <a href="#" data-toggle="tooltip" data-placement="bottom" title="Google Plus"><i className="fa fa-google-plus"></i></a>
                                    <a href="#" data-toggle="tooltip" data-placement="bottom" title="Pinterest"><i className="fa fa-pinterest"></i></a>
                                </div>

                                <hr className="invis" />

                                <div className="newsletter-widget text-left">
                                    <form className="form-inline">
                                        <input type="text" className="form-control" placeholder="Enter your email address" />
                                        <button type="submit" className="btns btns-primary">SUBMIT</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                        <div className="widget">
                            <h2 className="widget-title">Popular Categories</h2>
                            <div className="link-widget">
                                <ul>
                                    <li><a href="#">Marketing <span>(21)</span></a></li>
                                    <li><a href="#">SEO Service <span>(15)</span></a></li>
                                    <li><a href="#">Digital Agency <span>(31)</span></a></li>
                                    <li><a href="#">Make Money <span>(22)</span></a></li>
                                    <li><a href="#">Blogging <span>(66)</span></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-2 col-md-12 col-sm-12 col-xs-12">
                        <div className="widget">
                            <h2 className="widget-title">Copyrights</h2>
                            <div className="link-widget">
                                <ul>
                                    <li><a href="#">About us</a></li>
                                    <li><a href="#">Advertising</a></li>
                                    <li><a href="#">Write for us</a></li>
                                    <li><a href="#">Trademark</a></li>
                                    <li><a href="#">License & Help</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 text-center">
                        <br />
                        <div className="copyright">&copy; Tech Blog. Design: <a href="http://html.design">HTML Design</a>.</div>
                    </div>
                </div>
            </div>
        </footer> */}

        {/* <div className="dmtop">Scroll to Top</div> */}
        
    </div>
  )
}

export default NewsFeed