import types from "./types";
const initialState = {
    isDataAvailable: false,
}


function signupReducer(state = initialState, action) {
    switch (action.type) {
        case types.USER_SIGNUP:
            const mData = action.payload;
            return {
                ...state,
                isDataAvailable: true,
                signupData: mData,
            }
        default:
            return state;
    }
}

export default signupReducer;
