import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#555";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#555",
    borderBottomWidth: 1,
    alignItems: "center",
    height: "250px",
    fontStyle: "bold",
    fontSize: '10px',
  },
  leftCell: {
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 5,
    flexDirection: "column",
    paddingTop: 3,
    height: "250px",
    fontSize: "10px",
  },
  rightCell: {
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
    flexDirection: "column",
    paddingTop: 3,
    height: "250px",
    fontSize: '10px',
  },
  amount: {
    width: "15%",
    textAlign: "right",
    paddingRight: 8,
    flexDirection: "column",
    paddingTop: 3,
    height: "250px",
    fontSize: '10px',
  },
  normalFont:{
    fontFamily:'Helvetica',
    fontSize: '8px'
  }
});

const InvoiceTableRow = ({ items, payer, payee }) => {
  const rows = items.map((item, i) => (
    <View style={styles.row} key={i}>
      <View style={{ ...styles.rightCell, width: "5%" }}>
        <Text>{i + 1}</Text>
      </View>
     <View style={{...styles.leftCell ,width: "50%"}}>
     <Text style={{ }}>
        {" "}
        {item.description}
      </Text>
        {/* <View style={{ ...styles.rightCell }}> */}
          {/* <Text style={{opacity:0}}>-</Text> */}
          <Text style={{...styles.normalFont, marginTop: 10}}>IGST ({item.taxRate.igst}%)</Text>
          <Text style={styles.normalFont}>CGST ({item.taxRate.cgst}%)</Text>
          <Text style={styles.normalFont}>SGST ({item.taxRate.sgst}%)</Text>
        {/* </View> */}
     </View>
      <Text style={{ ...styles.rightCell, width: "10%" }}>{item.hsnCode}</Text>
      <Text style={{ ...styles.rightCell, width: "15%" }}>
        {item.netAmount}
      </Text>
      {/* {payer?.state !== payee?.state ? ( */}
      <>
        {/* <View style={{ ...styles.rightCell, width: "15%" }}>
          <Text style={{opacity:0}}>-</Text>
          <Text style={{fontSize:'10px'}}>IGST ({item.taxRate.igst}%)</Text>
          <Text>CGST ({item.taxRate.cgst}%)</Text>
          <Text>SGST ({item.taxRate.sgst}%)</Text>
        </View> */}
        {/* <View style={{ ...styles.rightCell, width: "14%" }}>
          <Text style={{opacity:0}}>-</Text>
          <Text>{(item.netAmount * item.taxRate.igst) / 100}</Text>
          <Text>{(item.netAmount * item.taxRate.cgst) / 100}</Text>
          <Text>{(item.netAmount * item.taxRate.sgst) / 100}</Text>
        </View> */}
        {/* <View style={{ ...styles.rightCell, width: "10%"}}>
            <Text>{"IGST"}</Text>
            <Text>{"CGST"}</Text>
            <Text>{"SGST"}</Text>
          </View> */}
      </>
      {/* ) : (
        <>
          <Text style={{ ...styles.rightCell, width: "10%" }}>
            {item.taxRate.igst}%
          </Text>
          <Text style={{ ...styles.rightCell, width: "10%" }}>
            {(item.netAmount * item.taxRate.igst) / 100}
          </Text>
          <Text style={{ ...styles.rightCell, width: "10%" }}>{"IGST"}</Text>
        </>
      )} */}

      <View style={{ ...styles.amount, width: "20%" }}>
      <Text >
        {(item.netAmount).toFixed(2)}
      </Text>
      <Text style={{...styles.normalFont,marginTop:10}}>{((item.netAmount * item.taxRate.igst) / 100).toFixed(2)}</Text>
          <Text style={styles.normalFont}>{((item.netAmount * item.taxRate.cgst) / 100).toFixed(2)}</Text>
          <Text style={styles.normalFont}>{((item.netAmount * item.taxRate.sgst) / 100).toFixed(2)}</Text>
      </View>
    </View>
  ));
  return <Fragment>{rows}</Fragment>;
};

export default InvoiceTableRow;
