/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { FaUpload, FaAngleLeft, } from 'react-icons/fa';

function RegisterHeader(props) {
  //const classes = useStyles();
  const { t } = useTranslation();
  //const globalStyle = globalStyles();
  const token = localStorage.getItem("accessToken");
  return (
    <div>

      <div className="create_profile_head">
          
      <h4>Register as a Professional</h4>
        <div>
        <button className="stroke_btn" onClick={()=> window.history.back()}>Back</button>
          
        </div>
      </div>

      <div className="stepwizard-row setup-panel clearfix">
        <div className="stepwizard-step ">
          <a
            type="button"
            className={props.step === 1 ? "btn btn-primary" : "btn btn-default"}
            onClick={() => props.changeRegisterStep(1)}
          >
            <span className="btn-circle">1</span>
            <p>Personal Info</p>
          </a>
        </div>
        <div className="stepwizard-step">
          <a
            type="button"
            className={props.step === 2 ? "btn btn-primary" : "btn btn-default"}
            onClick={() => props.changeRegisterStep(2)}
          >
            <span className="btn-circle">2</span>
            <p>Education</p>
          </a>
        </div>
        <div className="stepwizard-step">
          <a
            type="button"
            className={props.step === 3 ? "btn btn-primary" : "btn btn-default"}
            onClick={() => props.changeRegisterStep(3)}
          >
            <span className="btn-circle">3</span>
            <p>Experience</p>
          </a>
        </div>
        <div className="stepwizard-step">
          <a
            type="button"
            className={props.step === 4 ? "btn btn-primary" : "btn btn-default"}
            onClick={() => props.changeRegisterStep(4)}
          >
            <span className="btn-circle">4</span>
            <p>Physical Traits</p>
          </a>
        </div>
        <div className="stepwizard-step">
          <a
            type="button"
            className={props.step === 5 ? "btn btn-primary" : "btn btn-default"}
            onClick={() => props.changeRegisterStep(5)}
          >
            <span className="btn-circle">5</span>
            <p>Award</p>
          </a>
        </div>

        <div className="stepwizard-step">
          <a
            type="button"
            className={props.step === 6 ? "btn btn-primary" : "btn btn-default"}
            onClick={() => props.changeRegisterStep(6)}
          >
            <span className="btn-circle">6</span>
            <p>Invoice Info</p>
          </a>
        </div>
        <div className="stepwizard-step">
          <a
            type="button"
            className={props.step === 7 ? "btn btn-primary" : "btn btn-default"}
            onClick={() => props.changeRegisterStep(7)}
          >
            <span className="btn-circle">7</span>
            <p>Additional Info</p>
          </a>
        </div>

      </div>
    </div>
  );
}
export default RegisterHeader;
