import React from "react";
import NewSignupForm from "./NewSignupForm";
import FormFooter from "../../components/register/FormFooter";

const NewSignupPage = props => <section id="login">
  <img className="login_bg" alt="img" src="images/cinema.jpg" />
  <div className="container">
    <div className="row">
      <div className="col-md-6 login_form pull-right">
        <div className="bg_box clearfix">
          <NewSignupForm  {...props} />
          <FormFooter {...props} />
        </div>
      </div>
    </div>
  </div>
</section>


export default NewSignupPage;
