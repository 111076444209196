import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { city, gender, Lang, profession } from "./registerConst";
import { FaUpload } from "react-icons/fa";
import Select from "react-select";
import _ from "lodash";
import { vlidateNumberField } from "../../Utilities/validateInputField";
// import AsyncSelect from "react-select/async";
// import { loadOptionsResolveLanguage } from "../../Constants.js/languageConst";
// import cityStateConst from "./cityStateConst";
// import { countries } from './cityStateConst';
// import NoOptionsMessage from '../../commons/ReactSelectNoOptionsMessage';
// import moment from 'moment'
// import { config } from "../../actions/config";
// import currency from "./currency";
import axios from "axios";

// let StateOptions = _.map(cityStateConst, (val) => ({
//   label: val.state,
//   value: val.state,
// }));

function InvoiceInfo({
  handleChangeDropDown,
  handleChange,
  nextStep,
  errors,
  submitForm,
  defaultProps,
  handleChangeNestedValue,
  invoiceInfo,
  ...props
}) {
 
  const [countries, setCountries] = useState([])
  const [StateOptions, setStates] = useState([]);
  const [districtOptions, setCity] = useState([]);

  useEffect(() => {
    const headers ={
      "Accept": "application/json",
      "api-token": "pnQxi9SC9cQHgO4vfLHdvfU6SvZNRHTe-0VyoOn9CbIXPtQQ-mvXd9P7rFIea4K3DQU",
      "user-email": "rupeshv@tokyotechie.com"
    };
    axios.get('https://www.universal-tutorial.com/api/getaccesstoken',{headers}).then(res =>{
      axios.get('https://www.universal-tutorial.com/api/countries',{headers:{Authorization: "Bearer "+res.data.auth_token,Accept:'application/json'}}).then(contriesRes =>{
        let countries = _.map(contriesRes.data, (val) => ({
          label: val.country_name,
          value: val.country_name,
        }))
        setCountries(countries);
    })
  })
  }, [])

  useEffect(() => {
    if (invoiceInfo?.country) {
      const headers ={
        "Accept": "application/json",
        "api-token": "pnQxi9SC9cQHgO4vfLHdvfU6SvZNRHTe-0VyoOn9CbIXPtQQ-mvXd9P7rFIea4K3DQU",
        "user-email": "rupeshv@tokyotechie.com"
      };
      axios.get('https://www.universal-tutorial.com/api/getaccesstoken',{headers}).then(res =>{
        axios.get('https://www.universal-tutorial.com/api/states/'+invoiceInfo?.country,{headers:{Authorization: "Bearer "+res.data.auth_token,Accept:'application/json'}}).then(stateRes =>{
         let stateOptions = _.map(stateRes.data, (val) => ({
            label: val.state_name,
            value: val.state_name,
          }))
          setStates(stateOptions);
      })
    })
    }
  }, [invoiceInfo?.country])
  useEffect(() => {
    if (invoiceInfo?.state) {
      const headers ={
        "Accept": "application/json",
        "api-token": "pnQxi9SC9cQHgO4vfLHdvfU6SvZNRHTe-0VyoOn9CbIXPtQQ-mvXd9P7rFIea4K3DQU",
        "user-email": "rupeshv@tokyotechie.com"
      };
      axios.get('https://www.universal-tutorial.com/api/getaccesstoken',{headers}).then(res =>{
        axios.get('https://www.universal-tutorial.com/api/cities/'+invoiceInfo?.state,{headers:{Authorization: "Bearer "+res.data.auth_token,Accept:'application/json'}}).then(cityRes =>{
         let cityOptions = _.map(cityRes.data, (val) => ({
            label: val.city_name,
            value: val.city_name,
          }))
          setCity(cityOptions);
      })
    })
    }
  }, [invoiceInfo?.state])

  
  

  const { t } = useTranslation();

  // const districtArray = _.find(cityStateConst, {
  //   state: invoiceInfo?.state,
  // })?.districts;

  // const districtOptions = _.map(districtArray, (val) => ({
  //   label: val,
  //   value: val,
  // }));
  // const currencyOptions = _.map(currency, (val) => ({
  //   label: val.currency,
  //   value: val.currency,
  // }));
  return (
    <>
      <div className="setup-content row" id="step-1">
    
        <div className="form-group col-md-12">
          <label className="control-label">Address for invoice</label>
          <span className=" tooltp" >
            <span className="field-info">
            <img height={10} src={process.env.PUBLIC_URL+'icon/question-mark.svg'} alt="" />
            </span>
          <span class="tooltiptext">This Address will appear on Invoice</span>
          </span>
          <textarea
            placeholder="Enter Address"
            name="address"
            className="form-control"
            value={invoiceInfo?.address}
            onChange={(e) => {
              handleChangeNestedValue({
                name: "address",
                value: e.target.value,
                key: "invoiceInfo",
              });
            }}
          />
        </div>

        <div className="form-group col-md-4">
          <label className="control-label">{t("*Country")}</label>

          <Select
            options={countries}
            isClearable
            value={{ label: invoiceInfo?.country, value: invoiceInfo?.country }}
            // onChange={(e) =>
            //   handleChangeDropDown({ name: "country", value: e ?.value })
            // }
            onChange={(e) => {
              handleChangeNestedValue({
                name: "country",
                value: e?.value,
                key: "invoiceInfo",
              });
            }}
          />

          <small>{props.countryError}</small>

        </div>


        <div className="form-group col-md-4">
          <label className="control-label">{t("*State")}</label>
          <Select
            isClearable
            options={StateOptions}
            value={{ label: invoiceInfo?.state, value: invoiceInfo?.state }}
            onChange={(e) => {
              handleChangeNestedValue({
                name: "state",
                value: e?.value,
                key: "invoiceInfo",
              });
            }}
          />
        </div>
        <div className="form-group col-md-4">

          <label className="control-label">{t("*City")}</label>
          <Select
            isClearable
            options={districtOptions}
            value={{ label: invoiceInfo?.city, value: invoiceInfo?.city }}
            onChange={(e) => {
              handleChangeNestedValue({
                name: "city",
                value: e?.value,
                key: "invoiceInfo",
              });
            }}
          />
        </div>
       
       <div className="col-sm-12 tax-details-container">
       <div className="form-group col-sm-4">
          <label className="control-label">
           PAN
          </label>
          <span className=" tooltp" >
            <span className="field-info">
            <img height={10} src={process.env.PUBLIC_URL+'icon/question-mark.svg'} alt="" />
            </span>
          <span class="tooltiptext">PAN No is required for generating Invoice.</span>
          </span>
          <input
            type="text"
            name="PAN"
            className="form-control"
            placeholder='Enter PAN No'
            value={props?.PAN}
            onChange={handleChange
            }
          />
          </div>
          <div className="form-group col-sm-4">
          <label className="control-label">
           Name on PAN
          </label>
          <span className=" tooltp" >
            <span className="field-info">
            <img height={10} src={process.env.PUBLIC_URL+'icon/question-mark.svg'} alt="" />
            </span>
          <span class="tooltiptext">Name is required for generating Invoice.</span>
          </span>
          <input
            type="text"
            name="nameOnPan"
            className="form-control"
            placeholder='Enter Name as per PAN'
            value={props?.nameOnPan}
            onChange={handleChange
            }
          />
        </div>
        <div className="form-group col-sm-4">
          <label className="control-label">
           GST No.
          </label>
          <span className=" tooltp" >
            <span className="field-info">
            <img height={10} src={process.env.PUBLIC_URL+'icon/question-mark.svg'} alt="" />
            </span>
          <span class="tooltiptext">GST No is required for generating Invoice.</span>
          </span>
          <input
            type="text"
            name="GST"
            className="form-control"
            placeholder='Enter Your GST No'
            value={props?.GST}
            onChange={handleChange
            }
          />
        </div>
       </div>
        <hr />
        <div className="col-sm-12 tax-details-container">
        <div className="form-group col-sm-12">
          <h5>Bank Details</h5>
        </div>
       <div className="form-group col-sm-4">
          <label className="control-label">
          Beneficiary Name in Bank
          </label>
          <input
            type="text"
            name="accountHolderName"
            className="form-control"
            placeholder='Account Holder Name In Bank'
            value={invoiceInfo?.accountHolderName}
            onChange={(e) => {
               handleChangeNestedValue({
                name: "accountHolderName",
                value: e?.target?.value,
                key: "invoiceInfo",
              });
            }}
          />
          </div>
          <div className="form-group col-sm-4">
          <label className="control-label">
           Account Number
          </label>
          <input
            type="text"
            name="accountNo"
            className="form-control"
            placeholder='Enter Account Number'
            value={invoiceInfo?.accountNo}
            onChange={(e) => {
              vlidateNumberField(e) && handleChangeNestedValue({
                name: "accountNo",
                value: e?.target?.value,
                key: "invoiceInfo",
              });
            }}
          />
          </div>
          <div className="form-group col-sm-4">
          <label className="control-label">
           Branch Name
          </label>
          <input
            type="text"
            name="branchName"
            className="form-control"
            placeholder='Enter Branch Name'
            value={invoiceInfo?.branchName}
            onChange={(e) => {
              handleChangeNestedValue({
                name: "branchName",
                value: e?.target?.value,
                key: "invoiceInfo",
              });
            }}
            
          />
        </div>
        <div className="form-group col-sm-4">
          <label className="control-label">
           IFSC
          </label>
          <input
            type="text"
            name="ifsc"
            className="form-control"
            placeholder='Enter IFSC Code'
            value={invoiceInfo?.ifsc}
            onChange={(e) => {
              handleChangeNestedValue({
                name: "ifsc",
                value: e?.target?.value,
                key: "invoiceInfo",
              });
            }}
          />
        </div>
       </div>
        
        <div className="col-sm-12 text-right">
        <hr />
          <button
            type="submit"
            className="btn btn-primary nextBtn btn-lg blue_btn"
            style={{ background: "#1683f3", marginRight: "20px" }}
            onClick={submitForm}>

Save changes
          </button>

          <button
            className="btn btn-primary nextBtn btn-lg blue_btn"
            onClick={nextStep}
          >
            {t("ContinueBtn")}
          </button>{" "}
        </div>
      </div>
    </>
  );
}
export default InvoiceInfo;
