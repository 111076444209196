import React from "react";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import UserApplicationJobDetail from "../../components/UserApplication/UserApplicationJobDetail";
import ClientInviteMsg from "../../components/UserApplication/ClientInviteMsg";
import CoverLetter from "../../components/UserApplication/CoverLetter";
import UserChatbox from "../../components/UserApplication/UserChatbox";
import UserApplicationPopup from "../../components/UserApplication/UserApplicationPopup";

const UserApplicationPage = (props) => {
  return (
    <>
      <Header {...props} />
      <SubHeader />
      <section id="postjob_detail" className="default_bg">
        <img src="images/make_film_bg.png" alt="img" className="bg_img" />
        <div className="container">
          <h3 className="main_head">Application Details</h3>
          <UserApplicationJobDetail {...props} />
          <ClientInviteMsg {...props} />
          <CoverLetter {...props} />
          {/* <UserChatbox {...props} /> */}
          <UserApplicationPopup {...props} />
        </div>
      </section>
      <Footer />
    </>
  );
};

export default UserApplicationPage;
