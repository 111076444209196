import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight, FaTimes } from "react-icons/fa";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import '../../scss/celebrityManagement.scss'
import _ from "lodash";

class MobileMarketing extends Component {

  render() {
    return (
      <>
        <Header />
        <SubHeader /> 
          <section id="inner_celeb_banner">
            <div class="container">
              <div class="row">
                <div class="col-sm-6 col-xs-8 inner_celebrity_info">
                  <h1>MOBILE MARKETING COMPANY</h1>
                  <p align="justify">The way of marketing has changed in recent times and mobile phones are the main reason behind it. It is engaging customers with the brands. <strong>Mobile marketing</strong> is growing day by day, a large number of people check emails, visit websites, buy things and carry on other operations with the help of their smartphones.</p>
        
              <Link to="/ReachUs" class="stroke_btn">Contact US</Link>
                </div>
                <div class="col-sm-6 inner_celebrity_img">
                <img src="images/mobile-marketing-company.webp" alt="BollywoodisGood is best Celebrity Management Company in India" width="699" height="450" class="wow fadeInDown" data-wow-delay="0.3s" />	 
                </div>
              </div>
            </div>
          </section>

          <section id="inner_about">
          <div class="container">
          <div class="row">
          <div class="col-sm-5">
          <img src="images/mobile-marketing-agency.webp"  width="458" height="458" alt="Celebrity Management Services in India" />
          </div>
          <div class="col-sm-7 inner_about_info">
          <div class="inner_head">
          <h2>Mobile Marketing Services</h2>
          </div>
          <p align="justify">Our <strong>mobile marketing services</strong> will bring together the business and customers with an ease.</p>
       <br />
       <ul>
          <li><h6>Product Development</h6>
          <p align="justify">We develop the meaningful experience on mobile devices for the Web Apps by using the responsive design of CSS3 and HTML5.</p></li>
          <br />
          <li><h6>Mobile Websites services</h6>
          <p align="justify">We have a wide range of Mobile Web Services. Some of them include Mobile Design, Application Development and Strategy.</p></li>
          <br />
          <li><h6>SMS Marketing</h6>
          <p align="justify">Our SMS Marketing Platform is a special custom-made platform that will allow customer interaction.</p></li>
         
        </ul>
          </div>
          </div>
          </div>
          </section>

          <section id="our_role">
          <div class="container">
          <div class="row">
          <div class="col-sm-7 role_info">          
          <div class="inner_head">
          <h2>Mobile Marketing Agency</h2>
          </div>
         <p align="justify">We as a <strong>mobile marketing company</strong> at BollywoodisGood aim to bring the <strong>best mobile marketing services</strong> to the people. Our clients are always enthusiastic about our strategies and plans which helps to strengthen our trust with them and create a long time bond. Some of our productive and competent marketing services help to create great customer experiences across the audience.
<br /><br />
Our mobile marketing services<br /></p>
          <br /> 
          <ul>
            <li>SMS Marketing</li>
            <li>Product Development</li>
            <li>Mobile Websites</li>
            <li>2D Barcodes/QR Codes</li>
            <li>Mobile Commerce</li>
            <li> Mobile Marketing display campaigns</li>
            <li>Mobile Search Campaigns</li>
          </ul>
          </div>
          <div class="col-sm-5">
          <img src="images/mobile-marketing-services.webp"  loading="lazy" width="458" height="459" alt="Artist Management Services" />
          </div>
          </div>
          </div>
          </section>  

          <section id="inner_services">
          <div class="container">      
          <div class="text-center inner_head">
        <h2>Mobile Markeing Services</h2>
        </div>   
          <h6>Strategy</h6>
          <p align="justify">A great strategy always starts with having the right goal. We help to convert your visions into significant objectives and define a blueprint to achieve them.</p>
          <br />
          <h6>Mobile Optimization</h6>
          <p align="justify">We are the best at our work and know how to hit the charts. To boost and maximize your app’s potential our app marketing strategy includes pre-launch, launch and post launch activities.</p>
          <br />
          <h6>User Acquisition</h6>
          <p align="justify">Taking the app to the right audience is most important and that's what we do. Our team plans, optimizes and implements your user acquisition campaigns (CPC/CPI) on all global advertising networks.</p>
          <br />
          <h6>Influencer Marketing</h6>
          <p align="justify">We get to the right contacts with the influencers across the multiple categories. To really escalate the number of audience, Influencers marketing can help to make the product go viral.</p>
          </div>
        
          </section> 

         
          <section id="inner_about">
          <div class="container">
          <div class="row">
          <div class="col-sm-5">
          <img src="images/mobile-marketing-analysis.webp"  width="458" height="458" alt="Celebrity Management Services in India" />
          </div>
          <div class="col-sm-7 inner_about_info">        
       <ul>
          <li><h6>Press Outreach</h6>
          <p align="justify">We know exactly how to get your app covered. We create the right story angle for your app to reach the technology journalists. We also make sure that it reaches the right product review sites to increase its chances of conversion.</p></li>
          <br />
          <li><h6>App Analytics</h6>
          <p align="justify">Our main agenda is to achieve long term active users. A detailed understanding of the product usage metrics and product analytics helps us to accomplish this goal.</p></li>
        </ul>
        <br />
        <p align="justify">This is how we work and helps the companies to increase the revenue and enhance the mobile marketing.  Hence, if you required a <strong>mobile marketing company</strong> then get in touch with BollywoodisGood. Our team has knowledge and experience that makes us help the company in growth process. We take into consideration all the latest marketing tools and techniques and give the best<strong> Mobile Marketing services</strong> to our every client. So, get connected with us and we will make your mobile marketing journey smooth and easy. </p>
          </div>
          </div>
          </div>
          </section>
           
        
          <section id="last_info">
<div class="container">
<div class="row">
<div class="col-xs-12">
<blockquote>
<p align="justify"> Get in touch with BollywoodisGood to experience the best services in the <strong> Mobile Marketing field.</strong>As we are the leading <strong>Mobile Marketing Agency</strong> in Mumbai.
</p>
</blockquote>
</div>
</div>
</div>
</section>
           
        <Footer />
      </>
    );
  }
}

export default MobileMarketing;
