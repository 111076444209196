import React from 'react'
import './loader.css';

function BigLoader() {
  return (
    <div className="loader-overlay loading">
      <div className="loader"></div>
    </div>
  );
}

export default BigLoader