import React from "react";
import { Page, Document, Image, StyleSheet, View } from "@react-pdf/renderer";
import InvoiceTitle from "./InvoiceTitle";
import BillTo from "./BillTo";
import InvoiceNo from "./InvoiceNo";
import InvoiceItemsTable from "./InvoiceItemsTable";
import BillFrom from "./BillFrom";
// import logo from "../../../src/logo.png";

const styles = StyleSheet.create({
  page: {
    // fontFamily: "Helvetica-Bold",
    fontSize: 10,
    paddingTop: 30,
    paddingLeft: 30,
    paddingRight: 30,
    lineHeight: 1.5,
    flexDirection: "column",
    // marginTop: 30
  },
  logo: {
    width: 74,
    height: 66,
    marginLeft: "auto",
    marginRight: "auto",
  },

});


const Invoice = ({ invoice }) => {
  return (
    <Document >
      <Page size="A4" style={styles.page} orientation="portrait">
        <View style={{ border: "1px solid #555" }}>
          {/* <Image style={styles.logo} src={logo} /> */}
          <InvoiceTitle title="Invoice" />
          <InvoiceNo invoice={invoice} />

          <View style={{ flexDirection: "row" }}>
            <View style={{ width: "60%" }}>
              <BillFrom invoice={invoice} />
            </View>
            <View style={{ width: "40%" }}>
              <BillTo invoice={invoice} />
            </View>
          </View>
          <InvoiceItemsTable invoice={invoice} />
        </View>
      </Page>
    </Document>
  );
};

export default Invoice;
