export const functionalArea = [
    { label: "Select", value: "Select" },
    { label: "Hindi Film Industry", value: "Hindi Film Industry" },
    { label: "Punjabi Film Industry", value: "Punjabi Film Industry" },
    { label: "Tamil Film Industry", value: "Tamil Film Industry" },
    { label: "Telugu Film Industry", value: "Telugu Film Industry" },
    { label: "Tulu Film Industry", value: "Tulu Film Industry" },
    { label: "Marathi Film Industry", value: "Marathi Film Industry" },
    { label: "Malayalam Film Industry", value: "Malayalam Film Industry" },
    { label: "Bhojpuri Film Industry", value: "Bhojpuri Film Industry" },
    { label: "Other", value: "Other" },
  ];

  export const filmIndustry = [
    { label: "Select", value: "Select" },
    { label: "Hindi Film Industry", value: "Hindi Film Industry" },
    { label: "Punjabi Film Industry", value: "Punjabi Film Industry" },
    { label: "Tamil Film Industry", value: "Tamil Film Industry" },
    { label: "Telugu Film Industry", value: "Telugu Film Industry" },
    { label: "Tulu Film Industry", value: "Tulu Film Industry" },
    { label: "Marathi Film Industry", value: "Marathi Film Industry" },
    { label: "Malayalam Film Industry", value: "Malayalam Film Industry" },
    { label: "Bhojpuri Film Industry", value: "Bhojpuri Film Industry" },
    { label: "Other", value: "Other" },
  ];

  
 export const funtionalOption = [
    { label: "Cast", value: "Cast" },
    { label: "Creative Team", value: "Creative Team" },
    { label: "Production", value: "Production" },
    { label: "Script", value: "Script" },
    { label: "Location", value: "Location" },
    { label: "Camera", value: "Camera" },
    { label: "Sound", value: "Sound" },
    { label: "Grip", value: "Grip" },
    { label: "Electrical", value: "Electrical" },
    { label: "Art", value: "Art" },
    { label: "Hair and Makeup", value: "Group 2" },
    { label: "wardrob", value: "Group 2" },
    { label: "Post Production", value: "Group 2" },
    { label: "Visual Effects (VFX)", value: "Group 2" },
    { label: "Post Production - Sound/Music", value: "Group 1" },
    { label: "Other Production Crew", value: "Group 2" },
    { label: "Intractive Media", value: "Group 2" },
    { label: "Other", value: "Other" }
  ];

  export const roleOption = [
    { value: "Producer", label: "Producer" },
    { value: "Director", label: "Director" },
    { value: "Screenwriter", label: "Screenwriter" },
    { value: "Executive Producer", label: "Executive Producer" },
    { value: "Line Producer", label: "Line Producer" },
    { value: "Production Manager", label: "Production Manager" },
    { value: "Production Coordinator", label: "Production Coordinator" },
    { value: "Production Secretary", label: "Production Secretary" },
    { value: "Production Accountant", label: "Production Accountant" },
    { value: "Post-production Supervisor", label: "Post-production Supervisor" },
    { value: "First Assistant Director", label: "First Assistant Director" },
    { value: "Second Assistant Director", label: "Second Assistant Director" },
    { value: "Third Assistant Director", label: "Third Assistant Director" },
    { value: "Production Assistant/Production Runner", label: "Production Assistant/Production Runner" },
    { value: "Script Supervisor", label: "Script Supervisor" },
    { value: "Stunt Coordinator", label: "Stunt Coordinator" },
    { value: "Story Producer", label: "Story Producer" },
    { value: "Script Editor", label: "Script Editor" },
    { value: "Script Co-ordinator", label: "Script Co-ordinator" },
    { value: "Location Manager", label: "Location Manager" },
    { value: "Location Assistant", label: "Location Assistant" },
    { value: "Location Scout", label: "Location Scout" },
    { value: "Director of Photography/Cinematographer", label: "Director of Photography/Cinematographer" },
    { value: "Camera Operator", label: "Camera Operator" },
    { value: "First Assistant Camera (Focus Puller)", label: "First Assistant Camera (Focus Puller)" },
    { value: "Second Assistant Camera (Clapper Loader)", label: "Second Assistant Camera (Clapper Loader)" },
    { value: "Loader", label: "Loader" },
    { value: "Camera Production Assistant", label: "Camera Production Assistant" },
    { value: "Digital Imaging Technician (DIT)", label: "Digital Imaging Technician (DIT)" },
    { value: "Data Wrangler", label: "Data Wrangler" },
    { value: "Steadicam Operator", label: "Steadicam Operator" },
    { value: "Motion Control Technician/Operator", label: "Motion Control Technician/Operator" },
    { value: "Video Split/Assist Operator", label: "Video Split/Assist Operator" },
    { value: "Production Sound Mixer (Sound Recordist)", label: "Production Sound Mixer (Sound Recordist)" },
    { value: "Boom Operator (Boom Swinger)", label: "Boom Operator (Boom Swinger)" },
    { value: "Key Grip", label: "Key Grip" },
    { value: "Best Boy (Grip)", label: "Best Boy (Grip)" },
    { value: "Dolly Grip", label: "Dolly Grip" },
    { value: "Gaffer", label: "Gaffer" },
    { value: "Best Boy (Electrical)", label: "Best Boy (Electrical)" },
    { value: "Lighting Technician", label: "Lighting Technician" },    
    { label: "Other", value: "Other" },
  ]


  export const awardYear = [
    { label: "2019", value: "2019" },
    { label: "2018", value: "2018" },
    { label: "2017", value: "2017" },
    { label: "2016", value: "2016" },
    { label: "2015", value: "2015" },
    { label: "2014", value: "2014" },
    { label: "2013", value: "2013" },
    { label: "2012", value: "2012" },
    { label: "2011", value: "2011" },
    { label: "2010", value: "2010" },
    { label: "2009", value: "2009" },
    { label: "2008", value: "2008" },
  ];

  export const passingYear = [
    { label: "2020", value: "2020" },
    { label: "2019", value: "2019" },
    { label: "2018", value: "2018" },
    { label: "2017", value: "2017" },
    { label: "2016", value: "2016" },
    { label: "2015", value: "2015" },
    { label: "2014", value: "2014" },
    { label: "2013", value: "2013" },
    { label: "2012", value: "2012" },
    { label: "2011", value: "2011" },
    { label: "2010", value: "2010" },
    { label: "2009", value: "2009" },
    { label: "2008", value: "2008" },
  ];

  export const HighestQualification = [
    { label: "Select", value: "Select" },
    { label: "Qualification", value: "Qualification" },
    { label: "Doctorate/PhD", value: "Doctorate/PhD" },
    { label: "Masters/Post-Graduation", value: "Masters/Post-Graduation" },
    { label: "Graduation/Diploma", value: "Graduation/Diploma" },
    { label: "12th", value: "12th" },
    { label: "10th", value: "10th" },
    { label: "Other", value: "Other" },
  ];

  export const courses = [
    { label: "Select", value: "Select" },
    { label: "B.A. (Acting)", value: "B.A. (Acting)" },
    { label: "B.A. (Hons) (Drama)", value: "B.A. (Hons) (Drama)" },
    {
      label: "Bachelor of Theatre Arts (B.T.A.)",
      value: "Bachelor of Theatre Arts (B.T.A.)",
    },
    { label: "M.A. (Indian Theatre)", value: "M.A. (Indian Theatre)" },
    {
      label: "M.F.Tech. (Cinematography)",
      value: "M.F.Tech. (Cinematography)",
    },
    {
      label: "M.F.Tech. (Direction &amp; Screenplay Writing)",
      value: "M.F.Tech. (Direction &amp; Screenplay Writing)",
    },
    { label: "Ph.D. (Dramatics)", value: "Ph.D. (Dramatics)" },
    { label: "Ph.D. (Indian Theatre)", value: "Ph.D. (Indian Theatre)" },
    { label: "Other", value: "Other" },
  ];

  export const specialization = [
    { label: "Select", value: "Select" },
    { label: "Film Making", value: "Film Making" },
    { label: "Acting", value: "Acting" },
    { label: "Writing", value: "Writing" },
    { label: "Music", value: "Music" },
    { label: "Special Effects", value: "Special Effects" },
    { label: "Camera Operator", value: "Camera Operator" },
    { label: "Lightning", value: "Lightning" },
    { label: "Film Editing", value: "Film Editing" },
    { label: "Film distribution", value: "Film distribution" },
    { label: "Other", value: "Other" },
  ];

  export const city = [
    { label: "Mumbai", value: "Mumbai" },
    { label: "Delhi", value: "Delhi" },
    { label: "Bangalore", value: "Bangalore" },
    { label: "Hyderabad", value: "Hyderabad" },
    { label: "Ahmedabad", value: "Ahmedabad" },
    { label: "Chennai", value: "Chennai" },
    { label: "Kolkata", value: "Kolkata" },
    { label: "Surat", value: "Surat" },
    { label: "Pune", value: "Pune" },
    { label: "Jaipur", value: "Jaipur" },
  ];

  export const state = [
    { label: "Andhra Pradesh", value: "Andhra Pradesh" },
    { label: "Arunachal Pradesh", value: "Arunachal Pradesh" },
    { label: "Assam", value: "Assam" },
    { label: "Chhattisgarh", value: "Chhattisgarh" },
    { label: "Bihar", value: "Bihars" },
    { label: "Goa", value: "Goa" },
    { label: "Gujarat", value: "Gujarat" },
    { label: "Haryana", value: "Haryana" },
    { label: "Himachal Pradesh", value: "Film distribution" },
    { label: "Jharkhand", value: "Jharkhand" },
  ];
