import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#555";
const styles = StyleSheet.create({
  container: {
    fontFamily: "Helvetica-Bold",
    flexDirection: "row",
    borderBottomColor: borderColor,
    // backgroundColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
    fontSize: 10
  },
  cell: {
    borderRightColor: borderColor,
    borderRightWidth: 1,
    height: 24
  },
  taxType:{
    color:'red',
    fontWeight:600,
  },
  // qty: {
  //   width: "10%",
  //   borderRightColor: borderColor,
  //   borderRightWidth: 1
  // },
  // rate: {
  //   width: "15%",
  //   borderRightColor: borderColor,
  //   borderRightWidth: 1
  // },
});

const InvoiceTableHeader = () => (
  <View style={styles.container}>
    <Text style={{...styles.cell,width: '5%'}}>S.No</Text>
    <Text style={{...styles.cell,width: '50%'}}>Description</Text>
    <Text style={{...styles.cell,width: '10%'}}>HSN Code</Text>
    <Text style={{...styles.cell,width: '15%'}}>Net Amount</Text>
    {/* <Text style={{...styles.cell,width: '15%'}}>Tax Rate</Text>
    <Text style={{...styles.cell,width: '14%'}}>Tax Amount</Text> */}
    {/* <Text style={{...styles.cell,...styles.taxType, width: '10%'}}>*Type of Tax</Text> */}
    <Text style={{...styles.cell,width: '20%',borderRightWidth:0}}>Total Amount</Text>

  </View>
);

export default InvoiceTableHeader;
