import React from "react";
import Select from "react-select";
import { JobHistory } from "../JobPost/jobPostConst";
import _ from 'lodash';

const AuditionListSidebar = ({ onChangeFilter, filters, ...props }) => (
    <div className="col-sm-3 job_list_sidebar">
        <div className="list_bg clearfix">
            <h4>Filters</h4>

            <div className="filter_list">
                <p><strong>Visibility</strong></p>
                <label className="check_bx">
                    <input type="checkbox" checked={_.find(filters, { type: 'visibility', value: 'All' })} onChange={(e) => onChangeFilter({ type: 'visibility', value: 'All' })} />
                    All
                    <span className="checkmark"></span></label>
                <label className="check_bx"><input type="checkbox" checked={_.find(filters, { type: 'visibility', value: 'Invite Only' })} onChange={(e) => onChangeFilter({ type: 'visibility', value: 'Invite Only' })} />
                    Invite Only
                    <span className="checkmark"></span></label>
                <label className="check_bx"><input type="checkbox" checked={_.find(filters, { type: 'visibility', value: 'Public' })} onChange={(e) => onChangeFilter({ type: 'visibility', value: 'Public' })} />
                    Public
                    <span className="checkmark"></span></label>
                <label className="check_bx"><input type="checkbox" checked={_.find(filters, { type: 'visibility', value: 'Only Big Talent' })} onChange={(e) => onChangeFilter({ type: 'visibility', value: 'Only Big Talent' })} />
                    Only Big Talent
                    <span className="checkmark"></span></label>
            </div>

            <div className="filter_list">
                <p><strong>Type</strong></p>
                <label className="check_bx">
                    <input type="checkbox" checked={_.find(filters, { type: 'type', value: 'All' })} onChange={(e) => onChangeFilter({ type: 'type', value: 'All' })} />
                    All
                    <span className="checkmark"></span></label>
                <label className="check_bx"><input type="checkbox" checked={_.find(filters, { type: 'type', value: 'Fixed Price' })} onChange={(e) => onChangeFilter({ type: 'type', value: 'Fixed Price' })} />
                    Fixed Price
                    <span className="checkmark"></span></label>
                <label className="check_bx"><input type="checkbox" checked={_.find(filters, { type: 'type', value: 'Hourly' })} onChange={(e) => onChangeFilter({ type: 'type', value: 'Hourly' })} />
                    Hourly
                    <span className="checkmark"></span></label>
            </div>

            <div className="filter_list">
                <p><strong>Status</strong></p>
                <label className="check_bx">
                    <input type="checkbox" checked={_.find(filters, { type: 'status', value: 'All' })} onChange={(e) => onChangeFilter({ type: 'status', value: 'All' })} />
                    All
                    <span className="checkmark"></span></label>
                <label className="check_bx"><input type="checkbox" checked={_.find(filters, { type: 'status', value: 'Draft' })} onChange={(e) => onChangeFilter({ type: 'status', value: 'Draft' })} />
                    Draft
                    <span className="checkmark"></span></label>
                <label className="check_bx"><input type="checkbox" checked={_.find(filters, { type: 'status', value: 'Open' })} onChange={(e) => onChangeFilter({ type: 'status', value: 'Open' })} />
                    Open
                    <span className="checkmark"></span></label>
                <label className="check_bx"><input type="checkbox" checked={_.find(filters, { type: 'status', value: 'Filled' })} onChange={(e) => onChangeFilter({ type: 'status', value: 'Filled' })} />
                    Filled
                    <span className="checkmark"></span></label>
                <label className="check_bx"><input type="checkbox" checked={_.find(filters, { type: 'status', value: 'Closed' })} onChange={(e) => onChangeFilter({ type: 'status', value: 'Closed' })} />
                    Closed
                    <span className="checkmark"></span></label>
            </div>
        </div>
    </div>
);

export default AuditionListSidebar;