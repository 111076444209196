import React from 'react';
import Header from "../shared/header/Header";
import { Link } from 'react-router-dom';
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../shared/footer/Footer";
import AuditionForm from "./AuditionForm";
import AuditionHeader from "./AuditionHeader";

const createAuditionPage = props => {
    // console.log(props, "aditya")
    return (
        <>
            <Header />
            <SubHeader />
             {/* CTA section start*/}
     <section id="cta" className="mid_info dark_clr inner_banner">
          <div className="container side_space">
          <div class="home_grey_bx clearfix"><img src="images/audition_banner.png" alt="img" class="right_pattern" />
            <div className="row">    
            <div className="col-md-8 col-sm-8 col-xs-8 cta_info full">
            <h2>Post Auditions and Reach Talented Individuals</h2>
            <p>Expand Your Casting Reach, Post Auditions and Reach a Wide Pool of Talent with Bollywood is Good</p>
                 </div>
            </div>
            </div>
          </div>
      </section>
      {/* CTA section ends */}

            <section id="track_page_tab" className="dark_clr">
                <div className="container">
                    <AuditionHeader {...props} />
                    <div className="row">
                        <AuditionForm {...props} />
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
export default createAuditionPage;