import React from 'react';
import { Link } from 'react-router-dom';
import { FaTimes, FaSuitcase, FaHeart, FaSearch, FaAngleLeft, FaAngleRight, FaLocationArrow, FaMoneyBill, FaClock,} from 'react-icons/fa';
import _ from 'lodash';

const HireTab = (props) => { 

        return(             
        <div className="tab-pane" id="tab_default_4">   
        <div className="shadow_bg clearfix">   
        <div className="inside_listing_tab clearfix">                             
            <div className="listing_filter">
                <div class="form-group search_field">
                    <input type="search" placeholder="Search" class="form-control" />
                    <button type="button" class="default_btn"><FaSearch /></button></div>
                </div>                    
                <ul>
           
                {
                    _.map( props?.jobPostDetail?.hiredUsers, val => (
                        <li>
                            <div className="row">
                                <div className="people_img col-md-2">
                                    <img src="images/akshay.jpg" alt="img" />
                                    <span className="online"><div className="circle_icon"></div> </span>
                                </div>
                                <div className="people_list col-md-10">
                                    <div className="people_list_info clearfix">
                                        <span className="name_title">{val.userName}</span>
                                        {/* <h6>{}</h6> */}
                                        <div className="people_short_info">
                                            <span><FaLocationArrow /><strong>{val.city}</strong></span>
                                            <span><FaClock /><strong>{val?.additionalInfo?.currentCompensationAmount}</strong>{val?.additionalInfo?.currentCompensationType} </span>
                                            {/* <span><FaMoneyBill /><strong>Rs 80k+</strong> earned</span>
                                            <span><FaSuitcase /><strong>100%</strong> Job Succcess</span> */}
                                        </div>
                                        {/* <small>Received 10 minutes ago</small>
                                        <p><strong>Cover Letter -</strong> Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium</p> */}
                                    </div>
                                    <div className="side_action_btn pull-right">  
                                        <Link  className="stroke_btn" >Hired</Link>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))
                }
            

            </ul>
                <div class="pagination_part clearfix text-right">
                    <br />
                    <ul class="pagination">
                    <li><Link to="#"><FaAngleLeft /> Prev</Link></li>
                    <li><Link to="#">1</Link></li>
                    <li><Link to="#">2</Link></li>
                    <li><Link to="#">3</Link></li>
                    <li><Link to="#">4</Link></li>
                    <li><Link to="#">5</Link></li>
                    <li><Link to="#"> Next <FaAngleRight /></Link></li>
                    </ul>
                </div>
                </div>
                </div>
        </div>  
        ); 
}
export default HireTab;