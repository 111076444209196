import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { city, state } from "./registerConst";
import Select from "react-select";
import cityStateConst from "./cityStateConst";
import _ from "lodash";
const StateOptions = _.map(cityStateConst, (val) => ({
  label: val.state,
  value: val.state,
}));
function PersonalInfo({
  handleChangeDropDown,
  handleChange,
  nextStep,
  errors,
  ...props
}) {
  const { t } = useTranslation();
  const districtArray = _.find(cityStateConst, { state: props.state }) ?.districts;
  const districtOptions = _.map(districtArray, val => ({ label: val, value: val }));
  console.log(props, districtArray, _.find(cityStateConst, { state: props.state }), "kwjdihdw");
  return (
    <>
      <div className="setup-content" id="step-1">
        <div className="form-group">
          <label className="control-label">{t("SignUpNameLabel")}</label>
          <input
            name="userName"
            maxLength={100}
            value={props.userName}
            type="text"
            className="form-control"
            placeholder="Enter Full Name"
            onChange={handleChange}
            required
          />
          <span style={{ color: "red" }}>{errors["userName"]}</span>
        </div>
        <div className="form-group">
          <label className="control-label">{t("SignUpEmailLabel")}</label>
          <input
            maxLength={100}
            type="email"
            name="emailId"
            className="form-control"
            onChange={handleChange}
            placeholder="Enter Email"
            value={props.emailId}
            required
          />
          <span style={{ color: "red" }}>{errors["email"]}</span>
        </div>
        <div className="form-group">
          <label className="control-label">{t("SignUpPasswordLabel")}</label>
          <input
            maxLength={100}
            name="password"
            type="password"
            className="form-control"
            value={props.password}
            onChange={handleChange}
            placeholder="Enter Password"
            required
          />
          <span style={{ color: "red" }}>{errors["password"]}</span>
        </div>
        <div className="form-group">
          <label className="control-label">{t("SignUpNumberLabel")}</label>
          <input
            maxLength={10}
            name="mobileNumber"
            type="tel"
            value={props.mobileNumber}
            className="form-control"
            onChange={handleChange}
            placeholder="EnterNumber"
            required
          />
        </div>
        <div className="form-group">
          <div className="row">
            <div className="col-sm-6 half_field">
              <label className="control-label">{t("SignUpStateLabel")}</label>
              <Select
                options={StateOptions}
                value={{ label: props.state, value: props.state }}
                onChange={(e) =>
                  handleChangeDropDown({ name: "state", value: e.label })
                }
              />
            </div>
            <div className="col-sm-6 half_field">
              <label className="control-label">{t("SignUpCityLabel")}</label>
              <Select
                options={districtOptions}
                value={{ label: props.city, value: props.city }}
                onChange={(e) =>
                  handleChangeDropDown({ name: "city", value: e.label })
                }
              />
            </div>

          </div>

          <label className="check_bx inside_label">
            <input
              type="checkbox"
              name="field"
              onChange={handleChange}
              value={props.outsideIndia}
              name="outsideIndia"
            />
            <span className="checkmark" /> {t("SignUpOutsideCheckLabel")}
          </label>
        </div>
        <button
          className="btn btn-primary nextBtn btn-lg blue_btn"
          onClick={nextStep}
        >
          {t("ContinueBtn")}
        </button>{" "}
      </div>
    </>
  );
}
export default PersonalInfo;
