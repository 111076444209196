import React from "react";
import { FaAngleRight } from 'react-icons/fa';
import { workExp, workExpMax, } from "../CreateUserProfile/registerConst";
import AsyncSelect from 'react-select/async';
import { loadOptionsResolveLanguage, defaultOption } from "../../Constants.js/languageConst";
import cityStateConst from '../CreateUserProfile/cityStateConst';
import Select from "react-select";
import { JobHistory } from "../JobPost/jobPostConst";
import filmCourseOptions from "../CreateUserProfile/filmCoursesConst"
import { countries } from '../CreateUserProfile/cityStateConst'
import { profession, filmType, gender, read } from '../CreateUserProfile/registerConst';
import _ from 'lodash';

const stateOptions = _.map(cityStateConst, (val) => ({
    label: val.state, value: val.state
}));

const JobListSidebar = ({ onChangeFilter, filters, resetAllFilters, handleChangeDropdown, defaultProps, showFilter, ...props }) => {

    const districtArray = _.find(cityStateConst, {
        state: props.state,
    })?.districts;

    const districtOptions = _.map(districtArray, (val) => ({
        label: val,
        value: val,
    }));

    return (
        <div id="filter-sidebar" className={`col-md-3 col-sm-4 job_list_sidebar sliding-sidebar ${showFilter ? 'px-0 visible-sm visible-md visible-lg collapse' : ''}`}>
                   <div className="list_bg clearfix">

                <h4>Filters</h4>
                <a style={{ color: 'blue', cursor: 'pointer' }} onClick={() => resetAllFilters()}>Reset all filters</a>
                {/* <div className="form-group filter_list">
                    <label>Previous Job History</label>
                    <Select
                        options={JobHistory}
                        placeholder="Select"
                        onChange={e => onChangeFilter({ type: 'history', value: e.label })}
                    />
                </div> */}

                <div className="filter_points">
                    <h6>Job Category</h6>
                    <Select
                        options={profession}

                        onChange={e => onChangeFilter({ type: 'category', value: e.label })}
                    />
                </div>

                <div className="filter_points">
                    <h6 data-toggle="collapse" data-target="#group-0">
                        <FaAngleRight />
                        Age
                    </h6>
                    <div id="group-0" className="list-group collapse">
                        <div className="list-group-item no_padding row">
                            <div className="col-md-6">
                                <input type="text"
                                    className="form-control"
                                    placeholder="Min"
                                    name="minAge"

                                    onChange={e => onChangeFilter({ type: 'minAge', value: e.target.value })}
                                />
                            </div>
                            <div className="col-md-6">
                                <input type="text"
                                    className="form-control"
                                    placeholder="Max"
                                    name="maxAge"

                                    onChange={e => onChangeFilter({ type: 'maxAge', value: e.target.value })}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="filter_points">
                    <h6 data-toggle="collapse" data-target="#group-2">
                        <FaAngleRight />
                        Country
                    </h6>
                    <div id="group-2" className="list-group collapse">
                        <p className="list-group-item full_field">
                            <Select
                                options={countries}

                                onChange={(e) =>
                                    onChangeFilter({ type: "country", value: e.label })
                                }
                            />
                        </p>
                    </div>

                </div>

                <div className="filter_points">


                    <h6 data-toggle="collapse" data-target="#group-7">
                        <FaAngleRight />
                        State
                    </h6>
                    <div id="group-7" className="list-group collapse">
                        <p className="list-group-item full_field">
                            <Select
                                options={stateOptions}

                                onChange={(e) => onChangeFilter({ type: "state", value: e.label })}
                            />
                        </p>
                    </div>
                </div>


                <div className="filter_points">
                    <h6 data-toggle="collapse" data-target="#group-8">
                        <FaAngleRight />
                        City
                    </h6>
                    <div id="group-8" className="list-group collapse">
                        <p className="list-group-item full_field">

                            <Select
                                options={districtOptions}

                                onChange={(e) => onChangeFilter({ type: "city", value: e.label })
                                }
                            />

                        </p>
                    </div>

                </div>


                <div className="filter_points">
                    <h6 data-toggle="collapse" data-target="#lang-spk" aria-expanded="true">
                        <FaAngleRight />
                        Languages Speak
                    </h6>
                    <div id="lang-spk" className="list-group collapse in">
                        {/* {
                            _.map(read, val => (

                                <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" checked={_.find(filters, { type: 'readType', value: val.label })} onChange={(e) => onChangeFilter({ type: "readType", value: val.label })} />{val.label}<span className="checkmark" /></label></p>
                            ))
                        } */}
                        {/* <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" />Read  <span className="checkmark" /></label></p>
                        <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" />Write  <span className="checkmark" /></label></p> */}
                        <AsyncSelect
                            cacheOptions
                            isMulti
                            defaultOptions={defaultOption}
                            loadOptions={loadOptionsResolveLanguage}
                            {...defaultProps}
                            onChange={(e) => onChangeFilter({ type: "languagesSpeak", value: _.map(e, (val) => val.label) })}
                        />

                        {/* <p className="list-group-item full_field"><input type="search" placeholder="Search language" className="form-control" /> </p> */}
                    </div>


                    <h6 data-toggle="collapse" data-target="#lang-read" aria-expanded="true">
                        <FaAngleRight />
                        Languages Read
                    </h6>
                    <div id="lang-read" className="list-group collapse in">
                        <AsyncSelect
                            cacheOptions
                            isMulti
                            defaultOptions={defaultOption}
                            loadOptions={loadOptionsResolveLanguage}
                            {...defaultProps}
                            onChange={(e) => onChangeFilter({ type: "languagesRead", value: _.map(e, (val) => val.label) })}
                        />

                        {/* <p className="list-group-item full_field"><input type="search" placeholder="Search language" className="form-control" /> </p> */}
                    </div>


                    <h6 data-toggle="collapse" data-target="#lang-wr" aria-expanded="true">
                        <FaAngleRight />
                        Languages Write
                    </h6>
                    <div id="lang-wr" className="list-group collapse in">
                        <AsyncSelect
                            cacheOptions
                            isMulti
                            defaultOptions={defaultOption}
                            loadOptions={loadOptionsResolveLanguage}
                            {...defaultProps}
                            onChange={(e) => onChangeFilter({ type: "languagesWrite", value: _.map(e, (val) => val.label) })}
                        />

                    </div>


                </div>
                <div className="filter_points">
                    <h6 data-toggle="collapse" data-target="#group-5"> <FaAngleRight /> Film Relevant Institute  </h6>
                    <div id="group-5" className="list-group collapse">
                        <div className="list-group-item">
                            <Select
                                options={filmCourseOptions}
                                onChange={e => onChangeFilter({ type: 'instituteType', value: e.label })}
                            />
                        </div>
                    </div>
                </div>
                <div className="filter_points">
                    <h6 data-toggle="collapse" data-target="#group-4"> <FaAngleRight />  Experience  </h6>
                    <div id="group-4" className="list-group collapse">
                        <div className="list-group-item">
                            <label>Min</label>
                            <Select
                                options={workExp}

                                onChange={e => onChangeFilter({ type: 'minExperience', value: e.label })}
                            />
                        </div>


                        <div id="group-4" className="list-group collapse">
                            <div className="list-group-item">
                                <label>Max</label>
                                <Select
                                    options={workExp}

                                    onChange={e => onChangeFilter({ type: 'maxExperience', value: e.label })}
                                />
                            </div>
                        </div>
                    </div>
                </div>


                <div className="filter_points">
                    <h6 data-toggle="collapse" data-target="#group-6"> <FaAngleRight /> Film / Production Type</h6>
                    <div id="group-6" className="list-group collapse">
                        <div className="list-group-item">
                            {
                                _.map(filmType, val => (
                                    <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" checked={_.find(filters, { type: 'filmType', value: val.label })} onChange={(e) => onChangeFilter({ type: 'filmType', value: val.label })} /> {val.label}  <span className="checkmark" /></label></p>
                                ))
                            }
                        </div>
                    </div>
                </div>


                {/* <div className="filter_points">
                    <h6 data-toggle="collapse" data-target="#group-3" aria-expanded="true">
                        <FaAngleRight />
                        Payout
              </h6>
                    <div id="group-3" className="list-group collapse in">
                        {
                            _.map(payout, val => (
                                <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" checked={_.find(filters, { type: 'payType', value: val.label })} onChange={(e) => onChangeFilter({ type: 'payType', value: val.label })} /> {val.label} <span className="checkmark" /></label></p>
                            ))
                        }
                    </div>
                </div> */}
                <div className="filter_points">
                    <h6 data-toggle="collapse" data-target="#group-1" aria-expanded="true">
                        <FaAngleRight />
                        Gender
                    </h6>
                    <div id="group-1" className="list-group collapse in">

                        {
                            _.map(gender, val => (

                                <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" checked={_.find(filters, { type: 'talentType', value: val.label })} onChange={(e) => onChangeFilter({ type: 'talentType', value: val.label })} />{val.label}<span className="checkmark" /></label></p>
                            ))
                        }
                        {/* <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" />Male  <span className="checkmark" /></label></p>
                        <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" />Female  <span className="checkmark" /></label></p>
                        <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" />Any Other  <span className="checkmark" /></label></p> */}
                    </div>
                </div>


                {/* <div className="filter_list">
                <p><strong>Visibility</strong></p>
                <label className="check_bx">
                    <input type="checkbox" checked={_.find(filters, { type: 'visibility', value: 'All' })} onChange={(e) => onChangeFilter({ type: 'visibility', value: 'All' })} />
                    All
                    <span className="checkmark"></span></label>
                <label className="check_bx"><input type="checkbox" checked={_.find(filters, { type: 'visibility', value: 'Invite Only' })} onChange={(e) => onChangeFilter({ type: 'visibility', value: 'Invite Only' })} />
                    Invite Only
                    <span className="checkmark"></span></label>
                <label className="check_bx"><input type="checkbox" checked={_.find(filters, { type: 'visibility', value: 'Public' })} onChange={(e) => onChangeFilter({ type: 'visibility', value: 'Public' })} />
                    Public
                    <span className="checkmark"></span></label>
                <label className="check_bx"><input type="checkbox" checked={_.find(filters, { type: 'visibility', value: 'Only Big Talent' })} onChange={(e) => onChangeFilter({ type: 'visibility', value: 'Only Big Talent' })} />
                    Only Big Talent
                    <span className="checkmark"></span></label>
            </div>

            <div className="filter_list">
                <p><strong>Type</strong></p>
                <label className="check_bx">
                    <input type="checkbox" checked={_.find(filters, { type: 'type', value: 'All' })} onChange={(e) => onChangeFilter({ type: 'type', value: 'All' })} />
                    All
                    <span className="checkmark"></span></label>
                <label className="check_bx"><input type="checkbox" checked={_.find(filters, { type: 'type', value: 'Fixed Price' })} onChange={(e) => onChangeFilter({ type: 'type', value: 'Fixed Price' })} />
                    Fixed Price
                    <span className="checkmark"></span></label>
                <label className="check_bx"><input type="checkbox" checked={_.find(filters, { type: 'type', value: 'Hourly' })} onChange={(e) => onChangeFilter({ type: 'type', value: 'Hourly' })} />
                    Hourly
                    <span className="checkmark"></span></label>
            </div>

            <div className="filter_list">
                <p><strong>Status</strong></p>
                <label className="check_bx">
                    <input type="checkbox" checked={_.find(filters, { type: 'status', value: 'All' })} onChange={(e) => onChangeFilter({ type: 'status', value: 'All' })} />
                    All
                    <span className="checkmark"></span></label>
                <label className="check_bx"><input type="checkbox" checked={_.find(filters, { type: 'status', value: 'Draft' })} onChange={(e) => onChangeFilter({ type: 'status', value: 'Draft' })} />
                    Draft
                    <span className="checkmark"></span></label>
                <label className="check_bx"><input type="checkbox" checked={_.find(filters, { type: 'status', value: 'Open' })} onChange={(e) => onChangeFilter({ type: 'status', value: 'Open' })} />
                    Open
                    <span className="checkmark"></span></label>
                <label className="check_bx"><input type="checkbox" checked={_.find(filters, { type: 'status', value: 'Filled' })} onChange={(e) => onChangeFilter({ type: 'status', value: 'Filled' })} />
                    Filled
                    <span className="checkmark"></span></label>
                <label className="check_bx"><input type="checkbox" checked={_.find(filters, { type: 'status', value: 'Closed' })} onChange={(e) => onChangeFilter({ type: 'status', value: 'Closed' })} />
                    Closed
                    <span className="checkmark"></span></label>
            </div> */}
            </div>
        </div>
    );
}

export default JobListSidebar;