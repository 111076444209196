import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class peopleBanner extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <div>
 <section id="inner_banner">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 text-center inner_head">
                                <div className="top_left_info clearfix">
                                    <h2>STARmeter </h2>
                                    <p>Weekly rankings track the popularity of people, based on the activity of millions of IMDb users <a href="#">Learn More</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
export default peopleBanner;