import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight, FaTimes } from "react-icons/fa";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import '../../scss/celebrityManagement.scss'
import _ from "lodash";

class AdvertisingAgency extends Component {

  render() {
    return (
      <>
        <Header />
        <SubHeader /> 
          <section id="inner_celeb_banner">
            <div class="container">
              <div class="row">
                <div class="col-sm-6 col-xs-8 inner_celebrity_info">
                  <h1>GOOGLE AD AGENCY</h1>
                  <p align="justify"> There are only few of the<strong> Google Ads Agencies</strong> that have managed more than 640 paid advertisement campaigns for large industries like healthcare, ecommerce and manufacturing. The companies need to get connected with the most trusted <strong>Google AdWords agency</strong> in the industry who can give good results. </p>
                
              <Link to="/ReachUs" class="stroke_btn">Contact US</Link>
                </div>
                <div class="col-sm-6 inner_celebrity_img">
                <img src="images/google-adv-agency.webp" alt="BollywoodisGood is best Celebrity Management Company in India" width="699" height="450" class="wow fadeInDown" data-wow-delay="0.3s" />	 
                </div>
              </div>
            </div>
          </section>

          <section id="inner_about">
          <div class="container">
          <div class="row">
          <div class="col-sm-5">
          <img src="images/google-paid-ads.webp"  width="458" height="458" alt="Celebrity Management Services in India" />
          </div>
          <div class="col-sm-7 inner_about_info">
          <div class="inner_head">
          <h2>Why our Google Ads agency?</h2>
          </div>
          <p align="justify">BollywoodisGood is a renowned <strong>Google Ad Company</strong> that will give best results, hence share your budget, goal and details with us and our team will assist you. </p>
          <br />              
          <p align="justify">Selecting BollywoodisGood as your <strong>Google Ads agency</strong> provides numerous competitive benefits, containing:</p>
          <ul>
            <li>Google Premier Partner</li>
            <li>Proactive campaign management</li>
            <li>Custom Google Ads strategy</li>
            <li>Transparent pricing</li>
            <li>Tailored reporting dashboard</li>
            <li>Devoted account manager</li>
            <li>Copywriting, In-house advertising, development and design team.</li>
          </ul>
          <br />
          <p align="justify">Scroll down to know more about us and why we’re the <strong>best Google AdWords agency for businesses</strong>.</p>
          </div>
          </div>
          </div>
          </section>

          <section id="our_role">
          <div class="container">
          <div class="row">
          <div class="col-sm-7 role_info">          
          <div class="inner_head">
          <h2>What do our Google Paid Advertisement services contains?</h2>
          </div>
          <p align="justify"> BollywoodisGood as your <strong>Google Paid Advertisement services</strong> offers you with a numerous solution to marketing on Google. From remarketing campaigns, Google Local Services ads, or Google Shopping ads, we are here to assist you and have experts with intense knowledge and experience about the same.  </p>
        <br /> 
          <p align="justify"><strong>The management services for our Google Ads contain the following:</strong></p>
          <br /> 
          <ul>
            <li> Devoted account manager</li>
            <li> Strategic bid management</li>
            <li> Lead tracking</li>
            <li> Monthly performance and examination performance</li>
            <li> Custom strategy</li>
            <li> Google Analytics integration</li>
            <li> Call tracking</li>
            <li> Return on investment tracking</li>
          </ul>
         </div>
          <div class="col-sm-5">
          <img src="images/our_role_img.webp"  loading="lazy" width="458" height="459" alt="Artist Management Services" />
          </div>
          </div>
          </div>
          </section>  

          <section id="inner_about">
          <div class="container">
          <div class="row">
          <div class="col-sm-5">
          <img src="images/google-adv-services.webp"  loading="lazy" width="458" height="459" alt="Artist Management Services" />
          </div>
          <div class="col-sm-7 role_info">
          <div class="inner_head">
          <h2>Gogle Paid Advertisement Services</h2>
          </div>
          <p align="justify"> <strong>Particular services or service plans provides access to extra features. Some examples contains:</strong></p>
          <br />
          <ul>
          <li>Conversion analysis reporting</li>
          <li>Landing page design</li>
          <li>International campaign management</li>
          <li>Rule-based bidding setup and management</li>
          <li>Professional banner ad design</li>
          <li>Landing page performance testing</li>
          </ul>
          <br />
          <p align="justify">The BollywoodisGood working with our <strong>Google Ads agency</strong> Businesses from various industries and areas connect with us for complete service of Google Ads as we offer hands-on approach, proven results and custom strategies.</p>  
          </div>
          </div>
          </div>
          </section>

            <section id="our_role">
          <div class="container">
          <div class="row">
          <div class="col-sm-7 role_info">          
          <div class="inner_head">
          <h2>Get a preview of our paid advertising services</h2>
          </div>
          <h4>RESEARCH AND ANALYSIS</h4>
          <p align="justify">Every business is unique and need to be understood before performing any sort of marketing for it. Hence, BollywoodisGood invests a major amount of time in research process for your industry, products, and services. We even take time to understand the team you were with because it’s not about completing the work but it’s about building a brand and revenue of the company. While understanding the company and its team we even give emphasis on examining the following areas.</p>
          <br /> 
          <ul>
            <li>Latest ad strategy and campaigns of the company</li>
            <li>Latest ad account structure of the company</li>
            <li>Competitor’s ad strategy and campaigns of the company</li>
            <li>Target audience of the company</li>
          </ul>
          <br />
          <p align="justify">With the utilization of latest tools BollywoodisGood <strong>Google AdWords agency</strong> can obtain deep understanding of your competitor’s movement. Our team can even analyze low-competition, high-value keywords to enhance your results and reduce your cost-per-click (CPC).</p>
         </div>
          <div class="col-sm-5">
          <img src="images/google-adv-company.webp"  loading="lazy" width="458" height="410" alt="Artist Management Services" />
          </div>
          </div>
          </div>
          </section>   

          <section id="inner_services">
          <div class="container">  
          <div class="inner_head">
          <h2>STRATEGY DEVELOPMENT</h2>
          </div>       
          <blockquote>
          <p align="justify">For your business our devoted team begins the procedure of developing a custom campaign. The managers will tailor your campaign as per the goal of the company such as creating more leads to driving further product sales.</p>
          <br />
          <p align="justify">Their plan also includes the following areas:</p>
          <br />
          <ol>
            <li>Preferred landing page</li>
            <li>Targeting Keywords</li>
            <li>Audience targeting</li>
            <li>Suggested monthly budget</li>
            <li> Suggested bids</li>
            <li>Amount of campaigns and ad groups</li>
          </ol>
          <br />
          <p align="justify">As a complete Google Ad company, other ad networks can also be created by BollywoodisGood’s. For instance we have expert professional who can create campaign for Bing, and social media sites such as Facebook, LinkedIn and Instagram.</p>
          </blockquote>
         </div>
        
          </section> 

          <section id="inner_about">
          <div class="container">
          <div class="row">
          <div class="col-sm-5">
          <img src="images/google-ppc-ads-services.webp"  loading="lazy" width="458" height="459" alt="Artist Management Services" />
          </div>
          <div class="col-sm-7 role_info">
          <div class="inner_head">
          <h2>ONGOING MANAGEMENT</h2>
          </div>
          <p align="justify">The business or company connects with us for BiG’s Google Ads then your company gets a complete-service solution to advertising on Google. Team of BollywoodisGood manages the company’s ad campaigns on a continuing basis. This means we’re frequently enhance your conversion rates, develop your CPC, and maximize your bottom line.</p>
          <p align="justify"> <strong>Our expert team will offer the following:</strong></p>
          <br />
          <ul>
          <li>Strategic management of bids</li>
          <li>Performance testing of your ad copy</li>
          <li>Keyword research and advanced keyword selection</li>
          <li>Tracking of phone calls and leads with the utilization of latest techniques and tools</li>
          <li>Testing and analysis of landing page performance</li>
          </ul>
          <br />
          <p align="justify">For BollywoodisGood as a <strong>Google Ad Agency</strong> will be requiring few details from your team to help us create more accurate campaign for your business. With the combine collaboration we will definitely enhance your revenue and will offer you best results.</p>  
          </div>
          </div>
          </div>
          </section>

          <section id="last_info">
          <div class="container">
          <div class="row">
          <div class="col-xs-12">
          <blockquote>
          <p align="justify">Hence ,Get in touch with BollywoodisGood to experience the best <strong>Google Ads services</strong> to enhance your business.
          </p>
          </blockquote>
          </div>
          </div>
          </div>
          </section>

           
        <Footer />
      </>
    );
  }
}

export default AdvertisingAgency;
