import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class PeopleTableData extends Component {     
    state={
        list: [
            {url:'images/gallery1.jpg', title:'Priyanka Chopra', profession:'Actress | Producer | Soundtrack', movies: 'Halt and Catch Fire (2014-2017) | Black Mirror (2016)', starmeter:'1', thisweek:'50', age:'36', height:'5.10"'},
            {url:'images/gallery2.jpg', title:'Jacqueline fernandez', profession:'Actress | Producer | Soundtrack', movies: 'Halt and Catch Fire (2014-2017) | Black Mirror (2016)', starmeter:'1', thisweek:'50', age:'36', height:'5.10"'},
            {url:'images/slider_side_img.jpg', title:'Kareena Kapoor', profession:'Actress | Producer | Soundtrack', movies: 'Halt and Catch Fire (2014-2017) | Black Mirror (2016)', starmeter:'1', thisweek:'50', age:'36', height:'5.10"'},
        ]        
}

render(){
    const {list} = this.state;
    return(
        < >  
             {list.map(item => 
             <tr>
             <td>
                 <img src={item.url} alt="img" />
                 <h5><Link to="people_detail.php">{item.title}</Link></h5>
                 <small>{item.profession}</small><br />
                 <small><strong>{item.movies}</strong></small>
             </td>
             <td align="center">{item.starmeter}</td>
             <td align="center">{item.thisweek}</td>
             <td align="center">{item.age}</td>
             <td align="center">{item.height}</td>
         </tr>
              )              
             }        
        </ >
           
    );
}

}
 
export default PeopleTableData;