import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaSearch, FaHeart, FaAngleRight, FaStar, FaAngleLeft, FaBookmark, } from 'react-icons/fa';
import _, { isArray } from 'lodash';
import moment from 'moment';
import Pagination from '../Pagination/Pagination';

const SearchJobList = ({ data, onChangeSearch, saveJobs, getSavedJobs, getAppluedJobs, savedJobs, appliedJobs, setToggleFilter }) => {
  let pageSize = 10;
  const [newList, setNewList] = useState([])
  const [pageIndex, setPageIndex] = useState(1);

useEffect(() => {
  if(data){
    let d = data.slice(0,pageSize)
    setNewList(d)
  }
  }, [data])
  const pageChangeHandler = (index) => {
    console.log(index)
    const slicedList = data.slice((index - 1) * pageSize, (index * pageSize))
    setNewList(slicedList)
    setPageIndex(index)
  
  }
  return (
    <div className="col-md-9 col-sm-8 search_job_list" onClick={()=>{setToggleFilter(true)}}>
      <div className="inside_listing_tab shadow_bg">
        <ul className="nav nav-tabs">
          <li className="active"><Link to="#list_tab1" data-toggle="tab">Search</Link></li>
          <li><Link to="#list_tab2" data-toggle="tab"  >Saved Jobs ({savedJobs.length})</Link></li>
          <li><Link to="#list_tab3" data-toggle="tab" >Applied Jobs<span>({appliedJobs.length})</span> </Link></li>
        </ul>

        <div className="tab-content">
          <div className="tab-pane active" id="list_tab1">
            <div className="form-group search_field">
              <input type="search" placeholder="Search for Audition" className="form-control" onChange={e => onChangeSearch(e.target.value)} />
              <button type="button" className="default_btn"><FaSearch /></button>
            </div>
            <div className="searchjob_bx clearfix">
              <ul>

                {
                  _.map(_.orderBy(newList, 'createdAt','desc'), val => (
                    <li>
                         <div className="side_action_btn pull-right">
                        {!savedJobs.map(e=> e._id).includes(val._id) ?<div className="icon" onClick={() => saveJobs(val._id)}><FaBookmark /></div>:
                        <div className="icon saved_job"><FaBookmark /></div>}
                      </div>
                      <div className="searchjob_head clearfix">
                      <h3>{val.title}</h3>
                   <div className="disc_brief">
                   {val.jobTitle &&  <span>
                       <strong>Job Title :</strong>{" "}
                       {val.jobTitle}
                     </span>}
                   {val.filmType && val.filmType.filter(e=> e.length).length> 0 ?  <span>
                       <strong>Production Type :</strong>{" "}
                       {val.filmType?.join(", ")}
                     </span>: ''}
                    {val.talentType  && val.talentType.filter(e=> e.length).length> 0 ? <span>
                       <strong> Character type : </strong>{" "}
                       {val.talentType?.join(", ")}{" "}
                     </span>: ''}
                     {val.workStyle &&  <span>
                       <strong>Work Style :</strong>{" "}
                       {val.workStyle}
                     </span>}
                     {val.productionHouse &&  <span>
                       <strong>Production House :</strong>{" "}
                       {val.productionHouse}
                     </span>}
                     {val.benefits &&  val.benefits.length ?<span>
                       <strong>benefits :</strong>{" "}
                       {isArray(val.benefits) ? val.benefits?.join(", "): val.benefits}
                     </span>:''}
                    {val.currentCity &&  <span>
                       <strong>City : </strong> {val.currentCity}
                     </span>}
                    {val.minAge &&  <span>
                       <strong>Age: </strong> {val.minAge} - {val.maxAge}
                     </span>}
                     <span>
                       <strong>Posted on : </strong>{" "}
                       {moment(val.createdAt).format("YYYY-MM-DD")}
                     </span>
                   </div>
                   {val.description && <span className="discrption">
                     <strong>Charecter Breif / Sketch : </strong>
                     {val.description}
                   </span>}
                   {val.jobDescription && <span className="discrption">
                     <strong>Job Description : </strong>
                     {val.jobDescription}
                   </span>}{" "}
                  {val.languagesSpeak && val.languagesSpeak.length ? <span>
                     <strong>Language Speak : </strong>
                     {val.languagesSpeak?.join(", ")}
                   </span> : ''}
                   {/* <p><strong>{val.filmType}</strong> | <strong>{val.genre}</strong> | <span><strong>Hourly: {val.hourlyRate}</strong></span> | <span><strong>{val.experienceLevel}</strong></span> | <span>{moment(val.createdAt).format('LL') }</span> |  <span><strong>Mumbai</strong></span></p> */}
                 </div>
 
                      <div className="searchjob_point">
                        {/* <div className="search_btm_info">
                          <p><strong>Payment unverified</strong></p>
                          <div className="rating">
                            <div className="star">
                              <FaStar />
                              <FaStar />
                              <FaStar />
                              <FaStar className="unfill" />
                              <FaStar className="unfill" />
                            </div>
                          </div>
                          <p>Spent <strong>Rs 100k+</strong></p>
                        </div> */}
                        <Link  to={`/UserSideJob/${val._id}`}>More Details</Link>
                        <div className="application_info">
                          <p>Application: <strong>{val.applications ?.length}</strong></p>
                          <Link to={`/UserSideJob/${val._id}`}>Apply</Link>
                        </div>
                      </div>
                    </li>
                  ))
                }

              </ul>
              <div className="pagination_part clearfix text-right">
                <br />
                <Pagination totalCount={data?.length || 0} pageSize={pageSize} onPageChange={pageChangeHandler} currentPage={pageIndex} />

              </div>
            </div>
          </div>

          <div className="tab-pane" id="list_tab2">
            <div className="searchjob_bx clearfix">
              <ul>
                {
                  _.map(savedJobs, val => (
                    <li>
                       <div className="side_action_btn pull-right">
                        <div className="icon saved_job"><FaBookmark /></div>
                      </div>
                       <div className="searchjob_head clearfix">
                      <h3>{val.title}</h3>
                   <div className="disc_brief">
                   {val.jobTitle &&  <span>
                       <strong>Job Title :</strong>{" "}
                       {val.jobTitle}
                     </span>}
                   {val.filmType && val.filmType.filter(e=> e.length).length> 0 ?  <span>
                       <strong>Production Type :</strong>{" "}
                       {val.filmType?.join(", ")}
                     </span>: ''}
                    {val.talentType  && val.talentType.filter(e=> e.length).length> 0 ? <span>
                       <strong> Character type : </strong>{" "}
                       {val.talentType?.join(", ")}{" "}
                     </span>: ''}
                     {val.workStyle &&  <span>
                       <strong>Work Style :</strong>{" "}
                       {val.workStyle}
                     </span>}
                     {val.productionHouse &&  <span>
                       <strong>Production House :</strong>{" "}
                       {val.productionHouse}
                     </span>}
                     {val.benefits &&  val.benefits.length ?<span>
                       <strong>benefits :</strong>{" "}
                       {isArray(val.benefits) ? val.benefits?.join(", "): val.benefits}
                     </span>:''}
                    {val.currentCity &&  <span>
                       <strong>City : </strong> {val.currentCity}
                     </span>}
                    {val.minAge &&  <span>
                       <strong>Age: </strong> {val.minAge} - {val.maxAge}
                     </span>}
                     <span>
                       <strong>Posted on : </strong>{" "}
                       {moment(val.createdAt).format("YYYY-MM-DD")}
                     </span>
                   </div>
                   {val.description && <span className="discrption">
                     <strong>Charecter Breif / Sketch : </strong>
                     {val.description}
                   </span>}
                   {val.jobDescription && <span className="discrption">
                     <strong>Job Description : </strong>
                     {val.jobDescription}
                   </span>}{" "}
                  {val.languagesSpeak && val.languagesSpeak.length ? <span>
                     <strong>Language Speak : </strong>
                     {val.languagesSpeak?.join(", ")}
                   </span> : ''}
                   {/* <p><strong>{val.filmType}</strong> | <strong>{val.genre}</strong> | <span><strong>Hourly: {val.hourlyRate}</strong></span> | <span><strong>{val.experienceLevel}</strong></span> | <span>{moment(val.createdAt).format('LL') }</span> |  <span><strong>Mumbai</strong></span></p> */}
                 </div>
                     
                      <p className="job_des">{val.description}</p>
                      <div className="skills">
                        <span> {val.genre}</span>
                      </div>

                      <div className="searchjob_point">
                        <div className="search_btm_info">
                          {/* <p><strong>Payment unverified</strong></p>
                          <div className="rating">
                            <div className="star">
                              <FaStar />
                              <FaStar />
                              <FaStar />
                              <FaStar className="unfill" />
                              <FaStar className="unfill" />
                            </div>
                          </div>
                          <p>Spent <strong>Rs 100k+</strong></p> */}
                          <Link to={`/UserSideJob/${val._id}`}>More Details</Link>
                        </div>

                        <div className="application_info">
                          <p>Application: <strong>{val.applications ?.length}</strong></p>
                        </div>
                      </div>
                    </li>
                  ))
                }

              </ul>
              <div className="pagination_part clearfix text-right">
                <br />
                {/* <ul className="pagination">
                  <li><Link to="#"><FaAngleLeft /> Prev</Link></li>
                  <li><Link to="#">1</Link></li>
            <li><Link to="#">2</Link></li>
            <li><Link to="#">3</Link></li>
            <li><Link to="#">4</Link></li>
            <li><Link to="#">5</Link></li>
                  <li><Link to="#"> Next <FaAngleRight /></Link></li>
                </ul> */}
              </div>
            </div>
          </div>
          <div className="tab-pane" id="list_tab3">
            <div className="searchjob_bx clearfix">
              <ul>
                {
                  _.map(appliedJobs, val => (
                    <li>

                       <div className="searchjob_head clearfix">
                      <h3>{val.title}</h3>
                   <div className="disc_brief">
                   {val.jobTitle &&  <span>
                       <strong>Job Title :</strong>{" "}
                       {val.jobTitle}
                     </span>}
                   {val.filmType && val.filmType.filter(e=> e.length).length> 0 ?  <span>
                       <strong>Production Type :</strong>{" "}
                       {val.filmType?.join(", ")}
                     </span>: ''}
                    {val.talentType  && val.talentType.filter(e=> e.length).length> 0 ? <span>
                       <strong> Character type : </strong>{" "}
                       {val.talentType?.join(", ")}{" "}
                     </span>: ''}
                     {val.workStyle &&  <span>
                       <strong>Work Style :</strong>{" "}
                       {val.workStyle}
                     </span>}
                     {val.productionHouse &&  <span>
                       <strong>Production House :</strong>{" "}
                       {val.productionHouse}
                     </span>}
                     {val.benefits &&  val.benefits.length ?<span>
                       <strong>benefits :</strong>{" "}
                       {isArray(val.benefits) ? val.benefits?.join(", "): val.benefits}
                     </span>:''}
                    {val.currentCity &&  <span>
                       <strong>City : </strong> {val.currentCity}
                     </span>}
                    {val.minAge &&  <span>
                       <strong>Age: </strong> {val.minAge} - {val.maxAge}
                     </span>}
                     <span>
                       <strong>Posted on : </strong>{" "}
                       {moment(val.createdAt).format("YYYY-MM-DD")}
                     </span>
                   </div>
                   {val.description && <span className="discrption">
                     <strong>Charecter Breif / Sketch : </strong>
                     {val.description}
                   </span>}
                   {val.jobDescription && <span className="discrption">
                     <strong>Job Description : </strong>
                     {val.jobDescription}
                   </span>}{" "}
                  {val.languagesSpeak && val.languagesSpeak.length ? <span>
                     <strong>Language Speak : </strong>
                     {val.languagesSpeak?.join(", ")}
                   </span> : ''}
                   {/* <p><strong>{val.filmType}</strong> | <strong>{val.genre}</strong> | <span><strong>Hourly: {val.hourlyRate}</strong></span> | <span><strong>{val.experienceLevel}</strong></span> | <span>{moment(val.createdAt).format('LL') }</span> |  <span><strong>Mumbai</strong></span></p> */}
                 </div>
                     
                      <p className="job_des">{val.description}</p>
                      <div className="skills">
                        <span> {val.genre}</span>
                      </div>

                      <div className="searchjob_point">
                        <div className="search_btm_info">
                          {/* <p><strong>Payment unverified</strong></p>
                          <div className="rating">
                            <div className="star">
                              <FaStar />
                              <FaStar />
                              <FaStar />
                              <FaStar className="unfill" />
                              <FaStar className="unfill" />
                            </div>
                          </div>
                          <p>Spent <strong>Rs 100k+</strong></p> */}
                          {/* <Link to={`/UserSideJob/${val._id}`}>More Details</Link> */}
                          <Link to={`/UserApplication/${val._id}`}>More Details</Link>
                        </div>
                          <div className="application_info">
                          <p>Application: <strong>{val.applications ?.length}</strong></p>
                        </div>
                      </div>
                    </li>
                  ))
                }

              </ul>
              <div className="pagination_part clearfix text-right">
                <br />
                {/* <Pagination totalCount={data?.length || 0} pageSize={pageSize} onPageChange={pageChangeHandler} currentPage={pageIndex} /> */}
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  )
}
export default SearchJobList;
