import React, { Component, useState } from "react";
import { Link } from 'react-router-dom';
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import HiredContractsList from "../../components/HiredContracts/HiredContractsList";
import HiredContractsFilter from "../../components/HiredContracts/HiredContractsFilter";
import { FaSlidersH } from "react-icons/fa";

const HiredContractsPage = (props)  => {
  const [showFilter, setToggleFilter] = useState(true);
  return(  
  <div>
    <Header />
    <SubHeader />
     {/* CTA section start*/}
     <section id="cta" className="mid_info dark_clr inner_banner">
          <div className="container side_space">
          <div class="home_grey_bx clearfix"><img src="images/post_audition.png" alt="img" class="right_pattern" />
            <div className="row">   
            <div className="col-md-9 cta_info">
            <h2>Track and Manage Your Hired Talents</h2>
            <p>Streamline Your Process with Bollywood is Good's Hired List Feature</p>
            </div>
            </div>
            </div>
          </div>
          <br />
      </section>
      {/* CTA section ends */}
    <section id="breakdown_list" className="dark_clr audition_list">
      <div className="container">
        <div className="row">
        <div className="col-sm-12 heading header_btn">
                        <h2>List of hired contracts</h2>
                        <button onClick={()=> window.history.back()} className="blue_btn">Back</button> 
                </div>
                <div className="filter-toggler" onClick={()=>{setToggleFilter(!showFilter)}}><button className="blue_btn"><FaSlidersH /> Filters </button></div>
          <HiredContractsFilter {...props} {...{showFilter: showFilter, setToggleFilter: setToggleFilter}} />
          <HiredContractsList {...props} {...{showFilter: showFilter, setToggleFilter: setToggleFilter}} />
        </div>
      </div>
    </section>
    <Footer />
  </div>
  )};
export default HiredContractsPage;
