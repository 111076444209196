import { makeStyles } from '@material-ui/core/styles';

export const globalStyles = makeStyles((theme) => ({

    Button: {
        background: theme.palette.button.highlight.background.normal + ' !important',
        color: theme.palette.button.highlight.text.normal + ' !important',
        "&:hover": {
            textDecoration: "none" + ' !important',
            boxShadow: 'none' + ' !important',
            backgroundColor: theme.palette.button.highlight.background.hover + ' !important',
            cursor: "pointer",
            // Reset on touch devices, it doesn't add specificity
            "@media (hover: none)": {
                backgroundColor: theme.palette.button.highlight.background.hover + ' !important'
            }
        },
        "&:active": {
            backgroundColor: theme.palette.button.highlight.background.press + ' !important'
        },
        "&:disabled": {
            background: theme.palette.button.highlight.background.disabled + ' !important',
            color: theme.palette.button.highlight.text.disabled + ' !important',
            boxShadow: 'none' + ' !important',
            cursor: "not-allowed",
        },
        fontSize: theme.typography.fontSizeRegular + ' !important',
        fontFamily: theme.typography.fontFamily + ' !important',
        letterSpacing: '1px' + ' !important',
        lineHeight: '1.42857143' + ' !important',
        boxShadow: 'none' + ' !important'
    },

    normalLabel: {
        color: theme.palette.button.highlight.text.normal + ' !important',
        fontSize: theme.typography.fontSizeRegular + ' !important',
        fontWeight: theme.typography.fontWeightLight + ' !important',
        fontFamily: theme.typography.fontFamily + ' !important'
    },

    boldLabel: {
        color: theme.palette.button.highlight.text.normal + ' !important',
        fontSize: theme.typography.fontSizeRegular + ' !important',
        fontWeight: theme.typography.fontWeightBold + ' !important',
        fontFamily: theme.typography.fontFamily + ' !important'
    }
}));

