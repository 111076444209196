import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { FaMoneyBill, FaCalendarCheck, FaSuitcase, FaStarHalf, FaStar } from "react-icons/fa";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import _, { isArray } from "lodash";
import { saveJobs } from "../../actions";
import Swal from "sweetalert";

const UserSideJobDetail = ({ jobPostDetail, user, jobApplication, ...props }) => {
  let { job, clientRecentJobs,similarJobs } = jobPostDetail;
  console.log(jobPostDetail, jobApplication, "deijdoeijdoiej");
  job = job || {};
  clientRecentJobs = clientRecentJobs || [];

  const saveJob = id => {
    saveJobs(id)
        .then(res => {
            // localStorage.setItem('user', JSON.stringify(res.data.data))
            // this.getJobList(this.state.search);
            // if (this.state.token) {
            //     this.getCurrentUser()
            // }
            Swal(`Saved`, { buttons: false, timer: 1700, icon: 'success' });
            setTimeout(() => {
              window.location.reload()
            }, 500);
        });
}
  return (
    <>
      <Header {...props} />
      <SubHeader />
      <section id="postjob_detail" className="default_bg">
        <img src="images/make_film_bg.png" alt="img" className="bg_img" />
        <div className="container">

          <div className='justify-between'>
            <h3 className="main_head">Job Details</h3>
            <button className="blue_btn" onClick={()=> window.history.back()}>Back</button>
          </div>
          <div className="row">

            <div className="col-md-9">
              <div className="postjob_tab">
                <ul className="nav nav-tabs">
                  <li className="active"><Link to="#tab_default_1" data-toggle="tab">Job Details</Link></li>
                  <li><Link to="#tab_default_2" data-toggle="tab">Client history</Link></li>
                  <li><Link to="#tab_default_3" data-toggle="tab">Similar Jobs </Link></li>
                </ul>
              </div>
              <div className="tab-content">
                <div className="tab-pane active" id="tab_default_1">
                <div className="shadow_bg clearfix">
    <h5><strong>{job.title}</strong></h5>
    <hr />
    <div className='job_cat row' >
      {/* <h6>{job.category}</h6> */}
      <div className='col-md-6'><strong>Job Category: </strong> <small>{job.category}</small></div>
      {job.jobTitle && <div className='col-md-6'><strong>Job Title: </strong> <small>{job.jobTitle}</small></div>}

      <div className='col-md-6'><strong>Posted on: </strong> <small>{moment(job.createdAt).format('ll')}</small></div>
    </div>
    <hr />
    <div className="job_list job_des_info">
      <ul>
        {job.talentType  && job.talentType.filter(e=> e.length).length> 0 ? <li>
          <span>Character :</span> {job.talentType ?.join(', ')}
        </li> :''}
        {job.roleType && <li>
          <span>Role Type:</span> {job.roleType}
        </li>}
      {job.filmType && job.filmType.length ? <li>
          <span>Film / Production Type:</span>  {job.filmType ?.join(', ')}
        </li>:''}
       {job.filmIndustry && job.filmIndustry.length ? <li>
          <span>Film Industry:</span>  {job.filmIndustry ?.join(', ')}
        </li> :''}
        {job.productionHouse && <li>
          <span>Production House:</span> {job.productionHouse}
        </li>}
        {job.mustHave && <li>
          <span>Must Have:</span> {job.mustHave}
        </li>}
      </ul>
    </div>
    { job.description &&
    <>
    <strong>Description</strong>
    <p>{job.description}</p>
    </>
    }
    { job.jobDescription &&
    <>
    <strong>Job Description</strong>
    <p>{job.jobDescription}</p>
    </>
    }
    <hr />
    <div className="job_list mid_job_info clearfix">
      <ul>
        <li>
          <div className="side_icon text-center">
            <FaMoneyBill />
          </div>
          <div className="job_info">
            <span><strong>{job.minAmount} - {job.maxAmount}</strong></span>
            <small>{job.payType}</small>
          </div>
        </li>
        <li>
          <div className="side_icon text-center">
            <FaSuitcase />
          </div>
          <div className="job_info">
           {job.minExperience ? <span><strong>{job.minExperience} - {job.maxExperience}</strong></span> : ' Yet to Submit'}
            <small>Experience</small>
          </div>
        </li>
        <li>
          <div className="side_icon text-center">
            <FaCalendarCheck />
          </div>
          <div className="job_info">
            {job.shootStartDate ? <span><strong>{job.shootStartDate} to {job.shootEndDate}</strong></span> : ' Yet to Submit'}
            <small>Shoot / Task Dates</small>
          </div>
        </li>
      </ul>
    </div>
    <hr />
    <div class="row">
      {job.category === 'Artist or Actor or Talent' && <div className="job_list job_list_info col-md-6">
        <h6>Physical Information</h6>
        <ul>
          {job.minAge &&<li>
            <span>Age:</span>
            {job.minAge} yr to {job.maxAge} yr
                      </li>}
         { job.minHeightInch && <li>
            <span>Height:</span>
            {job.minHeightFeet} ft  {job.minHeightInch} in
                        to  {job.maxHeightFeet} ft  {job.maxHeightInch} in
                      </li>}
        {job.maxWeight &&  <li>
            <span>Weight:</span>
            {job.minWeight} kg to {job.maxWeight} kg
                      </li>}
         {job.physique && <li>
            <span>Physique:</span> {job.physique}
          </li>}
         {job.uniqueTraits && <li>
            <span>Unique Traits:</span> {job.uniqueTraits}
          </li>}
         {job.appearance && <li>
            <span>Appearance or D/C:</span>
            <small>{job.appearance}</small>
          </li>}
        </ul>
      </div>}
      {job.category !== 'Artist or Actor or Talent' && <div className="job_list job_list_info col-md-6">
      <h6>Basic Information</h6>
       <ul>
      {job.workStyle && <li>
            <span>Work Style:</span>
            {job.workStyle}
        </li>}
        { job.schedule && <li>
            <span>Schedule:</span>
            {job.schedule}
        </li>}
       {job.abilityToCommut && <li>
            <span>Ability To Commut:</span>
            {job.abilityToCommut}
        </li>}
      { isArray(job.benefits) && job.benefits.length ? <li>
            <span>Benefits:</span>
            {job.benefits?.join(', ')}
        </li> :''}
       </ul>
      </div>}
      <div className="job_list job_list_info col-md-6">
        <h6>Other Information</h6>
        <ul>
        {job.mandatoryConditions &&  <li>
            <span>Mandatory Conditions:</span> {job.mandatoryConditions}
          </li>}
          {job.category === 'Artist or Actor or Talent' && job.specificRoleCriteria && <li>
            <span>Specific Role Criteria:</span> {job.specificRoleCriteria}
          </li>}
          {job.shootLocations && <li>
            <span>Shoot Locations:</span> {job.shootLocations}
          </li>}
          {/* <li>
            <span>Passport:</span> Not required
                      </li> */}
         {job.jobNature && <li>
            <span>Job Nature:</span> {job.jobNature}
          </li>}
          {/* <li>
            <span>Introduction Video Needed:</span> Yes
                      </li> */}
        </ul>
      </div>
    </div>
    <hr />
    <div className="job_list">
      <h6>Screening Questions</h6>
      <ul>
        {
          _.map(job.screeningQuestions, (val, i) => (
            <li>{i + 1}. {val}</li>
          ))
        }

      </ul>
    </div>
    <hr />
    <div class="row">
      <div className="job_list col-md-6">
        <h6>Other Details</h6>
        <ul>
          { job.genre &&<li><strong>Genre:</strong> {job.genre}</li>}
        {job.languagesRead && job.languagesRead.length ?<li><strong>Languages Asked While Job Creation:</strong> {job.languagesRead}</li>:''}
          {job.visibility && <li><strong>Job Visibility:</strong> {job.visibility}</li>}
         {job.highestQualification && <li><strong>Highest Qualification:</strong> {job.highestQualification}</li>}
          { job.talentCount && <li><strong>Total Number of Vacancy:</strong> {job.talentCount}</li>}
          {job.currentCountry && <li><strong>Country:</strong>{job.currentCountry}</li>}
        </ul>
      </div>
      <div className="job_list col-md-6">
        <h6>Job Status</h6>
        <ul>
          <li><strong>Applied Users:</strong>{Number(job.appliedUsers ?.length || 0)}</li>
          <li><strong>Interviewing:</strong>{Number(job.shortListedUsers ?.length || 0)}</li>
          <li><strong>Invite Sent:</strong>{Number(job.invites ?.length || 0)}</li>
          {/* <li><strong>Unanswered Invites:</strong> 0</li> */}
        </ul>
      </div>
    </div>
  </div>
                </div>

                <div className="tab-pane" id="tab_default_2">
                  <div className="shadow_bg clearfix client_his">
                    <h5>
                      <strong>Client's recent history ({clientRecentJobs ?.length})</strong>
                    </h5>
                    <hr />
                    <ul>

                      {
                        _.map(clientRecentJobs, val => (
                          <>
                            <li className="breakdown_casting_info">
                            <div className="casting_list_head clearfix">
                   <h3>{val.title}</h3>
                   <div className="disc_brief">
                     {val.jobTitle &&  <span>
                       <strong>Job Title :</strong>{" "}
                       {val.jobTitle}
                     </span>}{' '}
                     
                   {val.filmType &&  <span>
                       <strong>Production Type :</strong>{" "}
                       {val.filmType?.join(", ")}
                     </span>}{' '}
                    {val.talentType  && val.talentType.filter(e=> e.length).length> 0 ? <span>
                       <strong> Character type : </strong>{" "}
                       {val.talentType?.join(", ")}{" "}
                     </span>: ''}{' '}
                     {val.workStyle &&  <span>
                       <strong>Work Style :</strong>{" "}
                       {val.workStyle}
                     </span>}{' '}
                     {val.productionHouse &&  <span>
                       <strong>Production House :</strong>{" "}
                       {val.productionHouse}
                     </span>}{' '}
                     {val.benefits &&  val.benefits.length ?<span>
                       <strong>benefits :</strong>{" "}
                       {isArray(val.benefits) ? val.benefits?.join(", "): val.benefits}
                     </span>:''}{' '}
                    {val.currentCity &&  <span>
                       <strong>City : </strong> {val.currentCity}
                     </span>}{' '}
                    {val.minAge &&  <span>
                       <strong>Age: </strong> {val.minAge} - {val.maxAge}
                     </span>}{' '}
                     <span>
                       <strong>Posted on : </strong>{" "}
                       {moment(val.createdAt).format("YYYY-MM-DD")}
                     </span>{' '}
                   </div>
                   {val.description && <span className="discrption">
                     <strong>Charecter Breif / Sketch : </strong>
                     {val.description}
                   </span>}
                   {val.jobDescription && <span className="discrption">
                     <strong>Job Description : </strong>
                     {val.jobDescription}
                   </span>}{" "}
                  {val.languagesSpeak && val.languagesSpeak.length ? <span>
                     <strong>Language Speak : </strong>
                     {val.languagesSpeak?.join(", ")}
                   </span> : ''}
                   {/* <p><strong>{val.filmType}</strong> | <strong>{val.genre}</strong> | <span><strong>Hourly: {val.hourlyRate}</strong></span> | <span><strong>{val.experienceLevel}</strong></span> | <span>{moment(val.createdAt).format('LL') }</span> |  <span><strong>Mumbai</strong></span></p> */}
                 </div>
                 <div className="casting_list_btn clearfix">
                   <Link to={`/PostJobDetail/${val._id}`}>More Details</Link>

                   </div>
                            </li>
                            <hr />
                          </>
                        ))
                      }

                    </ul>
                  </div>
                </div>

                <div className="tab-pane" id="tab_default_3">
                  <div className="shadow_bg clearfix other_job">
                    <h5>
                      <strong>Similar Jobs </strong>
                    </h5>
                    <hr />
                    <ul>
                      {
                        _.map(similarJobs, val => (
                          <>
                            <li>
                              <h6>{val.title}</h6>

                              <p>{val.description}</p>
                              <div className="applicant_rating">
                                <p>
                                  <small>To Applicant:</small>
                                  <Link to="">Rahul Sharma</Link>
                                  <div className="rating">
                                    <div className="star">
                                      <FaStar />
                                      <FaStar />
                                      <FaStar />
                                      <FaStar />
                                      <FaStarHalf />
                                    </div>
                                  </div>
                                </p>
                              </div>
                              <div className="project_shotinfo">
                                <p>{moment(val.createdAt).format("ll")}</p>
                                <p>
                                  <strong>Fixed-price Rs {val.totalBudget}</strong>
                                </p>
                              </div>
                            </li>
                            <hr />
                          </>
                        ))
                      }
                      {!similarJobs || similarJobs?.length === 0 ? <li style={{display:'flex', justifyContent:'center'}}>No Similar Job Found</li>:''}
                    </ul>
                  </div>
                </div>


              </div>
            </div>
            <div className="col-md-3">
              <div className="detail_sidebar">
                <div className="apply_job">
                  {!props.applied ?
                    // {true ?
                    <Link to={`/SubmitApplication/${job._id}`} class="blue_btn">
                      Submit Application
                  </Link>
                    : null
                  }
                  {!props.saved ?
                    <button onClick={()=>saveJob(job._id)} class="stroke_btn">
                      Save Job
                  </button>
                    :
                    <button  class="stroke_btn">
                      Saved
                  </button>
                  }
                  {/* <p>Required Connects to submit Application: 1</p>
                  <p>Available Connects: 14</p> */}
                </div>
                {/* <div className="about_client">
                  <h6>About the client</h6>
                  <strong>Payment method verified</strong>
                  <div className="rating">
                    <div className="star">
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStarHalf />
                    </div>
                    <p>4.50 of 10 reviews</p>
                  </div>
                  <ul>
                    <li>
                      <strong>Mumbai</strong>
                      <p>04:37 am</p>
                    </li>
                    <li>
                      <strong>45 jobs posted</strong>
                      <p>43% hire rate, 4 open jobs</p>
                    </li>
                    <li>
                      <strong>Rs 500k+ total spent</strong>
                      <p>10 hires, 5 active</p>
                    </li>
                    <li>
                      <strong>300/hr avg hourly rate paid</strong>
                      <p>369 hours</p>
                    </li>
                  </ul>
                  <small>Member since Nov 4, 2020</small>
                </div>
                <div className="job_link">
                  <h6>Job link</h6>
                  <input
                    type="text"
                    readOnly="readonly"
                    disabled="disabled"
                    class="form-control"
                  />
                  <Link to="">Copy link</Link>
                </div> */}
              </div>

            </div>


          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default UserSideJobDetail;
