import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FaAngleLeft, FaAngleRight, FaTimes } from "react-icons/fa";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import { FaMapMarkerAlt } from "react-icons/fa";
import Footer from "../../components/shared/footer/Footer";
import "../../scss/celebrityManagement.scss";
import _ from "lodash";

class ReachUsfFrom extends Component {
  render() {
    return (
      <>
        <Header />
        <SubHeader />
        <section id="celebrity_contact">
          <div class="container">
            <div class="row">
              <div class="col-xs-12 cele_contact_info">
                <h1>Contact Us</h1>
                <h3>Send us your query</h3>
              </div>
            </div>
          </div>
        </section>

        <section id="our_offices">
          <div class="container">
            <div class="row flex_row">
              <div class="col-md-7 col-sm-6 office_detail">
                <div class="row">
                  <div class="col-lg-12 col-md-12 office_info">
                    <div className="image_part">
                      <img
                        class="img"
                        src="https://bollywoodisgood.com/images/mumbai.webp"
                        width="100%"
                        height="200px"
                      />
                    </div>
                    <div className="loc_detail">
                      <h4>
                        Mumbai <strong>Office</strong>
                      </h4>
                      <ul>
                        <li>
                          {" "}
                          <strong>
                            <FaMapMarkerAlt /> Address:
                          </strong>
                          <br />
                          Parinee Crescenzo, 1B-1003, G Block BKC, Bandra Kurla
                          Complex, Bandra East, Mumbai - 400051 Maharashtra,
                          India.
                        </li>
                        <li>
                          <strong>Ph:</strong>+91 930 988 9849
                        </li>
                        <li>
                          <strong>Em:</strong>{" "}
                          <a href="mailto:info@bollywoodisgood.com">
                            info@bollywoodisgood.com
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 office_info">
                    <div className="image_part">
                      <img
                        class="img"
                        src="https://bollywoodisgood.com/images/delhi.webp"
                        width="100%"
                        height="200px"
                      />
                    </div>
                    <div className="loc_detail">
                      <h4>
                        Delhi <strong>Office</strong>
                      </h4>
                      <ul>
                        <li>
                          {" "}
                          <strong>
                            <FaMapMarkerAlt /> Address:
                          </strong>
                          <br />
                          Building No. 51, Opp. Patparganj Industrial Area Delhi
                          - 110092.
                        </li>
                        <li>
                          <strong>Ph:</strong>+91 930 988 9849
                        </li>
                        <li>
                          <strong>Em:</strong>{" "}
                          <a href="mailto:info@bollywoodisgood.com">
                            info@bollywoodisgood.com
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 office_info">
                    <div className="image_part">
                      <img
                        class="img"
                        src="https://bollywoodisgood.com/images/usanew.webp"
                        width="100%"
                        height="200px"
                      />
                    </div>
                    <div className="loc_detail">
                      <h4>
                        USA <strong>Office</strong>
                      </h4>
                      <ul>
                        <li>
                          {" "}
                          <strong>
                            <FaMapMarkerAlt /> Address:
                          </strong>
                          <br />
                          350 Oakmead Pkwy, Suit 200, Sunnyvale, CA - 94085
                        </li>
                        <li>
                          <strong>Ph:</strong> +1 (909) 999-7993
                        </li>
                        <li>
                          <strong>Em:</strong>{" "}
                          <a href="mailto:info@bollywoodisgood.com">
                            info@bollywoodisgood.com
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-5 col-sm-6 celebrity_form">
                <div class="cele_form_bg clearfix">
                  <div class="alert alert-danger hidden" id="contactError">
                    <strong>Error!</strong> There was an error sending your
                    message.
                  </div>
                  <form name="feedback" method="post" class="" action="">
                    <input type="hidden" name="form_name" value="feedback" />
                    <div class="form-group">
                      <label>Full Name *</label>
                      <input
                        type="text"

                        data-msg-required="Please enter your name."
                        maxlength="100"
                        class="form-control"
                        name="name"
                        id="name"
                        required
                      />
                    </div>
                    <div class="form-group">
                      <label>Email *</label>
                      <input
                        type="email"
                        
                        data-msg-required="Please enter your email address."
                        data-msg-email="Please enter a valid email address."
                        maxlength="100"
                        class="form-control"
                        name="email"
                        id="email"
                        required
                      />
                    </div>
                    <div class="form-group">
                      <label>Phone Number *</label>
                      <input
                        type="number"
                      
                        data-msg-required="Please enter the mobile no."
                        maxlength="17"
                        class="form-control"
                        name="mobile"
                        id="subject"
                        required
                      />
                    </div>
                    <div class="form-group">
                      <label>Subject *</label>
                      <input
                        type="text"
                        
                        data-msg-required="Please enter the subject."
                        maxlength="100"
                        class="form-control"
                        name="subject"
                        id="subject"
                        required
                      />
                    </div>
                    <div class="form-group">
                      <label>Message *</label>
                      <textarea
                        maxlength="5000"
                        data-msg-required="Please enter your message."
                        rows="6"
                        class="form-control"
                        name="message"
                        id="message"
                        required
                      ></textarea>
                    </div>
                    <div class="row">
                      <div class="form-group">
                        <div class="col-md-12">
                          <div class="text-center">
                            <img
                              src="https://tokyotechie.com/phpcaptcha/captcha.php?rand=555441786"
                              id="captchaimg"
                            />
                          </div>
                          <div class="text-center">
                            <label>
                              {" "}
                              Can't read the image? click  <span style={{color:"Blue"}}>here</span> to
                              refresh.{" "}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="form-group">
                        <div class="col-md-12">
                          <label>Enter Code *</label>
                          <input
                            id="captcha_code"
                            name="captcha_code"
                            required
                            class="form-control"
                            placeholder="Please enter above code"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="form-group">
                      <input
                        type="submit"
                        class="animate_btn"
                        value="Send Message"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </>
    );
  }
}

export default ReachUsfFrom;
