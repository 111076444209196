import React, { Component, useEffect, useState } from 'react';
import {
  FaStarHalf, FaStar,
  FaList,
  FaPencilAlt,
  FaSearch,
  FaSuitcase,
  FaUsers,
  FaLocationArrow,
  FaFilm,
  FaCaretDown,
} from 'react-icons/fa';
import { Link } from "react-router-dom";

import './index.css';

const SubHeader = ({ user }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState('');
  const openNav = ()=> {
    setShowMenu(true)
    document.getElementById("mySidenav").style.width = "275px";
  }
  
  const closeNav = () =>{
    setShowMenu(false)
    document.getElementById("mySidenav").style.width = "0";
  }
  useEffect(() => {
    // if(activeDropdown){
    var dropdown = document.getElementsByClassName("dropdown-btn");
    var i;
    
    for (i = 0; i < dropdown.length; i++) {
      dropdown[i].addEventListener("click", function() {
        this.classList.toggle(".active-ddm");
        var dropdownContent = this.nextElementSibling;
        if (dropdownContent.style.display === "block") {
          dropdownContent.style.display = "none";
        } else {
          dropdownContent.style.display = "block";
        }
      });
    }
  // }
  }, []);
//  const toggleDropdown =(className)=>{
 
//  }
  return (
    // <div className="quick_link">
    //   <div className="container-fluid">
    //     <ul>
    //       <li><Link to="/CreateUserProfile"><FaPencilAlt /> Create Profile</Link></li>
    //       <li><Link to="/FindTalent"><FaSearch /> Hire Talent</Link></li>
    //       <li><Link to="/SearchJob"><FaSearch /> Search Job</Link></li>
    //       <li><Link to="/PostJob"><FaSuitcase /> Post Job</Link></li>
    //       <li><Link to="/JobList"><FaList /> Post Job List</Link></li>
    //       <li><Link to="/SearchAudition"><FaLocationArrow /> Audition Near Me</Link></li>
    //       <li><Link to="/CreateAudition"><FaFilm /> Post Audition</Link></li>
    //       <li><Link to="/AuditionDirectorSide"><FaList /> Post Audition List</Link></li>
    //       <li><Link to="/hiredContracts"><FaList /> Hired List</Link></li>
    //       <li><Link to="/MakeFilm"><FaUsers /> Make Team/Film <small>(For Director)</small></Link></li>

    //     </ul>
    //   </div>
    // </div>
    <>
    {/* {!showMenu ? <span className="toggle-icon" onClick={()=>openNav()}>&#9776;</span> :null} */}
    <div id="mySidenav" className="sidenav">
  <a href="javascript:void(0)" className="closebtn" onClick={()=>closeNav()}>&times;</a>
  <Link to="/CreateUserProfile" style={{marginTop:25}}><FaPencilAlt /> &nbsp;Create Profile</Link>
  <a href="javascript:void"   class="dropdown-btn" onClick={()=>{setActiveDropdown('dropdown-btn')}}> <span><FaSuitcase /> &nbsp;Job</span> 
    <FaCaretDown />
  </a>
  <div class="dropdown-container">
    <Link to="/PostJob"><FaSuitcase /> &nbsp;Post Job</Link>
    <Link to="/SearchJob"><FaSearch /> &nbsp;Search Job</Link>
    <Link to="/JobList"><FaList /> &nbsp;Post Job List</Link>
  </div>
  <a href="javascript:void" class="dropdown-btn" onClick={()=>{setActiveDropdown('dropdown-btn')}}><span><FaFilm /> &nbsp;Audition </span>
    <FaCaretDown />
  </a>
  <div class="dropdown-container">
  <Link to="/CreateAudition"><FaFilm /> &nbsp;Post Audition</Link>
  <Link to="/SearchAudition"><FaList /> &nbsp;Post Audition List</Link>
  <Link to="/SearchAudition"><FaLocationArrow /> &nbsp;Audition Near Me</Link>
  </div>
  <a href="javascript:void" class="dropdown-btn" onClick={()=>{setActiveDropdown('dropdown-btn')}}><span><FaSearch /> &nbsp;Hire Talent </span>
    <FaCaretDown />
  </a>
  <div class="dropdown-container">
  <Link to="/FindTalent"><FaSearch /> &nbsp;Hire Talent</Link>
  <Link to="/hiredContracts"><FaList /> &nbsp;Hired List</Link>
  </div>
   
  <Link to="/MakeFilm"><FaUsers /> &nbsp;Make Team/Film <small>(For Director)</small></Link>
</div>
</>
  )
}

export default SubHeader;