import React, { Component, useState } from 'react';
import BrandIntegrationsPage from "../../pages/BrandIntegrations/BrandIntegrationsPage";
import _ from 'lodash'

const BrandIntegrationsContainer  =() => {   
    const [step, setStep]=useState(1);
    const nextStep = (stepNumber) => {setStep(stepNumber ? stepNumber : step + 1)};

    const prevStep = () => {setStep(step-1)};
        
        return <BrandIntegrationsPage 
        nextStep={nextStep}
        prevStep={prevStep}
        step={step}
        />


}

export default BrandIntegrationsContainer;