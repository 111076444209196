import React from 'react';
import { useTranslation } from "react-i18next";

const ForgotPassForm = ({ handleChangeSingle, submitForm }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="logo"> <a href="/"><img src="images/dark_logo.png" alt="img" /></a> </div>
      <form onSubmit={submitForm} className="form-space">
        <div className="form_head_part">
          <h4>{t("ForgotPassPageTitle")}</h4>
          <p>{t("ForgotPassPageinfo")} </p>
        </div>
        <div className="form-group">
          <label>{t("ForgotPassLabel")}</label>
          <input type="email"
            className="form-control"
            name="emailId"
            onChange={handleChangeSingle}
            required
          />
        </div>
        <div className="clearfix">
          <button type="submit" className="blue_btn" >{t("SubmitBtn")}</button>
        </div>
      </form>
    </>
  )
}

export default ForgotPassForm;