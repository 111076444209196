import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import moment from 'moment';


const styles = StyleSheet.create({
  invoiceNoContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 36,
    padding: 3,
  },
  invoiceDate: {
    fontSize: "10px",
    fontStyle: "bold",
  },
  label: {
    width: 90,
    fontSize: "12px",
    fontFamily:'Helvetica'
  },
  section: {
    margin: 10,
    padding: 10,
  },
});

const InvoiceNo = ({ invoice }) => (
  <Fragment>
    <View
      style={{
        ...styles.invoiceNoContainer,
        marginTop: 18,
        flexDirection: "row",
      }}>
      <View style={{ width: "60%" }}>
        <View style={{ flex: 1, flexDirection:'row' }}>
          <Text style={styles.label}>Invoice No:</Text>
          <Text style={styles.invoiceDate}>{invoice.invoiceNo}</Text>
        </View>
        <View style={{ flex: 1,flexDirection:'row'  }}>
          <Text style={styles.label}>Transaction Id :</Text>
          <Text style={styles.invoiceDate}>{invoice.transactionId}</Text>
        </View>
      </View>
      <View style={{ width: "40%" }}>
        <View style={{flexDirection:'row' }}>
          <Text style={styles.label}>Date: </Text>
          <Text style={styles.invoiceDate}>{invoice.createdAt ? moment().format('DD/MM/YYYY HH:MM:SS') : ''}</Text>
        </View>
        <View style={{flexDirection:'row' }}>
          <Text style={styles.label}>Tnx Date: </Text>
          <Text style={styles.invoiceDate}>{invoice.transactionDate ? moment(invoice.transactionDate).format('DD/MM/YYYY HH:MM:SS') : ''}</Text>
        </View>
      </View>
    </View>
    {/* <View style={styles.invoiceNoContainer}>

    </View> */}
  </Fragment>
);

export default InvoiceNo;
