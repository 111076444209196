import React from 'react';
import { Link } from 'react-router-dom';
import AppliedUserList from './AppliedUserList';
import ShortlistAudition from './ShortlistAudition';

const AuditionApplication = (props) => {
    return (
        <div className="tab-pane" id="tab_default_3">
            <div className="inside_listing_tab">
                <ul className="nav nav-tabs inner_tab">
                    <li className="active"><Link to="#review_tab1" data-toggle="tab">All Applications  ({Number(props.AuditionDetail.shortListedUsers ?.length) || 0})</Link></li>
                    <li><Link to="#review_tab2" data-toggle="tab" >Shortlisted ({Number(props.AuditionDetail.shortListedUsers ?.length) || 0})</Link></li>
                </ul>

                <div className="tab-content">
                    <br />
                    <AppliedUserList {...props} />
                    <ShortlistAudition {...props} />
                </div>
            </div>
        </div>
    );
}
export default AuditionApplication;