import React from 'react';
import { Link } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight, FaHeart, FaSave } from 'react-icons/fa';
import _ from 'lodash';
import { config } from '../../actions/config';
import Pagination from '../Pagination/Pagination';
import { useState } from 'react';
import { useEffect } from 'react';

const SearchPeople = ({ onUserSearch, searchedData, sendInvitaion, saveUser, jobPostDetail,AuditionDetail, ...props }) =>{ 
    let pageSize = 8;

    const [newList, setNewList] = useState([])
    const [pageIndex, setPageIndex] = useState(1);

    
  useEffect(() => {
    if(searchedData){
      let d = searchedData.slice(0,pageSize)
      setNewList(d)
    }
    }, [searchedData])
    const pageChangeHandler = (index) => {
      console.log(index)
      const slicedList = searchedData.slice((index - 1) * pageSize, (index * pageSize))
      setNewList(slicedList)
      setPageIndex(index)
    
    }
    return(
    <div className="tab-pane active" id="list_tab1">
        <div className="row">

            {
                _.map(newList, val => (
                    <div className="col-md-3 col-sm-4 col-xs-6 full">
                        <div className="celebrity_detail text-center clearfix">
                            <div className="people_img">
                                <img src={val.profilePic ? config.FILE_URL+val.profilePic : 'images/fill_user.png'} alt="img" />
                                {/* <span className="online"><div className="circle_icon"></div> </span> */}
                            </div>
                            <div className="people_list">
                                <div className="people_list_info clearfix">

                                    <h6>{val ?.userName}</h6>
                                    <div className="people_short_info">
                                        <span>{val ?.physicalTraits ?.jobType}</span>
                                        <span>{val ?.cntLocation}</span>
                                    </div>
                                    {/* <span className="cele_price">Rs {val ?.additionalInfo ?.expectedCompensationAmount} / {val ?.additionalInfo ?.expectedCompensationType}</span> */}
                                </div>
                                <div className="end_info">
                                    {/* {AuditionDetail?.savedUsers?.map(e=>e._id).indexOf(val?._id) === -1 && <div onClick={() => saveUser(val._id)} className="ico"><FaSave /></div>} */}
                                   {AuditionDetail?.invites?.map(e=>e._id).indexOf(val?._id) === -1 ? <a style={{ cursor: "pointer" }} onClick={() => sendInvitaion(val._id)} className="stroke_btn">Invite</a>
                                    :<a className="stroke_btn">Invited</a>}
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }

        </div>
        <div class="pagination_part clearfix text-right">
            <br />
        <Pagination totalCount={searchedData?.length || 0} pageSize={pageSize} onPageChange={pageChangeHandler} currentPage={pageIndex} />
            {/* <ul class="pagination dark_pagi">
                <li><Link to="#"><FaAngleLeft /> Prev</Link></li>
                <li><Link to="#">1</Link></li>
                <li><Link to="#">2</Link></li>
                <li><Link to="#">3</Link></li>
                <li><Link to="#">4</Link></li>
                <li><Link to="#">5</Link></li>
                <li><Link to="#"> Next <FaAngleRight /></Link></li>
            </ul> */}
        </div>
    </div>

)}
export default SearchPeople;