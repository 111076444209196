/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import RazorpayButton from "../Razorpay/Razorpay";
import {Modal} from 'react-bootstrap';
import { Link } from "react-router-dom";
import { FaUserEdit } from "react-icons/fa";
import WalletPayButton from "./walletPayModal";
import QRCodeModal from "./qrCodeModal";


export const AmountBreackdown = ({paymentDescription,onPaymentDone,user,disablePaymentOption,milestoneIndex, ...props}) =>{
  console.log(props)
    const [show, setShow] = useState(false);
    const [showWalletModal, setShowWalletModal] = useState(false);
    const [showUPIModal, setShowUPIModal] = useState(false);
  const [amount, setAmount] = useState(0);
  const [paybleAmount, setPaybleAmount] = useState(0);
  const [gstDetails, setGstDetails] = useState({});
  const [commission, setCommission] = useState(0);
  const [loginUser, setLoginUser] = useState();
  const jobCategory =props?.jobCategory
  
    const handleClose = () => setShow(false);
    const handleCloseCnf = () => setShowWalletModal(false);
    const handleCloseUpi = () => setShowUPIModal(false);
    const handleShow = () => setShow(true);
    const showWalletPopup = () => {
      setShowWalletModal(true)
    };
    const showUpiPopup = () => {
      setShowUPIModal(true)
    };

  useEffect(() => {
    const userStr = localStorage.getItem("user");
    const user = userStr ? JSON.parse(userStr):null
    setLoginUser(user)
  }, [])
  
  useEffect(() => {
    // setAmount(props?.depositeType ==='whole' ? props.maxFixedPay :  props?.firstMilestoneAmount || 0)
    setAmount(props?.firstMilestoneAmount || 0)
  }, [props?.depositeType,props.maxFixedPay,props.firstMilestoneAmount])
  
  useEffect(() => {
    // console.log(jobCategory,loginUser?.invoiceInfo)
    if (jobCategory) {
      const gst = props.getGstDetails?.find(e =>e.item === jobCategory);
      if (user?.invoiceInfo?.state === loginUser?.invoiceInfo?.state) {
        setGstDetails({sgst:gst?.sgst,cgst:gst?.cgst,igst:0})
      }else
      setGstDetails({sgst:0,cgst:0,igst:gst?.igst})
    }
  }, [jobCategory])
  
  useEffect(() => {
   if (props?.commissionRate?.artistCommission) {
   setCommission(amount * props?.commissionRate.artistCommission/100)
   }
  }, [props?.commissionRate?.artistCommission,amount]);
  
  useEffect(() => {
   if (gstDetails && amount && commission) {
    let tax = gstDetails.igst? amount*gstDetails.igst/100 : (amount*gstDetails.sgst/100) + (amount*gstDetails.cgst/100)
    // console.log(amount,commission,tax)
    const gstOnComm = gstDetails.igst ? commission * gstDetails.igst /100: (commission * gstDetails.cgst/100) + (commission * gstDetails.sgst/100)
    setPaybleAmount(Math.ceil(Number(amount)+Number(commission) + Number(gstOnComm)+tax))
   }
  }, [gstDetails,amount, commission])
  
    return (
      <>
        <button disabled={disablePaymentOption}
          onClick={handleShow}
          className={`milestone-status ${
            props.maxFixedPay ? "green" : "razorpay-btn"
          }`}>
          {"Pay Now"}
        </button>
        <Modal show={show} onHide={handleClose} animation={false}  className="amount_modal">
          <Modal.Header closeButton >
            <Modal.Title>Update Status</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="add_info">
              <label className="add_head">
                <h6>Address details</h6>{" "}
                <div onClick={()=>{localStorage.setItem('profileStep',6)}}><Link to="/CreateUserProfile" ><FaUserEdit /></Link> </div>
              </label>
              {/* <hr/> */}
              <div className="row" style={{marginTop: 16}}>
                <div className="col-sm-12 col-md-6">
                <label style={{width:'100%'}}>
                <div style={{display:'flex',justifyContent:'space-between'}}>
                <div><strong>From</strong>{" "}</div>
              
                </div>
                <div>
               <span style={{fontWeight:500}}>Name: </span> <span style={{fontWeight:300}}>{loginUser?.invoiceInfo?.accountHolderName || " Yet to Submit"}</span><br/>
                   <span style={{fontWeight:500}}>Address: </span> <span style={{fontWeight:300}}>{loginUser?.address || " Yet to Submit"}</span> <br/>
                   <span style={{fontWeight:500}}>City: </span><span style={{fontWeight:300}}>{loginUser?.invoiceInfo?.city || " Yet to Submit"}</span><br/>
                   <span style={{fontWeight:500}}>State: </span><span style={{fontWeight:300}}>{loginUser?.invoiceInfo?.state || " Yet to Submit"}</span><br/>
                   <span style={{fontWeight:500}}>Country: </span><span style={{fontWeight:300}}>{loginUser?.invoiceInfo?.country || " Yet to Submit"}</span><br/>
                   <span style={{fontWeight:500}}>PAN: </span><span style={{fontWeight:300}}>{loginUser?.PAN || " Yet to Submit"}</span><br/>
                   <span style={{fontWeight:500}}>GST: </span><span style={{fontWeight:300}}>{loginUser?.GST || " Yet to Submit"}</span><br/>
                </div>
              </label>
                </div>
                <div className="col-sm-12 col-md-6">
                <label style={{width:'100%'}}>
                <strong>To</strong>{" "}
             
              <div>
               <span style={{fontWeight:500}}>Name: </span> <span style={{fontWeight:300}}>{user?.invoiceInfo?.accountHolderName || " Yet to Submit"}</span><br/>
                   <span style={{fontWeight:500}}>Address: </span> <span style={{fontWeight:300}}>{user?.address || " Yet to Submit"}</span> <br/>
                   <span style={{fontWeight:500}}>City: </span><span style={{fontWeight:300}}>{user?.invoiceInfo?.city || " Yet to Submit"}</span><br/>
                   <span style={{fontWeight:500}}>State: </span><span style={{fontWeight:300}}>{user?.invoiceInfo?.state || " Yet to Submit"}</span><br/>
                   <span style={{fontWeight:500}}>Country: </span><span style={{fontWeight:300}}>{user?.invoiceInfo?.country || " Yet to Submit"}</span><br/>
                   <span style={{fontWeight:500}}>PAN: </span><span style={{fontWeight:300}}>{user?.PAN || " Yet to Submit"}</span><br/>
                   <span style={{fontWeight:500}}>GST: </span><span style={{fontWeight:300}}>{user?.GST || " Yet to Submit"}</span><br/>
                </div>
                </label>
                </div>
              </div>
            </div>
            <hr/>
            <div className="skills_part">
              <label>
                <h6>Payment details</h6>{" "}
              </label>
              {/* <hr /> */}
              <div style={{ display: "flex", justifyContent: "space-between" ,marginTop: 10}}>
                <div className="item-label">Amount</div>
                <div> {amount}</div>
              </div>
              {/* {gstDetails.igst?
                  <div style={{display:'flex', justifyContent:'space-between',}}>
                  <div className="item-label">IGST ({gstDetails.igst}%)</div>
                 <div> {amount*gstDetails.igst/100}</div>
                </div>
                  :  */}
              <>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}>
                  <div className="item-label">
                    IGST ({gstDetails.igst || 0}%)
                  </div>
                  <div> {(amount * gstDetails.igst || 0) / 100}</div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}>
                  <div className="item-label">
                    SGST ({gstDetails.sgst || 0} %)
                  </div>
                  <div> {(amount * gstDetails.sgst || 0) / 100}</div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}>
                  <div className="item-label">
                    CGST ({gstDetails.cgst || 0} %)
                  </div>
                  <div> {(amount * gstDetails.cgst || 0) / 100}</div>
                </div>
              </>
              {/* } */}
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="item-label">
                  Commission ({props?.commissionRate?.artistCommission} %)
                </div>
                <div> {commission}</div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="item-label">
                  GST on Commission (
                  {"IGST " +
                    gstDetails.igst +
                    "%, CGST " +
                    gstDetails.cgst +
                    " % + SGST " +
                    gstDetails.sgst}
                  %)
                </div>
                <div>
                  {" "}
                  {gstDetails.igst
                    ? (commission * gstDetails.igst) / 100
                    : (commission * gstDetails.cgst) / 100 +
                      (commission * gstDetails.sgst) / 100}
                </div>
              </div>
              <hr />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="item-label">Total</div>
                <div> {paybleAmount}</div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div style={{display:'flex', justifyContent:'space-between'}}>
            {/* <RazorpayButton
              paybleAmount={paybleAmount}
              amountWithouTax={amount - (paybleAmount * 1 /100)- (amount * 1 /100) }
              contractId={props.contractId}
              description={paymentDescription}
              milestoneIndex={milestoneIndex}
              payeeId={user._id}
              onPaymentDone={(res) => {
                if (res) {
                  onPaymentDone(res);
                  handleClose();
                }
              }}
            /> */}
               <QRCodeModal
                paybleAmount={paybleAmount}
                amountWithouTax={amount - (paybleAmount * 1 /100)- (amount * 1 /100) }
                contractId={props.contractId}
                description={paymentDescription}
                payeeId={user._id}
                handleClose = {handleCloseUpi}
                handleShowModal = {showUpiPopup}
                showModal ={showUPIModal}
                disablePaymentOption={disablePaymentOption}
                milestoneIndex={milestoneIndex}
                onPaymentDone={(res) => {
              if (res) {
                onPaymentDone(res);
              }
            }}
            />
             <WalletPayButton
              paybleAmount={paybleAmount}
              amountWithouTax={amount - (paybleAmount * 1 /100)- (amount * 1 /100) }
              contractId={props.contractId}
              description={paymentDescription}
              payeeId={user._id}
              handleClose = {handleCloseCnf}
              handleShowModal = {showWalletPopup}
              showModal ={showWalletModal}
              walletBalance= {props.walletBalance || 0}
              disablePaymentOption={disablePaymentOption}
              milestoneIndex={milestoneIndex}
              onPaymentDone={(res) => {
                if (res) {
                  onPaymentDone(res);
                }
              }}
            />
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  };