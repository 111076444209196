import React  from "react";
import { useTranslation } from "react-i18next";
import {passingYear , HighestQualification , courses , specialization} from './registerConst';
import Select from 'react-select';

const AdditionalInfo = ({handleChangeDropDown , submitForm, handleChange , nextStep , prevStep ,...props}) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="setup-content" id="step-2">
        <div className="form-group">
          <label className="control-label">
            {t("SignUpHighestQualiLabel")}
          </label>
          <Select
              options={HighestQualification}
              //isMulti
              value = {props.highestQualification}
              onChange = {e => handleChangeDropDown({name : 'highestQualification' , value : e}) }
           />
          {/* <span style={{ color: "red" }}>
            {this.state.errors["highestQualification"]}
          </span> */}
        </div>
        {props.highestQualification === "Other" && (
          <div className="form-group">
            <input
              maxLength={100}
              type="text"
              className="form-control"
              name="otherHigherQualification"
              placeholder="Other"
              value={props.otherHigherQualification}
              onChange={handleChange}
            />
          </div>
        )}
        <div className="form-group">
          <label className="control-label">{t("SignUpCoursesLabel")}</label>
          <Select
              options={courses}
              isMulti
              value = {props.courses}
              onChange = {e => handleChangeDropDown({name : 'courses' , value : e}) }
           />
        </div>
        {props.courses === "Other" && (
          <div className="form-group">
            <input
              maxLength={100}
              type="text"
              className="form-control"
              placeholder="Other"
              name="otherCourses"
              value={props.otherCourses}
              onChange={handleChange}
            />
          </div>
        )}
        <div className="form-group">
          <label className="control-label">
            {t("SignUpSpecializationLabel")}
          </label>
          <Select
              options={specialization}
              isMulti
              value = {props.specialization}
              onChange = {e => handleChangeDropDown({name : 'specialization' , value : e}) }
           />
        </div>
        {props.sepcialization === "Other" && (
          <div className="form-group">
            <input
              maxLength={100}
              type="text"
              className="form-control"
              name="otherSepcialization"
              placeholder="Other"
              value={props.otherSepcialization}
              onChange={handleChange}
            />
          </div>
        )}
        <div className="form-group">
          <label className="control-label">{t("SignUpUniversityLabel")}</label>
          <input
            type="text"
            name="universityOrCollegeName"
            className="form-control"
            placeholder="Enter University/College"
            value={props.universityOrCollegeName}
            onChange={handleChange}
            required
          />
          {/* <span style={{ color: "red" }}>
            {this.state.errors["collegeUniversity"]}
          </span> */}
        </div>
        <div className="form-group">
          <label className="control-label">{t("SignUpPassingYrLabel")}</label>
          <Select
              options={passingYear}
              isMulti
              value = {props.passingYear}
              onChange = {e => handleChangeDropDown({name : 'passingYear' , value : e}) }
           />
        </div>
        <div className="form-group text-right">
          <a href="#">
            <i className="fa fa-plus" /> {t("SignupAddMoreEdu")}
          </a>
          <hr />
        </div>
        <div className="form-group">
          <label className="control-label">{t("SignUpSkillsLabel")}</label>
          <input
            name="skills"
            type="text"
            className="form-control"
            placeholder="Enter Skills"
            value={props.skills}
            onChange={handleChange}
          />
        </div>
        <div className="form-group upload_resume_field">
          <label htmlFor="upload_resume" className="control-label stroke_btn">
            Upload Resume
          </label>
          <input
            maxLength={10}
            type="file"
            className="form-control"
            id="upload_resume"
          />
          <small>&nbsp; {t("SignupUpload")}</small>
        </div>
        <div className="form-group">
          <label className="check_bx">
            <input
              type="checkbox"
              value={props.agreeTermsAndCondition}
              onChange={handleChange}
              name="agreeTermsAndCondition"
            />
            <span className="checkmark" /> {t("SignUpAgreementLabel")}
          </label>
        </div>

        <button
          className="btn btn-primary nextBtn btn-lg blue_btn"
          onClick={submitForm}
        >
          {t("SubmitBtn")}
        </button>
        <button
          className="btn btn-primary nextBtn btn-lg stroke_btn pull-right"
          onClick={prevStep}
        >
          {t("PrevBtn")}
        </button>
      </div>
    </div>
  );
}

export default AdditionalInfo;
