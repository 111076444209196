import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { FaMoneyBill, FaCalendarCheck, FaSuitcase, FaStarHalf, FaStar, FaPaperPlane, FaPaperclip, } from 'react-icons/fa';
import _ from 'lodash';

const UserApplicationJobDetail = ({ jobPostDetail, withDrawApplication,jobApplication,currentUser ,...rest}) => {
    const jobId = jobPostDetail ?.job || {} ;
console.log(jobApplication)
    return (
        <>
            <div className="shadow_bg clearfix jobdetail_tab">
                <div className="row">
                    <div className="col-md-9">
                        <h5><strong>Job details</strong></h5>
                        <hr />
                        <div className="job_cat">
                            <h5>{jobId.title}</h5>
                            <small>{moment(jobId.createdAt).format('ll')}</small>
                        </div>
                        <p>{jobId.description}</p>
                        <Link to={`/UserSideJob/${jobId._id}`} >View Job Posting</Link>
                        <hr />
                        <div className="job_list mid_job_info clearfix">
                            <ul>
                                <li>
                                    <div className="side_icon text-center">
                                        <FaMoneyBill />
                                    </div>
                                    <div className="job_info">
                                        <strong>{jobId.totalBudget || ' Yet to Submit'}</strong>
                                        <small>Fixed Price</small>
                                    </div>
                                </li>
                                <li>
                                    <div className="side_icon text-center">
                                        <FaSuitcase />
                                    </div>
                                    <div className="job_info">
                                        <strong>{jobId.experienceLevel || ' Yet to Submit'}</strong>
                                        {/* <small>I am looking for a mix of experience and value</small>  */}
                                    </div>
                                </li>
                                <li>
                                    <div className="side_icon text-center">
                                        <FaCalendarCheck />
                                    </div>
                                    <div className="job_info">
                                        <strong>{moment(jobId.createdAt).format('ll')}</strong>
                                        <small>Submitted Date</small>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <hr />
                        <div className="job_list skills_part">
                            <h6>Skills and expertise</h6>
                            {_.map(jobId.skills, val => (<span>{val}</span>))}
                        </div>
                        <hr />
                        <div className="job_list terms_part">
                            <h6>Your proposed terms</h6>
                            <ul>
                                <li>
                                    <p><strong>{jobId?.payType ||'Hourly'} Rate</strong></p>
                                    <p>Total amount the client will see on your proposal</p>
                                    <br />
                                    <p>Rs {jobApplication ?.hourlyBidRate || currentUser?.additionalInfo?.expectedCompensationAmount || 0}</p>
                                </li>
                                <li>
                                    <p><strong>You'll Receive</strong></p>
                                    <p>The estimated amount you'll receive after service fees</p>
                                    <br />
                                    <p>Rs {jobApplication?.hourlyBidRate ? (jobApplication?.hourlyBidRate - (jobApplication?.hourlyBidRate * 2 /100)) : currentUser?.additionalInfo?.expectedCompensationAmount ? (currentUser?.additionalInfo?.expectedCompensationAmount - (currentUser?.additionalInfo?.expectedCompensationAmount * 2 /100)) :0} {jobId?.payType || '/hr'}</p>
                                </li>
                                <li>
                                    <hr />
                                    <Link to="" className="default_btn" data-toggle="modal" data-target="#change_term">Change Terms</Link>
                                    <button onClick={()=>withDrawApplication(jobId._id)} className="stroke_btn" >Withdraw Application</button>
                                </li>
                            </ul>
                        </div>
                    </div>

                    {/* <div className="col-md-3 detail_sidebar">
                        <div className="client">
                            <h6>Client</h6>
                            <p>Abhishek Gupta</p>
                        </div>
                        <div className="about_client">
                            <h6>About the client</h6>
                            <strong>Payment method verified</strong>
                            <div className="rating">
                                <div className="star">
                                    <FaStar />
                                    <FaStar />
                                    <FaStar />
                                    <FaStar />
                                    <FaStarHalf />
                                </div>
                                <p>4.50 of 10 reviews</p>
                            </div>
                            <ul>
                                <li>
                                    <strong>Mumbai</strong>
                                    <p>04:37 am</p>
                                </li>
                                <li>
                                    <strong>45 jobs posted</strong>
                                    <p>43% hire rate, 4 open jobs</p>
                                    <li>
                                        <strong>Rs 500k+ total spent</strong>
                                    </li>
                                    <p>10 hires, 5 active</p>
                                </li>
                                <li>
                                    <strong>300/hr avg hourly rate paid</strong>
                                    <p>369 hours</p>
                                </li>
                            </ul>
                            <small>Member since Nov 4, 2020</small>
                        </div>

                    </div> */}
                </div>
            </div>
        </>
    );
}

export default UserApplicationJobDetail;