export const MeritFactor = [
    {
        title:'Award Received',
        progress: 0,
        key: 'awards',
        profileStep:5
    },
    // {
    //     title:'Local Darama / Pathnatya',
    //     progress: 0
    // }, 
    // {
    //     title:'Availability',
    //     progress: 70
    // }, 
    // {
    //     title:'Punctuality',
    //     progress: 80
    // }, 
    // {
    //     title:'Commitment',
    //     progress: 100
    // },
    // {
    //     title:'Background Check From Local Police',
    //     progress: 0
    // },
    {
        title:'Physical Attributes Updated',
        progress: 0,
        key: 'physicalTraits',
        profileStep:4,
    },
    {
        title:'Profile Pic, Charecter images',
        progress: 0,
        key: 'profilePic',
        profileStep:1
    },
    // {
    //     title:'Recommendations From Senior',
    //     progress: 0
    // },
    {
        title:'Relavant Education',
        progress: 0,
        key:'education',
        profileStep:2
    },
    // {
    //     title:'Verified Address',
    //     progress: 0
    // },
    {
        title:'Verified Mobile Number',
        progress: 0,
        key:'phoneNumber',
        profileStep:1
    },
    {
        title:'Work Video Link',
        progress: 0,
        key:'videoLink',
        profileStep:3
    },
    {
        title:'Tax/invoice Information',
        progress: 0,
        key:'taxInfo',
        profileStep:6
    },

]
