import _ from 'lodash';
import moment from 'moment';
import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { config } from '../../../actions/config';

class BigThumbnail extends Component {     
    state={
        // item: [
        //     {url:'images/slider2.jpg', title:'Horror-Thriller Feature Film', description:'Casting and seeking crew for an untitled horror-thriller film set primarily in a single location with a small cast shooting this summer in Queens'},
        //    {url:'images/movie_img3.jpg', title:'Untitled Pregnant Horror Film', description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. '},
        //    {url:'images/dummy_img.png', title:'Horror-Thriller Feature Film', description:'Casting and seeking crew for an untitled horror-thriller film set primarily in a single location with a small cast shooting this summer in Queens'},
        //    {url:'images/movie_img3.jpg', title:'Untitled Pregnant Horror Film', description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. '},
        // ]        
}

render(){
    // const {item} = this.state;
    const userStr = localStorage.getItem("user");
    const user = userStr ? JSON.parse(userStr):null
    return(
        <div className='flex-row'>  
             {_.map(this.props.data, (val, i) => (
           <div className="mid_area col-md-6" key={i+'dhuw'}>           
           <Link to="">
             <div className="side_movie_img">
               <img src={val.banner ? config.FILE_URL + val.banner : 'images/dummy_img.png'} alt="img" />
             </div>
           </Link>
           <div className="full_movie_detail">
           <div className='list_part'>
              {val?.roleType && val?.roleType?.length ? <span>Role : {val.roleType.map((rt)=>(<span>{ rt},</span>))}</span>:null}
               {val?.shootLocations && <span>Location : {val.shootLocations}</span>}
               <span>Posted : {moment(val.createdAt).format('MMM DD, YYYY')}</span>
              </div>           
             {val?.title && <Link to=""><h6>{val.title}</h6></Link>}
             <p>{val.characterDescription}</p>
             <Link to={user ? "/AuditionDetail/"+val._id: '/login'} className="link_clr">View Details</Link>
           </div>
         </div>
              ))            
             }        
        </div>
           
    )
}

}

export default BigThumbnail



          

