import React from "react";
import { Link } from "react-router-dom";
import { FaMoneyBill, FaCalendarCheck, FaSuitcase, FaStarHalf, FaStar } from "react-icons/fa";
import Footer from "../shared/footer/Footer";
import _ from "lodash";
import moment from "moment";
import { config } from "../../actions/config";

const UserSideAuditionDetail = ({ AuditionDetail }) => {
  return (
    <>
      <section id="postjob_detail" className="default_bg">
        <img src="images/make_film_bg.png" alt="img" className="bg_img" />
        <div className="container">

          <h3 className="main_head">Job Details</h3>

          <div className="row">

            <div className="col-md-9">
              <div className="shadow_bg clearfix">
                <h5><strong>{AuditionDetail.title}</strong></h5>
                <hr />
                <div className="job_cat">
                  <h6>Banner</h6>
                  <img src={config.FILE_URL+ AuditionDetail.banner} style={{height:200,borderRadius:10}}/>
                </div>
                <hr />
                <div className="job_cat">
                  <h6>Audition type : {AuditionDetail.auditionType}</h6>
                  <small>Posted on: {moment(AuditionDetail.createdAt).format('ll')}</small>
                </div>
                <hr />
                <div className="job_list job_des_info">
                  <ul>
                    <li>
                      <span>Type : {AuditionDetail.actorType ?.join()}</span>
                    </li>
                    <li>
                      <span>Location:</span> {AuditionDetail.shootLocations}
                    </li>
                    <li>
                      <span>Actor:</span> {AuditionDetail.actorType ?.join()}
                    </li>
                    <li>
                      <span>Film Industry:</span> {AuditionDetail.filmIndustry ?.join()}
                    </li>
                    <li>
                      <span>Age:</span> {AuditionDetail.minAge} - {AuditionDetail.maxAge}
                    </li>
                  </ul>
                </div>
                <p><strong>Role Type:</strong> {AuditionDetail.roleType ?.join()}</p>
                <p>{AuditionDetail.characterDescription}</p>
                <br />
                <p><strong>Audition Time:</strong> {AuditionDetail.auditionStartTime} - {AuditionDetail.auditionEndTime}</p>
                <p><strong>Address:</strong> {AuditionDetail.auditionAddress}</p>
                <hr />
                <div className="job_list mid_job_info clearfix">
                  <ul>
                    <li>
                      <div className="side_icon text-center">
                        <FaMoneyBill />
                      </div>
                      <div className="job_info">
                        <span><strong>{AuditionDetail.minProjectBudget} - {AuditionDetail.maxProjectBudget}</strong></span>
                        <small>Payout Type : {AuditionDetail.payType}</small>
                      </div>
                    </li>
                    <li>
                      <div className="side_icon text-center">
                        <FaSuitcase />
                      </div>
                      <div className="job_info">
                        <span><strong> {AuditionDetail.minExperience} year - {AuditionDetail.maxExperience} year</strong></span>
                        <small>Experience</small>
                      </div>
                    </li>
                    <li>
                      <div className="side_icon text-center">
                        <FaCalendarCheck />
                      </div>
                      <div className="job_info">
                        <span><strong>{moment(AuditionDetail.auditionStartDate).format('ll')} - {moment(AuditionDetail.auditionEndDate).format('ll')}</strong></span>
                        <small>Audition Date</small>
                      </div>
                    </li>
                  </ul>
                </div>
                <hr />
                <div class="row">
                  <div className="job_list job_list_info col-md-6">
                    <h6>Physical Traits</h6>
                    <ul>
                      <li>
                        <span>Height:</span>
                        {AuditionDetail.minHeightFeet} feet {AuditionDetail.minHeightInch} Inch
                        to {AuditionDetail.maxHeightFeet} feet {AuditionDetail.maxHeightInch} Inch
                      </li>
                      <li>
                        <span>Weight:</span>
                        {AuditionDetail.minWeight} to  {AuditionDetail.maxWeight}
                      </li>
                      <li>
                        <span>Physique: {AuditionDetail.physique}</span>
                      </li>
                      <li>
                        <span>Unique Traits: {AuditionDetail.uniqueTraits}</span>
                      </li>
                      <li>
                        <span>Must Have: {AuditionDetail.mustHave}</span>
                      </li>
                      <li>
                        <span>Mandatory Conditions: {AuditionDetail.mandatoryConditions}</span>
                      </li>
                      <li>
                        <span>Appearance or D/C: {AuditionDetail.appearance}</span>
                      </li>
                    </ul>
                  </div>
                  <div className="job_list job_list_info col-md-6">
                    <h6>Other Information</h6>
                    <ul>
                      <li>
                        <span>Shoot Dates: {moment(AuditionDetail.shootStartDate).format('ll')} to {moment(AuditionDetail.shootEndDate).format('ll')}</span>
                      </li>
                      <li>
                        <span>Specific Role Criteria: {AuditionDetail.specificRoleCriteria}</span>
                      </li>
                      {/* <li>
                        <span>Competitor Brands (if any): {AuditionDetail.competitorBrands}</span>
                      </li> */}
                      <li>
                        <span>Shoot Locations: {AuditionDetail.shootLocations}</span>
                      </li>
                      <li>
                        <span>Passport:</span> {AuditionDetail.passportRequired ? "Required" : "Not Required"}
                      </li>
                    </ul>
                  </div>
                </div>
                <hr />
                <div className="job_list">
                  <h6>Organiser Details</h6>
                  <ul>
                    <li><strong>Name: {AuditionDetail.organiserName}</strong></li>
                    <li><strong>Disignation: {AuditionDetail.designation}</strong> </li>
                    <li><strong>Agency Name: {AuditionDetail.companyName}</strong> </li>
                    <li><strong>Production House: {AuditionDetail.productionHouse}</strong> </li>
                    <li><strong>Email Id: {AuditionDetail.emaiId}</strong> </li>
                    <li><strong>Contact Number: {AuditionDetail.contactNumber}</strong> </li>
                    <li><strong>WhatsApp: {AuditionDetail.whatsappNumber}</strong> </li>
                  </ul>
                </div>
                <hr />
                <div class="row">
                  <div className="job_list col-md-6">
                    <h6>Other Details</h6>
                    <ul>
                      <li><strong>Actor Introduction Video Needed: {AuditionDetail.introductionVideoNeeded ? "Needed" : "Not needed"}</strong></li>
                      <li><strong>Preferred Language: {AuditionDetail.languagesSpeak ?.join()}</strong> </li>
                      <li><strong>Project Budget: {AuditionDetail.maxProjectBudget}</strong> </li>
                      <li><strong>Allow Social Media Sharing: {AuditionDetail.allowSocialSharing ? "Allowed" : "Not Allowed"}</strong> </li>

                    </ul>
                  </div>

                  <div className="job_list col-md-6">
                    {/* <h6>Job Status</h6>
                    <ul>
                      <li><strong>Applied Users:</strong>0</li>
                      <li><strong>Interviewing:</strong> 0</li>
                      <li><strong>Invite Sent:</strong> 0</li>
                      <li><strong>Unanswered Invites:</strong> 0</li>
                    </ul> */}
                  </div>
                </div>
                <hr />
                <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                <button style={{margin:0}} onClick={()=>window.history.back()} class="stroke_btn ">
                    Back
                  </button>
                {
                  AuditionDetail._id && <Link to={`/ApplyAudition/${AuditionDetail._id}`} class="blue_btn ">
                    Submit Application
                  </Link>
                }
                </div>

              </div>
            </div>
            <div className="col-md-3">
              <div className="detail_sidebar">
                <div className="apply_job">
                  {AuditionDetail._id && <Link to={`/ApplyAudition/${AuditionDetail._id}`} class="blue_btn pull-right">
                    Submit Application
                  </Link>}
                  <p>Required Connects to submit Application:  Yet to Submit</p>
                  <p>Available Connects:  Yet to Submit</p>
                </div>
                {/* <div className="about_client">
                  <h6>About the client</h6>
                  <strong>Payment method verified</strong>
                  <div className="rating">
                    <div className="star">
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStarHalf />
                    </div>
                    <p>4.50 of 10 reviews</p>
                  </div>
                  <ul>
                    <li>
                      <strong>Mumbai</strong>
                      <p>04:37 am</p>
                    </li>
                    <li>
                      <strong>45 jobs posted</strong>
                      <p>43% hire rate, 4 open jobs</p>
                    </li>
                    <li>
                      <strong>Rs 500k+ total spent</strong>
                      <p>10 hires, 5 active</p>
                    </li>
                    <li>
                      <strong>300/hr avg hourly rate paid</strong>
                      <p>369 hours</p>
                    </li>
                  </ul>
                  <small>Member since Nov 4, 2020</small>
                </div>
                <div className="job_link">
                  <h6>Job link</h6>
                  <input
                    type="text"
                    readOnly="readonly"
                    disabled="disabled"
                    class="form-control"
                  />
                  <Link to="">Copy link</Link>
                </div> */}
              </div>

            </div>


          </div>
        </div>
      </section>
      {/* <Footer /> */}
    </>
  );
};

export default UserSideAuditionDetail;
