import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaSearch, FaAngleLeft, FaAngleRight, FaAngleDown, FaAngleUp, FaFileInvoice, FaSort, } from 'react-icons/fa';
import _, { orderBy, sortBy } from 'lodash';
import moment from 'moment';
// import RazorpayButton from '../Razorpay/Razorpay';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { Button,Dropdown,DropdownButton,Modal } from 'react-bootstrap';
import Table from 'react-bootstrap/Table'
import { useSearchFilter } from '../../hooks/useSearchFilter';
import Pagination from '../Pagination/Pagination';
import MyFunds from '../../pages/UserFunds/MyFunds';
import ArtistInvoiceContainer from '../../Container/ArtistInvoice/ArtistInvoiceContainer';
import AggregatorInvoiceToMMContainer from '../../Container/AggregatorInvoiceToMM/AggregatorInvoiceToMMContainer';
import AggregatorInvoiceToArtistContainer from '../../Container/AggregatorInvoiceToArtist/AggregatorInvoiceToArtistContainer';

const UserWalletList = ({ data,currentUser, ...rest}) => {
let pageSize = 10;
  // const [activeChildId, setActiveChildId] = useState('');
// const [tnxId, setTnxId] = useState('tnx2022-327432665X');
const [pageIndex, setPageIndex] = useState(1);
const [searchKey, setSearchKey] = useState()
const [Listdata, setListdata] = useState([])
const [tnxId, setTnxId] = useState('xxx')
const [isSorted, setIsSorted] = useState(false)
const searchResult = useSearchFilter(data || [], searchKey?.trim());
//  let tnxList = [];
    useEffect(() =>{
      
      if (searchResult && (searchKey?.length || searchKey === '')) {
        const slicedList = searchKey && searchKey.length ? searchResult.slice(0,  pageSize) :searchResult.slice((pageIndex - 1) * pageSize, (pageIndex * pageSize))
        setListdata(slicedList)
      }
    }, [searchResult, searchKey])

useEffect(() => {
 if (data) {
  const slicedList = data.slice((pageIndex - 1) * pageSize, (pageIndex * pageSize))
  setListdata(slicedList)
 }
}, [data])

const pageChangeHandler = (index) => {
  console.log(index)
  const slicedList = data.slice((index - 1) * pageSize, (index * pageSize))
  setListdata(slicedList)
  setPageIndex(index)

}

const sortList =(dataType, key)=>{
  
  let sorted =[]
  let order = isSorted ? 'desc': 'asc';
  if(key !== 'txType'){
    sorted = orderBy(data, [key], [order])
  }else{
    let d = data;
    d.forEach(element => {
      currentUser?._id === element.payee ? element['txType'] = 'Credit': element['txType'] ='Debit'
    });
    sorted = orderBy(d, [key],  [order])
  }
  setListdata(sorted)
  setIsSorted(!isSorted);

  // switch (dataType) {
  //   case 'string':
  //     sorted = orderBy(data, [key], [order])
  //   console.log(sorted)
  //     setListdata(sorted)
  //     isSorted = !isSorted;
  //     break;
  //     case 'number':
  //       sorted = data.sort((a,b)=> isSorted ? b[key]-a[key] : a[key]-b[key]);
  //       setListdata(sorted);
  //       isSorted =  !isSorted;
  //       break;
  //       case 'date':
  //         sorted = data.sort((a,b)=> isSorted ? (new Date(b[key]).getTime() - new Date(a[key]).getTime()): (new Date(a[key]).getTime() - new Date(b[key]).getTime()));
  //         setListdata(sorted)
  //         isSorted =  !isSorted;
  //         break;
  //   default:
  //     break;
  // }
} 
  return (
    <>
    <div className="col-xs-12">
    <MyFunds />
    </div>
    <div className="col-xs-12 search_job_list">
      <div className="inside_listing_tab shadow_bg">
       
        <div className="tab-content">
          <div className="tab-pane active" id="list_tab1">

          <div className="form-group search_field">
              <input 
                type="search"
                placeholder="Search By Order, Transaction_Type "
                className="form-control"
                onChange={(e) => setSearchKey(e.target.value)}
              />
              <button type="button" className="default_btn">
                <FaSearch />
              </button>
            </div>
            <div className='table-responsive'>
            <Table>
              <thead>
                <tr>
                  {/* <th>Project Name</th> */}
                  <th>Transaction ID <FaSort style={{cursor:'pointer'}} onClick={()=> sortList('string','transactionId')}/></th>
                  <th>Sender/Receiver <FaSort style={{cursor:'pointer'}} onClick={()=> sortList('string','otherPartyName')}/></th>
                  {/* <th>Sender</th>
                  <th>Receiver</th> */}
                  <th>Debit/Credit <FaSort style={{cursor:'pointer'}} onClick={()=> sortList('string', 'txType')}/></th>
                  <th>Payment Mode <FaSort style={{cursor:'pointer'}} onClick={()=> sortList('string','paymentMethod')}/></th>
                  <th>Transaction Date <FaSort style={{cursor:'pointer'}} onClick={()=> sortList('date', 'createdAt')}/></th>
                  <th>Amount <FaSort style={{cursor:'pointer'}} onClick={()=> sortList('number','amount')}/></th>
                  <th>Status <FaSort style={{cursor:'pointer'}} onClick={()=> sortList('string', 'status')}/></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {Listdata.map((t, i) => {
                  return (
                    <tr key={i+'shdgs'}>
                      {/* <td>{t.description}</td> */}
                      <td>{t.transactionId}</td>
                      <td>{t.otherPartyName}</td>
                      <td style={{color :currentUser?._id === t.payee ?'#06c20a': "#dc1515"}}>{currentUser?._id === t.payee ? 'Credit':'Debit'}</td>
                      <td>{t.paymentMethod}</td>
                      <td>{moment(t.createdAt).format('MMM DD YYYY, hh : mm a')}</td>
                      <td>{currentUser?._id === t.payee ? t.amountWithouTax : t.amount}</td>
                      <td style={{color : t.status ?'#06c20a': "#dc9815"}}>{t.status ? "Success" : "pending"}</td>
                      <td> {t.transactionId && (currentUser?._id === t.payee ? t.amountWithouTax : t.amount) && <DropdownButton id="dropdown-item-button" title="Invoices" onClick={()=>setTnxId(t.transactionId)} style={{width:'100%'}}>
                                 <Dropdown.Item as="button" style={{background:'none',border:'none'}}>
                                  <ArtistInvoiceContainer
                                    transactionId={tnxId}
                                  /> 
                                  </Dropdown.Item>

                                   {currentUser?._id === t.payer && <Dropdown.Item as="button" style={{background:'none',border:'none'}}>
                                  <AggregatorInvoiceToMMContainer
                                    transactionId={tnxId}
                                  />
                                   </Dropdown.Item>}
                                   {currentUser?._id === t.payee &&<Dropdown.Item as="button" style={{background:'none',border:'none'}}>
                                  <AggregatorInvoiceToArtistContainer
                                    transactionId={tnxId}
                                  />
                                  </Dropdown.Item>}
                                </DropdownButton>}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            </div>
            {/* <ul>
              {Listdata.map((t)=>{
                return(
                  <li>
                    <div style={{display: 'flex', justifyContent:'space-between'}}>
                      <div></div>
                    </div>
                  </li>
                )
              })
              }
            </ul> */}
            <Pagination
              totalCount={data?.length || 0}
              currentPage={searchKey && searchKey.length > 0 ? 1 : pageIndex}
              onPageChange={(p) => {
                pageChangeHandler(p);
              }}
              pageSize={pageSize}
              key={"P1" + Date.now.toString()}
            />
          </div>
        </div>
      </div>
    </div>
    </>
  );
}


export default UserWalletList;
