import React from "react";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import ClientsideApplicationDetail from "../../components/ClientSideApplication/ClientsideApplicationDetail";
import UserTopHeader from "../../components/ClientSideApplication/UserTopHeader";

const ClientSideUserApplicationPage = (props) => {
  return (
    <>
      <Header {...props}/>      
      <SubHeader />
      <section id="postjob_detail" className="default_bg">
        <img src="images/make_film_bg.png" alt="img" className="bg_img" />
        <div className="container">
          <UserTopHeader {...props}/>
          <div className="row">
            <ClientsideApplicationDetail {...props} />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ClientSideUserApplicationPage;
