import React, { useState } from "react";
import Cropper from "react-easy-crop";

function ImageCropper({ image, onCropDone, onCropCancel }) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState(null);
  const [aspectRatio, setAspectRatio] = useState(4 / 3);

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const onAspectRatioChange = (event) => {
    setAspectRatio(event.target.value);
  };
   const onDone = (imgCroppedArea) => {
    const canvasEle = document.createElement("canvas");
    canvasEle.width = imgCroppedArea.width;
    canvasEle.height = imgCroppedArea.height;

    const context = canvasEle.getContext("2d");

    let imageObj1 = new Image();
    imageObj1.src = image;
    imageObj1.onload = function () {
      context.drawImage(
        imageObj1,
        imgCroppedArea.x,
        imgCroppedArea.y,
        imgCroppedArea.width,
        imgCroppedArea.height,
        0,
        0,
        imgCroppedArea.width,
        imgCroppedArea.height
      );

      const dataURL = canvasEle.toDataURL("image/jpeg");

    //   console.log(dataURL);
      onCropDone(dataURLtoFile(dataURL))
    };
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
}
  return (
    <div className="cropper">
      <div>
        <Cropper
          image={image}
          aspect={aspectRatio}
          crop={crop}
          zoom={zoom}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropComplete={onCropComplete}
          style={{
            containerStyle: {
              width: "100%",
              height: "77%",
              backgroundColor: "#fff",
            },
          }}
        />
      </div>

      <div className="action-btns">
        <div className="aspect-ratios" onChange={onAspectRatioChange}>
          <div className="input-radio"><input type="radio" value={1 / 1} name="ratio" /> 1:1</div>
          <div className="input-radio"><input type="radio" value={5 / 4} name="ratio" /> 5:4</div>
          <div className="input-radio"><input type="radio" value={4 / 3} name="ratio" /> 4:3</div>
          <div className="input-radio"><input type="radio" value={3 / 2} name="ratio" /> 3:2</div>
          <div className="input-radio"><input type="radio" value={5 / 3} name="ratio" /> 5:3</div>
          <div className="input-radio"><input type="radio" value={16 / 9} name="ratio" /> 16:9</div>
          <div className="input-radio"><input type="radio" value={3 / 1} name="ratio" /> 3:1</div>
        </div>

        <button className="can_btn" onClick={onCropCancel}>
          Cancel
        </button>

        <button
          className="ok_btn"
          onClick={() => {
            onDone(croppedArea);
          }}
        >
          Done
        </button>
      </div>
    </div>
  );
}

export default ImageCropper;
