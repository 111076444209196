import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FaPencilAlt, FaTimes, FaRecycle, FaEyeSlash, FaUser } from 'react-icons/fa';

const JobDetailSidebar = ({ jobPostDetail, removePost }) => (
    <div className="col-md-3 detail_sidebar text-center">
        <ul>
            <li><Link to={{ pathname: "/PostJob", state: { id: jobPostDetail._id } }} className="stroke_btn"><FaPencilAlt /> Edit Posting</Link></li>
            <li onClick={removePost}><a href="javascript:void" className="stroke_btn"><FaTimes /> Remove Posting</a></li>
            <li><Link to={{ pathname: "/PostJob", state: { id: jobPostDetail._id, new: true } }} className="stroke_btn"><FaRecycle /> Reuse Posting</Link></li>
            {/* <li><Link to="/" className="stroke_btn"><FaEyeSlash /> Make Private</Link></li>
            <li><Link to="/" className="stroke_btn"><FaUser /> Applied Users</Link></li> */}
        </ul>
    </div>
);
export default JobDetailSidebar;