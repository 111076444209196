import React from "react";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import { Link } from 'react-router-dom';

const InfluencerServicesPage = (props) => (
  <>
    <Header />
    <SubHeader />
   {/* CTA section start*/}
   <section id="cta" className="mid_info dark_clr inner_banner">
          <div className="container side_space">
          <div class="home_grey_bx clearfix"><img src="images/hire.png" alt="img" class="right_pattern" />
            <div className="row">   
            <div className="col-md-9 cta_info">
            <h2>Need to hire talent for your next project?</h2>
            <p>Access the largest network of Producers, Directors, Writers, Artists, models, freelancers, and crew members to find the perfect creative talent for your next project.</p>
                 </div>
            </div>
            </div>
          </div>
      </section>
      {/* CTA section ends */}

      <section id="services_pages" className="dark_clr">
        <div className="container">
          <div className="row">
            <div className="col-md-12 head_part">
              <h2>Influencer Services</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-sm-6 col-xs-6 full">
              <div className="bx_bg">
              <Link to="/InfluencerMarketingCompany">
              <img src="images/influencer-marketing-agency.webp" alt="img" />
              <h6>Influencer Marketing</h6></Link>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-6 full">
              <div className="bx_bg">
              <Link to="/InfluencerAdNetwork">
              <img src="images/influencer-ad-network.webp" alt="img" />
              <h6>Influencer Ad Network</h6></Link>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-6 full">
              <div className="bx_bg">
              <Link to="/DigitalInfluencerAgency">
              <img src="images/digital-influencer-agency.webp" alt="img" />
              <h6>Digital Influencer</h6></Link>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-6 full">
              <div className="bx_bg">
              <Link to="/TechInfluencerMarketing">
              <img src="images/tech-influencer-marketing.webp" alt="img" />
              <h6>Technology</h6></Link>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-6 full">
              <div className="bx_bg">
              <Link to="/SportsFitnessInfluencerMarketing">
              <img src="images/fitness-influencer-marketing.webp" alt="img" />
              <h6>Sports & Fitness</h6></Link>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-6 full">
              <div className="bx_bg">
              <Link to="/FashionInfluencerMarketing">
              <img src="images/fashion-influencer-marketing.webp" alt="img" />
              <h6>Fashion</h6></Link>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-6 full">
              <div className="bx_bg">
              <Link to="/AutomotiveInfluencerCompany">
              <img src="images/automotive-influencer.webp" alt="img" />
              <h6>Automotive</h6></Link>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-6 full">
              <div className="bx_bg">
              <Link to="/EntertainmenInfluencerMarketing">
              <img src="images/entertainment-influencer-marketing.webp" alt="img" />
              <h6>Movies, Movies & Games</h6></Link>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-6 full">
              <div className="bx_bg">
              <Link to="/HomeApplianceInfluencerMarketing">
              <img src="images/home-appliances-influencer-marketing.webp" alt="img" />
              <h6>Home Appliances</h6></Link>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-6 full">
              <div className="bx_bg">
              <Link to="/AudioBooksInfluencerMarketing">
              <img src="images/books-audio-books-influencer-marketing.webp" alt="img" />
              <h6>Books & AudioBooks</h6></Link>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-6 full">
              <div className="bx_bg">
              <Link to="/BeautyHealthInfluencerMarketing">
              <img src="images/beauty-health-influencer-marketing.webp" alt="img" />
              <h6>Beauty & Health</h6></Link>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-6 full">
              <div className="bx_bg">
              <Link to="/KitchenAppliancesInfluencerMarketing">
              <img src="images/kitchen-appliances-influencer-marketing.webp" alt="img" />
              <h6>Kitchen Appliances</h6></Link>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-6 full">
              <div className="bx_bg">
              <Link to="/BabyProductsInfluencerMarketing">
              <img src="images/toys-baby-products-influencer-marketing.webp" alt="img" />
              <h6>Toys, Baby Products & Kids Fashion</h6></Link>
              </div>
            </div>
           
          </div>
        </div>
      </section>

    <Footer />
  </>
);

export default InfluencerServicesPage;
